import React, {useEffect, useState} from "react";

import {getByUrl} from "../../../../../../../../../../v3MethodsService";

import {OverlayPanel} from "primereact/overlaypanel";
import {Divider} from "primereact/divider";

import './style.scss'

const GroupToGroup = ({ stage, data, updateData, selectGroup, groupOp, renameGroups, isFinished }) => {

    const [groups, setGroups] = useState([])

    const places = data && data.rules ? data.rules : []
    const placesByGroup = data && data.groups ? data.groups.map(r => r.rules ? r.rules : []) : []

    useEffect(() => {
        if (stage && stage._id) {
            getByUrl(`groups?stageId=${stage._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setGroups(resp.data.filter(r => r.teams?.length > 0))
                    }
                })
        }
    }, [stage]);

    const updateSelected = (i, g, gr) => {
        if (places.find(p => p.groupId === gr._id && p.place === i)) {
            if (checkAvailability(i, g, gr._id) && !isFinished) {
                const newRules = data.rules.filter(p => !(p.groupId === gr._id && p.place === i))
                const newGroups = [...data.groups]
                newGroups[g].rules = newGroups[g]?.rules.filter(p => !(p.groupId === gr._id && p.place === i))
                updateData({...data, rules: newRules, groups: newGroups})
                return
            }
            return
        }
        const newRules = data?.rules ? [...data.rules] : []
        newRules.push({ groupId: gr._id, groupName: gr.name, place: i })
        const newGroups = [...data.groups]
        if (newGroups[g]?.rules) {
            newGroups[g]?.rules.push({ groupId: gr._id, groupName: gr.name, place: i })
        } else {
            newGroups[g].rules = []
            newGroups[g].rules.push({ groupId: gr._id, groupName: gr.name, place: i })
        }
        updateData({...data, rules: newRules, groups: newGroups})
    }

    const checkAvailability = (num, idx, grId) => placesByGroup.findIndex(g => g.find(p => p.place === num && p.groupId === grId)) === idx

    return (
        <div className='group-to-group circle-to-group'>
            <div className='circle-to-group__header'>Выберите команды по итогам предыдущей стадии</div>
            {data?.groups?.map((g, idx) => (
                <div key={idx} className='circle-to-group__container'>
                    <div className='circle-to-group__label' onClick={(e) => (idx === 0 ? groupOp.current.toggle(e) : null)}>
                        Группа {selectGroup === "alphabetic" ? String.fromCharCode(65 + idx) : idx + 1} {idx === 0 && <p className="pi pi-chevron-down" style={{margin: 0}}></p>}
                    </div>
                    <div className='group-to-circle__content'>
                        {groups?.map((gr, i_g) => [
                            <div key={i_g} className='group-to-circle__container'>
                                <div className='group-to-circle__group-label'>{gr.name}</div>
                                <div className='circle-to-circle__content'>
                                    {gr.teams.map((_, i) => (
                                        <div key={i} onClick={() => updateSelected(i + 1, idx, gr)} className={`group-to-circle__team circle-to-circle__team ${placesByGroup[idx]?.find(p => p.groupId === gr._id && p.place === i + 1) ? 'circle-to-circle__team_selected' : ''} ${places?.find(p => p.groupId === gr._id && p.place === i + 1) && !checkAvailability(i + 1, idx, gr._id) ? 'circle-to-circle__team_disabled' : ''}`}>
                                            {i + 1} <span>место</span>
                                        </div>
                                    ))}
                                </div>
                            </div>,
                            i_g !== groups?.length - 1 && <Divider key={`divider-${i_g}`} />
                        ])}
                    </div>
                </div>
            ))}
            <OverlayPanel ref={groupOp} style={{maxWidth: "363px"}}>
                <div className="group-structure__overlay">
                    <span className="group-structure__item-change" onClick={(e) => renameGroups("alphabetic")}>Буквенная</span>
                    <span className="group-structure__item-change" onClick={(e) => renameGroups("digital")}>Цифровая</span>
                </div>
            </OverlayPanel>
        </div>
    )
}

export default GroupToGroup