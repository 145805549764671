import React, { useContext, useEffect, useRef, useState } from "react";

import { useClickOutside } from "../../../../../../utils";
import {ENDPOINT} from "../../../../../../env";
import axios from "axios";
import service from "../../../../../Tournaments/Disqualifications/service";
import moment from "moment/moment";

import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import {InputText} from "primereact/inputtext";
import {InputSwitch} from "primereact/inputswitch";

import "./style.scss";

const SideBar = ({ visible, setVisible, state, toast, deleteHandler }) => {
    const ref = useRef();
    useClickOutside(visible, ref, () => setVisible(false));

    const [data, setData] = useState({});
    const [debQuery, setDebQuery] = useState('');
    const [players, setPlayers] = useState([]);
    const [progress, setProgress] = useState(false)
    const [isChanged, setIsChanged] = useState(false);
    const isQtType = data?.count ? true : !data?.finishDate

    useEffect(() => {
        setData(state)
        setPlayers([])
        setDebQuery('')
        setIsChanged(false)
    }, [state]);

    const getPlayers = (playerInfo) => {
        setPlayers([])
        const plrsQuery = `${ENDPOINT}v2/suggestPlayer?query=${playerInfo}&disqual=1${'&tournamentId='+data.tournamentId}`
        axios.get(plrsQuery, options)
            .then( _feed => {
                const mapd = _feed.data.map(p => ({...p, fio: `${p.surname} ${p.name}${p.teams && p.teams.length ? ' ('+p.teams[0].name+')': ''}`}));
                setPlayers(mapd)
                setProgress(false)
            })
            .catch(err => { setProgress(false); console.log('get plrs list faild', err) })
    }

    const waitingEndWrite = (str) => {
        if (!progress) {
            //console.log('waitingEndWrite', str);
            setProgress(true);
            getPlayers(str)
        }
    }

    useEffect(() => {
        if (debQuery && debQuery.length > 3) {
            waitingEndWrite(debQuery)
        }
    }, [debQuery])

    const exitHandler = () => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этой дисквалификации",
            header: "Удалить дисквалификацию?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            draggable: false,
            accept: () => {
                setVisible(false)
                deleteHandler(null, data)
            },
            reject: () => {},
        });
    };

    const handleSave = async () => {
        if (!data._id) {
            service.sendDisq(data, toast)
                .then(resp => {
                    if (resp) {
                        setVisible(false)
                    }
                })
            return
        }
        const patch = getPatch(data, state);
        if (Object.keys(patch).length > 1) {
            service.sendDisq(patch, toast)
                .then(resp => {
                    if (resp) {
                        setVisible(false)
                    }
                })
        } else{
            console.log('not have change', patch);
        }

    };

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
            setIsChanged(true)
        } else {
            setData(prev => ({ ...prev, [key]: val }));
            setIsChanged(true)
        }
    };

    const disabledBtn = isChanged && (data?._id ? data?.player?.fio : data?.squad)


    return (
        !!visible && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                    <div className="side-profile__header">
                        <div className="side-profile__btns">
                            <Button label="Сохранить" className="side-profile__button-save auth2__button" onClick={handleSave} disabled={!disabledBtn}/>
                            {data?._id && <Button label="Удалить" className="side-profile__button-save side-profile__button-delete auth2__button auth2__button_outlined" onClick={exitHandler} />}
                        </div>
                        <i className="side-profile__icon-close pi pi-times" onClick={() => setVisible(false)} />
                    </div>
                    <div className="side-profile__body">
                        <div className="side-profile__title">{data?._id ? 'Редактировать' : 'Создать'} дисквалификацию</div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Дисквалифицированный игрок*</label>
                            <span className="p-input-icon-right">
                                {progress ? <i className="pi pi-spin pi-spinner"/> : null}
                                <InputText value={data?._id ? data?.player?.fio : debQuery} onChange={e => setDebQuery(e.target.value)} placeholder="Введите ФИО" disabled={data?._id} style={{width: '100%'}}/>
                            </span>
                            {players?.length > 0 && <Dropdown value={data.player} onChange={e => {setData(prev => ({...prev, player: e.target.value, playerId: e.target.value?._id})); setIsChanged(true)}} options={players} placeholder="-- выберите игрока" appendTo="self" emptyMessage="Ничего не найдено" optionLabel="fio"/>}
                            {data?.player?.squads?.actived?.length > 0 && <Dropdown id='squads' onChange={e => {setData(prev => ({...prev, squad: e.target.value, squadId: e.target.value?.squadId})); setIsChanged(true)}} value={data?.squad} options={data?.player?.squads?.actived} placeholder='для всех заявок' optionLabel="team.name" appendTo="self"/>}
                        </div>
                        <div className="clubs-profile__container border">
                            <label className="clubs-profile__label">Комментарий</label>
                            <InputTextarea value={data?.comment || ''} onChange={e => updateData(e.target.value, 'comment')} placeholder="Опишите причину"/>
                        </div>
                        <div className="side-profile__title">Тип дисквалификации</div>
                        <div className="side-profile__it">
                            {isQtType ? (
                                <p className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>количество матчей</span>
                                    <InputNumber
                                        inputId="count"
                                        value={data?.count}
                                        onValueChange={e => updateData( e.target.value ? isNaN(parseInt(e.target.value)) ? 0 : parseInt(e.target.value) : e.target.value, 'count')}
                                        disabled={data?.finished}
                                    />
                                </p>
                            ) : (
                                <div className={'switch-obj'}>
                                    <label htmlFor="count" style={{marginRight: '0.5rem'}}>действительна до</label>
                                    <InputText
                                        inputId='count'
                                        disabled={data?.finished}
                                        type='date'
                                        value={isNaN(+data?.finishDate) ? moment(data?.finishDate, 'YY-MM-DD').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')}
                                        onChange={e =>  updateData(moment(e.target.value).format('YY-MM-DD'), 'finishDate')}
                                        placeholder="продолжительность дисквалификации"
                                    />
                                </div>
                            )}
                            <div className="tournamets2-settings__item">
                                <div className="tournamets2-settings__radio switch-obj">
                                    <InputSwitch
                                        checked={isQtType}
                                        onChange={() => {
                                            setData(prev => isQtType ? ({...prev, finishDate: moment().format('YY-MM-DD'), count: null, globalDisqTill: false}) : ({...prev, finishDate: null, count: 2, globalDisqTill: false}))
                                            setIsChanged(true)
                                        }}
                                        name='isQtType'
                                        disabled={data?.finished}
                                    />
                                    <label className="tournamets2-settings__label">Матчевая</label>
                                </div>
                            </div>
                            <div className="tournamets2-settings__item">
                                <div className="tournamets2-settings__radio switch-obj">
                                    <InputSwitch
                                        checked={data?.fictive}
                                        onChange={e => updateData(e.target.value, 'fictive')}
                                        name='fictive'
                                        disabled={data?.finished}
                                    />
                                    <label className="tournamets2-settings__label">Условная</label>
                                </div>
                            </div>
                            <div className="tournamets2-settings__item">
                                <div className="tournamets2-settings__radio switch-obj">
                                    <InputSwitch
                                        checked={data?.globalDisqTill}
                                        onChange={() => {
                                            setData(prev => ({...prev, finishDate: moment().format('YY-MM-DD'), count: null, globalDisqTill: true}))
                                            setIsChanged(true)
                                        }}
                                        name='globalDisqTill'
                                        disabled={data?.finished}
                                    />
                                    <label className="tournamets2-settings__label">Глобальная</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default SideBar;

const getPatch = (form, disqual) => {
    const patch = {_id: form._id}
    const keys = ['count','finishDate', 'fictive', 'isRedCardCheck', 'comment', 'globalDisqTill'];
    for (let key of keys) {
        if (disqual[key] !== form[key] ) {
            patch[key] = form[key]
        }
    }
    return patch;
}

const options = {
    headers: {
        authorization: localStorage.getItem('_amateum_auth_tkn'),
        SignedBy: localStorage.getItem('_amateum_auth_tkn')
    }
}
