import React, { useEffect, useRef, useState } from "react";

import utils from "../../../../../../../Tournaments/Structure/utils";
import service from "../../../../../../../Tournaments/service";
import {clearToast, diffData} from "../../../../../../../../utils";
import {putByUrl} from "../../../../../../../../v3MethodsService";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton"
import {confirmDialog} from "primereact/confirmdialog";

import ManualGroup from "./Manual";
import AutoGroup from "./Auto";

import "./style.scss";

const indexesChar = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

const GroupStructure = ({ current, state, toast, updateCurrent, isFinished }) => {
    const op = useRef(null);
    const groupOp = useRef(null);

    const [mode, setMode] = useState('manual')

    const [timeoutID, setTimeoutID] = useState(null);

    const [data, setData] = useState(null);
    const [filteredTeams, setFilteredTeams] = useState([]);

    const isDiff = diffData(data, state)

    const unassigned = state?.teams.filter((t) => !data?.teams?.map((c) => c._id).includes(t._id));

    const assigned = state?.teams.filter((t) =>
        data?.groups
            ?.reduce((acc, g) => {
                acc = acc.concat(g.teams.map((t) => t._id));
                return acc;
            }, [])
            ?.includes(t._id)
    );

    const [initial, setInitial] = useState(null);

    useEffect(() => {
        if (current && !data) {
            setData(current);
            if (current.parentId) {
                setMode('auto')
            }
        }
        if(current && current.initial && !initial && assigned?.length) {
            setInitial(utils.mapperInitial(current.initial, assigned))
        }
    }, [current, assigned]);

    useEffect(() => {
        if (data && isDiff) {
            updateCurrent(data)
        }
    }, [isDiff]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData((prev) => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else if (key) {
            setData((prev) => ({ ...prev, [key]: val }));
        } else {
            setData(val);
        }
    };

    const [selectGroup, setSelectGroup] = useState("alphabetic");

    const searchTeam = (evt) => {
        const assigned = data.groups.reduce((acc, g) => {
            acc = acc.concat(g.teams.map((t) => t._id));
            return acc;
        }, []);

        const filtered = state?.teams.filter((t) => !assigned.includes(t._id)).filter((t) => (evt.query.length ? t.name.toLowerCase().includes(evt.query.toLowerCase()) : t.name));

        setFilteredTeams(filtered);
    };

    const updateTeams = async (idx, gid, value) => {
        const mapped = value.map((t) => ({ _id: t._id }));
        setData({
            ...data,
            teams: data.groups
                .map((g, i) => (i === idx ? { ...g, teams: mapped } : g))
                .map((gr) => gr.teams.map((t) => t))
                .flat(),
            groups: data.groups.map((g, i) => (i === idx ? { ...g, teams: mapped } : g)),
        });

        await service.updateGroupTeams(gid, mapped, toast);
        await service.updateStage(
            data._id,
            {
                teams: data.groups
                    .map((g, i) => (i === idx ? { ...g, teams: mapped } : g))
                    .map((gr) => gr.teams.map((t) => t))
                    .flat(),
            },
            toast
        );
        clearToast(toast, timeoutID, setTimeoutID)
    };

    const assignTeams = (arr) => arr.map((t) => state.teams.find((_t) => _t._id === t._id));

    const updateInitial = (id, value, key = "pts") => {
        const mapd = initial.map((init) => (init.team._id === id ? { ...init, [key]: value } : init));
        setInitial(mapd);
    };

    const sendInitial = async () => {
        const init = utils.teamsToInitial(initial);
        if (op && op.current) {
            op.current.hide();
        }
        if (init || data.initial) {
            await service.updateStage(data._id, { initial: init }, toast);
            updateData(init, "initial");
        }
    };

    const sendTitle = async () => {
        await service.updateStage(data._id, { title: data?.title }, toast);
    };

    const sendRounds = async (val) => {
        if (data.roundsQty !== val) {
            await service.updateStage(data._id, { roundsQty: val }, toast);
            updateData(val, "roundsQty");
        }
    };

    const createGroup = async () => {
        const last = data.groups ? data.groups[data.groups.length - 1] : null;
        const idx = last ? (selectGroup === "alphabetic" ? indexesChar[indexesChar.findIndex((i) => i === last.name[last.name.length - 1]) + 1] : data.groups.length + 1) : "A";
        if (idx) {
            const created = await service.createGroup(data._id, "Группа " + idx, toast);
            updateData(data.groups ? data.groups.concat([created]) : [created], "groups");
        }
    };

    const renameGroups = async (value) => {
        setSelectGroup(value);
        const renamed = data?.groups?.map((g, i) => ({ ...g, name: "Группа " + (value === "alphabetic" ? indexesChar[i] : i + 1) }));
        updateData(renamed, "groups");

        for (let ro of renamed) {
            await service.renameGroup(ro._id, ro.name);
        }
    };

    const removeGroup = async (idx, _id) => {
        const filtered = data.groups.filter((g, i) => i !== idx);
        const reordered = filtered.map((g, i) => ({ ...g, name: "Группа " + (selectGroup === "alphabetic" ? indexesChar[i] : i + 1) }));
        await service.removeGroup(_id, toast);
        updateData(reordered, "groups");

        for (let ro of reordered) {
            await service.renameGroup(ro._id, ro.name);
        }
    };

    const handleFinish = () => {
        confirmDialog({
            message: 'Это действие нельзя отменить. Вы точно хотите завершить стадию?',
            header: 'Внимание!',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptFinish,
            acceptLabel: 'Да',
            rejectLabel: 'Нет'
        });
    }

    const acceptFinish = () => {
        putByUrl(`stages/${data._id}`, {finished: true})
            .then(resp => {
                if (resp && resp.success && toast) {
                    updateData(resp.data)
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Стадия завершена!', life: 1000})
                }
            })
    }

    const changeMode = (mode) => {
        if (mode === 'manual' && data && data.rules && data.rules.length > 0) {
            confirmDialog({
                message: 'После смены режима редактирования будут удалены все настройки наследования',
                header: 'Выбрать ручной режим?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                accept: changeModeToManual,
            });
            return
        }
        setMode(mode)
    }

    const changeModeToManual = () => {
        const body = { parentId: null, rules: null, matchdays: [], teams: [], extractedTeams: []}
        putByUrl(`stages/${data._id}`, body)
            .then(resp => {
                if (resp && resp.success && toast) {
                    updateData(resp.data)
                    setMode('manual')
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Настройки удалены!', life: 1000})
                }
            })
    }

    const Specified = mode ? wrap[mode] : wrap.manual

    return (
        !!data && (
            <div className="group-structure">
                <div className="group-structure__card">
                    <div className="group-structure__container">
                        <span className="group-structure__title">Основная информация</span>
                        <div className="group-structure__btns">
                            <Button
                                icon="pi pi-pencil"
                                label="Изменить очки"
                                className="p-button-outlined"
                                onClick={(e) => {
                                    if (!initial) {
                                        const init = utils.getInitial(assigned);
                                        setInitial(init);
                                    }
                                    op.current.toggle(e);
                                }}
                                disabled={isFinished}
                            />
                        </div>
                        <OverlayPanel ref={op} style={{ maxWidth: "363px" }}>
                            <div className="group-structure__overlay">
                                {initial?.map((item, idx) => (
                                    <div key={idx} className="group-structure__score-item">
                                        <span className="circle-structure__score-name">{item.team.name}</span>
                                        <InputNumber value={item.pts} onValueChange={(e) => updateInitial(item.team._id, e.value, "pts")} />
                                    </div>
                                ))}
                            </div>
                            <div className="cancel-wrap" style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                                <Button
                                    onClick={sendInitial}
                                    icon="pi pi-save"
                                    className="btn-create p-button-sm p-button-outlined"
                                    label="Сохранить"
                                    disabled={!initial || !initial.find((t) => t.pts)}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </OverlayPanel>
                    </div>
                    <div className="group-structure__container">
                        <div className="group-structure__input">
                            <label className="group-structure__input-label">Название стадии*</label>
                            <InputText value={data.title} onChange={(e) => updateData(e.target.value, "title")} onBlur={sendTitle} placeholder="Укажите название" disabled={isFinished}/>
                        </div>
                        <div className="group-structure__input">
                            <label className="group-structure__input-label">Количество кругов*</label>
                            <InputNumber value={data.roundsQty} onValueChange={(e) => sendRounds(e.target.value)} placeholder="Укажите количество " disabled={isFinished}/>
                        </div>
                    </div>
                    {state?.stages?.length > 1 && <SelectButton value={mode} onChange={e => e.value ? changeMode(e.value) : null} options={modeOptions} optionLabel="label" disabled={isFinished}/>}
                </div>
                <Specified
                    unassigned={unassigned}
                    assignTeams={assignTeams}
                    createGroup={createGroup}
                    data={data}
                    selectGroup={selectGroup}
                    filteredTeams={filteredTeams}
                    searchTeam={searchTeam}
                    updateTeams={updateTeams}
                    removeGroup={removeGroup}
                    renameGroups={renameGroups}
                    groupOp={groupOp}
                    toast={toast}
                    updateData={updateData}
                    state={state}
                    isFinished={isFinished}
                    handleFinish={handleFinish}
                />
                {/*<Button label="Удалить" className="group-structure__btn p-button-danger" />*/}
            </div>
        )
    );
};

const wrap = {
    manual: ManualGroup,
    auto: AutoGroup
}

const modeOptions = [{label: 'Ручной режим', value: 'manual'}, {label: 'Наследовать из другой стадии', value: 'auto'}]

export default GroupStructure;
