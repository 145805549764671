import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment/moment";
import { transliterate } from "transliteration";

import { useClickOutside, diffData } from "../../../../../utils";
import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";

import { Sidebar } from "primereact/sidebar";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { Tooltip } from "primereact/tooltip";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";

import FileUploadUi from "../FileUploadUi";

import "./style.scss";

const SideBar = ({ visible, setVisible, data, updateData, stateSidebr, toast, setStateSidebar, teamsCount, canonical, options, getData }) => {
    const ref = useRef();
    useClickOutside(visible, ref, () => setVisible(false));
    const dataToSend = diffData(stateSidebr, data);


    
    const exitHandler = () => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация этой команды",
            header: "Удалить команду?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            draggable: false,
            accept: () => {
                deleteByUrl(`teams/${stateSidebr._id}`).then((resp) => {
                    if (resp && resp.success) {
                        setVisible(false);
                        setStateSidebar(null);
                        getData({ severity: "success", summary: "Команда удалена" });
                    } else {
                        toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 3000 });
                    }
                });
            },
            reject: () => {},
        });
    };
    

    const handleSave = async () => {
        if (stateSidebr._id) {
            if (data.canonical && data._id !== canonical) {
                await putByUrl(`teams/${canonical}`, { canonical: false });
            }
            putByUrl(`teams/${data._id}`, dataToSend).then((resp) => {
                if (resp && resp.success && resp.data) {
                    setVisible(false);
                    setStateSidebar(resp.data);
                    getData({ severity: "success", summary: "Данные сохранены", life: 3000 });
                    
                } else {
                    toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 3000 });
                }
            });
        } else {
            postByUrl(`teams`, data).then((resp) => {
                if (resp && resp.success && resp.data) {
                    setVisible(false);
                    setStateSidebar(resp.data);
                    getData({ severity: "success", summary: "Данные сохранены", life: 1000 });
                } else {
                    toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 3000 });
                }
            });
        }
    };

    const onSelect = async (event, type) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        if (stateSidebr._id) {
            formData.append("sampleId", stateSidebr._id);
            formData.append("sampleType", "teams");
        } else {
            formData.append("s3only", true);
        }

        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                updateData(file, type);
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const handleInput = (e) => {
        if (e.target.value.length > 4) {
            e.target.value = e.target.value.slice(0, 4);
        }

        if (e.target.value.length === 4) {
            updateData(e.target.value, "age");
        }
    };

    const disabledBtn = data && data.name && data.emblem && dataToSend;

    return (
        !!visible &&
        !!data && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                {!data.canonical && <Tooltip target=".side-profile__button-delete" />}
                <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                    <div className="side-profile__header">
                        <div className="side-profile__btns">
                            <Button label="Сохранить" className="side-profile__button-save auth2__button" disabled={!disabledBtn} onClick={handleSave} />
                            <Tooltip target=".disabled-delete" position="bottom" />
                            <span className="disabled-delete" data-pr-tooltip={`${data.canonical ? "Нельзя удалить основную команду" : ""}`}>
                                <Button
                                    label="Удалить"
                                    className="side-profile__button-save side-profile__button-delete auth2__button auth2__button_outlined"
                                    disabled={data.canonical}
                                    onClick={exitHandler}
                                />
                            </span>
                        </div>
                        <i className="side-profile__icon-close pi pi-times" onClick={() => setVisible(false)} />
                    </div>
                    <div className="side-profile__body">
                        <Accordion activeIndex={0}>
                            <AccordionTab header="Основная информация">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Название команды*</label>
                                    <InputText placeholder="Укажите название" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")} />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Дисциплина</label>
                                    <Dropdown
                                        emptyMessage="Ничего не найдено"
                                        placeholder="Выберите дисциплину"
                                        value={data.disciplineId || ""}
                                        optionLabel="name"
                                        optionValue="_id"
                                        onChange={(e) => updateData(e.target.value, "disciplineId")}
                                        options={options}
                                        appendTo={"self"}
                                    />
                                </div>
                                <div className="side-profile__items">
                                    <FileUploadUi
                                        label="Эмблема команды*"
                                        mutedLabel="(jpg, jpeg, png, до 5 Мб)"
                                        image={data.emblem}
                                        onChange={(e) => onSelect(e, "emblem")}
                                        updateData={() => updateData(null, "emblem")}
                                    />
                                    <FileUploadUi
                                        label="Командное фото"
                                        mutedLabel="(jpg, jpeg, png, до 5 Мб)"
                                        image={data.team_img}
                                        onChange={(e) => onSelect(e, "team_img")}
                                        updateData={() => updateData(null, "team_img")}
                                    />
                                </div>
                            </AccordionTab>
                            <AccordionTab header="Дополнительная информация">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Ссылка на видео-визитку</label>
                                    <InputText placeholder="Вставьте ссылку" value={data.videoLink || ""} onChange={(e) => updateData(e.target.value, "videoLink")} />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Привязка к году рождения</label>
                                    <Calendar
                                        placeholder="Выберите год"
                                        showIcon
                                        locale="ru"
                                        dateFormat="yy"
                                        maxDate={new Date()}
                                        appendTo={"self"}
                                        value={data?.age ? moment(data?.age, "YYYY").toDate() : ""}
                                        onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("YYYY") : e.target.value, "age")}
                                        view="year"
                                        onInput={handleInput}
                                    />
                                </div>
                                {data._id && (
                                    <div className="clubs-profile__switch">
                                        <InputSwitch checked={data.canonical} onChange={(e) => updateData(e.value, "canonical")} disabled={teamsCount < 2} />
                                        <lablel className="clubs-profile__label">Основная команда</lablel>
                                    </div>
                                )}
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        )
    );
};

export default SideBar;
