import React, { useEffect, useRef, useState } from "react";

import moment from "moment";

import { getByUrl, putByUrl } from "../../../../v3MethodsService";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { useClickOutside } from "../../../../utils";

import "./style.scss";

const VerifyModal = ({ state = false, tab, onClose, setReq }) => {
    const [isVerify, setIsVerify] = useState(true);
    const [show, setShow] = useState(false);
    const [selectedTab, setSelectedTab] = useState("");
    const [inputValue, setInputValue] = useState(state?.league?.name);
    const [declainText, setDeclainText] = useState("");
    const ref = useRef();

    useClickOutside(state, ref, onClose);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (state && state.userId) {
            getByUrl(`users/${state.userId}`).then((resp) => {
                if (resp && resp.success) {
                    setUserData(resp.data);
                }
            });
        }
    }, [state]);

    const changeTabHandler = (id) => {
        setSelectedTab(selectedTab === id ? "" : id);
    };

    const handleSend = (bool) => {
        putByUrl(`verifications/${state._id}`, { decision: bool, comment: declainText }).then((resp) => {
            if (resp && resp.success) {
                console.log("resp", resp);
                setShow(false);
                onClose();
                setReq(true);
                setDeclainText("");
            }
        });
    };

    const renderAccordions = () => {
        switch (tab) {
            case "new_users": {
                return (
                    <>
                        {state.status === "NOT_VALID" && (
                            <div id="declain" key="declain" className={`side-profile__accordion${selectedTab === "declain" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title declain">Заявка отклонена</div>
                                    <i className={`${selectedTab === "declain" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("declain")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Причина отклонения</div>
                                    <div className="side-profile__accordion-declain-text">{state && state.moderatorData && state.moderatorData.comment ? state.moderatorData.comment : ""}</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата отклонения</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {state.status === "VALID" && (
                            <div id="accept" key="accept" className={`side-profile__accordion${selectedTab === "accept" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title accept">Заявка принята</div>
                                    <i className={`${selectedTab === "accept" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("accept")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата верификации</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!state.role && (
                            <div id="role" key="role" className={`side-profile__accordion${selectedTab === "role" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Запрошенная роль</div>
                                    <i className={`${selectedTab === "role" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("role")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">{state.role.name}</div>
                                    {state.region?.name || state.subjectType === "region" ? (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Регион</p>
                                            <span>{state.region?.name || state.subject?.name}</span>
                                        </div>
                                    ) : null}
                                    {state.league?.name || state.subjectType === "league" ? (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Лига</p>
                                            <span>{state.league?.name || state.subject?.name}</span>
                                        </div>
                                    ) : null}
                                    {state.subject?.name && state.subjectType === "club" ? (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Клуб</p>
                                            <span>{state.subject.name}</span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}

                        <div id="profile" key="profile" className={`side-profile__accordion${selectedTab === "profile" ? " side-profile__accordion_active" : ""}`}>
                            <div className="side-profile__accordion-header">
                                <div className="side-profile__accordion-title">Данные профиля</div>
                                <i className={`${selectedTab === "profile" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("profile")} />
                            </div>
                            <div className="side-profile__accordion-verify">
                                <div className="side-profile__accordion-subtext">
                                    <p>Создан через</p>
                                    <span>Email</span>
                                </div>
                                <div className="side-profile__accordion-subtext">
                                    <p>ID</p>
                                    <span>{state.user.email}</span>
                                </div>
                                <div className="side-profile__accordion-subtext">
                                    <p>Дата создания</p>
                                    <span>{moment(state.createdAt).format("DD.MM.YYYY")}</span>
                                </div>
                            </div>
                        </div>
                    </>
                );
            }

            case "new_leagues": {
                return (
                    <>
                        {state.status === "NOT_VALID" && (
                            <div id="declain" key="declain" className={`side-profile__accordion${selectedTab === "declain" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title declain">Заявка отклонена</div>
                                    <i className={`${selectedTab === "declain" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("declain")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Причина отклонения</div>
                                    <div className="side-profile__accordion-declain-text">{state && state.moderatorData && state.moderatorData.comment ? state.moderatorData.comment : ""}</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата отклонения</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {state.status === "VALID" && (
                            <div id="accept" key="accept" className={`side-profile__accordion${selectedTab === "accept" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title accept">Заявка принята</div>
                                    <i className={`${selectedTab === "accept" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("accept")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата верификации</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!state.league && (
                            <div id="reqLeague" key="reqLeague" className={`side-profile__accordion${selectedTab === "reqLeague" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Запрошенная лига</div>
                                    <i className={`${selectedTab === "reqLeague" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("reqLeague")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    {/* <div className="side-profile__accordion-subtext">
                                        <p>Внешний ресурс</p>
                                        <span>{state.subject.link}</span>
                                    </div> */}
                                    <div className="side-profile__accordion-subtext">
                                        <p>Целевой регион</p>
                                        <span>{state.region?.name}</span>
                                    </div>
                                    {(state.status == "VALID" || state.status == "NOT_VALID") && (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Название лиги</p>
                                            <span>{state.league?.name}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {(state.status === "MODERATION") && (
                            <div className="side-profile__accordion">
                                <label>Название лиги</label>
                                <InputText placeholder="Введите название лиги" value={state.subject?.name} onChange={(e) => setInputValue(e.target.value)} />
                            </div>
                        )}
                    </>
                );
            }

            case "new_clubs": {
                return (
                    <>
                        {state.status === "NOT_VALID" && (
                            <div id="declain" key="declain" className={`side-profile__accordion${selectedTab === "declain" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title declain">Заявка отклонена</div>
                                    <i className={`${selectedTab === "declain" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("declain")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Причина отклонения</div>
                                    <div className="side-profile__accordion-declain-text">{state && state.moderatorData && state.moderatorData.comment ? state.moderatorData.comment : ""}</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата отклонения</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {state.status === "VALID" && (
                            <div id="accept" key="accept" className={`side-profile__accordion${selectedTab === "accept" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title accept">Заявка принята</div>
                                    <i className={`${selectedTab === "accept" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("accept")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата верификации</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!state.subject && (
                            <div id="reqClub" key="reqClub" className={`side-profile__accordion${selectedTab === "reqClub" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Запрошенный клуб</div>
                                    <i className={`${selectedTab === "reqClub" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("reqClub")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    {/* <div className="side-profile__accordion-subtext">
                                        <p>Внешний ресурс</p>
                                        <span>{state.subject.link}</span>
                                    </div> */}
                                    <div className="side-profile__accordion-subtext">
                                        <p>Целевой регион</p>
                                        <span>{state.region?.name}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Целевая лига</p>
                                        <span>{state.league?.name}</span>
                                    </div>
                                    {(state.status !== "MODERATION") && (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Название клуба</p>
                                            <span>{state.subject?.name}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {(state.status === "MODERATION") && (
                            <div className="side-profile__accordion">
                                <label>Название клуба</label>
                                <InputText placeholder="Введите название клуба" value={state.subject?.name} onChange={(e) => setInputValue(e.target.value)} />
                            </div>
                        )}
                    </>
                );
            }

            case "rff":
            case "clubs":
            case "leagues": {
                return (
                    <>
                        {state.status === "NOT_VALID" && (
                            <div id="declain" key="declain" className={`side-profile__accordion${selectedTab === "declain" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title declain">Заявка отклонена</div>
                                    <i className={`${selectedTab === "declain" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("declain")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Причина отклонения</div>
                                    <div className="side-profile__accordion-declain-text">{state && state.moderatorData && state.moderatorData.comment ? state.moderatorData.comment : ""}</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата отклонения</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                        {state.status == "VALID" && (
                            <div id="accept" key="accept" className={`side-profile__accordion${selectedTab === "accept" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title accept">Заявка принята</div>
                                    <i className={`${selectedTab === "accept" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("accept")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{`${state.handler?.surname || ""} ${state.handler?.name || ""} ${state.handler?.middlename || ""}`}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата верификации</p>
                                        <span>{moment(state?.handledAt)?.format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}

                        {!!state.role && (
                            <div id="role" key="role" className={`side-profile__accordion${selectedTab === "role" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Запрошенная роль</div>
                                    <i className={`${selectedTab === "role" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("role")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">{state.role.name}</div>
                                    {state.region?.name || state.subjectType === "region" ? (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Регион</p>
                                            <span>{state.region?.name || state.subject?.name}</span>
                                        </div>
                                    ) : null}
                                    {state.league?.name || state.subjectType === "league" ? (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Лига</p>
                                            <span>{state.league?.name || state.subject?.name}</span>
                                        </div>
                                    ) : null}
                                    {state.subject?.name && state.subjectType === "club" ? (
                                        <div className="side-profile__accordion-subtext">
                                            <p>Клуб</p>
                                            <span>{state.subject.name}</span>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        )}

                        {/*{!!state.role && (
                            <div id="reqRole" key="reqRole" className={`side-profile__accordion${selectedTab === "reqRole" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Запрошенная роль</div>
                                    <i className={`${selectedTab === "reqRole" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("reqRole")} />
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">{state.role}</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Регион</p>
                                        <span>{state.federation?.name}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Лига</p>
                                        <span>{state.league?.name}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Клуб</p>
                                        <span>{state.club?.name}</span>
                                    </div>
                                </div>
                            </div>
                        )}*/}
                        {/* {!!state.availableRoles && (
                            <div id="role" key="role" className={`side-profile__accordion${selectedTab === "role" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Доступные роли</div>
                                    <i className={`${selectedTab === "role" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("role")} />
                                </div>
                                {state.availableRoles.map((item, idx) => (
                                    <div className="side-profile__accordion-verify" key={idx}>
                                        <div className="side-profile__accordion-subtitle">{item.role}</div>
                                        {item.federation && (
                                            <div className="side-profile__accordion-subtext">
                                                <p>Регион</p>
                                                <span>{item.federation.name}</span>
                                            </div>
                                        )}
                                        {item.leaugue && (
                                            <div className="side-profile__accordion-subtext">
                                                <p>Лига</p>
                                                <span>{item.league.name}</span>
                                            </div>
                                        )}
                                        {item.club && (
                                            <div className="side-profile__accordion-subtext">
                                                <p>Клуб</p>
                                                <span>{item.club.name}</span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        )} */}

                        {!!(userData && userData.rolescopes) && (
                            <div id="roles" key="roles" className={`side-profile__accordion${selectedTab === "roles" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Доступные роли </div>
                                    <i className={`${selectedTab === "roles" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("roles")} />
                                </div>
                                {Array.isArray(userData.rolescopes) &&
                                    userData.rolescopes.map((r, k) => (
                                        <div className="side-profile__accordion-role">
                                            <div className="side-profile__accordion-subtitle">{r.role.name}</div>
                                            {/*{r.scope.type === 'system' && <div className='side-profile__accordion-subtext'><p>Система</p><span>{r.scope.name}</span></div>}*/}
                                            {r.scope.type === "region" && (
                                                <div className="side-profile__accordion-subtext">
                                                    <p>Регион</p>
                                                    <span>{r.scope.name}</span>
                                                </div>
                                            )}
                                            {["league", "leagueStaff"].includes(r.scope.type) && (
                                                <div className="side-profile__accordion-subtext">
                                                    <p>Лига</p>
                                                    <span>{r.scope.name}</span>
                                                </div>
                                            )}
                                            {r.scope.type === "club" && (
                                                <div className="side-profile__accordion-subtext">
                                                    <p>Клуб</p>
                                                    <span>{r.scope.name}</span>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}

                        {!!(userData && userData.lastHandled) && (
                            <div id="verify" key="verify" className={`side-profile__accordion${selectedTab === "verify" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Верификация</div>
                                    <i className={`${selectedTab === "verify" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("verify")} />
                                </div>
                                {!!(userData.verified && userData.lastHandled.handler) && (
                                    <div className="side-profile__accordion-verify">
                                        <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                        <div className="side-profile__accordion-subtext">
                                            <p>Кем</p>
                                            <span>{`${userData.lastHandled.handler.surname || ""} ${userData.lastHandled.handler.name || ""} ${userData.lastHandled.handler.middlename || ""}`}</span>
                                        </div>
                                        <div className="side-profile__accordion-subtext">
                                            <p>Дата верификации</p>
                                            <span>{moment(userData.lastHandled.handledAt).format("DD.MM.YYYY")}</span>
                                        </div>
                                        {/*<div className='side-profile__accordion-subtext'>
                                            <p>Создан через</p>
                                            <span>{userData.lastHandled.method}</span>
                                        </div>*/}
                                        <div className="side-profile__accordion-subtext">
                                            <p>ID</p>
                                            <span>{userData.lastHandled.handler.email || "Не указан"}</span>
                                        </div>
                                        <div className="side-profile__accordion-subtext">
                                            <p>Дата создания</p>
                                            <span>{moment(userData.lastHandled.createdAt).format("DD.MM.YYYY")}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        {/*{!!state.handledBy && (
                            <div id="accept" key="verified"
                                 className={`side-profile__accordion${selectedTab === "verified" ? " side-profile__accordion_active" : ""}`}>
                                <div className="side-profile__accordion-header">
                                    <div className="side-profile__accordion-title">Верификация</div>
                                    <i className={`${selectedTab === "verified" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`}
                                       onClick={() => changeTabHandler("verified")}/>
                                </div>
                                <div className="side-profile__accordion-verify">
                                    <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Кем</p>
                                        <span>{state.handledBy}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата верификации</p>
                                        <span>{state.handledAt}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Создан через</p>
                                        <span>Email</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>ID</p>
                                        <span>{state.user?.email}</span>
                                    </div>
                                    <div className="side-profile__accordion-subtext">
                                        <p>Дата создания</p>
                                        <span>{moment(state.createdAt).format("DD.MM.YYYY")}</span>
                                    </div>
                                </div>
                            </div>
                        )}*/}
                    </>
                );
            }
        }
    };

    const renderFooter = () => {
        return (
            <div>
                <Button label="Не отклонять" onClick={() => setShow(false)} className="p-button-text" />
                <Button label="Отклонить" onClick={() => handleSend(false)} autoFocus className="p-button-danger" disabled={!declainText} />
            </div>
        );
    };

    return state ? (
        <div className="verifyModal">
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                    <Dialog style={{ width: "620px" }} header="Причина отклонения" visible={show} style={{ width: "50vw" }} footer={renderFooter} onHide={() => setShow(false)} className="verifyModal__dialog">
                        <InputTextarea placeholder="Опишите причину" value={declainText} onChange={(e) => setDeclainText(e.target.value)} />
                    </Dialog>
                    <div className="side-profile__header">
                        {!state.handledBy && (
                            <div className="side-profile__header-btn-group">
                                <Button className="side-profile__button-save auth2__button" label={btnName[tab] ? btnName[tab] : "Принять запрос на роль"} onClick={() => handleSend(true)} />
                                <Button className="side-profile__button-save auth2__button auth2__button_outlined" label="Отклонить" onClick={() => setShow(true)} />
                            </div>
                        )}
                        <i className="side-profile__icon-close pi pi-times" onClick={onClose} />
                    </div>
                    <div className="side-profile__body">
                        {!!state.user && (
                            <div className="side-profile__account">
                                <div className="side-profile__avatar">
                                    {state.user.icon ? (
                                        <img src={state.user.icon} alt="avatar" />
                                    ) : (
                                        `${(state.user.surname && state.user.surname.slice(0, 1).toUpperCase()) || ""}${(state.user.name && state.user.name.slice(0, 1).toUpperCase()) || ""}`
                                    )}
                                </div>
                                <div className="side-profile__account-info">
                                    <div className="side-profile__account-text">
                                        {/*{state.role && state.role.name ? <div className="side-profile__role">{state.role.name}</div> : null}*/}
                                        <div className="side-profile__account-main">{`${state.user.surname || ""} ${state.user.name || ""} ${state.user.middlename || ""}`}</div>
                                        <div className="side-profile__account-bday">
                                            Дата рождения
                                            <span>{state.user.birthday || "Не указана"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {renderAccordions()}
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};

export default VerifyModal;

// const data = { name: "Ян", surname: "Коваленко", patronymic: "Сергеевич", bday: "05.04.1988" };

const btnName = {
    new_user: "Верифицировать пользователя и роль",
    new_leagues: "Создать лигу и выдать доступ",
    new_clubs: "Создать клуб и выдать доступ",
};
