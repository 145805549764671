import React, {useState, useEffect} from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import {Tooltip} from "primereact/tooltip";

import FormBlock from "./FormBlock/index.jsx";

import icon1 from "./img/icon1.svg"
import icon2 from "./img/icon2.svg"
import icon3 from "./img/icon3.svg"
import icon4 from "./img/icon4.svg"
import icon5 from "./img/icon5.svg"

import './style.scss'

const FormBody = ({data, onChange}) => {
    const [idx, setIdx] = useState(0);
    
    const onDragStart = () => {
        const listContainer = document.querySelector('.form-body__content');
        if (listContainer) {
            const remainingHeight = listContainer.clientHeight;
            listContainer.style.height = `${remainingHeight}px`;
        }
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const newBlocks = [...data];
        const [movedItem] = newBlocks.splice(result.source.index, 1);
        newBlocks.splice(result.destination.index, 0, movedItem);

        onChange('body', newBlocks);

        const listContainer = document.querySelector('.form-body__content');
        if (listContainer) {
            listContainer.style.height = 'auto';
        }
    };

    const handleAddBlock = (block) => {
        const newBlocks = [...data]
        newBlocks.push({...block, id: block.key + idx})
        onChange('body', newBlocks)
        setIdx(prev => prev + 1)
    }

    const inputHandler = (val, index) => {
        const newBlocks = [...data]
        newBlocks[index] = val
        onChange('body', newBlocks)
    }

    const handleRemove = (index) => {
        const newBlocks = [...data]
        newBlocks.splice(index, 1)
        onChange('body', newBlocks)
    }

    return data && (
        <div className="form-body">
            {data.length > 0 ? (
                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.droppableProps} className='form-body__content'>
                                {data.map((block, k) => <FormBlock key={k} index={k} data={block} onChange={inputHandler} onRemove={handleRemove}/>)}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : null}
            <div className={`form-body__footer${data.length === 0 ? ' form-body__footer_empty' : ''}`}>
                {icons.map((i, k) => (
                    <div key={k}>
                        <Tooltip target={`.icon_${k}`} content={i.tooltip} position='top'/>
                        <img src={i.icon} className={`form-body__icon icon_${k}`} onClick={() => handleAddBlock(i.block)}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

const icons = [
    {icon: icon1, tooltip: 'Добавить заголовок', block: {"key": "subtitle", "subtitle": ""}},
    {icon: icon2, tooltip: 'Добавить текст', block: {"key": "text", "text": ""}},
    {icon: icon3, tooltip: 'Добавить видео', block: {"key": "video", "url": ""}},
    {icon: icon4, tooltip: 'Добавить фото', block: {"key": "image", "images": [], "author": ""}},
    {icon: icon5, tooltip: 'Добавить цитату', block: {"key": "quote", "text": "", "author": "", "post": ""}}
]

export default FormBody;