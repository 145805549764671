import React, {useEffect, useRef, useState} from "react"

import {Toast} from "primereact/toast";

import LeaguesList from "./List";

import './style.scss'
import { getByUrl } from "../../../v3MethodsService";
import LeaugesDetail from "./Detail";

const Leagues = () => {
    const param = window.location.pathname.split('/')
    const toast = useRef(null)
    
    const [state, setState] = useState({})

    const [dropdown, setDropdown] = useState(null)

    const updateState = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const Specified = param && param[2] ? wrap.detail : wrap.list;

    return (
        <div className='leagues'>
            <Toast ref={toast} position="top-right"/>
            <Specified toast={toast.current} state={state} updateState={updateState}  dropdown={dropdown} setDropdown={setDropdown} />
        </div>
    )
}

const wrap = {
    detail: LeaugesDetail,
    list: LeaguesList
}

export default Leagues