import React, { useEffect, useRef, useState } from "react";

import CircleStructure from "./Circle";
import GroupStructure from "./Group";
import PlayoffStructure from "./Playoff";

import "./style.scss";

const Structure = ({ current, state, toast, updateCurrent, isFinished }) => {

    const Specified = wrap[current?.type];

    return (
        <div className="calendar-structure">
            <Specified current={current} state={state} toast={toast} updateCurrent={updateCurrent} isFinished={isFinished}/>
        </div>
    );
};

export default Structure;

const wrap = {
    round: CircleStructure,
    groups: GroupStructure,
    playoff: PlayoffStructure
}
