import React, {useRef, useState} from 'react'

import { Toast } from 'primereact/toast'; //использовать для сообщений от бэка

import Email from './Email'
import Kind from './Kind'
import PersonData from './PersonData'

import './style.scss'

const Success = ({ tokenData, step, setStep }) => {

    const [state, setState] = useState({
        email: null,
        kind: null,
        personData: null,
    }) //в них мы будем писать, то что понадобится для трех компонентов и чтобы в каждом был доступ ко всему на всякий случай

    const Specified = wrap[step]

    return (
        <div className='auth2__container reg-success'>
            <Specified state={state} setState={setState} setStep={setStep} tokenData={tokenData}/>
        </div>
    )
}

const wrap = {
    email: Email,
    kind: Kind,
    personData: PersonData,
}

export default Success