import React, {useState, useEffect, useContext, useRef} from 'react'

import moment from 'moment'

import axios from 'axios'
import { ENDPOINT } from '../../../env'
import { GlobalContext } from '../../../ctx'

import { ProgressSpinner } from 'primereact/progressspinner'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { TabPanel, TabView } from 'primereact/tabview'
import { Calendar } from 'primereact/calendar'
import { Button } from 'primereact/button'
import {Toast} from "primereact/toast";

import defaultIcon from './img/no-icon.png'

import './style.scss'

const Renders = ({ subject }) => {
    const toast = useRef(null)
    const [data, setData] = useState(null)

    useEffect(() => {
        setData(null)
        if (subject && subject._id) {
            axios.get(`${ENDPOINT}client/sharableUrlsV3/${subject._id}`)
                .then(resp => {
                    if (resp.data) {
                        setData(resp.data)
                    }
                })
        }
    }, [subject])

    return <div className='renders'>
        <Toast ref={toast} position='top-right'/>
        <div className='content'>
            {!data ? (
                <ProgressSpinner style={{ width: 64, height: 64 }} />
            ) : <Accordion > 
                {/* activeIndex={0} */}
                    {data.filter(t => t.stages && t.stages.length).map((t, i) => (
                        <AccordionTab key={i} header={
                            <>
                                <img src={t.emblem ? t.emblem : defaultIcon} style={{height: 48, width: 48}}/>
                                <div className='p-accordion-title'>{t.name}</div>
                            </>
                        }>
                            <Stages data={t} toast={toast.current}/>
                        </AccordionTab>
                    ))}
                </Accordion>
            }
        </div>
    </div>
}

const Stages = ({ data, toast }) => {
    const [renderingGraphics, setRenderingGraphics] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0);
    const [state, setState] = useState(initState)

    const [matchdays, setMatchdays] = useState([])

    useEffect(() => {
        setState(initState)
    }, [activeIndex])

    useEffect(() => {
        setState(prev => ({...initState, group: prev.group}))
    }, [state?.group]);

    useEffect(() => {
        const stage = data.stages[activeIndex]
        let matches = []
        if (Array.isArray(state.range) && state.range[0] && state.range[1]) {
            const startDate = moment(state.range[0]).format('DD-MM')
            const endDate = moment(state.range[1]).format('DD-MM')
            matches = stage.matches[state.type === 'results' ? 'recent' : 'upcoming'].filter(m => m.date >= startDate && m.date <= endDate)
        } else if (!state.range) {
            matches = [...stage.matches.recent, ...stage.matches.upcoming]
        }
        const mdays = matches.reduce((acc, item) => {
            if (!acc.includes(item.matchdayId)) {
                acc.push(item.matchdayId);
            }
            return acc;
        }, []);
        setMatchdays(mdays)
    }, [state]);

    const clickHandler = (key, value) => {
        if (key === 'range') {
            if (value === state.range) {
                setState(prev => ({...prev, range: null, match: null}))
                return
            }
            setState(prev => ({...prev, range: value, match: null}))
            return;
        }
        setState(prev => ({...prev, [key]: value}))
    }

    const typeHandler = (item) => {
        switch (item.key) {
            case 'matches':
                setState(prev => ({...prev, url: item.standingsUrl, type: item.type, range: null, match: null}))
                break
            case 'stats':
                setState(prev => ({...prev, url: item.standingsUrl, type: item.type, range: null, match: null}))
                break
            case 'match':
                setState(prev => ({...prev, url: null, type: 'match', range: null, match: null}))
                break
            case 'info':
                setState(prev => ({...prev, url: item.standingsUrl, type: item.type}))
                break
            default:
                setState(prev => ({...prev, url: item.standingsUrl, type: item.type, range: null}))
        }
    }

    const downloadHandler = async (item) => {
        if (matchdays?.length > 0 && ['schedule', 'results'].includes(state?.type)) {
            for (let matchday of matchdays) {
                try {
                    setRenderingGraphics(true);

                    const renderResponse = await axios.get(`${ENDPOINT}render/elegance/matchdayv2/${matchday}${state?.type === 'schedule' ? '?type=schedule' : ''}`);
                    if (renderResponse) {
                        const shareResponse = await axios.get(`${ENDPOINT}share?url=${encodeURIComponent(`${ENDPOINT}render/elegance/matchdayv2/${matchday}`)}${state?.type === 'schedule' ? '?type=schedule' : ''}&asDecoded=true`);

                        if (shareResponse && shareResponse.data) {
                            const a = document.createElement("a");
                            a.href = "data:image/png;base64," + shareResponse.data;
                            a.download = `${data.name}_${item.name}_${matchday}${state.type && typeLabels[state.type] ? `_${typeLabels[state.type]}` : ''}.png`;
                            a.click();
                        } else {
                            toast.show({severity: 'error', summary: '', detail: 'Нет данных по этим параметрам. Пропробуйте изменить выбор', life: 1000});
                        }
                    } else {
                        toast.show({severity: 'error', summary: '', detail: 'Нет данных по этим параметрам. Пропробуйте изменить выбор', life: 1000});
                    }

                    setRenderingGraphics(false);
                } catch (e) {
                    toast.show({severity: 'error', summary: '', detail: 'Ошибка при обработке запроса. Попробуйте изменить выбор', life: 1000});
                    setRenderingGraphics(false);
                }
            }
        } else {
            try {
                setRenderingGraphics(true)
                await axios.get(`${ENDPOINT}${state.url}`)
                    .then(resp => {
                        if (resp) {
                            axios.get(`${ENDPOINT}share?url=${encodeURIComponent(ENDPOINT + state.url)}&asDecoded=true`)
                                .then(resp => {
                                    if (resp && resp.data) {
                                        const a = document.createElement("a")
                                        a.href = "data:image/png;base64," + resp.data
                                        a.download = `${data.name}_${item.name}${state.group?.name ? `_${state.group?.name}` : ''}${state.type && typeLabels[state.type] ? `_${typeLabels[state.type]}` : ''}.png`
                                        a.click()
                                    } else {
                                        toast.show({severity: 'error', summary: '', detail: 'Нет данных по этим параметрам. Пропробуйте изменить выбор', life: 1000})
                                    }
                                    setRenderingGraphics(false)
                                })
                        } else {
                            toast.show({severity: 'error', summary: '', detail: 'Нет данных по этим параметрам. Пропробуйте изменить выбор', life: 1000})
                            setRenderingGraphics(false)
                        }
                    })
            } catch (e) {
                toast.show({severity: 'error', summary: '', detail: 'Нет данных по этим параметрам. Пропробуйте изменить выбор', life: 1000})
                setRenderingGraphics(false)
            }
        }
    }

    return (
        <div className='content-stages'>
            <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} scrollable>
                {data?.stages?.map((item, index) => {
                    const matches = [...item.matches.recent, ...item.matches.upcoming].filter(f => {
                        switch (state.range) {
                            case 'upcoming':
                                return moment(`${f.date}, ${f.time}`, 'DD.MM, h:mm').isSameOrAfter(moment())
                                break;
                            case 'recent':
                                return moment(`${f.date}, ${f.time}`, 'DD.MM, h:mm').isBefore(moment())
                                break;
                            default:
                                if(Array.isArray(state.range) && state.range.every(e => e)) {
                                    return moment(`${f.date}, ${f.time}`, 'DD.MM, h:mm').isSameOrAfter(moment(state.range[0])) && moment(`${f.date}, ${f.time}`, 'DD.MM, h:mm').isSameOrBefore(moment(state.range[1]))
                                }
                                break;
                        }
                    })

                    return (
                        <TabPanel key={index} header={item.name}>
                            <div className='content-stages__item'>
                                {item?.groupsSplittedUrls?.length > 0 && (
                                    <div className='content-stages__item-block'>
                                        <div className='content-stages__item-title'>Выберите группу</div>
                                        <div className='content-stages__item-buttons'>
                                            <Button className={`${state.group === 'all' ? 'active' : ''}`} key={index} label='Все группы' onClick={e => clickHandler('group', 'all')}/>
                                            {item?.groupsSplittedUrls.map((item, index) => (
                                                <Button className={`${state.group?.name === item.name ? 'active' : ''}`} key={index} label={item.name} onClick={e => clickHandler('group', item)}/>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <div className='content-stages__item-block'>
                                    <div className='content-stages__item-title'>Тип графики</div>
                                    {state.group?.urls?.length > 0 ? (
                                        <div className='content-stages__item-buttons'>
                                            {state.group.urls.map((u, k) => (
                                                <Button key={k} className={`${state.url === u.standingsUrl ? 'active' : ''}`} label={u.name} onClick={e => typeHandler(u)}/>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className='content-stages__item-buttons'>
                                            {!!item.resultsUrl && <Button className={`${state.url == item.resultsUrl ? 'active' : ''}`} label='Результаты матчей' onClick={e => typeHandler({standingsUrl: item.resultsUrl, key: 'matches', type: 'results'})}/>}
                                            {!!item.scheduleUrl && <Button className={`${state.url == item.scheduleUrl ? 'active' : ''}`} label='Расписание' onClick={e => typeHandler({standingsUrl: item.scheduleUrl, key: 'matches', type: 'schedule'})}/>}
                                            {!!item.standingsUrl && <Button className={`${state.url == item.standingsUrl ? 'active' : ''}`} label='Турнирная таблица' onClick={e => typeHandler({standingsUrl: item.standingsUrl, key: 'stats', type: 'table'})}/>}
                                            <Button className={`${state.url === `render/elegance/avglineup/${data._id}` ? 'active' : ''}`} label='Символическая сборная' onClick={e => typeHandler({standingsUrl: `render/elegance/avglineup/${data._id}`, key: 'stats', type: 'lineup'})}/>
                                            {!!data.scorersUrl && <Button className={`${state.url === data.scorersUrl ? 'active' : ''}`} label='ТОП-10 бомбардиры' onClick={e => typeHandler({standingsUrl: data.scorersUrl, key: 'stats', type: 'scorers'})}/>}
                                            {!!data.scorersUrl && <Button className={`${state.url === `${data.scorersUrl}?type=a` ? 'active' : ''}`} label='ТОП-10 ассистенты' onClick={e => typeHandler({standingsUrl: `${data.scorersUrl}?type=a`, key: 'stats', type: 'assist'})}/>}
                                            {!!data.scorersUrl && <Button className={`${state.url === `${data.scorersUrl}?type=gas` ? 'active' : ''}`} label='ТОП-10 нападающие' onClick={e => typeHandler({standingsUrl: `${data.scorersUrl}?type=gas`, key: 'stats', type: 'forwards'})}/>}
                                            {!!data.scorersUrl && <Button className={`${state.url === `${data.scorersUrl}?type=cards` ? 'active' : ''}`} label='Худшие по наказаниям' onClick={e => typeHandler({standingsUrl: `${data.scorersUrl}?type=cards`, key: 'stats', type: 'cards'})}/>}
                                            {/*<Button className={`${state.type === 'preview' ? 'active' : ''}`} label='Афиша матча' onClick={e => typeHandler({standingsUrl: null, name: 'Афиша'})}/>*/}
                                            <Button className={`${['match', 'report', 'preview', 'mvph', 'mvpa'].includes(state.type) ? 'active' : ''}`} label='По матчам' onClick={e => typeHandler({standingsUrl: null, key: 'match'})}/>
                                        </div>
                                    )}
                                </div>
                                {!!ranges[['match', 'report', 'preview', 'mvph', 'mvpa'].includes(state.type) ? 'match' : state.type] && (
                                    <div className='content-stages__item-block'>
                                        <div className='content-stages__item-title'>Диапазон</div>
                                        <div className='content-stages__item-buttons'>
                                            {ranges[['match', 'report', 'preview', 'mvph', 'mvpa'].includes(state.type) ? 'match' : state.type].map((item, index) => {
                                                const isCalendar = item.key == 'period' && state?.range && Array.isArray(state?.range) ? true : false
                                                const hideBtn = (item.key == 'upcoming' && state.type == 'results') || (item.key == 'recent' && state.type == 'schedule')
                                                return (
                                                    item.key != 'period' ?
                                                        !hideBtn && <Button className={`${state.range == item.key ? 'active' : ''}`} key={index} label={item.label} icon={`pi pi-${item.icon}`} iconPos="right" onClick={e => clickHandler('range', item.key)}/>
                                                        :
                                                        <Calendar
                                                            value={isCalendar ? state?.range : []}
                                                            className={`${isCalendar ? 'active' : ''}`}
                                                            onChange={(e) => clickHandler('range', e.value)}
                                                            selectionMode="range"
                                                            readOnlyInput
                                                            hideOnRangeSelection
                                                            placeholder='Выбрать период'
                                                            prevIcon
                                                            showIcon
                                                            dateFormat={'dd.mm.yy'}
                                                            key={'calendar'}
                                                        />
                                                )
                                            })}
                                        </div>
                                    </div>
                                )}

                                {['match', 'report', 'preview', 'mvph', 'mvpa'].includes(state.type) && matches.length > 0 && (
                                    <div className='content-stages__item-block'>
                                        <div className='content-stages__item-title'>Матч</div>
                                        <div className='content-stages__item-buttons'>
                                            {matches.map((m, k) => (
                                                <Button key={k} className={`${state.match && state.match.url === (m.reportUrl || m.coverUrl) ? 'active' : ''}`} label={m.teams} onClick={e => clickHandler('match', {...m, url: m.reportUrl || m.coverUrl})}/>
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {!!(state.match && state.match.url) && (
                                    <div className='content-stages__item-block'>
                                        <div className='content-stages__item-title'>Информация о матче</div>
                                        <div className='content-stages__item-buttons'>
                                            {!!state.match.reportUrl && <Button className={`${state.url == state.match.reportUrl ? 'active' : ''}`} label='Матч репорт' onClick={e => typeHandler({standingsUrl: state.match.reportUrl, key: 'info', type: 'report'})}/>}
                                            {!!state.match.coverUrl && <Button className={`${state.url == state.match.coverUrl ? 'active' : ''}`} label='Афиша матча' onClick={e => typeHandler({standingsUrl: state.match.coverUrl, key: 'info', type: 'preview'})}/>}
                                            {!!state.match.homeMvpUrl && <Button className={`${state.url == state.match.homeMvpUrl ? 'active' : ''}`} label='MVP хозяев' onClick={e => typeHandler({standingsUrl: state.match.homeMvpUrl, key: 'info', type: 'mvph'})}/>}
                                            {!!state.match.awayMvpUrl && <Button className={`${state.url == state.match.awayMvpUrl ? 'active' : ''}`} label='MVP гостей' onClick={e => typeHandler({standingsUrl: state.match.awayMvpUrl, key: 'info', type: 'mvpa'})}/>}
                                        </div>
                                    </div>
                                )}
                                <Button className='content-stages__item-checkBtn active' onClick={() => downloadHandler(item)} label={'Проверить данные и скачать'} icon={`pi pi-download`} iconPos="right" disabled={!state?.url} loading={renderingGraphics}/>
                            </div>
                        </TabPanel>
                    )
                })}
            </TabView>

        </div>
    )
}

const Download = ({label, url, suffix}) => {
    const [renderingGraphics, setRenderingGraphics] = useState(false)

    const action = () => {
        setRenderingGraphics(true)
        axios.get(`${ENDPOINT}share?url=${encodeURIComponent(url)}&asDecoded=true`)
            .then(resp => {
                setRenderingGraphics(false)
                const a = document.createElement("a")
                a.href = "data:image/png;base64," + resp.data
                a.download = `${label}_${suffix}.png`
                a.click()
            })
    }

    return url ? <Button
        className='p-button-sm btn-create'
        icon='pi pi-image'
        onClick={() => action()}
        loading={renderingGraphics}
        label={label}
    /> : null

}

const ranges = {
    schedule: [
        {
            label: 'Следующая неделя',
            key: 'upcoming'
        },
        {
            label: 'Выбрать период',
            key: 'period',
            icon: 'calendar'
        },
    ],
    results: [
        {
            label: 'Прошедшая неделя',
            key: 'recent'
        },
        {
            label: 'Выбрать период',
            key: 'period',
            icon: 'calendar'
        },
    ],
    match: [
        {
            label: 'Прошедшая неделя',
            key: 'recent'
        },
        {
            label: 'Следующая неделя',
            key: 'upcoming'
        },
        {
            label: 'Выбрать период',
            key: 'period',
            icon: 'calendar'
        },
    ]
}

const initState = {
    group: 'all',
    // type: '', // types[0].key ||
    url: '',
    range: ranges[0]?.key || '',
    match: '',
}

const typeLabels = {
    results: 'Результаты матчей',
    schedule: 'Расписание',
    table: 'Турнирная таблица',
    scorers: 'ТОП-10 бомбардиры',
    assist: 'ТОП-10 ассистенты',
    forwards: 'ТОП-10 нападающие',
    report: 'Матч репорт',
    preview: 'Афиша матча',
    mvph: 'MVP хозяев',
    mvpa: 'MVP гостей',
    cards: 'Худшие по наказаниям',
    lineup: 'Символическая сборная'
}

export default Renders
