import React, {useContext, useEffect, useRef, useState} from "react";

import moment from "moment";

import {getByUrl, postByUrl, putByUrl} from "../../../../v3MethodsService";
import { useClickOutside } from "../../../../utils";
import {WorkspaceContext} from "../../../../ctx";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import {SelectButton} from "primereact/selectbutton";

import "./style.scss";

const NoticesModal = ({ state = false, onClose, onRemove, toast }) => {
    const wctx = useContext(WorkspaceContext);
    const role = wctx && wctx.appSubject && wctx.appSubject.type

    const ref = useRef();
    useClickOutside(state, ref, onClose);

    const [data, setData] = useState(null);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (state) {
            setData(!Array.isArray(state) && role === 'league' && !state._id ? {...state, type: 'admin'} : state)
        }
    }, [state]);

    const handleSend = () => {
        const body = {type: data.type, title: data.title, description: data.description, published: true};

        const executeAfterRequest = (resp) => {
            if (resp && resp.success) {
                toast.current.show({severity: 'success', detail: 'Уведомление отправлено пользователям', life: 2000})
                onClose()
            }
        }

        if (data._id) {
            putByUrl(`notices/${data._id}`, body).then(resp => executeAfterRequest(resp))
        } else {
            postByUrl('notices', body).then(resp => executeAfterRequest(resp))
        }
    }

    const handleSave = () => {
        const body = {type: data.type, title: data.title, description: data.description};

        const executeAfterRequest = (resp) => {
            if (resp && resp.success) {
                toast.current.show({severity: 'success', detail: 'Уведомление сохранено', life: 2000})
                onClose()
            }
        }

        if (data._id) {
            putByUrl(`notices/${data._id}`, body).then(resp => executeAfterRequest(resp))
        } else {
            postByUrl('notices', body).then(resp => executeAfterRequest(resp))
        }
    }

    const changeData = (value, key) => {
        setData(prev => ({ ...prev, [key]: value }))
    }

    const renderHeader = () => {
        if (Array.isArray(data)) {
            return <span className='notices-modal__type'>Уведомления</span>
        }
        if (data.published) {
            return <span className='notices-modal__type'>{typeLabels[data.type]}</span>
        }
        return (
            <div className="side-profile__header-btn-group">
                <Button className="side-profile__button-save auth2__button" label='Сохранить черновик' onClick={handleSave} disabled={!data.title || !data.description} loading={sending}/>
                <Button className="side-profile__button-save notices-modal__button_outlined" label="Отправить пользователям" onClick={handleSend} disabled={!data.title || !data.description} loading={sending}/>
                {!!data._id && <Button className="side-profile__button-save auth2__button auth2__button_outlined" label="Удалить" onClick={onRemove} loading={sending}/>}
            </div>
        )
    }

    const renderBody = () => {
        if (Array.isArray(data)) {
            return data.length > 0 ? data.map((d, i) => <Notice key={i} d={d}/>) : 'Нет уведомлений'
        }
        if (data.published) {
            return (
                <div className='notices-modal__notice'>
                    <div className='notices-modal__notice-header'>
                        <div className='notices-modal__notice-title'>{data.title || ''}</div>
                        <div className='notices-modal__notice-date'>{data.publishedDateFull ? moment(data.publishedDateFull).format('DD.MM HH:mm') : ''}</div>
                    </div>
                    <div className='notices-modal__notice-text'>{decodeURIComponent(data.description) || ''}</div>
                </div>
            )
        }
        return (
            <div className='notices-modal__body'>
                <div className='notices-modal__header'>{data._id ? 'Редактировать уведомление' : 'Создать уведомление'}</div>
                {role !== 'league' && <SelectButton value={data?.type} options={typeOptions} onChange={e => changeData(e.target.value, 'type')} className='notices-modal__select' unselectable={false}/>}
                <div key='title' className={`auth2__input-container auth2__input-container_full`}>
                    <label htmlFor='title' className="auth2__input-container-label">Заголовок*</label>
                    <InputText value={data.title || ""} onChange={(e) => changeData(e.target.value, 'title')} placeholder='Укажите заголовок' className='auth2__input'/>
                </div>
                <div key='description' className={`auth2__input-container auth2__input-container_full`}>
                    <label htmlFor='description' className="auth2__input-container-label">Описание*</label>
                    <InputTextarea value={decodeURIComponent(data.description) || ""} onChange={(e) => changeData(e.target.value, 'description')} placeholder='Опишите подробнее' className='auth2__input notices-modal__textarea'/>
                </div>
            </div>
        )
    }

    return !!data && !!state && (
        <div className="verifyModal notices-modal">
            <div
                className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <div className="side-profile p-component p-sidebar-enter-done notices-modal__side" ref={ref}>
                <div className="side-profile__header">
                        {renderHeader()}
                        <i className="side-profile__icon-close pi pi-times" onClick={onClose}/>
                    </div>
                    <div className="side-profile__body">
                        {renderBody()}
                    </div>
                </div>
            </div>
        </div>
    )
}

const typeLabels = {
    public: 'Публичное уведомление',
    admin: 'Административное уведомление'
}

const typeOptions = [
    {value: 'public', label: 'Публичные'},
    {value: 'admin', label: 'Административные'}
]

const Notice = ({ d }) => {
    const [showAll, setShowAll] = useState(decodeURIComponent(d.description)?.length <= 200)

    return (
        <div className='notices-modal__notice'>
            <div className='notices-modal__notice-header'>
                <div className='notices-modal__notice-title'>{d.title || ''}</div>
                <div
                    className='notices-modal__notice-date'>{d.publishedDateFull ? moment(d.publishedDateFull).format('DD.MM HH:mm') : d.publishedDate ? moment(d.publishedDate, 'YY-MM-DD').format('DD.MM HH:mm') : ''}</div>
            </div>
            <div className='notices-modal__notice-text'>{showAll ? (decodeURIComponent(d.description) || '') : (decodeURIComponent(d.description).slice(0, 200) + '...')}</div>
            {!showAll && <div className='notices-modal__notice-showall' onClick={() => setShowAll(true)}>Показать еще</div>}
        </div>
    )
}

export default NoticesModal