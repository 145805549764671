import React, {useEffect, useState} from "react";

import {getByUrl} from "../../../../../../../../../../v3MethodsService";

import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import './style.scss'

const GroupToPlayoff = ({ selectedStage, item, data, updateData, isFinished }) => {
    const [groups, setGroups] = useState([])

    const parentRound = item && item.parentId && data && data.playoffgrids ? data.playoffgrids.find(p => p._id === item.parentId) : false

    const unassignedPlacesWinners = parentRound?.rules
        ?.map((_, k) => k + 1)
        .filter(t => !item?.rules?.map(r => r.home?.pair).includes(t) && !item?.rules?.map(r => r.away?.pair).includes(t))
        ?.map(v => ({value: { who: "winner", pair: v}, label: `Победитель ${v} пары`}))

    useEffect(() => {
        if (selectedStage && selectedStage._id) {
            getByUrl(`groups?stageId=${selectedStage._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setGroups(resp.data.filter(r => r.teams?.length > 0))
                    }
                })
        }
    }, [selectedStage]);

    const addPair = () => {
        const newRules = item.rules ? [...item.rules] : []
        newRules.push({pair: newRules.length + 1 , home: parentRound ? {who: "winner", place: null} : {groupId: null, groupName: null, place: null}, away: parentRound ? {who: "winner", place: null} : {groupId: null, groupName: null, place: null}})
        const newPlayoffgrids = [...data.playoffgrids]
        const idxItem = newPlayoffgrids.findIndex(p => p._id === item._id)
        newPlayoffgrids[idxItem] = {...item, rules: newRules}
        updateData(newPlayoffgrids, 'playoffgrids')
    }

    const selectGroup = (value, idx, target) => {
        const newRules = item.rules ? [...item.rules] : []
        newRules[idx][target] = {...newRules[idx][target], ...value, place: null}
        const newPlayoffgrids = [...data.playoffgrids]
        const idxItem = newPlayoffgrids.findIndex(p => p._id === item._id)
        newPlayoffgrids[idxItem] = {...item, rules: newRules}
        updateData(newPlayoffgrids, 'playoffgrids')
    }

    const selectPlace = (value, idx, target) => {
        const newRules = item.rules ? [...item.rules] : []
        newRules[idx][target] = value
        const newPlayoffgrids = [...data.playoffgrids]
        const idxItem = newPlayoffgrids.findIndex(p => p._id === item._id)
        newPlayoffgrids[idxItem] = {...item, rules: newRules}
        updateData(newPlayoffgrids, 'playoffgrids')
    }

    const selectedPlaceTemplate = (option, props) => props.value && (props.value.place || props.value.pair) ? parentRound ? `Победитель ${props.value.pair} пары` : `${props.value.place} место` : props.placeholder

    const getPlaceOptions = (p, target) => {
        const targetGroup = groups.find(g => g._id === p[target].groupId)

        if (targetGroup) {
            const targetGroupRulesHome = item.rules.map(r => targetGroup._id === r.home.groupId && r.home.place)
            const targetGroupRulesAway = item.rules.map(r => targetGroup._id === r.away.groupId && r.away.place)

            return targetGroup?.teams
                ?.map((_, k) => k + 1)
                ?.filter(g => !targetGroupRulesHome.includes(g) && !targetGroupRulesAway.includes(g))
                ?.map(v => ({
                    value: {place: v, groupId: p[target].groupId, groupName: p[target].groupName},
                    label: `${v} место`
                }))
        }

        return []
    }

    return (
        <div className='group-to-playoff circle-to-playoff'>
            {item.rules?.map((p, k) => [
                <div key={k} className='circle-to-playoff__pair'>
                    <div className='circle-to-playoff__label'>
                        Пара {k + 1}
                    </div>
                    <div className='circle-to-playoff__selects'>
                        <div className='group-to-playoff__select-group'>
                            {!parentRound && <Dropdown value={{groupId: p.home.groupId, groupName: p.home.groupName}} onChange={e => selectGroup(e.value, k, 'home')} options={groups ? groups.map(g => ({value: {groupId: g._id, groupName: g.name}, label: g.name})) : []} placeholder='Выберите группу' className='circle-to-playoff__dropdown'/>}
                            <Dropdown
                                value={p.home}
                                onChange={e => selectPlace(e.value, k, 'home')}
                                options={parentRound ? unassignedPlacesWinners : groups ? getPlaceOptions(p, 'home') : []}
                                placeholder='Выберите место'
                                className='circle-to-playoff__dropdown'
                                disabled={(!parentRound && !p.home.groupId) || isFinished}
                                valueTemplate={selectedPlaceTemplate}
                                emptyMessage="Ничего не найдено"
                            />
                        </div>
                        <span>vs</span>
                        <div className='group-to-playoff__select-group'>
                            {!parentRound && <Dropdown value={{groupId: p.away.groupId, groupName: p.away.groupName}} onChange={e => selectGroup(e.value, k, 'away')} options={groups ? groups.map(g => ({value: {groupId: g._id, groupName: g.name}, label: g.name})) : []} placeholder='Выберите группу' className='circle-to-playoff__dropdown'/>}
                            <Dropdown
                                value={p.away}
                                onChange={e => selectPlace(e.value, k, 'away')}
                                options={parentRound ? unassignedPlacesWinners : groups ? getPlaceOptions(p, 'away') : []}
                                placeholder='Выберите место'
                                className='circle-to-playoff__dropdown'
                                disabled={(!parentRound && !p.away.groupId) || isFinished}
                                valueTemplate={selectedPlaceTemplate}
                                emptyMessage="Ничего не найдено"
                            />
                        </div>
                    </div>
                </div>,
                <Divider key={`divider-${k}`}/>
            ])}
            <Button onClick={addPair} label='Добавить пару' icon='pi pi-plus' className='circle-to-playoff__button' disabled={isFinished}/>
        </div>
    )
}

export default GroupToPlayoff;