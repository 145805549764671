import React, { useEffect, useRef, useState } from "react";

import { deleteByUrl, getByUrl, putByUrl } from "../../../../v3MethodsService";
import service from "../../../Tournaments/service";
import axios from "axios";
import {ENDPOINT} from "../../../../env";

import Profile from "./Profile";
import Settings from "./Settings";
import Teams from "./Teams";
import Stages from "./Stages";
import Platform from "./Platform";
import Team from "./DreamLineups";
import Disqualification from "./Disqualification";
import Documents from "./Documents";
import SideBar from "./SideBar";

import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { ListBox } from "primereact/listbox";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import {InputText} from "primereact/inputtext";

import "./style.scss";

const options = [
    { name: "Профиль", key: "profile" },
    { name: "Настройки", key: "settings" },
    { name: "Команды", key: "teams" },
    { name: "Структура и календарь", key: "calendar" },
    { name: "Площадки", key: "platform" },
    { name: "Сборная", key: "team" },
    { name: "Дисквалификации", key: "disqualification" },
    { name: "Документы", key: "documents" },
];

const TournamentsV2Detail = () => {
    const param = window.location.pathname.split("/");
    const toast = useRef(null);

    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [state, setState] = useState({ loader: true, btnOptions: false });

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [sideBarData, setSideBarData] = useState([]);
    const [sideBarState, setSideBarState] = useState([]);
    const [docs, setDocs] = useState([]);
    const [view, setView] = useState('list')
    
    const [filterValue, setFilterValue] = useState('');
    const [quering, setQuering] = useState(false)
    const filterInputRef = useRef();

    const mode = window.location.search.replace('?mode=', '')

    useEffect(() => {
        if (mode && !mode.includes('?editmatch=')) {
            const option = options.find(o => o.key === mode) || options[0];
            setSelectedOption(option)
        }
    }, [mode]);

    useEffect(() => {
        if (param[2]) {
            updateState(true, "loader");
            getByUrl(`tournaments/${param[2]}`).then((resp) => {
                if (resp && resp.success) {
                    setState((prev) => ({ ...prev, ...resp.data, loader: false }));
                }
            });
        }
    }, [param[2]]);

    useEffect(() => {
        if (selectedOption?.key === 'calendar') {
            updateState(true, "loader");
            setView('list')
            getByUrl(`tournaments/${param[2]}`).then((resp) => {
                if (resp && resp.success) {
                    setState((prev) => ({ ...prev, ...resp.data, loader: false }));
                }
            });
        }
    }, [selectedOption]);

    useEffect(() => {
        if(filterValue.length > 2 && !quering) {
            setQuering(true)
            axios.get(`${ENDPOINT}v2/list/queryTeams?query=${encodeURIComponent(filterValue)}`, {
                headers: {
                    authorization: localStorage.getItem('_amateum_auth_tkn')
                }
            })
                .then(suggs => {
                    const filtred = suggs.data.filter(s => !state.teams.find(t => t._id === s._id)).sort((s1, s2) => s1.name.toUpperCase().indexOf(filterValue.toUpperCase()) > s2.name.toUpperCase().indexOf(filterValue.toUpperCase()) ? 1 : -1)

                    updateState({...state?.btnOptions?.listbox, options: filtred.map(ul => ({label: ul.name, value: ul}))}, 'btnOptions', 'listbox')
                    setQuering(false)
                })
        } else {
            setQuering(false)
            if (state?.btnOptions?.listbox) {
                updateState({...state?.btnOptions?.listbox, options: []}, 'btnOptions', 'listbox')
            }
        }
    }, [filterValue])

    useEffect(() => {
        updateState(false, "btnOptions");
        setFilterValue('')
    }, [selectedOption]);

    const updateState = (value, key, subkey) => {
        if (subkey) {
            setState((prev) => ({ ...prev, [key]: { ...prev[key], [subkey]: value } }));
            return;
        }
        if (key) {
            setState((prev) => ({ ...prev, [key]: value }));
            return;
        }
        setState(value);
    };

    const handleClick = () => {
        if (selectedOption.key === "documents") {
            setSideBarData([])
            setSidebarVisible(true);
        }
        updateState(!!state.btnOptions ? false : { click: true }, "btnOptions");
    };

    const handleListbox = (item) => {
        switch (selectedOption?.key) {
            case 'teams': {
                state?.btnOptions?.listbox?.onClick(item);
                updateState(false, "btnOptions");
                setFilterValue('')
                break
            }
            case 'platform': {
                service.addLocationSlot(item._id, state._id, toast.current).then((resp) => {
                    if (resp) {
                        state?.btnOptions?.listbox?.onClick(item);
                        updateState(false, "btnOptions");
                        setFilterValue('')
                    }
                });
                break
            }
            case 'calendar': {
                service.patchData(null, 'stages', {
                    title: 'Новая стадия',
                    type: item,
                    tournamentId: state._id,
                    leagueId: state.leagueId,
                    roundsQty: 1,
                    seasonId: state.seasonId,
                    federationId: state.federationId
                }, toast.current).then(resp => {
                    if (resp) {
                        state?.btnOptions?.listbox?.onClick(resp);
                        const newStages = state.stages ? [...state.stages] : [];
                        newStages.push(resp);
                        updateState({ ...state, stages: newStages, btnOptions: false });
                    }
                });
                break
            }
        }
    };

    const getLeague = (settlement) => {
        getByUrl(`tournaments/${param[2]}`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setState(resp.data);
                setDocs(resp.data.docs);
            }
        });
    };

    const updateSideBar = (val, key) => {
        setSideBarData({ ...sideBarData, [key]: val });
    };

    const handleItem = (e) => {
        if (e.data) {
            let sideData = state.attachments.find((item) => item._id === e.data._id);
            setSideBarData(sideData);
            setSideBarState(sideData);
        } else {
            setSideBarData({});
            setSideBarState({});
        }
        setSidebarVisible(true);
    };

    const deleteHandler = (id) => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этому документу",
            header: "Удалить документ?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: () => {
                removeAttachment(id);
            },
            reject: () => {},
        });
    };
    const handleDocs = (toastName) => {
        putByUrl(`tournaments/${state._id}`, { docs: docs }).then((resp) => {
            if (resp && resp.success) {
                toast.current.show({ severity: "success", summary: toastName ? toastName : "Данные сохранены" });
                getLeague();
            } else {
                toast.current.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
        setSidebarVisible(false);
    };

    const removeAttachment = (id) => {
        deleteByUrl(`remove_attach/${id || sideBarData._id}`).then((resp) => {
            if (resp && resp.success) {
                const newDocs = state.docs.filter((d) => d !== sideBarData._id);
                setDocs(newDocs);
                handleDocs("Документ удален");
            } else {
                toast.current.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const Specified = selectedOption ? wrap[selectedOption.key] : wrap["profile"];

    const filterTemplate = () => {
        return (
            <div className="p-input-icon-right" style={{width: '100%'}}>
                <InputText value={filterValue} ref={filterInputRef} onChange={(e) => setFilterValue(e.target.value)} placeholder='Введите название команды' style={{width: '100%'}}/>
                {quering ? <i className="pi pi-spin pi-spinner" /> : null}
            </div>
        )
    }

    return (
        <div className="tournaments2">
            <Toast ref={toast} position="top-right" />
            <SideBar
                sideBarState={sideBarState}
                getLeague={getLeague}
                visible={sidebarVisible}
                setVisible={setSidebarVisible}
                data={sideBarData}
                state={state}
                updateData={updateSideBar}
                setData={setSideBarData}
                toast={toast.current}
                docs={docs}
                setDocs={setDocs}
                deleteHandler={deleteHandler}
                handleDocs={handleDocs}
            />
            {!param[3] && (
                <div className="tournaments2__header">
                    <SelectButton
                        options={options}
                        value={selectedOption}
                        onChange={(e) => {
                            if (e.value) setSelectedOption(e.value);
                        }}
                        optionLabel="name"
                    />
                    <div className="tournaments2__btn-container">
                        {((selectedOption?.key == 'calendar' && view == 'detail') ? false : !["profile", "settings"].includes(selectedOption?.key)) && <Button icon="pi pi-plus" onClick={handleClick} aria-controls="popup_menu" aria-haspopup />}
                        {!!state?.btnOptions?.listbox && <ListBox options={state?.btnOptions?.listbox?.options} onChange={(e) => handleListbox(e.value)} filter={!!state?.btnOptions?.listbox?.search} filterTemplate={filterTemplate}/>}
                    </div>
                </div>
            )}
            {state.loader ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <Specified toast={toast.current} getLeague={getLeague} state={state} updateState={updateState} setState={setState} deleteHandler={deleteHandler} handleItem={handleItem} setView={setView}/>
            )}
        </div>
    );
};

const wrap = {
    profile: Profile,
    settings: Settings,
    teams: Teams,
    calendar: Stages,
    platform: Platform,
    team: Team,
    disqualification: Disqualification,
    documents: Documents,
};

export default TournamentsV2Detail;
