import React, {useEffect, useState} from "react";

import service from "../../../../../../../Tournaments/service";
import {diffData} from "../../../../../../../../utils";

import Matchday from "../Matchday";

import {Button} from "primereact/button";

import "./style.scss";

const GroupsCalendar = ({ state, current, toast, updateCurrent, isFinished }) => {
    const [data, setData] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState(null)
    const [saveClick, setSaveClick] = useState(false)

    const isDiff = diffData(data, state)

    useEffect(() => {
        if (current && !data) {
            const st = {...current}
            const mds = st.matchdays && st.matchdays.length ? st.matchdays.sort((a,b) => a.name && b.name ? getNumFromName(a.name) - getNumFromName(b.name) : 0) : [];
            if (mds.length > 0) {

                const grteams = st.groups.reduce((acc, cur) => {
                    if (cur.teams && cur.teams.length > acc.length) {
                        acc = cur.teams
                    }
                    return acc;
                }, []);

                const totalTeamCount = st.groups.reduce((acc, g) => {
                    if (g.teams) {
                        acc += g.teams.length
                    }

                    return acc
                }, 0)

                const grmatchesCount = st.groups.reduce((acc, cur) => {
                    if (cur.teams) {
                        const mdsCurCount = cur.teams.length % 2 === 0 ? cur.teams.length - 1 : cur.teams.length;
                        const msCurCount = cur.teams.length % 2 === 0 ? cur.teams.length / 2 : ((cur.teams.length - 1) / 2);
                        acc += mdsCurCount * msCurCount;
                    }
                    return acc;
                }, 0);

                const mdsCount = grteams.length % 2 === 0 ? grteams.length - 1 : grteams.length;

                const msDBcount = mds.reduce((acc, cur) => {
                    acc += cur.matches.length
                    return acc
                }, 0)

                let settedMds = [];

                if (mdsCount === mds.filter(md => md._id).length || (st.roundsQty === 2 && mdsCount / st.roundsQty <= mds.filter(md => md._id).length)) {
                    if (msDBcount === grmatchesCount * mdsCount || (st.roundsQty === 2 && msDBcount >= grmatchesCount * mdsCount / st.roundsQty)) {
                        setType('fromDB')
                        //updateStage({...st, fillType: 'fromDB'})
                        settedMds = mds
                    } else {
                        setType('manual')
                        //updateStage({...st, fillType: 'manual'})
                        settedMds = manualMdsFilled(st, st.groups, mds);
                    }
                } else {
                    if (mds.find(md => md._id || md.matches.find(m => !m.home || !m.away))) {
                        setType('manual')
                        settedMds = manualMdsFilled(st, st.groups, mds);
                        //updateStage({...st, fillType: 'manual'})
                    } else {
                        //setType('auto')
                        //updateStage({...st, fillType: 'auto'})
                        settedMds = mds;
                    }
                }

                setData({
                    ...current,
                    matchdays: settedMds.sort((a, b) => a.name && b.name ? getNumFromName(a.name) - getNumFromName(b.name) : 0)
                })

            } else {
                setData(current)
            }
        }
    }, [current]);

    useEffect(() => {
        if (data && isDiff) {
            updateCurrent(data)
        }
    }, [isDiff]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData(prev => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else {
            setData(prev => ({ ...prev, [key]: val }));
        }
    };

    const checkUnassigned = () => {
        const assigned = data && data.teams ? data.teams.map(t => t._id): []

        return state?.teams
            .filter(t => !assigned.includes(t._id))
            .map(t => t.name)
    }

    const unassigned = checkUnassigned()

    const handleSave = async () => {
        const mapd = data?.matchdays?.map(md => {
            const ms = md.matches
                .map(m => ({homeId: m.homeId, awayId: m.awayId, matchId: m._id || null, matchdayId: md._id || null}));
            return {...md, matches: ms}
        })

        if (hasDuplicates(mapd)) {
            toast.show({severity: 'error', summary: '', detail: 'У некоторых туров совпадающие названия'})
            return
        }

        const settedMds = manualMdsFilled(data, data.groups, data?.matchdays);

        setLoading(true)
        setSaveClick(true)
        service.applyCalendarDraftV2(data._id, toast, mapd)
            .then(_ => {
                setLoading(false)
                setIsChanged(false)
                setType(null)
                setSaveClick(false)
                updateData(settedMds, 'matchdays')
            });
    }

    const updateMd = (md, id) => {
        const newMdays = [...data.matchdays]
        newMdays[id] = md
        updateData(newMdays, 'matchdays')
        setIsChanged(true)
    }

    const autoGenerate = async () => {
        setLoading(true)

        const draft = await service.calendarDraftV2({
            stageId: data._id,
            toast: toast
        })

        if (draft && draft.matchDays) {
            updateData(draft.matchDays, 'matchdays')
            setType('auto')
            setIsChanged(true)
        }

        setLoading(false)
    }

    const manualGenerate = async () => {
        const mdays = generateEmptyGroupMds(data.groups, '', data.roundsQty, data.roundsQty > 1, !!data.isReverseMirror);
        if (mdays) {
            updateData(mdays, 'matchdays')
        }
        setType('manual')
    }

    return data && data.matchdays?.length > 0 ? (
        <div className="calendar-detail">
            {unassigned?.length > 0 && (
                <div className='calendar-item__unassigned'>
                    <i className='pi pi-info-circle'/>
                    Не распределены команды: {unassigned.join(', ')}
                </div>
            )}
            <div className='calendar-item__row'>
                {data.matchdays.map((m, key) => <Matchday id={key} key={key} mday={m} current={data} state={state} updateMd={updateMd} saveClick={saveClick} isFinished={isFinished}/>)}
            </div>
            <div className="clubs-detail__button">
                {isChanged && <Button label="Сохранить" onClick={handleSave} disabled={!isChanged || loading || isFinished} loading={loading}/>}
                {type === 'auto' && <Button label="Другой вариант" onClick={autoGenerate} disabled={loading || isFinished} loading={loading} className='p-button-warning'/>}
            </div>
        </div>
    ) : (
        <div className="calendar-item calendar-item_empty">
            <div className='calendar-item__container'>
                Календаря еще нет.<br/>Выберите режим создания
                <div className='calendar-item__container-btn-set'>
                    <Button label='Сгенерировать автоматически' className='calendar-item__container-btn' onClick={autoGenerate} disabled={isFinished}/>
                    <Button label='Ручной режим' className='calendar-item__container-btn calendar-item__container-btn_outlined' onClick={manualGenerate} disabled={isFinished}/>
                </div>
            </div>
        </div>
    );
};

const generateEmptyGroupMds = (groups, dayName, roundsQty, isNeedReverse=false, isReverseMirror=false) => {
    //console.log('generateEmptyGroupMds', groups, dayName);
    const mds = [];
    for (let group of groups) {
        const teamsCount = group.teams.length;
        const matchesCount = Math.floor((teamsCount * 10 / 2)/10);
        let numberOfRounds = teamsCount % 2 === 0 ? (teamsCount - 1) * roundsQty : teamsCount * roundsQty;

        for (let i=0; i < numberOfRounds; i++) {
            const md = {
                name: dayName ? dayName.replace(/\d/g,'_').replace('_', `${i+1}`).replace(/_/g, '') : `${i+1} тур`,
                stageId: group.stageId,
                type: 'plain',
                matches: [],
                _id: i
            }
            for (let j=0; j < matchesCount; j++) {
                const newmatch = {
                    home: null,
                    away: null,
                    homeId: null,
                    awayId: null,
                    groupId: group._id,
                    groupName: group.name,
                }
                md.matches.push(newmatch)
            }
            mds.push(md)
        }
    }
    const matchdaysRed = mds.reduce((acc, cur) => {
        if (acc[cur.name]) {
            acc[cur.name]['matches'].push(...cur.matches)
        } else {
            acc[cur.name] = cur
        }
        return acc
    },{})

    const matchdays = Object.values(matchdaysRed).sort((a,b) => a.name && b.name ? getNumFromName(a.name) - getNumFromName(b.name) : 0)
    return matchdays
}

const getNumFromName = name => {
    const replaced = name.replace(/\D/g,'');
    if (replaced) {
        return +replaced
    } else {
        return 1
    }
}

const manualMdsFilled = (stage, groups, fromDBMds) => {
    const bindedCountMatchesWithGroups = groups.reduce((acc, cur) => {
        const teamsCount = cur.teams.length;
        const matchesCount = Math.floor((teamsCount * 10 / 2)/10);
        if (cur.name && !acc[cur.name]) {
            acc[cur.name] = matchesCount;
        }
        return acc;
    }, {})

    // if (fromDBMds && fromDBMds.length) {
    //     const mapdFilled = mergemdWithEmpty(fromDBMds, generateEmptyGroupMds(groups, fromDBMds[0].name));
    //     return mapdFilled;
    // } else {
    const filledEmpty = fromDBMds && fromDBMds.length ? generateEmptyGroupMds(groups, fromDBMds[0].name, stage.roundsQty) : generateEmptyGroupMds(groups);
    const mapdFilled = [];
    //console.log('filledEmpty', filledEmpty, 'fromDBMds', fromDBMds);
    for (let md of filledEmpty) {
        //console.log('md.name', md.name);
        const curMdMs = [];
        const fromDbMd = fromDBMds.find(item => item.name === md.name);
        if (fromDbMd && fromDbMd.matches) {
            const bindedMatchesWithGroups = fromDbMd.matches.reduce((acc, m) => {
                if (m.groupName && !acc[m.groupName]) {
                    acc[m.groupName] = []
                }
                if (acc[m.groupName]){
                    acc[m.groupName].push(m);
                }

                return acc;
            }, {})
            //console.log('bindedMatchesWithGroups', bindedMatchesWithGroups, 'bindedCountMatchesWithGroups', bindedCountMatchesWithGroups);
            for (let groupName in bindedCountMatchesWithGroups) {
                const countCurMs = bindedMatchesWithGroups[groupName] ? bindedMatchesWithGroups[groupName].length : 0;
                const restCount = bindedCountMatchesWithGroups[groupName] - countCurMs;

                if (bindedMatchesWithGroups[groupName] && bindedMatchesWithGroups[groupName].length) {
                    curMdMs.push(...bindedMatchesWithGroups[groupName]);
                }
                //console.log('groupName', groupName, countCurMs, 'restCount', restCount);
                if ( restCount > 0) {
                    curMdMs.push(...md.matches.filter(m => m.groupName === groupName).slice(0, restCount))
                }

            }
            mapdFilled.push({...fromDbMd, matches: curMdMs})

            /*for (let m of md.matches) {
                const fromDbM = curMdMs.filter(mt => mt.groupName === m.groupName);
                console.log('fromDbM', fromDbM, m.groupName);

                if (!fromDbM) {
                    curMdMs.push(m)
                }
            }*/
        } else {
            mapdFilled.push(md)
        }
    }
    //console.log('manualMdsFilled', mapdFilled);
    return mapdFilled.sort((a,b) => a.name && b.name ? getNumFromName(a.name) - getNumFromName(b.name) : 0);
    //}
}

const hasDuplicates = (arr) => {
    const nameSet = new Set();
    return arr.some(item => {
        if (nameSet.has(item.name)) {
            return true; // если найдено повторение
        }
        nameSet.add(item.name);
        return false;
    });
};

export default GroupsCalendar;
