import React, {useEffect, useState} from "react";

import './style.scss'
import {Divider} from "primereact/divider";
import {getByUrl} from "../../../../../../../../../../v3MethodsService";

const PlayoffToGroup = ({ stage, data, updateData, selectGroup, groupOp, renameGroups }) => {
    const [selected, setSelected] = useState({});

    const [playoffGrids, setPlayoffGrids] = useState([])

    const places = data && data.rules ? data.rules : []
    const placesByGroup = data && data.groups ? data.groups.map(r => r.rules ? r.rules : []) : []

    useEffect(() => {
        if (stage && stage._id) {
            getByUrl(`playoffgrids?playoffId=${stage._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setPlayoffGrids(resp.data.filter(r => r.teams?.length > 0))
                    }
                })
        }
    }, [stage]);

    const updateSelected = (i, g) => {
        if (selected[g] && selected[g].includes(i)) {
            const newSelected = {...selected}
            newSelected[g] = newSelected[g].filter(p => p !== i)
            setSelected(newSelected)
            return
        }
        const newSelected = {...selected}
        if (!newSelected[g]) newSelected[g] = []
        newSelected[g].push(i)
        setSelected(newSelected)
    }

    return (
        <div className='playoff-to-group circle-to-group'>
            <div className='circle-to-group__header'>Выберите команды по итогам предыдущей стадии</div>
            {data?.groups?.map((g, idx) => (
                <div key={idx} className='circle-to-group__container'>
                    <div className='circle-to-group__label' onClick={(e) => (idx === 0 ? groupOp.current.toggle(e) : null)}>
                        Группа {selectGroup === "alphabetic" ? String.fromCharCode(65 + idx) : idx + 1} {idx === 0 && <p className="pi pi-chevron-down" style={{margin: 0}}></p>}
                    </div>
                    <div className='group-to-circle__content'>
                        <div className='group-to-circle__container'>
                            <div className='group-to-circle__group-label'>Победитель</div>
                            <div className='circle-to-circle__content'>
                                {Array.from({ length: playoffGrids.teams?.length || 0 },(_, i) => (
                                    <div key={i} onClick={() => updateSelected(i, 'winner')} className={`group-to-circle__team circle-to-circle__team ${selected['winner'] && selected['winner'].includes(i) ? 'circle-to-circle__team_selected' : ''}`}>
                                        {i + 1} <span>пара</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <Divider/>
                        <div className='group-to-circle__container'>
                            <div className='group-to-circle__group-label'>Проигравший</div>
                            <div className='circle-to-circle__content'>
                                {g.teams.map((_, i) => (
                                    <div key={i} onClick={() => updateSelected(i, 'loser')} className={`group-to-circle__team circle-to-circle__team ${selected['loser'] && selected['loser'].includes(i) ? 'circle-to-circle__team_selected' : ''}`}>
                                        {i + 1} <span>пара</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default PlayoffToGroup