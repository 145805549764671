import React, {useState, useEffect, useContext} from 'react'
import { useHistory } from "react-router-dom";
import Hotkeys from "react-hot-keys";

import {postByUrl} from "../../../../v3MethodsService";
import {GlobalContext} from "../../../../ctx";
import axios from "axios";
import {ENDPOINT} from "../../../../env";

import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";

const oldTkn = localStorage.getItem("_amateum_tkn")

const EmailPassword = () => {
    const history = useHistory()

    const [state, setState] = useState(null)
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(false)

    const [global, setGlobal] = useContext(GlobalContext)
    const toast = global && global.toast ? global.toast : {}

    useEffect(() => {
        if (state) {
            const notValid = formData.map(f => !state[f.key]).filter(f => f)
            setIsValid(!notValid.length)
        } else {
            setIsValid(false)
        }
    }, [state]);

    const inputHandler = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const sendHandler = async () => {
        const resp = await axios.post(
            `${ENDPOINT}v2/migrateUserToRoles`,
            {email: state.email, password: state.password},
            {headers: {Signedby: oldTkn}}
        )

        if (resp && resp.data && resp.data.success) {
            toast.show({ severity: "success", life: 4000, summary: "Письмо для завершения регистрации отправлено на почту" });
            history.push('/login')
        } else {
            toast.show({ severity: "error", life: 4000, summary: resp?.data?.msg || 'Ошибка завершения регистрации' });
        }
    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return (
        <div className='auth2__container login'>
            <div className='auth2__title'>
                Завершение регистрации
            </div>
            <span className='auth2__input-label'>Для входа в обновленный кабинет укажите действующую почту и новый пароль</span>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className='auth2__inputs'>
                    {formData.map(f => renderField(state, f, inputHandler, error))}
                    <Button label='Зарегистрироваться' onClick={sendHandler} className='auth2__button' disabled={!isValid}/>
                </div>
            </Hotkeys>
        </div>
    )
}

const formData = [
    {key: "email", placeholder: "Введите почту", type: "input", size: "full"},
    {key: "password", label: "Пароль", placeholder: "Придумайте пароль", type: "password", size: "half"},
    {key: "repeatPassword", label: "Подтверждение пароля", placeholder: "Повторите пароль", type: "password", size: "half"}
]

const renderField = (state, field, inputHandler, error) => {
    switch (field.type) {
        case 'input': return <InputText id={field.key} key={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`auth2__input${error ? ' auth2__input_invalid' : ''}`}/>
        case 'password': return <Password id={field.key} key={field.key} value={state?.[field.key] || ''} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className={`auth2__password${error ? ' auth2__password_invalid' : ''}`} toggleMask feedback={false}/>
        default: return null
    }
}

export default EmailPassword