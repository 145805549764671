import React, {useEffect, useState} from 'react';

const ImageWithFallback = ({ src, alt, fallback, ...props }) => {
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(!src)
    }, [src])

    if (isError) {
        return <div {...props}>{fallback}</div>;
    }

    return (
        <img
            src={src}
            alt={alt}
            onError={() => setIsError(true)}
            {...props}
        />
    )
};

export default ImageWithFallback;