import React, {useEffect, useContext, useRef} from "react"
import { useParams, useHistory } from "react-router-dom"

import { GlobalContext } from '../../ctx'

import {Toast} from "primereact/toast";

import Login from "./Login"
import Reg from "./Reg"
import Recover from "./Recover"
import OldUser from "./OldUser";

import authText from "./img/authText.svg";

import './style.scss'

const Root = () => {
    const { firstParam } = useParams()
    const history = useHistory()
    const toast = useRef(null)

    const [global, setGlobal] = useContext(GlobalContext)
    
    useEffect(() => {
        const oldTkn = localStorage.getItem("_amateum_tkn")

        const routing = () => {
            if (oldTkn && firstParam !== 'old-user') {
                history.push('/old-user')
                return
            }
            if (!firstParam || !wrap[firstParam]) {
                history.push('/login')
            }
        }

        routing()

        setGlobal(prev => ({...prev, toast: toast.current}))
    }, [])

    const updateGlobal = (val, key) => {
        setGlobal(prev => ({...prev, [key]: val}))
    }

    const Specified = wrap[firstParam] ? wrap[firstParam] : wrap.login;

    return (
        <div className='auth2'>
            <Toast ref={toast} position="top-right"/>
            <div className='auth2__bg'>
                <img className='auth2__bg-authText' src={authText} alt="authText"/>
                <img className='auth2__bg-authTextMob' src={authText} alt="authText"/>
            </div>
            <Specified updateGlobal={updateGlobal}/>
        </div>
    )
}

const wrap = {
    login: Login,
    reg: Reg,
    recover: Recover,
    'old-user': OldUser
}

export default Root
