import React, { useState } from "react";

import moment from "moment";

import { pluralForm } from "../../../../utils";
import { Tooltip } from "primereact/tooltip";
import { Button } from "primereact/button";
import { putByUrl } from "../../../../v3MethodsService";
import { confirmDialog } from "primereact/confirmdialog";
import icon1 from "../img/icon1.png";

import "./style.scss";

const Payment = ({ d, idx, role, onSuccessfulPost, onError }) => {
    const [showAll, setShowAll] = useState(false);

    const [year, month, day] = d.createDate.split("-").map(Number);

    const dateObject = new Date(2000 + year, month - 1, day);

    const handleAccept = async () => {
        const response = await putByUrl(`bills/${d._id}`, { status: "finished" });
        console.log(response);
        response?.success ? onSuccessfulPost("Платеж отмечен как подтвержденный") : onError(response?.message);
    };

    const handleReject = async () => {
        const response = await putByUrl(`bills/${d._id}`, { status: "declined" });
        response?.success ? onSuccessfulPost("Платеж отклонен") : onError(response?.message);
    };

    const accpetModal = () => {
        confirmDialog({
            message: "Представитель лиги будет ожидать, что вы оплатите данную сумму",
            header: "Подтвердить заявку на платеж?",
            icon: "pi pi-exclamation-triangle",
            acceptLabel: "Подтвердить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: handleAccept,
        });
    };

    const rejectModal = () => {
        confirmDialog({
            message: "Заявка на платеж будет безвозвратно удалена и вы не можете подтвердить ее позже",
            header: "Отклонить заявку на платеж?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Отклонить",
            rejectLabel: "Отмена",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: handleReject,
        });
    };

    return (
        <div className="payment" key={idx}>
            <div className="payment__header">
                <div className="payment__bill">{d.amount.toLocaleString('ru-RU') + " ₽" || ""}</div>
                <div className="payment__date">{moment(dateObject).format("DD.MM")}</div>
            </div>
            <div className="payment__title">{d.title || ""}</div>
            <div className="notices-modal__notice-text">
                {showAll || d.description.length < 200 ? decodeURIComponent(d.description) || "" : decodeURIComponent(d.description).slice(0, 200) + "..."}
            </div>
            {!showAll && d.description.length >= 200 && (
                <div className="notices-modal__notice-showall" onClick={() => setShowAll(true)}>
                    Показать еще
                </div>
            )}
            <div className="payment__container">
                {d.clubs ? (
                    d.clubs.length > 1 ? (
                        <div className="payment__teams">
                            <Tooltip className="payment__tooltip" target={`.payment__imgs-${idx}`} position="top" autoHide={false}>
                                {d.clubs.map((team) => {
                                    return (
                                        <div key={team._id} className="payment__team">
                                            <img src={team.emblem || icon1} />
                                            <span>{team.name}</span>
                                        </div>
                                    );
                                })}
                            </Tooltip>
                            <div className={`payment__imgs payment__imgs-${idx}`}>
                                <img src={d?.clubs[0].emblem || icon1} />
                                <img src={d?.clubs[1].emblem || icon1} />
                                {d.clubs[2] && <img src={d.clubs[2].emblem || icon1} />}
                            </div>
                            <span>{d.clubs.length + " клуб" + pluralForm(d.clubs.length, ["", "а", "ов"])}</span>
                        </div>
                    ) : (
                        <div className="payment__team">
                            <img src={d.clubs[0].emblem || icon1} />
                            <span>{d.clubs[0].name}</span>
                        </div>
                    )
                ) : (
                    <div className="payment__team">
                        <img src={d.club.emblem || icon1} />
                        <span>{d.club.name}</span>
                    </div>
                )}
            </div>
            {role === "club" && d.status === "active" && (
                <div className="payment__btns">
                    <Button icon="pi pi-check" label="Подтвердить" className="payment__accept" onClick={accpetModal} />
                    <Button icon="pi pi-times" label="Отклонить" className="payment__reject" onClick={rejectModal} />
                </div>
            )}
        </div>
    );
};

export default Payment;
