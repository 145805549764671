import React, { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";

import "./style.scss";
import DataTable from "../../../Common/DataTable";
import SideProfile from "../../../SideProfile";
import { deleteByUrl, getByUrl } from "../../../../../v3MethodsService";

const Members = ({ toast, data, setData }) => {
    const [state, setState] = useState(data);
    const [status, setStatus] = useState('')
    const param = window.location.pathname.split("/");

    const [selectedProfile, setSelectedProfile] = useState(null);
    const [id, setId] = useState(null);

    const createCustomItem = (info) => !!info && (
        <div className="clubs-members__item">
            <div className="clubs-members__avatar">
                {info.icon ? <img src={info.icon} alt="avatar" /> : `${info.surname ? info.surname.slice(0, 1).toUpperCase() : ""}${info.name ? info.name.slice(0, 1).toUpperCase() : ""}`}
            </div>
            <span>{`${info.surname ? info.surname : ""} ${info.name ? info.name.slice(0, 1).toUpperCase() + "." : ""}${info.middlename ? info.middlename.slice(0, 1).toUpperCase() + "." : ""}`}</span>
        </div>
    );

    const createStatusItem = (status) => {
        switch (status) {
            case "VALID":
                return <div className="clubs-members__status valid">верифицирован</div>;
            case "NOT_VALID":
            case "ARCHIVED":
                return <div className="clubs-members__status notvalid">заблокирован</div>;
            case "MODERATION":
                return <div className="clubs-members__status">идет верификация</div>;
            default:
                return <div className="clubs-members__status valid">верифицирован</div>;
        }
    };

    const openProfile = (e) => {
        setSelectedProfile(e.data.user._id);
        setId(e.data._id);
        setStatus(e.data?.status?.custom?.props?.children)
    };


    const handleBlockRole = async () => {
        await deleteByUrl(`rolesscopes/${id}`).then((resp) => {
            if (resp && resp.success) {
                getByUrl(`clubs/${param[2]}`).then((resp) => {
                    if (resp && resp.success && resp.data) {
                        setState(resp.data);
                        setData(resp.data);
                        toast.show({ severity: "success", summary: "Роль отозвана", life: 1000 });
                    } else {
                        toast.show({ severity: "error", summary: resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                    }
                });
            } else {
                toast.show({ severity: "error", summary: resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
        setSelectedProfile(null);
    };

    let rows = state ? [...new Set([...state?.scopes, ...state?.verifications?.filter((item) => item.status !== "VALID")])] : [];

    return (
        state && rows && (
            <div className="clubs-members">
                <SideProfile status={status} visible={!!selectedProfile} onHide={() => setSelectedProfile(null)} target="clubs" id={selectedProfile} toast={toast} handleButton={handleBlockRole} />
                <DataTable
                    noCreateBtn
                    searchPlaceholder="ФИО"
                    search={true}
                    columns={[
                        { field: "info", header: "Пользователь" },
                        { field: "email", header: "Email" },
                        { field: "phone", header: "Телефон" },
                        { field: "status", header: "Статус представителя" },
                    ]}
                    rows={rows.map((row) => ({
                        ...row,
                        info: { custom: createCustomItem(row?.user), value: row?.user },
                        email: { value: row?.user?.email },
                        phone: { value: row?.user?.phone },
                        status: { custom: createStatusItem(row?.status), value: row?.status },
                    }))}
                    paginator={state.length > 8}
                    perPage={8}
                    onClick={openProfile}
                />
            </div>
        )
    );
};

export default Members;
