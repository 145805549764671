import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import moment from "moment/moment";
import { transliterate } from "transliteration";
import { diff } from "deep-object-diff";
import { useClickOutside } from "../../../utils";

import { Tooltip } from "primereact/tooltip";

import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../v3MethodsService";
import { GlobalContext, WorkspaceContext } from "../../../ctx";
import { PUBLICENDPOINT } from "../../../env";
import { confirmDialog } from "primereact/confirmdialog";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";
import { OverlayPanel } from "primereact/overlaypanel";
import { FileUpload } from "primereact/fileupload";

import SideProfileEditor from "./Editor";

import "./style.scss";

const SideProfile = ({ visible = false, onHide = () => {}, target = "", id, updateUsers, data, noVerify, handleButton, status = false }) => {
    const ref = useRef();
    const ctx = useContext(WorkspaceContext);
    const type = ctx && ctx.workspace && ctx.workspace.subject && ctx.workspace.subject.type;
    useClickOutside(visible, ref, onHide);

    const history = useHistory();
    const location = useLocation();
    const fileUploadRef = useRef(null);
    const [global, setGlobal] = useContext(GlobalContext);
    const toast = global && global.toast ? global.toast : {};
    const [state, setState] = useState(null);
    const [changableState, setChangableState] = useState(null);
    const [isEditor, setIsEditor] = useState(false);
    const [selectedTab, setSelectedTab] = useState("");
    const [stateRole, setStateRole] = useState({});

    const [isValid, setIsValid] = useState(false);
    const [showInvalid, setShowInvalid] = useState(false);

    const [disabledBtn, setDisabledBtn] = useState(true);

    useEffect(() => {
        let body = document.querySelector(".page");
        if (body && visible) {
            // body.style.position = 'fixed';
            // body.style.top = '0px';

            body.style.overflow = "hidden";
            body.style["touch-action"] = "none";
            body.style["-webkit-overflow-scrolling"] = "none";
            body.style["overscroll-behavior"] = "none";

            body.style.width = "100%";
        } else {
            body.style = {};
        }

        return () => {
            setIsEditor(false);
            setStateRole(false);
        };
    }, [visible]);

    useEffect(() => {
        if (noVerify && !['/', '/support'].includes(location.pathname)) {
            history.replace("/");
        }
    }, [noVerify, location]);

    useEffect(() => {
        if (id || (data && data._id)) {
            getByUrl(`users/${id || data._id}`).then((resp) => {
                if (resp && resp.success) {
                    setState(resp.data);
                    setChangableState(resp.data);
                }
            });
        } else {
            setState(data || noVerify || null);
            setChangableState(data || noVerify || null);
        }
    }, [id, data, isEditor, noVerify]);

    useEffect(() => {
        if (changableState) {
            const notValid = formData.map((f) => !changableState[f.key] && !["password", "repeat", "phone"].includes(f.key)).filter((f) => f);
            setIsValid(!notValid.length);
        } else {
            setIsValid(false);
        }
    }, [changableState]);

    const changeTabHandler = (id) => {
        setSelectedTab(selectedTab === id ? "" : id);
    };

    const blockHandler = () => {
        confirmDialog({
            message: "Вы уверены, что хотите заблокировать аккаунт?",
            header: "Заблокировать?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            draggable: false,
            accept: () => blockUser(true, "blocked"),
            reject: () => {},
        });
    };

    const blockUser = async (val, key) => {
        const resp = await putByUrl(`users/${id || changableState._id}`, { [key]: val });
        if (resp && resp.success) {
            changeState(val, key)
            if (val) {
                toast.show({ severity: "success", summary: "Аккаунт пользователя заблокирован", life: 2000 });
                return;
            }
            toast.show({ severity: "success", summary: "Аккаунт пользователя разблокирован", life: 2000 });
            return;
        }
        toast.show({ severity: "warn", summary: "Произошла ошибка. Повторите попытку позже.", life: 2000 });
    };

    const unblockHandler = () => {
        confirmDialog({
            message: "Вы уверены, что хотите разблокировать аккаунт?",
            header: "Разблокировать?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            draggable: false,
            accept: () => blockUser(false, "blocked"),
            reject: () => {},
        });
    };

    const saveHandler = async () => {
        if (isValid) {
            const isEmailChanged = changableState.email !== state.email;
            const changedObj = {
                surname: changableState.surname,
                name: changableState.name,
                middlename: changableState.middlename,
                phone: changableState.phone,
                birthday: changableState.birthday,
            };
            const respChangeProfile = await putByUrl(`users/${id || changableState._id}`, changedObj);
            if (respChangeProfile && respChangeProfile.success) {
                setIsEditor(false);
                toast.show({ severity: "success", summary: "Изменения сохранены", life: 1000 });
                if (updateUsers) {
                    updateUsers();
                }
            }
            if (isEmailChanged && target === "self") {
                confirmDialog({
                    message: (
                        <>
                            Вы получите письмо с ссылкой для подтверждения нового адреса электронной почты.
                            <br />
                            Обратите внимание, что вы потеряете доступ к аккаунту, пока не подтвердите новый адрес.
                        </>
                    ),
                    header: "Вы уверены, что хотите сменить почту?",
                    icon: "pi pi-exclamation-triangle",
                    acceptClassName: "p-button-danger",
                    acceptLabel: "Да",
                    rejectLabel: "Нет",
                    draggable: false,
                    accept: async () => {
                        const respChangeEmail = await postByUrl("changeEmail", { email: changableState.email });
                        if (respChangeEmail && respChangeEmail.success) {
                            // navigator.clipboard.writeText(respChangeEmail.token) only dev !!!!!!
                            navigator.clipboard.writeText(respChangeEmail.token).then((r) => {
                                getByUrl("init").then((r) => {
                                    if (r && r.success) {
                                        setGlobal((prev) => ({ ...prev, auth: r }));
                                    } else {
                                        setGlobal((prev) => ({ ...prev, noVerify: r, auth: false, menu: false }));
                                    }
                                });
                            });
                        } else {
                            toast.show({ severity: "error", summary: respChangeEmail?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                        }
                    },
                    reject: () => {},
                });
            }
        } else {
            setShowInvalid(true);
            toast.show({ severity: "error", summary: "Заполните все обязательные поля", life: 1000 });
        }
    };

    const changeState = (val, key) => {
        setState((prev) => ({ ...prev, [key]: val }));
    };

    const changeSaveState = (val, key) => {
        setChangableState((prev) => ({ ...prev, [key]: val }));
    };

    const exitHandler = () => {
        confirmDialog({
            message: "Вы уверены, что хотите выйти из аккаунта?",
            header: "Выйти из аккаунта",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            draggable: false,
            accept: () => {
                localStorage.removeItem("_amateum_auth_tkn");
                localStorage.removeItem("_amateum_auth_tkn");
                window.location.reload();
            },
            reject: () => {},
        });
    };

    const sendRole = () => {
        const { clubId, leagueId, regionId, roleId } = stateRole;
        const type = clubId ? "club" : leagueId ? "league" : regionId ? "region" : "system";
        const subjectId = clubId || leagueId || regionId || state._id;
        postByUrl("verifications", {
            roleId: roleId,
            subjectId: subjectId,
            subjectType: type,
            userId: state._id,
        }).then((resp) => {
            if (resp && resp.success) {
                setIsEditor(false);
                setStateRole({});
                toast.show({ severity: "success", summary: "Запрос отправлен", life: 3000 });
            } else {
                toast.show({ severity: "error", summary: resp && resp.message ? resp.message : "Ошибка", life: 3000 });
            }
        });
    };

    const diffData = (data, state) => {
        const prepareData = (obj) => {
            if (obj) {
                return Object.keys(obj)?.reduce((acc, row) => {
                    if (Array.isArray(obj[row]) && obj[row].some((s) => s._id)) {
                        //если масиив, то приводим к нужному виду
                        acc[row] = obj[row].map((it) => it._id);
                    } else if (typeof obj[row] === "string" && obj[row].match(/(\d+)-(\d+)-(\d+)\w(\d+):(\d+):(\d+)/gim)) {
                        //если дата, то приводим к new Date
                        acc[row] = new Date(obj[row]);
                    } else {
                        acc[row] = obj[row];
                    }

                    return acc;
                }, {});
            }
        };

        const newState = prepareData(state);
        const newData = prepareData(data);

        const obj = diff(newData, newState);
        const arr = obj && Object.keys(obj);
        const resp = {};

        if (arr?.length) {
            arr.forEach((item) => {
                if (obj[item] !== undefined) {
                    resp[item] = newState[item];
                }
            });
        }

        return Object.keys(resp).length ? resp : false;
    };

    const lkHandler = () => {
        window.open(`${PUBLICENDPOINT}?fromAdmin`, "_blank");
    };


    const getHeader = () => {
        switch (target) {
            case "foreign": {
                if (isEditor) {
                    return (
                        <div className="side-profile__header-btn-group">
                            <i className="side-profile__icon-close pi pi-chevron-left" onClick={() => setIsEditor(false)} />
                            <Button className="side-profile__button-save auth2__button auth2__button_outlined" disabled={!diffData(state, changableState)} label="Сохранить" onClick={saveHandler} />
                        </div>
                    );
                }

                return type === 'system' ? <Button className={`${!state.blocked ? 'side-profile__button-block' : 'side-profile__button-unblock'} auth2__button_outlined`} label={`${!state.blocked ? 'Заблокировать' : 'Разблокировать'}`} onClick={state.blocked ? unblockHandler : blockHandler} /> : null
            }

            case "self": {
                if (isEditor) {
                    return (
                        <div className="side-profile__header-btn-group">
                            <i
                                className="side-profile__icon-close pi pi-chevron-left"
                                onClick={() => {
                                    setIsEditor(false);
                                    setStateRole({});
                                }}
                            />
                            <Button
                                className="side-profile__button-save auth2__button auth2__button_outlined"
                                disabled={isEditor.key === "role" ? !disabledBtn : !diffData(state, changableState)}
                                label={`${isEditor.key === "role" ? "Отправить запрос" : "Сохранить"}`}
                                onClick={isEditor.key === "role" ? sendRole : saveHandler}
                            />
                        </div>
                    );
                }

                return (
                    <div className="side-profile__header-btn-group">
                        <Button className="side-profile__button-block auth2__button_outlined" label="Выйти" onClick={exitHandler} />
                        {!!global?.auth?.hasOtherVerifyAccount && <Button className="side-profile__button-save auth2__button_outlined" label="ЛК публичной части" onClick={lkHandler} />}
                    </div>
                );
            }

            case "clubs": {
                if (!state.blocked && status === "верифицирован") {
                    return (
                        <div className="side-profile__header-btn-group">
                            <Button className="side-profile__button-block auth2__button_outlined" label="Заблокировать" onClick={handleButton} />
                        </div>
                    );
                }
                return null;
            }

            default:
                return null;
        }
    };

    const uploadAttachment = (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("sampleId", state._id);
        formData.append("sampleType", "users");
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.attachId;
                const newDocs = state && state.docs && state.docs.length > 0 ? state.docs.concat(file) : [].concat(file);
                putByUrl(`users/${id || state._id}`, { ...state, docs: newDocs }).then((resp2) => {
                    if (resp2 && resp2.success) {
                        changeState(newDocs, "docs");
                        toast.show({ severity: "success", life: 3000, summary: `Данные успешно отправлены` });
                        getByUrl(`users/${id || data._id}`).then((resp) => {
                            if (resp && resp.success) {
                                setState(resp.data);
                                setChangableState(resp.data);
                            }
                        });
                    }
                });
            } else {
                toast.show({ severity: "warn", life: 4000, summary: `Ошибка загрузки файла` });
            }
        });

        fileUploadRef.current.clear();
    };

    const removeAttachment = (_id) => {
        deleteByUrl(`remove_attach/${_id}`).then((resp) => {
            if (resp && resp.success) {
                const newDocs = state.docs.filter((d) => d !== _id);
                putByUrl(`users/${id || state._id}`, { docs: newDocs }).then((resp2) => {
                    if (resp2 && resp2.success) {
                        toast.show({ severity: "success", life: 3000, summary: `Данные успешно удалены` });
                        getByUrl(`users/${id || state._id}`).then((resp) => {
                            if (resp && resp.success) {
                                setState(resp.data);
                                setChangableState(resp.data);
                            }
                        });
                    }
                });
            }
        });
    };

    return (
        !!visible &&
        !!state && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <div
                    className={`side-profile p-component p-sidebar-enter-done${(target === "self" && !state.verified) || !ctx.appSubject || ctx.appSubject.onlyLiveMode  ? " side-profile_no-verify" : ""}`}
                    ref={target === "self" && !state.verified ? null : ref}
                >
                    <Tooltip target=".p-fileupload" />

                    <div className="side-profile__header">
                        {getHeader()}
                        {!noVerify && <i className="side-profile__icon-close pi pi-times" onClick={onHide} />}
                    </div>
                    {isEditor ? (
                        <SideProfileEditor
                            setDisabledBtn={setDisabledBtn}
                            state={changableState}
                            changeState={changeSaveState}
                            editorData={isEditor}
                            showInvalid={showInvalid}
                            isRole={isEditor && isEditor.key === "role"}
                            stateRole={stateRole}
                            setStateRole={setStateRole}
                        />
                    ) : (
                        <div className="side-profile__body">
                            <div className="side-profile__account">
                                <div className="side-profile__avatar">
                                    {state.icon ? (
                                        <img src={state.icon} alt="avatar" />
                                    ) : state.surname ? (
                                        state.surname?.slice(0, 1).toUpperCase()
                                    ) : "null" + "" + state.name ? (
                                        state.name?.slice(0, 1).toUpperCase()
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="side-profile__account-info">
                                    <div className="side-profile__account-text">
                                        <div className="side-profile__account-main">
                                            {`${state.surname || ""} ${state.name || ""} ${state.middlename || ""}`}
                                            {status ? (
                                                status === "верифицирован" ? (
                                                    <Tag value="верифицирован" icon="pi pi-check" severity="success" className="side-profile__tag" />
                                                ) : (
                                                    <Tag value={status} icon="pi pi-times" className="side-profile__tag side-profile__tag_gray" />
                                                )
                                            ) : state.verified && !state.blocked ? (
                                                <Tag value="верифицирован" icon="pi pi-check" severity="success" className="side-profile__tag" />
                                            ) : (
                                                <Tag value={!state.blocked ? "не верифицирован" : 'заблокирован'} icon="pi pi-times" className="side-profile__tag side-profile__tag_gray" />
                                            )}
                                        </div>
                                        <div className="side-profile__account-bday">
                                            Дата рождения
                                            <span>{state.birthday || "Не указано"}</span>
                                        </div>
                                    </div>
                                    {(!!state.verified || target === "foreign") && target !== "clubs" && (
                                        <i className="side-profile__account-edit pi pi-pencil" onClick={() => setIsEditor({ formData: formData, title: "Данные профиля", key: "profile" })} />
                                    )}
                                </div>
                            </div>
                            {target === "self" && !state.verified ? (
                                noVerify && noVerify.message ? (
                                    noVerify.success ? (
                                        noVerify.message
                                    ) : (
                                        "Ваша учетная запись заблокирована"
                                    )
                                ) : global.auth && global.auth.blocked && global.auth.reason ? (
                                    <div id="declain" key="declain" className={`side-profile__accordion${selectedTab === "declain" ? " side-profile__accordion_active" : ""}`}>
                                        <div className="side-profile__accordion-header">
                                            <div className="side-profile__accordion-title declain">Заявка отклонена</div>
                                            <i className={`${selectedTab === "declain" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("declain")} />
                                        </div>
                                        <div className="side-profile__accordion-verify">
                                            <div className="side-profile__accordion-subtitle">Причина отклонения</div>
                                            <div className="side-profile__accordion-declain-text">{global.auth.reason}</div>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="side-profile__not-verify">
                                        <i className="pi pi-clock" />
                                        Ваш профиль проходит процедуру верификации, пожалуйста зайдите позднее
                                    </div>
                                )
                            ) : target === "clubs" ? (
                                [
                                    <div id="contacts" key="contacts" className={`side-profile__accordion${selectedTab === "contacts" ? " side-profile__accordion_active" : ""}`}>
                                        <div className="side-profile__accordion-header">
                                            <div className="side-profile__accordion-title">Контактная информация </div>
                                            <i className={`${selectedTab === "contacts" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("contacts")} />
                                        </div>
                                        <div className="side-profile__accordion-verify">
                                            {state.email ? (
                                                <div className="side-profile__accordion-subtext">
                                                    <p>Email</p>
                                                    <span>{state.email}</span>
                                                </div>
                                            ) : null}
                                            {state.phone ? (
                                                <div className="side-profile__accordion-subtext">
                                                    <p>Телефон</p>
                                                    <span>{state.phone}</span>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>,

                                    (state.scopes?.find((s) => s.token === global?.auth?.token)?.type !== "system" && target === "self") ||
                                        (state.lastHandled && target !== "self" && state.verified && (
                                            <div id="verify" key="verify" className={`side-profile__accordion${selectedTab === "verify" ? " side-profile__accordion_active" : ""}`}>
                                                <div className="side-profile__accordion-header">
                                                    <div className="side-profile__accordion-title">Верификация</div>
                                                    <i className={`${selectedTab === "verify" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("verify")} />
                                                </div>
                                                {!!(state.verified && state.lastHandled && state.lastHandled.handler) && (
                                                    <div className="side-profile__accordion-verify">
                                                        <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>Кем</p>
                                                            <span>{`${state.lastHandled.handler.surname || ""} ${state.lastHandled.handler.name || ""} ${
                                                                state.lastHandled.handler.middlename || ""
                                                            }`}</span>
                                                        </div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>Дата верификации</p>
                                                            <span>{moment(state.lastHandled.handledAt).format("DD.MM.YYYY")}</span>
                                                        </div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>ID</p>
                                                            <span>{state.lastHandled.handler.email || "Не указан"}</span>
                                                        </div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>Дата создания</p>
                                                            <span>{moment(state.lastHandled.createdAt).format("DD.MM.YYYY")}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )),
                                ]
                            ) : (
                                [
                                    <div id="roles" key="roles" className={`side-profile__accordion${selectedTab === "roles" ? " side-profile__accordion_active" : ""}`}>
                                        <div className="side-profile__accordion-header">
                                            <div className="side-profile__accordion-title">
                                                Доступные роли{" "}
                                                {target === "self" && <i className="pi pi-plus-circle" onClick={() => setIsEditor({ formData: roleData, title: "Запросить роль", key: "role" })} />}
                                            </div>
                                            <i className={`${selectedTab === "roles" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("roles")} />
                                        </div>
                                        {state.rolescopes &&
                                            Array.isArray(state.rolescopes) &&
                                            selectedTab === "roles" &&
                                            state.rolescopes.map((r, k) => <RenderRole key={k} r={r} target={target} roles={state.rolescopes} changeState={changeState} />)}
                                    </div>,
                                    <div id="docs" key="docs" className={`side-profile__accordion${selectedTab === "docs" ? " side-profile__accordion_active" : ""}`}>
                                        <div className="side-profile__accordion-header">
                                            <div className="side-profile__accordion-title">
                                                Загруженные документы
                                                {target === "self" && (
                                                    <FileUpload
                                                        data-pr-tooltip="К загрузке допускаются файлы форматов pdf, doc, docx, xls, xlsx, odf, ppt, pptx размером не более 10 Мб"
                                                        data-pr-position="bottom"
                                                        ref={fileUploadRef}
                                                        mode="basic"
                                                        customUpload
                                                        onSelect={uploadAttachment}
                                                        onUpload={removeHandler}
                                                        accept=".pdf, .doc, .docx, .xls, .xlsx, .odf, .ppt, .pptx"
                                                        chooseOptions={chooseOptions}
                                                        className="side-profile__fileupload"
                                                        maxFileSize={1048576}
                                                        accept=".pdf"
                                                    />
                                                )}
                                            </div>
                                            <i
                                                className={`${selectedTab === "docs" && state.attachments?.length ? "pi pi-chevron-up" : "pi pi-chevron-down"} ${
                                                    state.attachments?.length ? "" : "disabled"
                                                }`}
                                                onClick={() => (state.attachments?.length ? changeTabHandler("docs") : "")}
                                            />
                                        </div>
                                        {state.attachments && selectedTab === "docs" && state.attachments.map((d, k) => <RenderAttachment key={k} d={d} removeAttachment={removeAttachment} />)}
                                    </div>,
                                    (state.scopes?.find((s) => s.token === global?.auth?.token)?.type !== "system" && target === "self") ||
                                        (state.lastHandled && target !== "self" && state.verified && (
                                            <div id="verify" key="verify" className={`side-profile__accordion${selectedTab === "verify" ? " side-profile__accordion_active" : ""}`}>
                                                <div className="side-profile__accordion-header">
                                                    <div className="side-profile__accordion-title">Верификация</div>
                                                    <i className={`${selectedTab === "verify" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("verify")} />
                                                </div>
                                                {!!(state.verified && state.lastHandled && state.lastHandled.handler) && (
                                                    <div className="side-profile__accordion-verify">
                                                        <div className="side-profile__accordion-subtitle">Верифицирован</div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>Кем</p>
                                                            <span>{`${state.lastHandled.handler.surname || ""} ${state.lastHandled.handler.name || ""} ${
                                                                state.lastHandled.handler.middlename || ""
                                                            }`}</span>
                                                        </div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>Дата верификации</p>
                                                            <span>{moment(state.lastHandled.handledAt).format("DD.MM.YYYY")}</span>
                                                        </div>
                                                        {/*<div className='side-profile__accordion-subtext'>
                                            <p>Создан через</p>
                                            <span>{state.lastHandled.method}</span>
                                        </div>*/}
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>ID</p>
                                                            <span>{state.lastHandled.handler.email || "Не указан"}</span>
                                                        </div>
                                                        <div className="side-profile__accordion-subtext">
                                                            <p>Дата создания</p>
                                                            <span>{moment(state.lastHandled.createdAt).format("DD.MM.YYYY")}</span>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )),
                                ]
                            )}
                            {/* {target === "clubs"  ([ target === "clubs" && <div id="contacts" key="contacts" className={`side-profile__accordion${selectedTab === "contacts" ? " side-profile__accordion_active" : ""}`}>
                                    <div className="side-profile__accordion-header">
                                        <div className="side-profile__accordion-title">
                                        Контактная информация{" "}
                                        </div>
                                        <i className={`${selectedTab === "contacts" ? "pi pi-chevron-up" : "pi pi-chevron-down"}`} onClick={() => changeTabHandler("contacts")} />
                                    </div>,])} */}
                        </div>
                    )}
                </div>
            </div>
        )
    );
};

const RenderAttachment = ({ d, removeAttachment }) => {
    const op = useRef(null);

    return (
        <div className="side-profile__accordion-doc">
            <OverlayPanel ref={op} className="side-profile__overlay-panel" appendTo={"self"}>
                <div className="side-profile__overlay-panel-item side-profile__overlay-panel-item_red" onClick={() => removeAttachment(d._id)}>
                    Удалить
                </div>
            </OverlayPanel>
            <div className="side-profile__accordion-doc-info">
                <i className={`${d.status === "valid" ? "pi pi-check" : "pi pi-question"}`} />
                <div className="side-profile__accordion-doc-text">
                    {d.date}
                    {/*{d.status === 'valid' ? `проверен${d.date}` : 'на проверке'}*/}
                    <span>{d.filename}</span>
                </div>
            </div>
            <i className="pi pi-ellipsis-v" onClick={(e) => op.current.toggle(e)} />
        </div>
    );
};

const RenderRole = ({ r, target, roles, changeState }) => {
    const op = useRef(null);
    const wctx = useContext(WorkspaceContext);
    const token = wctx && wctx.workspace && wctx.workspace.subject && wctx.workspace.subject.token;
    const type = wctx && wctx.workspace && wctx.workspace.subject && wctx.workspace.subject.type;

    const changeActiveRole = (e, role) => {
        op.current.toggle(e);

        localStorage.setItem("_amateum_auth_tkn", role.scope.token);
        wctx.setWorkspace({ ...wctx.workspace, subject: role.scope });
    };

    const removeRole = (e) => {
        if (target === 'self') {
            confirmDialog({
                message: `Вы отказываетесь от роли: ${r.role.name}. После отказа Вам станут недоступны функции управления.`,
                header: "Отказаться от роли?",
                icon: "pi pi-exclamation-triangle",
                acceptClassName: "p-button-danger",
                acceptLabel: "Да",
                rejectLabel: "Нет",
                draggable: false,
                accept: () => {
                    deleteByUrl(`rolesscopes/${r._id}`).then((resp) => {
                        if (resp && resp.success) {
                            let role = roles.filter((role) => role._id !== r._id);

                            if (r.scope.token === token) {
                                changeActiveRole(e, role.slice(-1)[0]);
                            }
                            changeState(role, "rolescopes");
                        }
                    });
                },
                reject: () => {},
            });
        } else {
            confirmDialog({
                message: `Вы отзываете роль ${r.role.name}. Пользователю станут недоступны функции управления.`,
                header: "Отозвать роль?",
                icon: "pi pi-exclamation-triangle",
                acceptClassName: "p-button-danger",
                acceptLabel: "Да",
                rejectLabel: "Нет",
                draggable: false,
                accept: () => {
                    deleteByUrl(`rolesscopes/${r._id}`).then((resp) => {
                        if (resp && resp.success) {
                            let role = roles.filter((role) => role._id !== r._id);

                            changeState(role, "rolescopes");
                        }
                    });
                },
                reject: () => {},
            });
        }
    };

    return (
        !!(r && r.scope) && (
            <div className="side-profile__accordion-role">
                {
                    <OverlayPanel ref={op} className="side-profile__overlay-panel" appendTo={"self"}>
                        {r.scope.token !== token && target === "self" && (
                            <div className="side-profile__overlay-panel-item" onClick={(e) => changeActiveRole(e, r)}>
                                Cделать активной ролью
                            </div>
                        )}
                        {((target === "self" && roles.length > 1) || (type === 'system' && target === "foreign")) && (
                            <div className="side-profile__overlay-panel-item side-profile__overlay-panel-item_red" onClick={(e) => removeRole(e)}>
                                {target === "self" ? 'Удалить' : 'Отозвать роль'}
                            </div>
                        )}
                    </OverlayPanel>
                }
                <div className="side-profile__accordion-subtitle">
                    {r.role.name}
                    {r.scope.token === token && <div className="side-profile__tag side-profile__tag_blue">активная роль</div>}
                    {((target === "self" && roles.length > 1) || (type === 'system' && target === "foreign")) && <i className="pi pi-ellipsis-v" onClick={(e) => op.current.toggle(e)} />}
                </div>
                {/*{r.scope.type === 'system' && <div className='side-profile__accordion-subtext'><p>Система</p><span>{r.scope.name}</span></div>}*/}
                {r.scope.type === "region" && (
                    <div className="side-profile__accordion-subtext">
                        <p>Регион</p>
                        <span>{r.scope.name}</span>
                    </div>
                )}
                {["league", "leagueStaff"].includes(r.scope.type) && (
                    <div className="side-profile__accordion-subtext">
                        <p>Лига</p>
                        <span>{r.scope.name}</span>
                    </div>
                )}
                {r.scope.type === "club" && (
                    <div className="side-profile__accordion-subtext">
                        <p>Клуб</p>
                        <span>{r.scope.name}</span>
                    </div>
                )}
            </div>
        )
    );
};

const formData = [
    { key: "surname", label: "Фамилия*", placeholder: "Укажите фамилию", type: "input", size: "full" },
    { key: "name", label: "Имя*", placeholder: "Укажите имя", type: "input", size: "full" },
    { key: "middlename", label: "Отчество*", placeholder: "Укажите отчество", type: "input", size: "full" },
    { key: "birthday", label: "Дата рождения*", placeholder: "Укажите дату рождения", type: "calendar", size: "full" },
    { key: "email", label: "Email*", placeholder: "Укажите email", type: "input", size: "full" },
    {
        key: "phone",
        label: "Номер телефона",
        placeholder: "+ 7__________",
        type: "mask",
        size: "full",
        mask: "+79999999999",
    },
];

const roleData = [
    { type: "dropdown", label: "Роль*", key: "roles", stateKey: "roleId", placeholder: "Выберите роль", disabled: [] },
    { type: "dropdown", label: "Регион*", key: "regions", stateKey: "regionId", placeholder: "Выберите регион", disabled: ["system"] },
    { type: "dropdown", label: "Лига*", key: "leagues", stateKey: "leagueId", placeholder: "Выберите лигу", disabled: ["system", "region"] },
    { type: "dropdown", label: "Клуб*", key: "clubs", stateKey: "clubId", placeholder: "Выберите клуб", disabled: ["system", "region", "league", "leagueStaff"] },
];

const chooseOptions = { label: " ", icon: "pi pi-plus-circle" };

const removeHandler = (event) => {
    event.options.clear();
};

const options = [
    { label: "Ссылка на файл", value: "link" },
    { label: "Загрузка файла", value: "file" },
];

export default SideProfile;
