import React, { useEffect, useRef, useState } from "react";

import {postByUrl} from "../../../../v3MethodsService";

import { Button } from "primereact/button";

import DataTable from "../../Common/DataTable";

import "./style.scss";

const ListInvite = ({ data, setData, toast }) => {
    const [isSending, setIsSending] = useState(false);
    const [amount, setAmount] = useState(0);

    const handleRemove = (e, item ,idx) => {
        setData((prevState) => prevState.filter((_, i) => i !== idx?.rowIndex));
    };

    const handleSend = async () => {
        setIsSending(true)
        setAmount(data.length)
        let invalid = false
        for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const body = {email: item.email, scope: item.scope}

            const resp = await postByUrl('create', body)
            if (resp && resp.success) {
                setData(prevData => prevData.filter((_, index) => index !== 0));
            } else {
                invalid = true
                toast?.show({severity: "error", life: 4000, summary: resp.message || "Ошибка при отправке данных"});
                break;
            }
        }
        if (!invalid) {
            toast?.show({severity: "success", life: 4000, summary: "Приглашения отправлены"});
            setData([])
        } else {
            toast?.show({severity: "warn", life: 4000, summary: "Не все приглашения были отправлены"});
        }
        setIsSending(false)
    };

    return (
        data && (
            <div className="listInvite">
                <div className="listInvite__header">
                    <span className="listInvite__title">Список для приглашения</span>
                    <Button label={isSending ? `Отправлено ${amount - data.length}/${amount}` : `Отправить ${data.length} приглашений`} className="listInvite__btn" onClick={handleSend} disabled={isSending} />
                </div>
                <DataTable
                    handleRemove={handleRemove}
                    columns={[
                        { field: "email", header: "Почта" },
                        { field: "role", header: "Роль" },
                        { field: "region", header: "Регион" },
                        { field: "league", header: "Лига" },
                        { field: "club", header: "Клуб" },
                    ]}
                    rows={data.map((row) => ({
                        ...row,
                        email: { value: row?.email },
                        role: { value: row?.desc?.role },
                        region: { value: row?.desc?.region },
                        league: { value: row?.desc?.league },
                        club: { value: row?.desc?.club },
                    }))}
                />
            </div>
        )
    );
};

export default ListInvite;
