import React, {useEffect, useState} from "react"

import TabTable from "../../../Common/TabTable";
import SideProfile from "../../../SideProfile";

import './style.scss'

const RolesUsers = ({ toast, users, updateUsers, state }) => {
    const [selectedProfile, setSelectedProfile] = useState(null)

    const openProfile = (e) => {
        setSelectedProfile(e.data._id)
    }

    return (
        <div className='roles-users'>
            <SideProfile visible={!!selectedProfile} onHide={() => setSelectedProfile(null)} target='foreign' id={selectedProfile} toast={toast} updateUsers={updateUsers}/>
            <TabTable content={users} onClick={openProfile} name={state?.name}/>
        </div>
    )
}

export default RolesUsers