import React, {useRef, useState} from "react";

import {Toast} from "primereact/toast";

import FormInvite from "./FormInvite";
import ListInvite from "./ListInvite";

import "./style.scss";

const Invitation = () => {
    const toast = useRef(null)
    const [inviteList, setInviteList] = useState([]);

    const updateList = (item) => {
        const newList = [...inviteList]
        newList.push(item)
        setInviteList(newList)
    }

    return (
        <div className="invitation">
            <Toast ref={toast} position="top-right"/>
            <FormInvite updateList={updateList}/>
            {inviteList?.length ? <ListInvite data={inviteList} setData={setInviteList} toast={toast?.current || null}/> : null}
        </div>
    );
};

export default Invitation;

