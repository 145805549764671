import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory} from "react-router-dom";

import {useClickOutside, useDebounce} from "../../../utils";
import {getByUrl} from "../../../v3MethodsService";
import {WorkspaceContext} from "../../../ctx";

import {InputText} from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";

import icon1 from './img/icon1.png'
import icon2 from './img/icon2.png'

import './style.scss'

const SearchPanel = ({isVisible, onClose}) => {
    const ref = useRef()
    const history = useHistory();

    const wctx = useContext(WorkspaceContext)
    const role = wctx && wctx.appSubject && wctx.appSubject.type

    const [loading, setLoading] = useState(false)
    const [searchString, setSearchString] = useState('')
    const debouncedSearchTerm = useDebounce(searchString, 700);
    const [selectedOption, setSelectedOption] = useState('all')
    const [searchData, setSearchData] = useState(null)
    const [showAll, setShowAll] = useState(false)

    const visibleData = searchData && selectedOption ? selectedOption === 'all' ? searchData : searchData[selectedOption] : null

    useEffect(() => {
        setSearchData(null)
        setSearchString('')
        setSelectedOption('all')
    }, [isVisible]);

    useEffect(() => {
        setSearchData(null)
        setShowAll(false)
        if (selectedOption === 'all' ? debouncedSearchTerm.length >=5 : debouncedSearchTerm.length >=3) {
            setLoading(true)
            getByUrl(`searchData?query=${debouncedSearchTerm}${selectedOption !== 'all' ? `&section=${selectedOption}` : ''}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setSearchData(selectedOption === 'all' ? resp.data : {[selectedOption]: resp.data?.[selectedOption]})
                    }
                    setLoading(false)
                })
        }
    }, [debouncedSearchTerm, selectedOption]);

    const clickHandler = (link) => {
        onClose()
        history.push(link)
    }

    const renderList = () => {

        const renderObject = (obj, type) => {
            switch (type) {
                case 'teams': return (
                    <div className='search-panel__object' onClick={() => clickHandler(`/clubs/${obj.club?._id}?mode=teams&id=${obj._id}`)}>
                        <img src={obj.emblem || icon1}/>
                        <div className='search-panel__object-text'>
                            {obj.name || ''}
                            <span>{obj.club?.name || ''}</span>
                        </div>
                    </div>
                )
                case 'players': return (
                    <div className='search-panel__object' onClick={() => clickHandler(`/players?search=${encodeURIComponent(searchString)}&id=${obj._id}`)}>
                        <img src={obj.avatarUrl || icon2}/>
                        <div className='search-panel__object-text'>
                            {obj.surname || ''} {obj.name || ''} {obj.middlename || ''}
                            <span>{obj.birthday || ''}</span>
                        </div>
                    </div>
                )
                case 'locations': return (
                    <div className='search-panel__object' onClick={() => clickHandler(`/locations?id=${obj._id}`)}>
                        <div className='search-panel__object-text'>
                            {obj.name || ''}
                            <span>{obj.address || ''}</span>
                        </div>
                    </div>
                )
                case 'tournaments': return (
                    <div className='search-panel__object' onClick={() => clickHandler(`/tournaments/${obj._id}`)}>
                        <img src={obj.emblem || icon1}/>
                        <div className='search-panel__object-text'>
                            {obj.name || ''}
                            <span>{obj.teams?.length || 0} команд</span>
                        </div>
                    </div>
                )
                case 'leagues': return (
                    <div className='search-panel__object' onClick={() => clickHandler(`/leagueData/${obj._id}`)}>
                        <img src={obj.emblem || icon1}/>
                        <div className='search-panel__object-text'>
                            {obj.name || ''}
                            <span>{obj.tournaments?.length || 0} турниров</span>
                        </div>
                    </div>
                )
                case 'rffs': return (
                    <div className='search-panel__object' onClick={() => clickHandler(`/regions/${obj._id}`)}>
                        <div className='search-panel__object-text'>
                            {obj.name || ''}
                            <span>{obj.leagues?.length || 0} лиги</span>
                        </div>
                    </div>
                )
                default:
                    return ''
            }
        }

        if (visibleData) {
            if (selectedOption === 'all') {
                const keys = Object.keys(visibleData)
                if (keys.find(k => visibleData[k]?.length > 0)) {
                    return (
                        <div className='search-panel__list'>
                            {keys.map(key => visibleData[key]?.length > 0 && options.find(o => o.value === key) ? (
                                    <div key={key} className='search-panel__container'>
                                        <div className='search-panel__title'>{options.find(o => o.value === key).label}</div>
                                        <div className='search-panel__objects'>{(showAll ? visibleData[key] : visibleData[key] && [...visibleData[key]].splice(0, 3))?.map(v => renderObject(v, key))}</div>
                                    </div>
                                ) : null)}
                            {!!keys.find(k => visibleData[k]?.length > 3) && !showAll && <Button label='Показать все' onClick={() => setShowAll(true)} className='search-panel__show-all'/>}
                        </div>
                    )
                }
            }
            if (visibleData?.length > 0) {
                return (
                    <div className='search-panel__list'>
                        <div className='search-panel__container'>
                            {/*<div className='search-panel__title'>{options.find(o => o.value === selectedOption).label}</div>*/}
                            <div className='search-panel__objects'>
                                {(showAll ? visibleData : [...visibleData].splice(0, 12)).map(v => renderObject(v, selectedOption))}
                            </div>
                        </div>
                        {visibleData?.length > 12 && !showAll && <Button label='Показать все' onClick={() => setShowAll(true)} className='search-panel__show-all'/>}
                    </div>
                )
            }
        }
        if (selectedOption === 'all' ? debouncedSearchTerm.length >= 5 : debouncedSearchTerm.length >= 3) {
            return <div className='search-panel__list search-panel__list_empty'>К сожалению, по вашему запросу ничего не найдено</div>
        }
        return <div className='search-panel__list search-panel__list_empty'>Введите для поиска не менее {selectedOption === 'all' ? 'пяти' : 'трех'} символов</div>
    }

    useClickOutside(isVisible, ref, onClose);

    return !!isVisible && (
        <div className='search-panel__overlay'>
            <div ref={ref} className='search-panel'>
                <span className="p-input-icon-left" style={{width: '100%'}}>
                    <i className="pi pi-search"/>
                    <InputText value={searchString} onChange={e => setSearchString(e.target.value)} className='search-panel__input'/>
                </span>
                <div className='search-panel__results'>
                    {!loading && role === 'league' && <SelectButton value={selectedOption} onChange={e => setSelectedOption(e.target.value)} options={optionsSelect} className='search-panel__select' unselectable={false}/>}
                    {loading ? (
                        <div className='search-panel__list search-panel__list_empty'>
                            <ProgressSpinner style={{width: 64, height: 64}} />
                        </div>
                    ) : (
                        renderList()
                    )}
                </div>
            </div>
        </div>
    )
}

const options = [
    {label: 'Все', value: 'all'},
    {label: 'РФФ', value: 'rffs'},
    {label: 'Лиги', value: 'leagues'},
    {label: 'Турниры', value: 'tournaments'},
    {label: 'Команды', value: 'teams'},
    {label: 'Игроки', value: 'players'},
    {label: 'Площадки', value: 'locations'}
]

const optionsSelect = [
    {label: 'Все', value: 'all'},
    {label: 'Турниры', value: 'tournaments'},
    {label: 'Команды', value: 'teams'},
    {label: 'Игроки', value: 'players'},
    {label: 'Площадки', value: 'locations'}
]

export default SearchPanel