import React, { useContext, useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { useHistory } from "react-router-dom";

import { RadioButton } from "primereact/radiobutton";
import SideBar from "./SideBar";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { ProgressBar } from 'primereact/progressbar';

import Profile from "./Profile";
import Documents from "./Documents";

import defaultLogo from "../img/defaultLogo.png";
import MediaBank from "../../ClubsManagment/Detail/MediaBank";
import FilesUploadUi from "../../ClubsManagment/Detail/MediaBank/FilesUpload";
import DataTable from "../../Common/DataTable";

import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../../v3MethodsService";
import { GlobalContext } from "../../../../ctx";

import "./style.scss";
import emptyDoc from './empty_protocol.pdf'
import { calcMb } from "../../../../utils";
import Fantasy from "./Fantasy";

const options = [
    { name: "Профиль", key: "profile" },
    { name: "Банк документов", key: "documents" },
    { name: "Медиабанк", key: "mediabank" },
    { name: 'Fantasy', key: 'fantasy' }
];

const archivedOptions = [
    { name: "Активные", key: "active" },
    { name: "Архивные", key: "archived" },
];

const LeaugesDetail = ({ clubs, toast }) => {
    const history = useHistory()
    const [global, setGlobal] = useContext(GlobalContext)
    const token = global && global.auth && global.auth.token
    const param = window.location.pathname.split("/");
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [archived, setArchived] = useState(archivedOptions[0]);
    const [data, setData] = useState(null);
    const [checkbox, setCheckbox] = useState([]);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [state, setState] = useState(null);
    const [optionRegions, setOptionRegions] = useState([]);
    const [optionSettelments, setOptionSettelments] = useState([]);
    const [sideBarData, setSideBarData] = useState([]);
    const [sideBarState, setSideBarState] = useState([]);
    const [docs, setDocs] = useState([]);
    const [medias, setMedias] = useState([])
    const toastRef = useRef(null)

    useEffect(() => {
        getByUrl(`getRefsList?section=regions`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setOptionRegions(resp.data);
            }
        });
    }, [token]);

    useEffect(() => {
        if (param[2] === "add") {
            setData(initialData);
            setState(initialData);
            return;
        }
        getLeague(true);
    }, [param[2]]);

    const getLeague = (settlement) => {
        getByUrl(`leagues/${param[2]}`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setData(resp.data);
                setState(resp.data);
                setDocs(resp.data.docs);

                if (resp.data._id) {
                    getByUrl(`medias?sampleId=${resp.data._id}&sampleType=leagues&isPublished=true`).then((resp) => {
                        if (resp && resp.success && resp.data) {
                            setMedias(resp.data);
                        }
                    });
                }

                if (resp.data.regionId && settlement) {
                    getByUrl(`getRefsList?section=settlements&subjectId=${resp.data.regionId}`).then((resp) => {
                        if (resp && resp.success && resp.data) {
                            setOptionSettelments(resp.data);
                        }
                    });
                }
            }
        });
    };

    const updateData = (val, key, subKey, dropdown) => {
        if (subKey) {
            setData({ ...data, [key]: { ...data[key], [subKey]: val } });
        } else if (key) {
            setData({ ...data, [key]: val });
        } else {
            setData(val)
        }

        if (dropdown) {
            getByUrl(`getRefsList?section=settlements&subjectId=${val}`).then((resp) => {
                if (resp && resp.success && resp.data) {
                    setOptionSettelments(resp.data);
                }
            });
        }
    };

    const updateSideBar = (val, key) => {
        setSideBarData({ ...sideBarData, [key]: val });
    };

    const Specified = selectedOption ? wrap[selectedOption.key] : wrap["profile"];

    const onChecboxChange = (e) => {
        let _checkbox = [...checkbox];

        if (e.checked) _checkbox.push(e.value);
        else _checkbox.splice(_checkbox.indexOf(e.value), 1);

        setCheckbox(_checkbox);
    };

    const handleItem = (e) => {
        if (e.data) {
            let sideData = data.attachments.find((item) => item._id === e.data._id);
            setSideBarData(sideData);
            setSideBarState(sideData);
        } else {
            setSideBarData({});
            setSideBarState({});
        }
        setSidebarVisible(true);
    };

    const deleteHandler = (id) => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этому документу",
            header: "Удалить документ?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: () => {
                removeAttachment(id);
            },
            reject: () => {},
        });
    };

    const handleDocs = (toastName) => {
        putByUrl(`leagues/${state._id}`, { docs: docs }).then((resp) => {
            if (resp && resp.success) {
                toast.show({ severity: "success", summary: toastName ? toastName : "Данные сохранены" });
                getLeague();
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
        setSidebarVisible(false);
    };

    const removeAttachment = (id) => {
        deleteByUrl(`remove_attach/${id || sideBarData._id}`).then((resp) => {
            if (resp && resp.success) {
                const newDocs = state.docs.filter((d) => d !== sideBarData._id);
                setDocs(newDocs);
                handleDocs("Документ удален");
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const downloadEmpty = () => {
        const link = document.createElement('a')
        link.href = emptyDoc
        link.download = `Пустой протокол.pdf`
        link.click()
        // window.open(emptyDoc)
    }

    const handleFantasy = () => {
        history.replace(`${window.location.pathname}?create=newFantasy`)
    }
       
    return (
        <div className="leagues-detail">
            <SideBar
                sideBarState={sideBarState}
                getLeague={getLeague}
                visible={sidebarVisible}
                setVisible={setSidebarVisible}
                data={sideBarData}
                state={state}
                updateData={updateSideBar}
                setData={setSideBarData}
                toast={toast}
                docs={docs}
                setDocs={setDocs}
                deleteHandler={deleteHandler}
                handleDocs={handleDocs}
            />
            {param?.[2] !== "add" && (
                <div className="leagues-detail__header">
                    <SelectButton
                        options={options}
                        value={selectedOption}
                        onChange={(e) => {
                            if (e.value) setSelectedOption(e.value);
                        }}
                        optionLabel="name"
                    />
                    <Toast ref={toastRef} position="top-right"/>
                    {selectedOption?.key === "mediabank" && (
                        <div className="leagues-detail__buttons mediabank">
                            <FilesUploadUi
                                mode={'basic'}
                                accept=".jpeg, .jpg, .png, .svg"
                                customUpload
                                multiple
                                // minFileSize={10}
                                minFileSize={102400}
                                maxFileSize={10485760}
                                className='mediabank__upload'
                                toast={toastRef}
                                remainingSpace={medias?.quota?.remainingSpace}
                                onChange={setMedias}
                                data={data}
                            />
                            <div className="mediabank__size">
                                <div className="mediabank__size-text">Использовано</div>
                                <div className="mediabank__size-text">{calcMb(medias?.list)?.length ? calcMb(medias?.list)[0] + ' ' + calcMb(medias?.list)[1] : 0} / из 15 ГБ</div>
                                {/* <div className="mediabank__size-text">{calcMb(medias?.list)?.length ? calcMb(medias?.list)[0] + ' ' + calcMb(medias?.list)[1] : 0} / из {calcMb([{size: medias?.quota?.quota}])}</div> */}
                                <ProgressBar className="mediabank__size-progress" value={(calcMb(medias?.list, true) / 1e+9) * 100}></ProgressBar>
                            </div>
                        </div>
                    )}
                    {['fantasy', "documents"].includes(selectedOption?.key) && (
                        <div className="leagues-detail__buttons">
                            <SelectButton
                                options={archivedOptions}
                                value={archived}
                                onChange={(e) => {
                                    if (e.value) setArchived(e.value);
                                }}
                                optionLabel="name"
                            />
                            {selectedOption?.key === 'documents' && <Button icon="pi pi-download" label="Шаблон" onClick={downloadEmpty} />}
                            {selectedOption?.key === 'documents' && <Button icon="pi pi-plus" label="Добавить документ" onClick={handleItem} />}
                            {selectedOption?.key === 'fantasy' && <Button icon="pi pi-plus" label="Добавить сезон" onClick={handleFantasy} />}
                        </div>
                    )}
                </div>
            )}
            <Specified
                data={data}
                state={state}
                updateData={updateData}
                setData={setData}
                setState={setState}
                checkbox={checkbox}
                onChecboxChange={onChecboxChange}
                handleItem={handleItem}
                optionRegions={optionRegions}
                optionSettelments={optionSettelments}
                archived={archived}
                toast={toastRef}
                deleteHandler={deleteHandler}
                medias={medias}
                setMedias={setMedias}
            />
        </div>
    );
};

const wrap = {
    profile: Profile,
    documents: Documents,
    mediabank: MediaBank,
    fantasy: Fantasy
};

export default LeaugesDetail;

const initialData = {
    emblem: "",
    name: "",
    shortName: "",
    regionId: "",
    settlementId: "",
    phone: "",
    email: "",
    inn: "",
    organization: "",
    address: "",
    outerLinks: { vk: "", tg: "", yt: "" },
};

const initialSideData = {
    file: "",
    name: "",
};
