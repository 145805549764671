import React from "react";

import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";
import {OverlayPanel} from "primereact/overlaypanel";

const ManualGroup = ({ unassigned, assignTeams, createGroup, data, selectGroup, filteredTeams, searchTeam, updateTeams, removeGroup, renameGroups, groupOp, isFinished, handleFinish }) => {
    return (
        <div className="group-structure__card teams">
            <div className="group-structure__container teams">
                <span className="group-structure__title">Группы</span>
                <Button label="Добавить группу" icon="pi pi-users" className="p-button-outlined" onClick={createGroup} disabled={isFinished}/>
            </div>
            {unassigned?.length > 0 && (
                <div className="group-structure__hint">
                    <i className="pi pi-info-circle"/>
                    <span>Не распределены команды: {unassigned.map((t) => t.name).join(", ")}</span>
                </div>
            )}
            <div className="group-structure__items">
                {data.groups?.map((group, idx) => (
                    <div className="group-structure__item" key={idx}>
                        <div className={`group-structure__item-text ${idx === 0 ? "first" : ""}`} onClick={(e) => (idx === 0 ? groupOp.current.toggle(e) : null)}>
                            Группа {selectGroup === "alphabetic" ? String.fromCharCode(65 + idx) : idx + 1} {idx === 0 && <p className="pi pi-chevron-down" style={{margin: 0}}></p>}
                        </div>
                        <AutoComplete
                            value={assignTeams(group.teams)}
                            field="name"
                            suggestions={filteredTeams}
                            completeMethod={searchTeam}
                            onChange={(e) => updateTeams(idx, group._id, e.value)}
                            multiple
                            placeholder="Начните вводить название команды"
                            appendTo={'self'}
                            disabled={isFinished}
                        />
                        {!group.teams?.length > 0 && <Button icon="pi pi-times" onClick={() => removeGroup(idx, group._id)} disabled={isFinished}/>}
                    </div>
                ))}
                <OverlayPanel ref={groupOp} style={{maxWidth: "363px"}}>
                    <div className="group-structure__overlay">
                        <span className="group-structure__item-change" onClick={(e) => renameGroups("alphabetic")}>Буквенная</span>
                        <span className="group-structure__item-change" onClick={(e) => renameGroups("digital")}>Цифровая</span>
                    </div>
                </OverlayPanel>
            </div>
            {!isFinished && <Button onClick={handleFinish} label="Завершить стадию" className="circle-structure__btn p-button-warning"/>}
        </div>
    )
}

export default ManualGroup;