import React, { useEffect, useState } from "react";
import {useHistory, useLocation} from "react-router-dom";

import "./style.scss";

const Breadcrumbs = ({title }) => {
    const history = useHistory();
    const params = window.location.pathname.split("/").filter(p => p);
    const location = useLocation();

    const bread = Object.values(params)
        .reduce(
            (acc, row, idx) => {
                acc.push({
                    label: crumbs[row]?.label || title,
                    link: `${acc[idx].link}${idx ? "/" + row : row}${location.search}`,
                });
                return acc;
            },
            [{ label: "Главная", link: "/" }]
        )
        .filter((f) => f.link !== "/");

    return (
        params[1] && (
            <div className="breadcrumbs">
                <div className="breadcrumbs__crumbs">
                    {bread.map((i, idx) => {
                        return (
                            <span key={idx} className="breadcrumbs__crumb" onClick={() => history.push(i.link)}>
                                {i.label}
                                {idx > 0 || title ? <span className="breadcrumbs__more">&gt;</span> : null}
                            </span>
                        );
                    })}
                </div>
            </div>
        )
    );
};

const crumbs = {
    roles: { label: "Управление ролями" },
};

export default Breadcrumbs;
