import { useRef, useState } from "react";

import { FileUpload } from "primereact/fileupload";

import "./style.scss";
import { Button } from "primereact/button";


const FileUploadUi = ({
    mainStore,
    index = 0,
    onChange = (any) => {},
    image = null,
    label = "",
    mutedLabel = "",
    minSize = 512000,
    maxSize = 5242880,
    invalid = false,
    accept,
    className = "",
    multiple = false,
    preview = "",
    previewId = "",
    previewKey = "",
    scrollKey = "",
    previewTitle = "",
    filesLimit = 0,
    queue = false,
    disabled,
    chooseLabel,
    updateData = (any) => {},
}) => {
    const fileUploadRef = useRef(null);

    const uploadHandler = (event) => {
        event.options.clear();
    };

    const chooseOptions = { label: " ", icon: "pi pi-fw pi-plus" };



    return (
        <div className={`fileupload ${className}`}>
            {image ? (
                <div className="fileupload__field">
                    {label ? (
                        <label htmlFor="comment" className="fileupload__label">
                            {label} <span>{mutedLabel}</span>
                        </label>
                    ) : null}
                    <div className="fileupload__input fileupload__input_item">
                        <img src={image} />
                        <div className="fileupload__input_btns">
                            <Button icon="pi pi-trash" className="fileupload__remove-btn" onClick={updateData} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className="fileupload__field">
                    {label ? (
                        <label htmlFor="comment" className="fileupload__label">
                            {label} <span>{mutedLabel}</span>
                        </label>
                    ) : null}
                    <FileUpload
                        ref={fileUploadRef}
                        mode={"basic"}
                        accept={accept ? accept : ".jpeg, .jpg, .png"}
                        onSelect={onChange}
                        customUpload
                        uploadHandler={uploadHandler}
                        chooseOptions={chooseOptions}
                        className={`fileupload__input ${invalid ? "fileupload__input_invalid" : ""}`}
                        multiple={multiple}
                        disabled={disabled}
                        chooseLabel={chooseLabel}
                    />
                </div>
            )}
        </div>
    );
};

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

export default FileUploadUi;
