import React, { useState, useEffect } from 'react'

import {Button} from "primereact/button";

import cardIcon_1 from './img/cardIcon_1.svg'
import cardIcon_2 from './img/cardIcon_2.svg'

import './style.scss'

const Kind = ({ state, setState, setStep }) => {

    const clickHandler = (val) => {
        setState(prev => ({...prev, kind: val}))
        if (val === 'gos_uslugi') {
            window.open('https://www.gosuslugi.ru/', '_self')
            return
        }
        setStep('personData')
    }

    return (
        <div className='reg-success-kind'>
            <div className='auth2__title'>
                Выберите способ<br/>завершения регистрации
            </div>
            <div className='reg-success-kind__subtitle'>
                Выбранный Вами способ будет в дальнейшем использоваться<br/>для входа в личный кабинет
            </div>
            <div className='reg-success-kind__cards'>
                {cards.map((c, k) => (
                    <div key={k} className='reg-success-kind__card'>
                        <div className='reg-success-kind__card-content'>
                            <div className='reg-success-kind__card-header'>
                                <img src={c.icon} alt='icon' className='reg-success-kind__card-icon'/>
                                <div className='reg-success-kind__card-title'>{c.title.replace('USER_EMAIL', state.email)}</div>
                            </div>
                            <ul className='reg-success-kind__card-body'>
                                {c.text.map((t, i) => <li key={i} className='reg-success-kind__card-text'>{t}</li>)}
                            </ul>
                        </div>
                        <Button label='Выбрать' className={`auth2__button ${c.buttonClassName}`} onClick={() => clickHandler(c.key)}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

const cards = [
    {
        key: "gos_uslugi",
        icon: cardIcon_1,
        title: "Иcпользовать\nВаш профиль\nна Госуслугах",
        text: ["Данные заполнятся автоматически", "Мгновенная верификация"],
        buttonClassName: ''
    },
    {
        key: "email",
        icon: cardIcon_2,
        title: "Иcпользовать\nemail-адрес\nUSER_EMAIL",
        text: ["Необходимо заполнить профиль  вручную и придумать пароль", "Пройти процедуру верификации"],
        buttonClassName: 'auth2__button_outlined'
    }
]

export default Kind