import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {pluralForm} from "../../../../../utils";
import axios from "axios";
import {ENDPOINT} from "../../../../../env";
import {postByUrl} from "../../../../../v3MethodsService";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import {Sidebar} from "primereact/sidebar";
import {RadioButton} from "primereact/radiobutton";

import Request from "../../../../Clubs/Request";
import TeamRequestModal from "../../../../Tournaments/Teams/TeamRequestModal";
import ConfirmModal from "../../../Common/ConfirmModal";

import logo from "../img/defaultLogo.png";

import emblem from "../../../../Emblem";

import "./style.scss";

const Teams = ({ state, toast, updateState, getLeague }) => {
    const history = useHistory();
    const location = useLocation();

    const [confirmModal, setConfirmModal] = useState(false)

    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [newTeam, setNewTeam] = useState(null)
    const [visibleRight, setVisibleRight] = useState(searchString.tournamentId && searchString.tournamentId.length === 24);

    const [isVisible, setIsVisible] = useState(false)
    const [optionRequest, setOptionRequest] = useState('')

    useEffect(() => {
        if (state && state.teams) {
            setData(state.teams);
        }

        if (state && state.btnOptions && state.btnOptions.click) {
            updateState({
                click: false,
                listbox: {
                    search: true,
                    searchLink: 'v2/list/queryTeams?query=`',
                    options: [],
                    onClick: handleAdd
                }
            }, 'btnOptions')
        }
    }, [state]);

    useEffect(() => {
        let filteredData = [...state?.teams];
        if (searchString) {
            filteredData = filteredData.filter((item) => {
                return Object.keys(item).some((key) => {
                    return (
                        item[key] &&
                        (item[key].toString().toLowerCase().includes(searchString.toLowerCase()) || (item[key].value && item[key].value.toString().toLowerCase().includes(searchString.toLowerCase())))
                    );
                });
            });
        }

        setData(filteredData);
    }, [searchString]);

    const handleOpen = (team) => {
        history.push(`/queries/${team.squad?._id}?mode=squads&path=tournaments&id=${state._id}`)
        /*history.push(`${location.pathname}/teamsquad/${team.squad?._id}?tournamentId=${state._id}`)
        setSelectedTeam(team)
        setVisibleRight(true)*/
    }

    const updateTeamsList = (teamId) => {
        updateState(state.teams.filter(t => t._id !== teamId), 'teams')
    }

    const handleAdd = (item) => {
        setNewTeam({...item})
    }

    const acceptReplace = (tournamentId, sourceTeamId, targetTeamId) => {
        postByUrl(`replaceTeamInTourn`, {tournamentId: tournamentId, sourceTeamId: sourceTeamId, targetTeamId: targetTeamId})
            .then(resp => {
                if (resp && resp.success) {
                    toast?.show({severity: 'success', summary: 'Успешно', detail: 'Команда заменена', life: 1000})
                    getLeague()
                    return
                }
                toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            })
    }
    
    const replaceTeam = (team) => {
        const handleTeam = (targetTeam) => {
            setConfirmModal({
                message: `команда <span>${team.name}</span> будет заменена в турнире<br/><span>${state.name}</span><br/>на команду <span>${targetTeam.name}</span>.<br/>Результаты сыгранных матчей будут наследованы,<br/>персональная статистика игроков будет удалена.`,
                header: 'Замена команды в турнире',
                acceptLabel: 'Заменить команду',
                rejectLabel: 'Оставить без изменений',
                accept: () => acceptReplace(state._id, team._id, targetTeam._id),
            })
        }

        setConfirmModal({
            message: `команда <span>${team.name}</span> будет заменена в турнире<br/><span>${state.name}</span><br/>для выбора новой команды, начните вводить ее название`,
            customBody: <BodyReplace currentTeam={team} stateTeams={state.teams} updateTargetTeam={handleTeam}/>,
            header: 'Замена команды в турнире',
            acceptLabel: null,
            rejectLabel: 'Оставить без изменений',
            accept: () => acceptReplace(state._id, team._id),
        })
    }

    return (
        <div className="tournamets2-teams">
            <ConfirmModal visible={!!confirmModal} {...confirmModal} onClose={() => setConfirmModal(false)}/>
            {newTeam ? (
                <TeamRequestModal
                    data={newTeam}
                    getLeague={getLeague}
                    isVisible={true}
                    onClose={() => setNewTeam(null)}
                    toast={toast}
                    tournamentId={state._id}
                    pushTeam={team => {
                        console.log(team)
                    }}
                    updateTeam={(team, players) => {
                        console.log('update')
                    }}
                />
            ) : null}
            {(isVisible ? <div className={'modal'} onClick={() => setIsVisible(false)}>
                <div className={'modal-dialog'} onClick={e => e.stopPropagation()}>
                    <div className={'emblem-wrap'}>
                        {emblem({source: selectedTeam.club.emblem || (selectedTeam.club.origin ? selectedTeam.club.origin.emblem : null) || require('./pennant.png'), backdroped: true, size: 'lg'})}
                    </div>
                    <div className={'modal-background'}>
                        <div className={'text'}>Исключить команду {selectedTeam.name}<br/> из турнира:</div>
                        <div className={'actions'}>
                            <div className="action">
                                <RadioButton
                                    inputId="removeAllMatches"
                                    name="mode"
                                    value='removeAllMatches'
                                    onChange={(e) => setOptionRequest(e.value)}
                                    checked={optionRequest === 'removeAllMatches'}
                                />
                                <label htmlFor="removeAllMatches" className={'label'}>и удалить все матчи</label>
                            </div>
                            <div className="action">
                                <RadioButton
                                    inputId="valkoverRestMatches"
                                    name="mode"
                                    value='valkoverRestMatches'
                                    onChange={(e) => setOptionRequest(e.value)}
                                    checked={optionRequest === 'valkoverRestMatches'}
                                />
                                <label htmlFor="valkoverRestMatches" className={'label'}>и назначить технические поражения</label>
                            </div>
                        </div>
                        <div className='button-group'>
                            <Button label="Отмена" className="button button-escape" onClick={() => setIsVisible(false)}/>
                            <Button
                                label={"Отзаявить команду"}
                                icon="pi pi-check"
                                className='button button-sub'
                                onClick={async () => {
                                    await handleDeleteTeam(selectedTeam.squad.tournamentId, selectedTeam._id, toast, optionRequest);
                                    updateTeamsList(selectedTeam._id)
                                    setIsVisible(false)
                                    setSelectedTeam(null)
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div> : null)}
            <Sidebar className='request-sidebar' visible={visibleRight} position="right" showCloseIcon={false} onHide={() => {history.goBack(); setVisibleRight(false)}} style={{width: '1546px', overflow: 'hidden'}}>
                <Request team={selectedTeam} setVisibleRight={() => setVisibleRight(false)}/>
            </Sidebar>
            <div className="tournamets2-teams__search">
                <InputText placeholder="Название..." value={searchString || ""} onChange={(e) => setSearchString(e.target.value)} />
                <i className="pi pi-search" />
            </div>
            {data?.length > 0 ? (
                <div className="tournamets2-teams__content">
                    {data.map(item => (
                        <div key={item._id} onClick={() => handleOpen(item)} className="tournamets2-teams__card">
                            <img src={item.club?.emblem || logo} className="tournamets2-teams__card-logo" />
                            <span className="tournamets2-teams__card-title">{item.name}</span>
                            <span className="tournamets2-teams__card-player">{item.squad?.players?.length || 0} игрок{pluralForm(item.squad?.players?.length || 0, ["", "а", "ов"])}</span>
                            <Button icon="pi pi-refresh" className="p-button-secondary p-button-outlined tournamets2-teams__refresh-btn" onClick={e => {e.stopPropagation(); replaceTeam(item);}} />
                            <Button icon="pi pi-trash" className="p-button-danger p-button-outlined" onClick={e => {e.stopPropagation(); setIsVisible(true); setSelectedTeam(item)}} />
                        </div>
                    ))}
                </div>
            ) : (
                <div className="tournamets2-disqualification__notfound">{searchString ? 'Поиск не дал результатов' : 'Еще не были добавлены команды'}</div>
            )}
        </div>
    );
};

const BodyReplace = ({stateTeams, updateTargetTeam}) => {
    const [filterValue, setFilterValue] = useState('');
    const [quering, setQuering] = useState(false)
    const filterInputRef = useRef();
    const [teams, setTeams] = useState([])

    useEffect(() => {
        if(filterValue.length > 2 && !quering) {
            setQuering(true)
            axios.get(`${ENDPOINT}v2/list/queryTeams?query=${encodeURIComponent(filterValue)}`, {
                headers: {
                    authorization: localStorage.getItem('_amateum_auth_tkn')
                }
            })
                .then(suggs => {
                    const filtred = suggs.data.filter(s => !stateTeams.find(t => t._id === s._id)).sort((s1, s2) => s1.name.toUpperCase().indexOf(filterValue.toUpperCase()) > s2.name.toUpperCase().indexOf(filterValue.toUpperCase()) ? 1 : -1)

                    setTeams(filtred.map(ul => ({label: ul.name, value: ul})))
                    setQuering(false)
                })
        } else {
            setQuering(false)
            setTeams([])
        }
    }, [filterValue])

    return (
        <div className='body-replace'>
            <div className="p-input-icon-right" style={{width: '100%'}}>
                <InputText value={filterValue} ref={filterInputRef} onChange={(e) => setFilterValue(e.target.value)} placeholder='Введите название команды' style={{width: '100%'}}/>
                {quering ? <i className="pi pi-spin pi-spinner"/> : null}
            </div>
            {teams?.length > 0 && teams.map((t, k) => (
                <div key={k} className='body-replace__team' onClick={() => updateTargetTeam(t.value)}>
                    {t.value.emblem ? <img src={t.value.emblem}/> : null}
                    {t.label}
                </div>
            ))}
        </div>
    )
}

const handleDeleteTeam = (tournamentId, teamId, toastRef, method) => {
    axios.post(`${ENDPOINT}v2/kickTeamFromTournament`,
        {
            "tournamentId": tournamentId,
            "teamId": teamId,
            "method": method
        },
        {
            headers: {
                Authorization: localStorage.getItem('_amateum_auth_tkn'),
                SignedBy: localStorage.getItem('_amateum_auth_tkn')
            }
        }).then(resp => {
        toastRef.show({severity: 'success', summary: 'Успешно', detail: 'Команда удалена!'})
    })
}

/*const state = [
    { name: "Самара область", player: "12 игроков", logo: "" },
    { name: "МОсква область", player: "12 игроков", logo: "" },
    { name: "Ростовская область", player: "12 игроков", logo: "" },
    { name: "123 область", player: "12 игроков", logo: "" },
    { name: "Ростовская область", player: "12 игроков", logo: "" },
];*/

export default Teams;
