import React, { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import { Checkbox } from "primereact/checkbox";

import DataTable from "../../../Common/DataTable";

import logoDefault from "../.././img/defaultLogo.png";

import "./style.scss";

const Teams = ({ data, updateData, checkbox, onChecboxChange, handleItem }) => {
    const id = window.location.search.replace('?mode=teams&id=', '')

    useEffect(() => {
        if (id) {
            const team = data.teams?.find((team) => team._id === id);
            if (team) {
                handleItem({data: team});
            }
        }
    }, [id]);

    const createCustomItem = (logo, name, canonical, id) => (
        <div className="clubs-list__table-item">
            <Checkbox value={id} onChange={onChecboxChange} checked={checkbox.includes(id)} />
            {logo ? <img src={logo} /> : <img src={logoDefault} />}
            <div className="clubs-teams__text">
                <span>{name}</span>
                {canonical && <span className="clubs-teams__active">основная команда</span>}
            </div>
        </div>
    );

    return (
        data && (
            <div className="clubs-teams">
                <DataTable
                    noCreateBtn={true}
                    emptyMessage="Команды не найдены"
                    onClick={handleItem}
                    columns={[
                        { field: "name", header: "Название" },
                        { field: "discipline", header: "Дисципллина" },
                        { field: "age", header: "Привязка к году рождения" },
                        { field: "team_img", header: "Командное фото" },
                    ]}
                    rows={data.teams?.map((row) => ({
                        ...row,
                        name: { custom: createCustomItem(row?.emblem, row?.name, row?.canonical, row?._id), value: row?.name },
                        discipline: { value: row?.discipline?.name },
                        age: { value: row?.age ? row?.age + ' г.р.' : 'нет' },
                        team_img: { value: row?.team_img, type: "photo" },
                    }))}
                    paginator={data.length > 8}
                    perPage={8}
                    search
                />
            </div>
        )
    );
};

export default Teams;
