import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import { ENDPOINT } from "../../../../../../env";
import service from "../../../../../Tournaments/DreamLineups/service";
import { schemas } from "../../../../../../references";
import { diffData } from "../../../../../../utils";

import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import Lineup from "./Lineup";

import "./style.scss";

const DreamLineupsDetail = ({ current, state, toast, closeLineup, getDreamLineups }) => {
    const [data, setData] = useState(current);
    const [arrBasic, setArrBasic] = useState([]);

    const dataToSend = diffData(current, data);
    const disabledBtn = !dataToSend || !data?.name || !data?.formation || arrBasic.find((item) => item.indx);
    const [renderingGraphics, setRenderingGraphics] = useState(false);

    const [searchString, setSearchString] = useState("");
    const [searchPlayers, setSearchPlayers] = useState([]);
    const [empty, setEmpty] = useState(false);
    const debouncedSearchTerm = useDebounce(searchString, 700);

    const formationKey = state?.league.discipline && state?.league.discipline.format && state?.league.discipline.format.includes("x") ? state?.league.discipline.format.split("x")[0] : 8;
    const lineups = schemas[formationKey].map((s) => "1-" + s);

    useEffect(() => {
        if (!current._id) {
            setData({
                name: "",
                players: arrBasic,
                tournamentId: state._id,
                federationId: state.federationId,
                formation: state.config.schema ? "1-" + state.config.schema : "1-" + schemas[formationKey][0],
            });
        }
        setSearchString("");
        setSearchPlayers([]);
        setArrBasic(current.players);
    }, [current]);

    useEffect(() => {
        if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
            getDataBySearchString(debouncedSearchTerm);
        } else {
            setSearchPlayers([]);
        }
    }, [debouncedSearchTerm]);

    const getDataBySearchString = (string) => {
        const regexp = /\D\D+/;
        if (string.match(regexp) !== null) {
            axios
                .get(`${ENDPOINT}v2/getTournamentPlayers/${state._id}?query=${encodeURIComponent(string)}`, {
                    headers: {
                        authorization: localStorage.getItem("_amateum_auth_tkn"),
                    },
                })
                .then((resp) => {
                    setSearchPlayers(resp.data || []);
                    if (resp.data.length === 0) {
                        setEmpty(true);
                    } else {
                        setEmpty(false);
                    }
                });
        }
    };

    const updateData = (key, val) => {
        setData((prev) => ({ ...prev, [key]: val }));
    };

    const addToBasic = (plr, ind) => {
        const plrs = [...arrBasic];
        const checkPlr = plrs.find((item) => item._id.toString() === plr._id.toString());
        if (!checkPlr) {
            const mapdPlrs = plrs.map((item) => {
                if (item.active) {
                    return { ...plr };
                } else {
                    return item;
                }
            });
            setArrBasic(mapperGetActive(mapdPlrs));
        }
    };

    const renderLineupGraphics = () => {
        setRenderingGraphics(true);
        const sharableUrl = `${ENDPOINT}render/${data.federationId === "624c17e25887f52dbfc6819c" ? "ole" : "elegance"}/lineup/${data._id}`;
        axios.get(`${ENDPOINT}share?url=${encodeURIComponent(sharableUrl)}&asDecoded=true`).then((resp) => {
            setRenderingGraphics(false);
            const a = document.createElement("a");
            a.href = "data:image/png;base64," + resp.data;
            a.download = `${data.name}.png`;
            a.click();
        });
    };

    const handleSave = () => {
        const updLineup = { ...data, players: arrBasic, managerDream: data.managerDream ? data.managerDream : null };
        /*const newLineup = {
            name: data.name,
            players: arrBasic,
            tournamentId: state._id,
            federationId: state.federationId,
            formation: state.config.schema ? '1-'+state.config.schema : '1-'+schemas[formationKey][0]
        }*/
        if (updLineup._id) {
            service.updateDreamLineup(updLineup._id, updLineup, toast).then((resp) => resp && closeLineup());
        } else {
            service.createDreamLineup(updLineup, toast).then((resp) => resp && closeLineup());
        }
    };

    const handleDelete = () => {
        service.deleteDreamLineup(data._id, toast).then((resp) => resp && closeLineup());
    };

    return (
        <div className="team-detail">
            <div className="team-detail__card">
                <div className="team-detail__card-header">
                    <span className="team-detail__card-title">Основная информация</span>
                    <Button onClick={renderLineupGraphics} className="p-button-outlined" label="Графика" icon="pi pi-download" loading={renderingGraphics} />
                </div>
                <div className="team-detail__info">
                    <div className="team-detail__input">
                        <label className="team-detail__input-label">Название сборной*</label>
                        <InputText placeholder="Укажите название" value={data.name} onChange={(e) => updateData("name", e.target.value)} />
                    </div>
                    <div className="team-detail__input">
                        <label className="team-detail__input-label">Схема*</label>
                        <Dropdown
                            value={data.formation}
                            onChange={(e) => updateData("formation", e.target.value)}
                            options={lineups.map((l) => ({ value: l, label: l }))}
                            placeholder="Выберите схему"
                            emptyFilterMessage="Ничего не найдено"
                            emptyMessage="Ничего не найдено"
                            appendTo="self"
                        />
                    </div>
                    <div className="team-detail__input">
                        <label className="team-detail__input-label">MVP сборной</label>
                        <Dropdown
                            value={data.mvpId}
                            onChange={(e) => updateData("mvpId", e.target.value)}
                            options={arrBasic?.filter((a) => !a._id.includes("plr"))?.map((p) => ({ value: p._id, label: `${p.name || ""} ${p.surname || ""}` }))}
                            placeholder="Выберите MVP"
                            emptyFilterMessage="Ничего не найдено"
                            emptyMessage="Ничего не найдено"
                            appendTo="self"
                        />
                    </div>
                    <div className="team-detail__input">
                        <label className="team-detail__input-label">Менеджер сборной</label>
                        <InputText value={data.managerDream} placeholder="Выберите менеджера" onChange={(e) => updateData("managerDream", e.target.value)} />
                    </div>
                </div>
            </div>
            <div className="team-detail__container">
                <div className="team-detail__players">
                    <span className="team-detail__players-title">Выбор игроков</span>
                    <div className="team-detail__search">
                        <InputText
                            placeholder="Начните вводить ФИО игрока..."
                            value={searchString || ""}
                            onChange={(e) => {
                                setSearchString(e.target.value);
                                setEmpty(false);
                            }}
                        />
                        <i className="pi pi-search" />
                    </div>
                    <div className="team-detail__tip">
                        <i className="pi pi-info-circle" />
                        <span className="team-detail__tip-text">Кликните по игроку в списке для привязки к выделенной позиции</span>
                    </div>
                    <div className="team-detail__players-container">
                        {empty ? (
                            <div className="team-detail__empty">Ничего не найдено</div>
                        ) : (
                            searchPlayers.map((player) => {
                                return (
                                    <div className="team-detail__player" key={player._id} onClick={() => addToBasic(player)}>
                                        <span className="team-detail__player-position">{player.num || "БН"}</span>
                                        <span className="team-detail__player-name">{player.fio}</span>
                                        <span className="team-detail__player-place">{player.teams?.[0]?.name}</span>
                                        <span className={`team-detail__player-main ${arrBasic.find((plr) => plr._id === player._id) ? "visible" : ""}`}>в основе</span>
                                    </div>
                                );
                            })
                        )}
                    </div>
                </div>
                <Lineup lineup={data} arrBasic={arrBasic} updateArrBasic={setArrBasic} />
            </div>
            <div className="clubs-detail__button">
                {!!data._id && <Button label="Удалить" onClick={handleDelete} className="p-button-danger" />}
                <Button label="Сохранить" onClick={handleSave} disabled={disabledBtn} />
            </div>
        </div>
    );
};

export default DreamLineupsDetail;

const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value]);

    return debouncedValue;
};

const mapperGetActive = (arr) => {
    let checkFirst = false;
    const res = [];
    for (let item of arr) {
        if (item.surname || item.name || item.num) {
            res.push(item);
        } else {
            if (!checkFirst) {
                res.push({ ...item, active: true });
                checkFirst = true;
            } else {
                res.push(item);
            }
        }
    }
    return res;
};
