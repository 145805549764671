import React, {useState} from "react";

import {OverlayPanel} from "primereact/overlaypanel";

import './style.scss'

const CircleToGroup = ({ stage, data, updateData, selectGroup, groupOp, renameGroups, isFinished }) => {
    const places = data && data.rules ? data.rules.map(r => r.place) : []
    const placesByGroup = data && data.groups ? data.groups.map(r => r.rules ? r.rules.map(_r => _r.place) : []) : []

    /*const updateSelected = (i, g) => {
        if (selected[g] && selected[g].includes(i)) {
            const newSelected = {...selected}
            newSelected[g] = newSelected[g].filter(p => p !== i)
            setSelected(newSelected)
            return
        }
        const newSelected = {...selected}
        if (!newSelected[g]) newSelected[g] = []
        newSelected[g].push(i)
        setSelected(newSelected)
    }*/

    const updateSelected = (i, g) => {
        if (places.includes(i)) {
            if (checkAvailability(i, g) && !isFinished) {
                const newRules = data.rules.filter(p => p.place !== i)
                const newGroups = [...data.groups]
                newGroups[g].rules = newGroups[g]?.rules.filter(p => p.place !== i)
                updateData({...data, rules: newRules, groups: newGroups})
                return
            }
            return;
        }
        const newRules = data?.rules ? [...data.rules] : []
        newRules.push({place: i})
        const newGroups = [...data.groups]
        if (newGroups[g]?.rules) {
            newGroups[g]?.rules.push({place: i})
        } else {
            newGroups[g].rules = []
            newGroups[g].rules.push({place: i})
        }

        updateData({...data, rules: newRules, groups: newGroups})
    }

    const checkAvailability = (num, idx) => placesByGroup.findIndex(p => p.includes(num)) === idx

    return (
        <div className='circle-to-group'>
            {data?.groups?.length > 0 && <div className='circle-to-group__header'>Команды занявшие место*</div>}
            {data?.groups?.map((g, idx) => (
                <div key={idx} className='circle-to-group__container'>
                    <div className='circle-to-group__label' onClick={(e) => (idx === 0 ? groupOp.current.toggle(e) : null)}>
                        Группа {selectGroup === "alphabetic" ? String.fromCharCode(65 + idx) : idx + 1} {idx === 0 && <p className="pi pi-chevron-down" style={{margin: 0}}></p>}
                    </div>
                    <div className='circle-to-circle__content'>
                        {stage.teams?.map((t, i_t) => (
                            <div key={i_t} onClick={() => updateSelected(i_t + 1, idx)} className={`circle-to-circle__team ${placesByGroup[idx]?.includes(i_t + 1) ? 'circle-to-circle__team_selected' : ''} ${places?.includes(i_t + 1) && !checkAvailability(i_t + 1, idx) ? 'circle-to-circle__team_disabled' : ''}`}>
                                {i_t + 1} <span>место</span>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
            <OverlayPanel ref={groupOp} style={{maxWidth: "363px"}}>
                <div className="group-structure__overlay">
                    <span className="group-structure__item-change" onClick={() => renameGroups("alphabetic")}>Буквенная</span>
                    <span className="group-structure__item-change" onClick={() => renameGroups("digital")}>Цифровая</span>
                </div>
            </OverlayPanel>
        </div>
    )
}

export default CircleToGroup