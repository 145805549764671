import React, { useEffect, useState } from "react";
import {useHistory} from "react-router-dom";

import {deleteByUrl, getByUrl, postByUrl, putByUrl} from "../../../../v3MethodsService";

import {Button} from "primereact/button";

import RolesAccess from "./Access";
import RolesUsers from "./Users";
import Header from "../../Common/Header";
import Breadcrumbs from "../../Common/Breadcrumbs";

import "./style.scss";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const RolesDetail = ({ toast, state, getRoles }) => {
    const history = useHistory()

    const [tab, setTab] = useState("users");
    const [isUpdate, setIsUpdate] = useState(false);
    const [data, setData] = useState(null)
    const [isChanged, setIsChanged] = useState(false);

    useEffect(() => {
        if (state && ((state.active && state.active.type === "flex") || (state.copy))) {
            setTab("access");
        } else {
            setTab('users')
        }
        setData(state ? state.copy || state.active : null);
    }, [state]);

    const Specified = wrap[tab] ? wrap[tab] : wrap.users;

    const items = [
        { label: "Права доступа", icon: "pi pi-lock", type: "access" },
        { label: "Список пользователей " + (data?.users?.length || ''), icon: "pi pi-users", type: "users" },
    ];

    const updateForm = (key, val) => {
        setData(prev => ({...prev, [key]: val}))
        setIsChanged(true)
    };

    const typeState = (x) => {
        switch (x) {
            case "flex":
                return <div className="roles-list__row flex">Гибкая роль</div>;
            default:
                return <div className="roles-list__row">Системная роль</div>;
        }
    };

    const renderHeader = () => {
        return (
            <>
                <div className="header__top">
                    <Breadcrumbs title={state ? state?.copy?.name || state?.active?.name : ''}/>
                    {typeState(data.type)}
                </div>
                {renderTitleOrInput()}
            </>
        );
    };

    const handleBlur = async () => {
        setIsUpdate(false);
    };

    const renderTitleOrInput = () => {
        return isUpdate ? (
            <input value={data.name} className="header__title" onChange={(e) => updateForm("name", e.target.value)} onBlur={handleBlur} autoFocus placeholder="Название роли" />
        ) : (
            <span className="header__title">
                {data.name}
                {data.type === "flex" ? <i className="pi pi-pencil" onClick={() => setIsUpdate(true)}></i> : null}
            </span>
        );
    };

    const updateUsers = () => {
        getByUrl(`users?roleId=${state.active._id}`)
            .then(resp => {
                if (resp && resp.success) {
                    setData(prev => ({...prev, 'users': resp.data}))
                }
            })
    }

    const saveHandler = () => {
        const respHandler = (resp) => {
            if (resp && resp.success) {
                toast.show({severity: 'success', summary: 'Роль успешно сохранена', life: 3000})
                getRoles()
                history.push('/roles')
                return
            }

            toast.show({severity: 'error', summary: 'Ошибка сервера', detail: 'Не удалось сохранить роль', life: 3000})
        }

        if (state.copy) {
            postByUrl('roles', data)
                .then(resp => respHandler(resp))
            return
        }

        putByUrl(`roles/${state.active._id}`, data)
            .then(resp => respHandler(resp))

    }

    const confirmDelete = () => {
        confirmDialog({
            message: 'Вы уверены, что хотите удалить роль?',
            header: 'Удалить роль',
            icon: 'pi pi-info-circle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            accept: deleteHandler,
        });
    };

    const deleteHandler = () => {
        deleteByUrl(`roles/${state.active._id}`)
            .then(resp => {
                if (resp && resp.success) {
                    toast.show({severity: 'success', summary: 'Роль успешно удалена', life: 3000})
                    getRoles()
                    history.push('/roles')
                    return
                }

                toast.show({severity: 'error', summary: 'Ошибка сервера', detail: 'Не удалось удалить роль', life: 3000})
            })
    }

    return !!data && (
        <div className="roles-detail">
            <Header state={data} setTab={setTab} tab={tab} items={items} headerTop={renderHeader} />
            <Specified toast={toast} users={data?.users || []} updateUsers={updateUsers} state={state.copy || state.active} updateForm={updateForm} />
            <div className='roles-detail__buttons'>
                {!((state && state.copy) || (state.active && state.active.type === "system")) && <Button label='Удалить' icon='pi pi-trash' className="p-button-danger" onClick={confirmDelete}/>}
                {((state && state.copy) || (state.active && isChanged)) && <Button label='Сохранить' icon='pi pi-check' onClick={saveHandler}/>}
            </div>
        </div>
    )
};

const wrap = {
    access: RolesAccess,
    users: RolesUsers,
};

export default RolesDetail;
