import React, { useState, useEffect } from "react";

import { InputText } from "primereact/inputtext";

import './style.scss'

const InputUi = ({ value = '', onChange = (any) => {}, placeholder = '', label = '', mutedLabel='', id = 'input', className = '', type = '', keyfilter = '', disabled = false, description = '', invalid=false, isValid=true, onKeyDown = (any) => {}, loading = false }) => {
    return (
        <div className={`input-container ${className}`}>
            {label ? <label htmlFor={id} className='input-container__label'>{label} <span>{mutedLabel}</span></label> : null}
            <span className="p-input-icon-right">
                {loading ? <i className="pi pi-spin pi-spinner"/> : null}
                <InputText id={id} value={value} onChange={onChange} placeholder={placeholder} className={`input ${type} ${invalid ? 'p-invalid' : ''} ${isValid ? '' : 'input_invalid'}`} keyfilter={keyfilter} disabled={disabled} onKeyDown={onKeyDown}/>
            </span>
            {description ? <div className='input-container__description'>{description}</div> : null}
        </div>
)
}

export default InputUi