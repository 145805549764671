import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

import iconEmblem from "../img/i-no-emblem.png";

import './style.scss'

const Matchday = ({ id, mday, current, state, updateMd, saveClick, updateName, isFinished }) => {
    const history = useHistory()
    const location = useLocation()
    const [editor, setEditor] = useState(false)
    const [name, setName] = useState(mday ? mday.name : '')

    useEffect(() => {
        if (saveClick) {
            setEditor(false)
        }
    }, [saveClick]);

    useEffect(() => {
        setName(mday?.name)
    }, [mday?.name]);

    const teamsOptions = state?.teams
        .filter(t => current?.teams?.map(c => c._id).includes(t._id))
        .filter(t => !mday?.matches?.some(c => c.homeId === t._id || c.awayId === t._id))

    const teamOptionsByGroup = current?.groups?.reduce((acc, g) => {
        acc[g._id] = teamsOptions?.filter(to => g.teams?.find(t => t._id === to._id))
        return acc
    }, {}) || {}

    const updateData = (val, key, matchKey) => {
        if (matchKey !== undefined) {
            const newMatches = [...mday.matches]
            newMatches[matchKey][key] = val
            newMatches[matchKey][`${key}Id`] = val?._id || null
            updateMd({ ...mday, matches: newMatches }, id);
        } else {
            updateMd({ ...mday, [key]: val }, id);
        }
    };

    const selectedTeamTemplate = (option, props) => props.value ? (
        <div className="calendar-matchday__team calendar-matchday__team_editor">
            <img src={props.value.emblem || iconEmblem}/>
            <div className='calendar-matchday__team-name'>{props.value.name}</div>
        </div>
    ) : (
        <div className="calendar-matchday__team calendar-matchday__team_editor">
            {props.placeholder}
        </div>
    )

    const teamOptionTemplate = option => (
        <div className="calendar-matchday__team">
            <img src={option.emblem || iconEmblem}/>
            {option.name}
        </div>
    );

    const changeName = (value) => {
        if (value <= current.matchdays?.length) {
            const string = value + ' ' + name.replace(/[0-9]+/g, '').replace(' ', '');
            updateData(string, 'name')
        }
    }

    return !!mday && (
        <div className='calendar-matchday'>
            <div className='calendar-matchday__header'>
                {editor ? <InputText value={current?.type === 'playoff' ? mday.name : ((name && name.match(/\d+/)) ? name.match(/\d+/)[0] : '')} onChange={e => changeName(e.target.value)} onBlur={updateName} disabled={current?.type === 'playoff'}/> : mday.name}
                {!editor && <Button icon='pi pi-pencil' className='p-button-outlined' onClick={() => setEditor(true)} disabled={isFinished}/>}
            </div>
            <div className='calendar-matchday__matches'>
                {mday.matches.map((m, key) => (
                    <div key={key} className={`calendar-matchday__match${editor ? ' calendar-matchday__match_editor' : ''}`} onClick={() => !!(m._id && m.home && m.away && !editor) && history.push(`${location.pathname}?editmatch=${m._id}`)}>
                        {editor ? (
                            <Dropdown emptyMessage="Ничего не найдено" value={m.home} options={m.groupId ? teamOptionsByGroup[m.groupId] : teamsOptions} placeholder='Команда хозяев' optionLabel="name" onChange={e => updateData(e.value, 'home', key)} valueTemplate={selectedTeamTemplate} itemTemplate={teamOptionTemplate} showClear/>
                        ) : (
                            <div className='calendar-matchday__team'>
                                <img src={m.home?.emblem || iconEmblem}/>
                                <div className='calendar-matchday__team-name'>{m.home?.name}</div>
                            </div>
                        )}
                        <div className={`calendar-matchday__score${editor ? ' calendar-matchday__score_editor' : ''}`}>
                            {current?.type === 'groups' && !!m.groupName && <div className='calendar-matchday__group'>{m.groupName}</div>}
                            {m.scores?.full && (m.scores?.full?.home || m.scores?.full?.away) ? `${m.scores?.full?.home || 0} : ${m.scores?.full?.away || 0}` : '-'}
                        </div>
                        {editor ? (
                            <Dropdown emptyMessage="Ничего не найдено" value={m.away} options={m.groupId ? teamOptionsByGroup[m.groupId] : teamsOptions} placeholder='Команда гостей' optionLabel="name" onChange={e => updateData(e.value, 'away', key)} valueTemplate={selectedTeamTemplate} itemTemplate={teamOptionTemplate} showClear/>
                        ) : (
                            <div className='calendar-matchday__team'>
                                <div className='calendar-matchday__team-name'>{m.away?.name}</div>
                                <img src={m.away?.emblem || iconEmblem}/>
                            </div>
                        )}
                        {!!(m._id && m.home && m.away && !editor) && <div className='calendar-matchday__arrow'><i className='pi pi-chevron-right'/></div>}
                    </div>
                ))}
            </div>
        </div>
    )
}

const getNumFromName = name => {
    const replaced = name.replace(/\D/g,'');
    if (replaced) {
        return +replaced
    } else {
        return 1
    }
}

export default Matchday