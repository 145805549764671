import React, { useEffect, useState } from "react";

import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";

import CircleToPlayoff from "./CircleToPlayoff";
import GroupToPlayoff from "./GroupToPlayoff";
import PlayoffToPlayoff from "./PlayoffToPlayoff";

import './style.scss'

const AutoPlayoff = ({ selectedStage, data, renderTitleOrInput, removeGrid, updateData, state, toast, assignTeams, isFinished }) => {

    const roundOptions = data && data.playoffgrids ? data.playoffgrids.map(p => ({value: p._id, label: p.name})) : []

    const Specified = selectedStage && selectedStage.type && wrap[selectedStage.type] ? wrap[selectedStage.type] : wrap.round

    const selectParent = (value, idx) => {
        const newPlayoffgrids = [...data.playoffgrids]
        newPlayoffgrids[idx].parentId = value
        updateData(newPlayoffgrids, 'playoffgrids')
    }

    return !!selectedStage && (
        <div className='auto-playoff'>
            {data.playoffgrids?.map((item, idx) => (
                <div key={idx} className='playoff-structure__card teams'>
                    <div className="playoff-structure__container teams">
                        {renderTitleOrInput(item, idx)}
                        <div className="playoff-structure__btns">
                            <Button icon="pi pi-trash" className="playoff-structure__remove p-button-outlined  p-button-danger" onClick={() => removeGrid(item)} disabled={isFinished}/>
                        </div>
                    </div>
                    {idx !== 0 && (
                        <div className='auto-playoff__select'>
                            <div className='auto-playoff__label'>Из какого раунда наследуется*</div>
                            <Dropdown value={item.parentId} onChange={e => selectParent(e.value, idx)} options={roundOptions.filter(r => r.value !== item._id)} placeholder='Выберите раунд' disabled={isFinished}/>
                        </div>
                    )}
                    <Specified selectedStage={selectedStage} item={item} data={data} updateData={updateData} state={state} assignTeams={assignTeams} isFinished={isFinished}/>
                </div>
            ))}
        </div>
    )
}

const wrap = {
    round: CircleToPlayoff,
    groups: GroupToPlayoff,
    playoff: PlayoffToPlayoff
}

export default AutoPlayoff