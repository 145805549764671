import React, {useState} from "react";

import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";

const PlayoffItem = ({item, idx, renderTitleOrInput, state, removeGrid, addAllTeams, assignTeams, updateTeams, isFinished }) => {

    const [filteredTeams, setFilteredTeams] = useState([]);

    const unassigned = state?.teams
        .filter(t => !item?.teams?.map(c => c._id).includes(t._id))

    const searchTeam = (event) => {
        const filtered = unassigned.filter(team => team.name?.toLowerCase().includes(event.query.toLowerCase()));
        setFilteredTeams(filtered);
    };

    return (
        <div className="playoff-structure__card teams" key={idx}>
            <div className="playoff-structure__container teams">
                {renderTitleOrInput(item, idx)}
                <div className="playoff-structure__btns">
                    <Button icon="pi pi-trash" className="playoff-structure__remove p-button-outlined  p-button-danger" onClick={() => removeGrid(item)} disabled={isFinished}/>
                    {(idx === 0 && unassigned?.length > 0) && <Button onClick={addAllTeams} icon="pi pi-users" label="Добавить все команды" className="p-button-outlined" disabled={isFinished}/>}
                </div>
            </div>
            {unassigned?.length > 0 && (
                <div className="playoff-structure__hint">
                    <i className="pi pi-info-circle"></i>
                    <span>Не распределены команды: {unassigned?.map(t => t.name).join(", ")}</span>
                </div>
            )}
            <div className="playoff-structure__items">
                <div className="playoff-structure__item">
                    <AutoComplete
                        value={assignTeams(item.teams)}
                        field='name'
                        suggestions={filteredTeams}
                        completeMethod={searchTeam}
                        onChange={(e) => updateTeams(idx, item._id, e.value)}
                        multiple
                        placeholder="Начните вводить название команды"
                        appendTo='self'
                        disabled={isFinished}
                    />
                </div>
            </div>
        </div>
    )
}

export default PlayoffItem