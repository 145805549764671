export const contents = {
    federation: {
        structure: {
            title: '🎉 Добро пожаловать в управление футбольной организацией!',
            body: (
                <p>
                    Это — раздел <b>«Структура»</b>.<br /><br />Посмотрите небольшое видео о том, как создавать в нём <b>лиги</b>, <b>сезоны</b> и <b>турниры</b> для дальнейшего управления.
                </p>
            ),
            media: require('./tutorials/structure.mp4')
        },
        editmatch: {
            title: null,
            body: (
                <p><b>«Лента матчей»</b> — идеальное место для внесения и редактирования статистики недавних игр, а также просмотра информации о ближайших.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/editmatch.mp4')
        },
        tournaments: {
            title: null,
            body: (
                <p>В этом разделе можно внести начальные настройки для новых турниров или скорректировать уже идущие.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/tournaments.mp4')
        },
        clubs: {
            title: null,
            body: (
                <p>Управление командами и представителями, быстрый доступ к заявкам в турниры.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/clubs.mp4')
        },
        inbox: {
            title: null,
            body: (
                <p>В этом разделе можно видеть различные запросы от представителей команд.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/addons.mp4')
        },
        publications: {
            itle: null,
            body: (
                <p>Создавайте или импортируйте публикации для ваших сайтов и приложений.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/publications.mp4')
        },
        players: {
            itle: null,
            body: (
                <p>Изменяйте данные и объединяйте задублированные профили игроков.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/players.mp4')
        }
    },
    club: {
        manageclub: {
            title: null,
            body: (
                <p>Отправляйте заявки и дозаявки игроков из этого раздела.<br />Наше небольшое видео поможет Вам разобраться с основными действиями.</p>
            ),
            media: require('./tutorials/club_addons.mp4')
        }
    },
    employee: {

    },
    superAdmin: {

    }
}
