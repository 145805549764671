import React, {useContext, useEffect, useRef, useState} from 'react';

import moment from "moment";

import {deleteByUrl, getByUrl, postByUrl, putByUrl} from "../../../v3MethodsService";
import {WorkspaceContext} from "../../../ctx";

import DataTable from "../Common/DataTable";
import NoticesModal from "./Modal";

import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {SelectButton} from "primereact/selectbutton";
import {Dropdown} from "primereact/dropdown";
import {confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";
import {Column} from "primereact/column";

import './style.scss'

const Notices = ({}) => {
    const toast = useRef(null)

    const wctx = useContext(WorkspaceContext);
    const role = wctx && wctx.appSubject && wctx.appSubject.type

    const [state, setState] = useState(null)
    const [loading, setLoading] = useState(null)

    const [tableData, setTableData] = useState([])
    const [options, setOptions] = useState({...initOptions})

    const [modalState, setModalState] = useState(null)

    useEffect(() => {
        if (role === 'league') {
            changeOptions('admin', 'type')
        }
    }, [role]);

    useEffect(() => {
        getData()
    }, []);

    useEffect(() => {
        if (options && options.type && state) {
            let filteredData = options.isPublished ? state.list ? [...state.list] : [] : state.notPublished ? [...state.notPublished] : []
            filteredData = filteredData.filter(item => item.type === options.type)
            if (options.author) {
                filteredData = filteredData.filter(item => item.creator?._id === options.author)
            }
            setTableData(filteredData)
        }
    }, [options, state]);

    const getData = () => {
        setLoading(true)
        getByUrl('notices')
            .then(resp => {
                if (resp && resp.success) {
                    setState({...resp.data, users: resp.data.users?.map(u => ({label: `${u.surname} ${u.name}`, value: u._id})) || []})
                }
                setLoading(false)
            })
    }

    const changeOptions = (value, key) => {
        setOptions(prev => ({ ...prev, [key]: value }))
    }

    const handleClick = (item) => {
        setModalState(item.data)
    }

    const handleDelete = (e, item) => {
        e.stopPropagation();
        confirmDialog({
            message: 'После удаления вы не сможете восставновать уведомлние',
            header: 'Удалить уведомление?',
            icon: 'pi pi-exclamation-triangle',
            defaultFocus: 'reject',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Удалить',
            rejectLabel: 'Отмена',
            accept: () => acceptDelete(item._id),
        });
    }

    const acceptDelete = (id) => {
        deleteByUrl(`notices/${id}`)
            .then(resp => {
                if (resp && resp.success) {
                    toast.current.show({severity: 'success', detail: 'Успешно удалено', life: 2000})
                    setModalState(null)
                    getData()
                }
            })
    }

    const closeModal = () => {
        setModalState(false)
        getData()
    }

    const handleSend = (e, item) => {
        e.stopPropagation();
        const body = {type: item.type, title: item.title, description: item.description, published: true};

        const executeAfterRequest = (resp) => {
            if (resp && resp.success) {
                toast.current.show({severity: 'success', detail: 'Уведомление отправлено пользователям', life: 2000})
                getData()
            }
        }

        if (item._id) {
            putByUrl(`notices/${item._id}`, body).then(resp => executeAfterRequest(resp))
        } else {
            postByUrl('notices', body).then(resp => executeAfterRequest(resp))
        }
    }

    return !!state && (
        <section id='notices' className='notices'>
            <Toast ref={toast} position='top-right'/>
            <NoticesModal state={modalState} onClose={closeModal} onRemove={e => handleDelete(e, modalState)} toast={toast}/>
            <div className='notices__header'>
                <div className='notices__options'>
                    {role !== 'league' && <SelectButton value={options?.type} options={typeOptions} onChange={e => changeOptions(e.target.value, 'type')} className='notices__select' unselectable={false}/>}
                    <SelectButton value={options?.isPublished} options={publishedOptions} onChange={e => changeOptions(e.target.value, 'isPublished')} className='notices__select' unselectable={false}/>
                    <Dropdown value={options.author} options={state.users} onChange={e => changeOptions(e.target.value, 'author')} placeholder='Все авторы' emptyMessage="Ничего не найдено" showClear />
                </div>
                <Button icon='pi pi-plus' onClick={() => setModalState({...defaultNotice})}/>
            </div>
            {loading ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <DataTable
                    onClick={handleClick}
                    search
                    noCreateBtn
                    columns={[
                        { field: "_title", header: "Название" },
                        { field: "_author", header: "Автор", className: 'secondary' },
                        options?.isPublished ? { field: "_date", header: "Дата отправки", className: 'secondary', sortable: true } : null
                    ]}
                    rows={tableData.map((row) => ({
                        ...row,
                        _title:  { value: row.title || '' },
                        _author: { value: `${row.creator?.surname || ''} ${row.creator?.name || ''}  ${row.creator?.middlename || ''}` },
                        _date: { value: row.publishedDate ? moment(row.publishedDate, 'YY-MM-DD').format('DD.MM.YYYY') : ''}
                    }))}
                    paginator={tableData.length > 8}
                    perPage={8}
                    handleRemove={!options?.isPublished ? handleDelete : false}
                    customDataColumn={() =>
                        !options?.isPublished ?
                            <Column
                                headerClassName="data-table__action"
                                bodyClassName="data-table__action"
                                body={(rowData, idx) => (
                                    <div className="data-table__action-download" onClick={(e) => handleSend(e, rowData)}>
                                        <i className="pi pi-send"></i>
                                    </div>
                                )}
                            /> : null
                    }
                />
            )}
        </section>
    )
}

const typeOptions = [
    {value: 'public', label: 'Публичные'},
    {value: 'admin', label: 'Административные'}
]

const publishedOptions = [
    {value: true, label: 'Отправленные'},
    {value: false, label: 'Не отправленные'}
]

const initOptions = {
    type: 'public',
    isPublished: true,
    author: null
}

const defaultNotice = {
    type: "public",//тип уведомления public admin
    title: "",//заголовок уведомления
    description: "" ,//описание      уведомления
    creatorId: null, //автор документа
    userId: null,//редактор документа
    createDate: null, //дата создания
    published: false, //файл архивирован администратором
    publishedDate: null, //дата архивации
    comment: null, //комментарий к документу

}

export default Notices