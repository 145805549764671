import React, {useEffect, useRef, useState} from 'react'

import {useHistory, useParams } from 'react-router-dom'

import {InputText} from 'primereact/inputtext'
// import {Editor} from 'primereact/editor'
import {Tag} from 'primereact/tag'
import {Checkbox} from 'primereact/checkbox'
import {Calendar} from 'primereact/calendar'
import {Button} from 'primereact/button'
import {Toast} from 'primereact/toast'

import TagsControl from './TagsControl'
import CustomUploads from './CustomUploads'
import Attachment from '../Attachment'

import FormMain from "./FormMain";
import FormBody from "./FormBody";
import './style.scss'
import { diffData } from "../../../utils";
import moment from 'moment'

import CardUi from '../../../UI/CardUi'

import axios from 'axios'
import {ENDPOINT} from '../../../env'
import SwitchUi from '../../../UI/SwitchUi'

const exclude = ['link', 'poll']

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

const Form = ({ data, external, socialId, profile, onSaved, setRemoveDialog }) => {
    const params = useParams()
    const toastRef = useRef(null)
    const history = useHistory()
    
    const [formData, setFormData] = useState(params.id == 'create' ? initialState : data)
    const [state, setState] = useState(params.id == 'create' ? initialState : data);
    const [changed, setChanged] = useState(diffData({}, formData));
    const [content, setContent] = useState(null)
    const [createdId, setCreatedId] = useState(null)
    const publicationId = createdId || (params && params.id && params.id !== 'create' ? params.id : null)

    // console.log('data', data);
    // console.log('state', state);

    useEffect(() => {
        if(data) {
            const obj = {...data, date: moment(data?.date, 'YY-MM-DD')}
            setFormData(params.id == 'create' ? initialState : obj)
            setState(params.id == 'create' ? initialState : obj)
        }
    }, [data])

    // console.log('data', data)
    // console.log('external', external)
    // console.log('state', state)

    useEffect(() => {
        if(data || external) {
            const obj = data ? {...data} : {...external}
            const singleVideoTitle = obj.attachments && obj.attachments.length === 1 && obj.attachments[0].type === 'video' ? obj.attachments[0].video.title : null
            const federationToken = localStorage.getItem('_amateum_auth_tkn');
            //const federation = JSON.parse(localStorage.getItem('_am_federation'))
            let body = obj.body ? obj.body : (obj.content || obj.text) ? [{key: 'text', id: 'text0', text: obj.content || obj.text}] : []
            const attachmentsPhotos = obj.attachments ? obj.attachments.filter(o => o.type === 'photo') : []
            if (!obj.body && attachmentsPhotos.length > 0) {
                body.push({key: 'image', id: 'image0', images: attachmentsPhotos.map(o => o.photo?.sizes?.[0])})
            }
            setState({
                title: obj.title || singleVideoTitle || null,
                published: obj.published || true,
                date: obj.date ? moment(obj.date, 'YY-MM-DD') :  moment().format('YY-MM-DD'),
                category: obj.category || 'news',
                content: obj.content || obj.text || null,
                attachments: obj.attachments ? obj.attachments.filter(a => !exclude.includes(a.type)).map(a => ({...a, selected: true, splitted: a.type === 'video' ? {title: a.video.title} : null})) : [],
                originId: obj.originId || obj.id || null,
                socialId: obj.socialId || socialId || null,
                userId: obj.userId || (profile ? profile.userId : null),
                type: obj.type || 'mixed',
                //federationId: federation ? federation._id : null,
                federationToken: federationToken || null,
                tags:obj.tags ,
                body: body,
                tagsdata: obj.tagsdata || {matches: [], players: [], tournaments: [], teams: []}
            })
            setContent(obj.content || obj.text || null)
        }
    }, [external])
    
    useEffect(() => {
        if (formData) {
            setChanged(diffData(formData, state, ['_id', 'title']));
        }
        }, [state]);

    const updateForm = (key, val) => {
        setState(prev => ({ ...prev, [key]: val }))
    }

    const sendDecoded = async (d) => {
        try {
            const resp = await axios.post(`${ENDPOINT}v2/attachment`,
            {base64Data: await convertBase64(d.file)},
            {
                headers: {
                    Authorization: localStorage.getItem('_amateum_auth_tkn'),
                    SignedBy: localStorage.getItem('_amateum_auth_tkn')
                }
            })
            if (resp.data.path) {
                return resp.data.path
            } else return null
        }
        catch (e) {
            console.log('error')
        }
    }
    
    const sendPublication = async (body) => {
        await axios.put(`${ENDPOINT}v2/publications${publicationId ? '/'+publicationId : ''}`, body, {
            headers: {
                Authorization: localStorage.getItem('_amateum_auth_tkn'),
                SignedBy: localStorage.getItem('_amateum_auth_tkn')
            }
        }).then(resp => {
            if(resp.data) {
                if(!publicationId && resp.data._id) {
                    window.history.pushState({}, null, `/publications/${resp.data._id}`)
                    setCreatedId(resp.data._id)
                }

                toastRef.current.show({severity: 'success', summary: 'Успешно!', detail: 'Публикация сохранена'})
                if(typeof(onSaved) !== 'undefined') {
                    onSaved()
                }
            } else {
                toastRef?.current?.show({severity: 'error', summary: 'Невозможно сохранить', detail: 'Ошибка сервера. Пожалуйста, сообщите в поддержку'})
            }
        })
    }
    
    const saveHandler = async () => {
        if(!state.title || !state.title.length) {
            toastRef.current.show({severity: 'error', summary: 'Невозможно сохранить', detail: 'Добавьте заголовок публикации'})
        } else {
            if(state.socialId) {
                const body = {
                    ...state,
                    date: moment(state.date).format('YY-MM-DD'),
                    attachments: state.attachments.filter(att => att.selected).map(({selected, ...att}) => att),
                    content: content
                }
                await sendPublication(body)
            } else {
                const decoded = state.attachments.filter(att => att.selected && att.type !== 'photo').map(({ selected, ...att}) => att)
                const decodedData = []
                if (decoded.length) {
                    decoded.map(async (d, index) => {
                        d = {
                            ...d,
                            file: {
                                name: d.file.name,
                                path: await sendDecoded(d)
                            }
                        }
                        decodedData.push(d)
                        if(decoded.length === index+1){
                            const body = {
                                ...state,
                                date: moment(state.date).format('YY-MM-DD'),
                                attachments: decodedData,
                                content: content
                            }
                            if (body){
                                await sendPublication(body)
                            }
                        }
                    })
                } else {
                    const body = {
                        ...state,
                        date: moment(state.date).format('YY-MM-DD'),
                        attachments: state.attachments.filter(att => att.selected && att.type === 'photo'),
                        content: content
                    }
                    await sendPublication(body)
                }
            }
            setFormData(state)
        }
    }
    
    let disableSaveBtn = !changed || !state.title || !state.date // || !state.image
    if(state && state?.body?.length && !state?.body?.filter(f => f.key == 'image').every(e => e.images.length)) disableSaveBtn = true
    if(state && state?.body?.length && !state?.body?.filter(f => f.key == 'subtitle').every(e => e.subtitle)) disableSaveBtn = true
    if(state && state?.body?.length && !state?.body?.filter(f => f.key == 'text').every(e => e.text)) disableSaveBtn = true
    if(state && state?.body?.length && !state?.body?.filter(f => f.key == 'quote').every(e => e.text && e.author)) disableSaveBtn = true

    const switchHandler = (val, fkey) => {
        updateForm(fkey, val)
    }

    return (
        <div className="mediaForm-detail">
            <Toast ref={toastRef} />
            <CardUi
                header={
                    <>
                        <div className='mediaForm-detail__categories'>
                            Выбор категории
                            <div>
                                {categories.map((item, index) => {
                                    return (
                                        <div onClick={() => setState({...state, category: item.value})} className={`mediaForm-detail__categories-item ${item.value === state?.category ? ' active' : ''}`} key={index}>{item.label}</div>
                                    )
                                })}
                            </div>
                        </div>
                        <SwitchUi checked={state?.published} value={state?.published} onChange={(e) => switchHandler(e.value, 'published')} label='Опубликовано'/>

                    </>
                }
                content={
                    <FormMain data={state} setData={setState} onChange={updateForm} isExternal={!!external}/>
                }
            />
            <CardUi
                header='Тело новости'
                className='mediaForm-detail__body'
                content={
                    <FormBody data={state?.body || []} onChange={updateForm}/>
                }
            />
            <div className="mediaForm-detail__buttons">
                {/* {!!state._id && <Button label="Удалить" icon="pi pi-trash" onClick={() => deleteDialog('publications', state._id, mainStore, navigation, getPublications)} className="button_delete"/>} */}
                {!!data?._id && <Button label="Удалить" icon="pi pi-trash" onClick={() => setRemoveDialog(data)} className="button_delete"/>}
                <Button label="Сохранить" icon="pi pi-check" onClick={saveHandler} className="translationForm__save" disabled={disableSaveBtn}/>
            </div>
        </div>
    );
}

const categories = [
    {label: 'новости', value: 'news'},
    {label: 'трансляции', value: 'broadcast'},
    {label: 'интервью', value: 'interview'},
    {label: 'обзоры',value: 'highlights'},
    {label: 'анонсы', value: 'previews'},
    {label: 'фото', value: 'photos'},
    {label: 'видео', value: 'videos'}
]

const initialState = {
    "title": "",
    "date": new Date(),
    "url": "",
    "directionId": null,
    "image": "",
    "isPinned": false,
    "isVisible": false,
    "body": []
}

export default Form
