import React, { useRef, useState } from "react";

import "./style.scss";

const CustomToolbar = ({ onNavigate, label }) => {
    return (
        <div className="custom-toolbar">
            <div className="custom-toolbar__btns">
                <div className="custom-toolbar__btn prev-btn" onClick={() => onNavigate("PREV")}>
                    <i className="pi pi-angle-left" />
                </div>
                <div className="custom-toolbar__btn next-btn" onClick={() => onNavigate("NEXT")}>
                    <i className="pi pi-angle-right" />
                </div>
            </div>
            <span className="custom-toolbar__label">{label}</span>
            <div className="custom-toolbar__today" onClick={() => onNavigate("TODAY")}>
                Сегодня
            </div>
        </div>
    );
};

export default CustomToolbar;
