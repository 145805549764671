import React, { useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";

import DataTable from "../../../../Common/DataTable";

import "./style.scss";
import service from "../../../../../Tournaments/DreamLineups/service";

const DreamLineupsList = ({ state, handleCurrent, toast, getDreamLineups }) => {
    const handleDelete = (e, item) => {
        e.stopPropagation();
        service.deleteDreamLineup(item._id, toast).then(() => getDreamLineups());
    };

    return !!(state && state.lineups) ? (
        <div className="tournamets2-team-list">
            <DataTable
                onClick={handleCurrent}
                search
                noCreateBtn
                columns={[
                    { field: "name", header: "Название" },
                    { field: "author", header: "Автор" },
                    { field: "scheme", header: "Схема" },
                ]}
                rows={state.lineups.map((row) => ({
                    ...row,
                    name: { value: row?.name },
                    author: { value: `${row?.author?.name || ""} ${row?.author?.surname || ""}` },
                    scheme: { value: row?.formation },
                }))}
                paginator={state.lineups.length > 8}
                perPage={8}
                handleRemove={handleDelete}
            />
        </div>
    ) : (
        <div className="tournamets2-disqualification__notfound">Еще не было добавлено сборных</div>
    );
};

export default DreamLineupsList;
