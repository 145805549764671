import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Hotkeys from "react-hot-keys";

import moment from "moment/moment";

import { getByUrl, postByUrl } from "../../../../../v3MethodsService";
import { Tooltip } from "primereact/tooltip";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";

import { GlobalContext } from "../../../../../ctx";

import "./style.scss";

const PersonData = ({ state, setState, tokenData }) => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [isValid, setIsValid] = useState(false);
    const [checkboxes, setCheckboxes] = useState([...checkboxesInit]);
    const [showPasswordInvalid, setShowPasswordInvalid] = useState(false);
    const [global, setGlobal] = useContext(GlobalContext);
    const toast = global && global.toast ? global.toast : {};

    useEffect(() => {
        const { personData } = state;
        if (personData) {
            const notValid = formData
                .filter((f) => (tokenData ? f.title !== "Права доступа" : f))
                ?.map((d) => d.fields.map((f) => f.key !== 'phone' && !personData[f.key]))
                .flat()
                .filter((f) => f);
            setIsValid(!notValid.length && !checkboxes.find((c) => !c.value && c.key !== "ad"));
        } else {
            setIsValid(false);
        }
        if (personData && personData.password === personData.repeatPassword && isValidPassword(personData.password)) {
            setShowPasswordInvalid(false);
        }
    }, [state, checkboxes]);

    useEffect(() => {
        if (!tokenData) {
            getByUrl("getRegistrationRefs?section=regions")
                .then((resp) => {
                    if (resp && resp.success && resp.data) {
                        setData((prev) => ({ ...prev, roles: resp.data }));
                    }
                })
        }
    }, []);

    const checkboxHandler = (val, key) => {
        setCheckboxes((prev) => prev.map((c) => (c.key === key ? { ...c, value: val } : c)));
    };

    const inputHandler = (val, key) => {
        setState((prev) => ({ ...prev, personData: { ...prev.personData, [key]: val } }));
    };

    const sendHandler = async () => {
        setIsValid(false);
        const { personData } = state;
        if (personData && personData.password === personData.repeatPassword) {
            if (isValidPassword(personData.password)) {
                const data = {
                    password: personData.password,
                    surname: personData.surname,
                    name: personData.name,
                    middlename: personData.middlename,
                    birthday: personData.birthday,
                    phone: personData.phone,
                    scopes: tokenData?.scope,
                };
                postByUrl(`registration?verify=${tokenData?.token}`, data).then((resp) => {
                    if (resp && resp.success) {
                        history.push("/login");
                    } else {
                        toast.show({ severity: "error", life: 4000, summary: (resp && resp.message) || "Ошибка создания пользователя" });
                        setIsValid(true);
                    }
                });
                return;
            }
            toast.show({ severity: "error", life: 4000, summary: "Пароль должен быть: мин. 8 симв., латиница, мин. 1 буква, мин. 1 цифра" });
            setShowPasswordInvalid(true);
            return;
        }
        setShowPasswordInvalid(true);
        toast.show({ severity: "error", life: 4000, summary: "Пароли не совпадают" });
    };

    const onKeyDown = async (e) => {
        if (e === "Enter" || e.key === "Enter") {
            await sendHandler();
        }
    };

    return (
        <div className="reg-success-person-data">
            <Tooltip target=".custom-target-icon" position="top"/>
            <div className="auth2__title reg-success-person-data__title">Завершение регистрации</div>
            {!!tokenData && <Divider />}
            {!!tokenData && (
                <div className="reg-success-person-data__role">
                    <span>Вам будет присвоена роль</span>
                    {tokenData.role && tokenData.role.name} {tokenData.role && tokenData?.subject?.name && `• ${tokenData.subject.name}`}
                </div>
            )}
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                {formData
                    .filter((f) => (tokenData ? f.title !== "Права доступа" : f))
                    ?.map((d, k) => (
                        <div key={k} className="reg-success-person-data__form">
                            <Divider />
                            <div className="reg-success-person-data__subtitle">{d.title}</div>
                            <div className="reg-success-person-data__fields">
                                {d.fields.map((f) => (
                                    <div key={f.key} className={`auth2__input-container auth2__input-container_${f.size}`}>
                                        <label htmlFor={f.key} className="auth2__input-container-label">
                                            {f.label}{" "}
                                            {f.key === 'password' ? <i
                                                className="custom-target-icon pi pi-info-circle p-text-secondary"
                                                data-pr-tooltip="Требования&nbsp;к&nbsp;паролю: Мин&nbsp;8&nbsp;симв,&nbsp;латиница,&nbsp;мин&nbsp;1&nbsp;буква,&nbsp;мин&nbsp;1&nbsp;цифра"
                                                style={{ cursor: "pointer" }}
                                            ></i> : null}
                                        </label>
                                        {renderField(state.personData, f, inputHandler, showPasswordInvalid, data)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                <Divider />
                <div className="reg-success-person-data__checkboxes">
                    {checkboxes.map((c) => (
                        <div key={c.key} className="auth2__checkbox-container">
                            <Checkbox checked={c.value} onChange={(e) => checkboxHandler(e.checked, c.key)} />
                            <label className="auth2__checkbox-label">
                                {c.label}
                                <a href={c.link} target="_blank">
                                    {c.linkText}
                                </a>
                            </label>
                        </div>
                    ))}
                </div>
                <Button label="Завершить регистрацию" onClick={sendHandler} className="auth2__button" disabled={!isValid} />
            </Hotkeys>
        </div>
    );
};

const formData = [
    {
        title: "Личные данные",
        fields: [
            { key: "surname", label: "Фамилия*", placeholder: "Укажите фамилию", type: "input", size: "full" },
            { key: "name", label: "Имя*", placeholder: "Укажите имя", type: "input", size: "half" },
            { key: "middlename", label: "Отчество*", placeholder: "Укажите отчество", type: "input", size: "half" },
            { key: "birthday", label: "Дата рождения*", placeholder: "Укажите дату рождения", type: "calendar", size: "half" },
            { key: "phone", label: "Номер телефона", placeholder: "+ 7__________", type: "mask", size: "half", mask: "+79999999999" },
            { key: "password", label: "Пароль*", placeholder: "Придумайте пароль", type: "password", size: "half" },
            { key: "repeatPassword", label: "Подтверждение пароля*", placeholder: "Повторите пароль", type: "password", size: "half" },
        ],
    },
    {
        title: "Права доступа",
        fields: [
            {
                key: "role",
                label: "Роль",
                placeholder: "Выберите роль",
                type: "dropdown",
                size: "half",
                options: [
                    { name: "Представитель РФФ", _id: "rff" },
                    { name: "Представитель лиги", _id: "league" },
                    { name: "Представитель клуба", _id: "club" },
                ],
            },
            { key: "region", label: "Регион", placeholder: "Выберите регион", type: "dropdown", size: "half" },
        ],
    },
];

const checkboxesInit = [
    { key: "pers", value: false, label: "Я даю согласие на ", linkText: "обработку моих персональных данных", link: "/docs/personalData.pdf" },
    // {key: 'rules', value: false, label: 'Я ознакомился и принимаю ', linkText: 'правила использования сервиса', link: ''},
    { key: "terms", value: false, label: "Я принимаю ", linkText: "пользовательское соглашение", link: "/docs/agreement.pdf" },
    { key: "ad", value: false, label: "Я даю согласие на ", linkText: "получение рекламных рассылок", link: "/docs/advertising.pdf" },
];

const renderField = (state, field, inputHandler, passwordInvalid, data) => {
    switch (field.type) {
        case "input":
            return <InputText id={field.key} value={state?.[field.key]?.trim() || ""} onChange={(e) => inputHandler(e.target.value, field.key)} placeholder={field.placeholder} className="auth2__input" />;
        case "password":
            return (
                <Password
                    id={field.key}
                    value={state?.[field.key] || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__password${passwordInvalid ? " auth2__password_invalid" : ""}`}
                    toggleMask
                    feedback={false}
                />
            );
        case "calendar":
            return (
                <Calendar
                    id={field.key}
                    value={state?.[field.key] ? moment(state?.birthday, "DD.MM.YYYY").toDate() : ""}
                    onChange={(e) => inputHandler(e.target.value ? moment(e.target.value).format("DD.MM.YYYY") : e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className="auth2__calendar"
                    showIcon
                    locale="ru"
                    dateFormat="dd.mm.yy"
                    maxDate={new Date()}
                    mask="99.99.9999"
                />
            );
        case "mask":
            return (
                <InputMask
                    id={field.key}
                    value={state?.[field.key] || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className="auth2__input"
                    mask={field.mask}
                />
            );
        case "dropdown":
            return (
                <Dropdown
                    id={field.key}
                    value={state?.[field.key] || ""}
                    optionLabel="name"
                    optionValue="_id"
                    options={field.options || data["roles"] || []}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    appendTo="self"
                    placeholder={field.placeholder}
                    className="auth2__input"
                    emptyMessage={"Ничего не найдено"}
                    emptyFilterMessage={"Ничего не найдено"}
                    filter
                    showFilterClear
                />
            );
        default:
            return null;
    }
};

const isValidPassword = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/
    return regex.test(password);
};

addLocale("ru", {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Субота'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', ' Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    today: "Сегодня",
    clear: "Очистить",
});

export default PersonData;
