const schemas = {
    '11': [
        '5-4-1',
        '5-3-2',
        '4-4-2',
        '4-3-3',
        '4-2-4',
        '3-5-2',
        '3-4-3',
        '3-2-5',
        '2-3-5'
    ],
    '9': [
        '5-2-1',
        '5-1-2',
        '4-3-1',
        '4-2-2',
        '4-1-3',
        '3-4-1',
        '3-3-2',
        '3-2-3',
        '3-1-4',
        '2-5-1',
        '2-4-2',
        '2-3-3',
        '2-2-4',
        '2-1-5'
    ],
    '8': [
        '4-1-2',
        '4-2-1',
        '3-3-1',
        '3-2-2',
        '3-1-3',
        '2-3-2',
        '2-4-1',
        '2-2-3',
        '1-3-3',
        '1-4-2'
    ],
    '7': [
        '4-1-1',
        '3-2-1',
        '3-1-2',
        '2-3-1',
        '2-2-2',
        '2-1-3'
    ],
    '6': [
        '3-1-1',
        '2-2-1',
        '2-1-2',
        '1-3-1',
        '1-2-2',
        '1-1-3'
    ],
    '5': [
        '2-0-2',
        '2-1-1',
        '1-2-1',
        '1-1-2'
    ]
}

export { schemas }
