import React, { useState, useEffect } from "react";

import {Button} from "primereact/button";

import './style.scss'

const ButtonUi = ({ onClick=()=>{}, label='', className='', type='', disabled=false, loading=false, icon=null, iconPos='left', visible=true, outlined=false }) => {
    return <Button data-pr-tooltip='Показать на сайте' onClick={onClick} label={label} className={`button ${className} ${type}`} disabled={disabled} loading={loading} icon={icon} iconPos={iconPos} visible={visible} outlined={outlined} />
}

export default ButtonUi