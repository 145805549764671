import React, { useEffect, useRef, usedata } from "react";

import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import FileUploadUi from "../FileUploadUi";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { transliterate } from "transliteration";
import { postByUrl, putByUrl } from "../../../../../v3MethodsService";
import { Button } from "primereact/button";
import { diffData } from "../../../../../utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import "./style.scss";

const Profile = ({ data, updateData, optionRegions, state, toast, setState, name, matched }) => {
    const param = window.location.pathname.split("/");
    const dataToSend = diffData(state, data);
    const history = useHistory();

    const onSelect = async (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                updateData(file, "emblem");
            }
        });
    };

    const handleSave = async () => {
        await putByUrl(`rffs/${param[2]}`, { federationData: data }).then((resp) => {
            if (resp && resp.success) {
                setState(data);
                toast?.show({ severity: "success", summary: "Данные сохранены" });
                if (matched.type === "system") {
                    history.push("/regions");
                }
            } else {
                toast?.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const disabledBtn = data && data.emblem && data.name && dataToSend;

    return (
        data && (
            <div className="clubs-profile">
                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Основная информация</span>
                    </div>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Эмблема федерации* <span className="clubs-profile__label muted">(jpg, jpeg, png, до 5 Мб)</span>
                            </label>
                            <FileUploadUi image={data.emblem} onChange={onSelect} updateData={updateData} />
                        </div>
                        <div className="clubs-profile__main">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Название*</label>
                                <InputTextarea placeholder="Укажите название" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Сокращенное название</label>
                                <InputText placeholder="Укажите сокращенное название" value={data.shortName || ""} onChange={(e) => updateData(e.target.value, "shortName")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Регион</label>
                                <Dropdown
                                    placeholder="Выберите регион"
                                    emptyFilterMessage={"Ничего не найдено"}
                                    emptyMessage={"Ничего не найдено"}
                                    value={name}
                                    options={[name]}
                                    filter
                                    disabled
                                    showFilterClear
                                    appendTo={"self"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <span className="clubs-profile__header-title">Контактная информация</span>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Юридическое лицо</label>
                            <InputText placeholder="Укажите название" value={data.organization || ""} onChange={(e) => updateData(e.target.value, "organization")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Адрес</label>
                            <InputText
                                placeholder="Пример: 108823, Московская область, г. Подольск, ул. Подольская, д. 1"
                                value={data.address || ""}
                                onChange={(e) => updateData(e.target.value, "address")}
                            />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Телефон</label>
                            <InputMask mask="+7 (999) 999 99 99" placeholder="+7 (___) ___ __ __" value={data.phone || ""} onChange={(e) => updateData(e.target.value, "phone")} />
                        </div>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">ИНН</label>
                                <InputText placeholder="Укажите ИНН" value={data.inn || ""} onChange={(e) => updateData(e.target.value, "inn")} maxLength={12} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Email</label>
                                <InputText placeholder="Укажите почту" value={data.email || ""} onChange={(e) => updateData(e.target.value, "email")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <span className="clubs-profile__header-title">Внешние ресурсы</span>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Сообщество ВКонтакте</label>
                            <InputText placeholder="Вставьте ссылку" value={data.outerLinks?.vk || ""} onChange={(e) => updateData(e.target.value, "outerLinks", "vk")} />
                        </div>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Канал в Telegram</label>
                                <InputText placeholder="Вставьте ссылку" value={data.outerLinks?.tg || ""} onChange={(e) => updateData(e.target.value, "outerLinks", "tg")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Канал Youtube</label>
                                <InputText placeholder="Вставьте ссылку" value={data.outerLinks?.yt || ""} onChange={(e) => updateData(e.target.value, "outerLinks", "yt")} />
                            </div>
                        </div>
                    </div>
                </div>
                <Button className="clubs-detail__button" label="Сохранить" onClick={handleSave} disabled={!disabledBtn} />
            </div>
        )
    );
};

export default Profile;
