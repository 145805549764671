import React, { useContext, useEffect, useRef, useState } from "react";

import { useClickOutside, diffData } from "../../../../../utils";
import "./style.scss";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { deleteByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";
import { confirmDialog } from "primereact/confirmdialog";
import { FileUpload } from "primereact/fileupload";
import { transliterate } from "transliteration";
import { ProgressSpinner } from "primereact/progressspinner";
import {Toast} from "primereact/toast";

const SideBar = ({ sideBarState, docs, setDocs, getLeague, visible, setVisible, data, setData, updateData, state, deleteHandler, handleDocs }) => {
    const ref = useRef();
    const fileUploadRef = useRef();
    const toast = useRef(null);
    useClickOutside(visible, ref, () => setVisible(false));
    const dataToSend = diffData(sideBarState, data);
    const [loader, setLoader] = useState(false);

    const archivedHandler = () => {
        confirmDialog({
            message: "Архивированные документы остаются в системе, но не отображаются для пользователей публичной части платформы",
            header: "Архивировать документ?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Архивировать",
            rejectLabel: "Не архивировать",
            draggable: false,
            style: { maxWidth: "582px" },
            accept: () => updateAttchement({ archived: true }, "Документ архивирован"),
            reject: () => {},
        });
    };

    const unzipHandler = () => {
        confirmDialog({
            header: "Разархивировать документ?",
            acceptClassName: "p-button-danger",
            acceptLabel: "Разархивировать",
            rejectLabel: "Не разархивировать",
            draggable: false,
            style: { maxWidth: "582px" },
            accept: () => updateAttchement({ archived: false }, "Документ разархивирован"),
            reject: () => {},
        });
    };

    const handleSave = () => {
        if (docs && !data._id) {
            handleDocs();
        } else if (data._id) {
            updateAttchement(dataToSend, "Документ обновлен", true);
        }
    };

    const onSelect = async (event) => {
        setLoader(true);
        const formData = new FormData();

        const originalFile = event.files[0];

        if (originalFile.size >= 5242880) {
            toast.current.show({severity: 'error', summary: '', detail: 'Максимальный размер файла 5 МБ'})
            setLoader(false);
            fileUploadRef.current.clear()
            return
        }

        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("sampleId", state._id);
        formData.append("sampleType", "federations");
        formData.append("file", renamedFile);
        formData.append("publicname", data.publicname);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.attachId;
                const newDocs = state && state.docs && state.docs.length > 0 ? state.docs.concat(file) : [].concat(file);
                setDocs(newDocs);
                updateData(resp.data.file.data.filename, "filename");
            }
            setLoader(false);
        });
    };

    const handleChangeFile = (event) => {
        setLoader(true);
        const formData = new FormData();

        const originalFile = event.files[0];

        if (originalFile.size >= 5242880) {
            toast.current.show({severity: 'error', summary: '', detail: 'Максимальный размер файла 5 МБ'})
            setLoader(false);
            fileUploadRef.current.clear()
            return
        }

        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                // fileUploadRef.current.clear();
                setData({ ...data, path: resp.data.file.data.path, filename: resp.data.file.data.filename, localname: resp.data.file.data.localname });
            }
            setLoader(false);
        });
    };

    const updateAttchement = (docData, toastName, update) => {
        putByUrl(`update_attach/${data._id}`, docData).then((resp) => {
            if (resp && resp.success) {
                if (update) {
                    setVisible(false);
                    getLeague();
                    toast.current.show({ severity: "success", summary: toastName, life: 1000 });
                } else {
                    setDocs(state.docs);
                    handleDocs(toastName);
                }
            } else {
                toast.current.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    const disableButton = data && data.publicname && data.filename && dataToSend;

    return (
        !!visible &&
        !!data &&
        state && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <Toast ref={toast} position="bottom-right"/>
                <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                    <div className="side-profile__header">
                        <div className="side-profile__btns">
                            <Button label="Сохранить" className="side-profile__button-save auth2__button" onClick={handleSave} disabled={!disableButton} />
                            {data.filename && data._id && (
                                <Button
                                    label={data.archived ? "Разархивировать" : "Архивировать"}
                                    className="side-profile__button-save side-profile__button-delete auth2__button auth2__button_outlined"
                                    disabled={data.canonical}
                                    onClick={data.archived ? unzipHandler : archivedHandler}
                                />
                            )}
                            {data.filename && data._id && (
                                <Button
                                    label="Удалить"
                                    className="side-profile__button-save side-profile__button-delete auth2__button auth2__button_outlined"
                                    disabled={data.canonical}
                                    onClick={() => deleteHandler(data._id)}
                                />
                            )}
                        </div>
                        <i className="side-profile__icon-close pi pi-times" onClick={() => setVisible(false)} />
                    </div>
                    <div className="side-profile__body">
                        <span className="side-profile__title">{data._id ? "Редактировать документ" : "Загрузить документ"}</span>
                        <div className="clubs-profile__container border">
                            <label className="clubs-profile__label">
                                Отображаемое название* <span className="clubs-profile__label muted">(формат .doc, .docx, .txt, .pdf, размер файла до 5 мб)</span>
                            </label>
                            <InputText placeholder="Укажите название" value={data?.publicname || ""} onChange={(e) => updateData(e.target.value, "publicname")} />
                        </div>

                        {!data.filename && !loader ? (
                            <FileUpload
                                ref={fileUploadRef}
                                disabled={!data.publicname}
                                mode="basic"
                                customUpload
                                accept=".doc,.docx,.txt,.pdf"
                                className="load-data-item__fileupload load-data-item__fileupload_button"
                                chooseLabel="Загрузить файл"
                                chooseOptions={{ icon: "pi pi-upload" }}
                                onSelect={onSelect}
                            />
                        ) : loader ? (
                            <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
                        ) : (
                            <div className="side-profile__file">
                                <span className="side-profile__file-name">{data.filename.split(".")[0]}</span>
                                <span className="side-profile__file-format">{data.filename.split(".").at(-1).toUpperCase()}</span>
                                <div className="side-profile__buttons">
                                    <FileUpload
                                        ref={fileUploadRef}
                                        disabled={!data.publicname}
                                        mode="basic"
                                        customUpload
                                        accept=".pdf"
                                        className="load-data-item__fileupload load-data-item__fileupload_button load-data-item__refresh"
                                        chooseOptions={{ icon: "pi pi-refresh" }}
                                        onSelect={handleChangeFile}
                                    />
                                    <Button icon="pi pi-trash" className="p-button-outlined p-button-danger" onClick={() => updateData(null, "filename")} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    );
};

export default SideBar;
