import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import Hotkeys from "react-hot-keys";
import ReCAPTCHA from "react-google-recaptcha";
import {SITE_KEY} from "../../../env";

import { postByUrl } from "../../../v3MethodsService";

import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

import { GlobalContext } from "../../../ctx";

import "./style.scss";

const Login = ({ updateGlobal }) => {
    const history = useHistory();

    const [state, setState] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [error, setError] = useState(false);
    const [useCaptcha, setUseCaptcha] = useState(false);
    const [global, setGlobal] = useContext(GlobalContext);
    const toast = global && global.toast ? global.toast : {};

    useEffect(() => {
        if (state) {
            const notValid = formData.map((f) => !state[f.key]).filter((f) => f);
            setIsValid(!notValid.length);
        } else {
            setIsValid(false);
        }
    }, [state]);

    const inputHandler = (val, key) => {
        setError(false);
        setState((prev) => ({ ...prev, [key]: val }));
    };

    const sendHandler = async () => {
        setIsValid(false);
        postByUrl("signin", state).then((resp) => {
            if (resp && resp.success) {
                localStorage.removeItem("_login_attempt_count");
                updateGlobal(resp, "auth");
                if (resp.token) {
                    // updateGlobal(resp, "auth");
                    localStorage.setItem("_amateum_auth_tkn", resp.token);

                    let currentToken = resp.data ? resp.data.find(item => item.type === "system") : false
                    let page = currentToken ? '/roles' : '/'

                    setTimeout(() => {
                        history.push(page)

                        window.location.reload();
                    }, 300);

                    
            //         if (selected.type === 'system') {
            //     console.log(111)
            //     history.push('/roles')
                
            // }
            
            // else {
            //     history.push('/')
            //     wctx.setWorkspace({...wctx.workspace, subject: selected})
            // }
                    // setTimeout(() => {
                    //     history.push('/')

                    //     window.location.reload();
                    // }, 300);

                } else {
                    updateGlobal(resp.entry, "noVerify")
                    history.push('/')
                }
            } else {
                toast.show({ severity: "error", life: 4000, summary: resp.message || "Ошибка сервера. Повторите попытку позже." });
                setError(true)
                setIsValid(true);
                const loginAttemptCount = parseInt(localStorage.getItem("_login_attempt_count"));
                localStorage.setItem("_login_attempt_count", (loginAttemptCount || 0) + 1);
                if (loginAttemptCount >= 9) {
                    setUseCaptcha(true);
                    postByUrl('logs', {
                        "action": "post",
                        "event": "USER_AUTH_MAX_FAILED",
                        "affected_type": "users",
                        "affected_id": "",
                        "severity": "WARNING",
                        "authorCollection": "users",
                        "controlled_by": "/methodsV3/signin",
                        "user": "",
                        "author": state.email
                    })
                }
            }
        });
    };


    const onKeyDown = async (e) => {
        if (e === "Enter" || e.key === "Enter") {
            await sendHandler();
            // setDisBtn(false);
        }
    };

    return (
        <div className="auth2__container login">
            <div className="auth2__title">Вход в личный кабинет</div>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className="auth2__inputs">
                    {formData.map((f) => renderField(state, f, inputHandler, error))}
                    <Button label="Войти" onClick={sendHandler} className="auth2__button" disabled={!isValid || useCaptcha}/>
                    <label className="auth2__checkbox-label">
                        <a onClick={() => history.push("/recover")} target="_self">
                            Не помню пароль
                        </a>
                    </label>
                </div>
                {useCaptcha && <ReCAPTCHA sitekey={SITE_KEY} onChange={() => setUseCaptcha(false)}/>}
                {/* <div className='login__line'>
                    <Divider/>
                    или
                    <Divider/>
                </div> */}
                {/* <Button label='Войти через Госуслуги' onClick={gosHandler} className='auth2__button auth2__button_outlined'/> */}
            </Hotkeys>
        </div>
    );
};

const formData = [
    {key: "email", placeholder: "Введите почту", type: "input", size: "full"},
    { key: "password", placeholder: "Введите пароль", type: "password", size: "full" },
];

const renderField = (state, field, inputHandler, error) => {
    switch (field.type) {
        case "input":
            return (
                <InputText
                    id={field.key}
                    key={field.key}
                    value={state?.[field.key] || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__input${error ? " auth2__input_invalid" : ""}`}
                />
            );
        case "password":
            return (
                <Password
                    id={field.key}
                    key={field.key}
                    value={state?.[field.key] || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__password${error ? " auth2__password_invalid" : ""}`}
                    toggleMask
                    feedback={false}
                />
            );
        default:
            return null;
    }
};

export default Login;
