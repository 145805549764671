import {Tag} from "primereact/tag";
import PositionIcon from "../../../assets/img/position-icon.svg";
import {InputText} from "primereact/inputtext";
import PlayerNumberIcon from "../../../assets/img/player-number-icon.svg";
import React, {useContext, useRef, useState} from "react";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import service from "../service";

import moment from 'moment'
import { putByUrl } from "../../../v3MethodsService";
import { GlobalContext } from "../../../ctx";

const TeamItem = (props) => {
    const team = props.team
    const selectedPlayer = props.selectedPlayer
    const setSelectedPlayer = props.setSelectedPlayer
    const patchItem = props.patchItem
    const toast = props.toast
    const setReload = props.setReload
    
    const op = useRef(null);

    const [global, setGlobal] = useContext(GlobalContext);
    const profileData = global && global.profile;
    
    const [dis, setDis] = useState({count: 0})

    const sendData = async (count) => {
        if(count > 0) {
            const data = {count: count, createdDate: moment().format('YY-MM-DD'), playerId: selectedPlayer._id, squadId: team.squadId, tournamentId: team.tournament._id}
            const resp = await service.sendDisq({dis: data})
            if(resp && Object.keys(resp).length) {
                patchItem({...selectedPlayer, disqualifications: [...selectedPlayer.disqualifications, resp]})
                toast.show({severity: 'success', summary: 'Успешно', detail: 'Игрок дисквалифицирован', life: 4000})
                setSelectedPlayer(null)
            }
        }
    }

    const handleUnlink = () => {
        const body = {
            action: 'unlinkedOne',
            playerId: selectedPlayer._id,
        }
        
        putByUrl(`squads/${team.squadId}`, body)
            .then(resp => {
                if (resp && resp.success) {
                    toast && toast?.show({severity: 'success', detail: 'Игрок успешно отзаявлен', life: 3000})
                    // getData()
                    setReload()
                }
                else {
                    toast && toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            })
    }

    const handleLink = () => {
        const body = {
            action: 'restore',
            players: [{_id: selectedPlayer._id}],
        }

        putByUrl(`squads/${team.squadId}`, body)
            .then(resp => {
                if (resp && resp.success) {
                    toast &&  toast.show({severity: 'success', detail: 'Игрок успешно восстановлен', life: 3000})
                    // getData()
                    setReload()
                }
                else {
                    toast &&  toast.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            })
    }
    
    return <div className={'team-item'} id={team.squadId}>
        <div className={'default-info'}>
            <div className={'team-info'}>
                <div className={'team-name'}>{team.team.name}</div>
                <Tag className="tag-league" severity="info" value={team.tournament.name}/>
            </div>
            <i className="pi pi-chevron-circle-right" onClick={() => props.openTeamCard(team.squadId)}></i>
        </div>
        <div className={'opened-info'}>
            <div className={'linked'}>
                <div className={'tag-group'}>
                    <Tag className="tag-linked" severity="info" value={`Заявлен ${moment(team.linked, 'YY-MM-DD').format('D MMM YYYY')}`}/>
                    {team.unlinked!==null ? <Tag className="tag-unlinked" severity="info" value={`Отзаявлен ${team.unlinked}`}/> : null}
                </div>
                {selectedPlayer.disqualifications.map(d => {                  
                    if (d.squadId === team.squadId) {
                        return <Tag icon={'pi pi-exclamation-triangle'} className="tag-disqualification" severity="info" value={`Дисквалификация: ${d.globalDisqTill ? 'глобальная' : `${d.missedMatches ? d.missedMatches.length : 0} из ${d.count} матчей`}`}/>
                    }
                })}
                <div className={'player-info-group'}>
                    <div className={'position'}>
                        <div className={'icon'}>
                            <img src={PositionIcon}/>
                        </div>
                        <InputText value={team.position || ''} style={{border: "none", background: 'none', width: '38px', paddingLeft: '0px'}}/>
                        <i className="pi pi-chevron-circle-down"></i>
                    </div>
                    <div className={'player-number'}>
                        <div className={'icon'}>
                            <img src={PlayerNumberIcon}/>
                        </div>
                        <InputText value={team.number || 'БН'} style={{border: "none", background: 'none', width: '39px', paddingLeft: '0px'}}/>
                    </div>
                </div>
                {/* <div className={'tag-group'}> */}
                    {/* {selectedPlayer.disqualifications.filter(d => d.squadId === team._id).length === 0 ? <Tag icon={'pi pi-exclamation-triangle'} className="tag-disqualification" severity="info" value={`Дисквалифицировать`} onClick={(e) => op.current.toggle(e)}/> : null}
                    <OverlayPanel ref={op}>
                        Колличество матчей:
                        <InputText value={dis.count} onChange={(e) => setDis({count: e.target.value})} style={{border: "none", background: 'none', width: '39px', paddingLeft: '0px'}}/>
                        <Button disabled={dis.count <= 0} icon={'pi pi-save'} label="Сохранить" className="p-button-outlined p-button-success" onClick={() => sendData(dis.count)}/>
                    </OverlayPanel>
                    {team.unlinked===null ? <Tag icon={'pi pi-exclamation-triangle'} onClick={handleUnlink} className="tag-unlinked" severity="info" value={`Отзаявить`} style={{cursor: 'pointer'}}/> : null} */}
                    {/* <Tag icon={'pi pi-sync'} className="tag-return" severity="info" value={`Вернуть в заявку`} onClick={handleLink} style={{cursor: 'pointer'}}/> */}
                {/* </div> */}
            </div>
        </div>
    </div>
}

export default TeamItem
