import { diff } from "deep-object-diff";
import {useEffect, useState} from "react";


export const useClickOutside = (val, ref, cb) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target) && !e.target.closest('.p-dialog-mask')) {
      cb();
    }
  };

  useEffect(() => {
    if (val) {
      document.addEventListener("click", handleClick, { capture: true });
    } else {
      document.removeEventListener("click", handleClick, { capture: true });
    }

    return () => {
      document.removeEventListener("click", handleClick, { capture: true });
    };
  });
};

export const diffData = (data, state) => {

  const prepareData = (obj) => {
    if (obj) {
      return Object.keys(obj)?.reduce((acc, row) => {
        if (Array.isArray(obj[row]) && obj[row].some((s) => s?._id)) {
          //если масиив, то приводим к нужному виду
          acc[row] = obj[row].map((it) => it._id);
        } else if (
          typeof obj[row] === 'string' &&
          obj[row].match(/(\d+)-(\d+)-(\d+)\w(\d+):(\d+):(\d+)/gim)
        ) {
          //если дата, то приводим к new Date
          acc[row] = new Date(obj[row]);
        } else {
          acc[row] = obj[row];
        }

        return acc;
      }, {})
    }
  }

  const newState = prepareData(state);
  const newData = prepareData(data);


  const obj = diff(newData, newState);
  const arr = obj && Object.keys(obj);
  const resp = {};



  if (arr?.length) {
    arr.forEach((item) => {
      if (obj[item] !== undefined) {
        resp[item] = newState[item];
        ;
      }
    });
  }

  return Object.keys(resp).length ? resp : false;
};

export const pluralForm = (n, arrType = []) => {
  return arrType[
    n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ];
};

export const clearToast = (toast, timeoutID, setTimeoutID) => {
  if (timeoutID) {
    clearTimeout(timeoutID);
  }
  const newTimeoutID = setTimeout(() => {
    toast.clear();
  }, 1000);
  setTimeoutID(newTimeoutID);
}

export const calcMb = (arr, onlyKb=false) => {
  const units = ['Байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
  const size = arr?.reduce((acc, val) => acc + val.size, 0)
  const index = Math.floor(Math.log(size) / Math.log(1024));
  if(onlyKb) {
      return size || 0
  } else if(index && size) {
      return [(size / Math.pow(1024, index)).toFixed(1), units[index]];
  }
}

export const useDebounce = (value, delay) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(
        () => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        },
        [value]
    );

    return debouncedValue;
}

export const delay = (sec) => new Promise(resolve => setTimeout(resolve, sec))