import axios from "axios";
import { ENDPOINT } from '../env'

let headers = {
    "Content-Type": "application/json",
    "Authorization": localStorage.getItem('_amateum_auth_tkn'),
    "scopetoken": localStorage.getItem('_amateum_auth_tkn')
}

const instance = axios.create({
    baseURL: `${ENDPOINT}methodsV3/`,
    headers: headers
})

const getByUrl = async (url) => {
    try {
        if (instance.defaults.headers["Authorization"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["Authorization"] = localStorage.getItem('_amateum_auth_tkn')
        }
        if (instance.defaults.headers["scopetoken"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["scopetoken"] = localStorage.getItem('_amateum_auth_tkn')
        }
        let req = await instance.get(url)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

const postByUrl = async (url, data, isFile) => {
    try {
        if (instance.defaults.headers["Authorization"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["Authorization"] = localStorage.getItem('_amateum_auth_tkn')
        }
        if (instance.defaults.headers["scopetoken"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["scopetoken"] = localStorage.getItem('_amateum_auth_tkn')
        }
        const extraParamsFile = isFile ? { headers: { ...headers, 'Content-Type': "multipart/form-data;charset=UTF-8", 'Accept-Encoding': "UTF-8", "Authorization": localStorage.getItem('_amateum_auth_tkn') } } : false
        let req = await instance.post(url, data, extraParamsFile || {})
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

const putByUrl = async (url, data) => {
    try {
        if (instance.defaults.headers["Authorization"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["Authorization"] = localStorage.getItem('_amateum_auth_tkn')
        }
        if (instance.defaults.headers["scopetoken"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["scopetoken"] = localStorage.getItem('_amateum_auth_tkn')
        }
        let req = await instance.put(url, data)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

const deleteByUrl = async (url) => {
    try {
        if (instance.defaults.headers["Authorization"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["Authorization"] = localStorage.getItem('_amateum_auth_tkn')
        }
        if (instance.defaults.headers["scopetoken"] !== localStorage.getItem('_amateum_auth_tkn')) {
            instance.defaults.headers["scopetoken"] = localStorage.getItem('_amateum_auth_tkn')
        }
        let req = await instance.delete(url)
        if (req.data) {
            if (req.data.msg) console.log(req.data.msg);
            return req.data
        }
    } catch (e) {
        console.log(e)
        return e.response && e.response.data ? e.response.data : false
    }
}

export { getByUrl, postByUrl, putByUrl, deleteByUrl }