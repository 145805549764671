import { Chips } from "primereact/chips";
import React, { useEffect, useState } from "react";

import service from "../../../../../Tournaments/service";

import { Button } from "primereact/button";
import {confirmDialog} from "primereact/confirmdialog";

import "./style.scss";

const struct = [
    { label: "Пн", wd: "mon" },
    { label: "Вт", wd: "tue" },
    { label: "Ср", wd: "wed" },
    { label: "Чт", wd: "thu" },
    { label: "Пт", wd: "fri" },
    { label: "Сб", wd: "sat" },
    { label: "Вс", wd: "sun" },
];

const ChipsItems = ({ toast, slot, setData, data, idx, locations }) => {
    const loc = locations.find(l => l._id === slot?.locationId)

    const customInput = (day, e) => {
        switch (e.target.value.length) {
            case 1:
                if (!new RegExp(/[0-2]$/).exec(e.target.value)) {
                    e.target.value = "";
                }
                break;
            case 2:
                if (new RegExp(/^(0[0-9]|1[0-9]|2[0-3])$/).exec(e.target.value)) {
                    e.target.value += `:`;
                } else e.target.value = e.target.value.slice(0, -1);
                break;
            case 4:
                if (!new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5]$/).exec(e.target.value)) {
                    e.target.value = e.target.value.slice(0, -1);
                }
                break;
            case 6:
                e.target.value = e.target.value.slice(0, -1);
                break;
        }
    };

    const updateRanges = (day, time) => {
        const re = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
        const result = time.value.map((v) => re.exec(v));
        if (result.findIndex((r) => !r) === -1 || time.originalEvent.type === "click") {
            const newData = [...data];
            newData[idx] = { ...newData[idx], ranges: {...newData[idx].ranges, [day]: time.value.sort((a, b) => (a > b ? 1 : -1)) }};
            service.addLocationSlotRanges(slot._id, newData[idx].ranges, toast)
                .then(resp => {
                    if (resp) {
                        setData(newData);
                    }
                })
        } else toast.current.show({ severity: "error", detail: "Введите правильное время", life: 3000 });
    };

    const acceptRemove = () => {
        service.removeLocationSlot(slot._id, toast)
            .then(resp => {
                if (resp) {
                    const newData = [...data];
                    newData.splice(idx, 1);
                    setData(newData);
                }
            })

    }

    const handleRemove = () => {
        confirmDialog ({
            message: 'Вы действительно хотите отвязать площадку от турнира?',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Да',
            rejectLabel: 'Нет',
            accept: acceptRemove
        });
    }

    return (
        <div className="chips-container">
            <div className="chips-container__header">
                <span className="chips-container__title">{loc?.name || 'Поле 1'}</span>
                <Button  icon="pi pi-trash" className="p-button-danger p-button-outlined" onClick={handleRemove}/>
            </div>
            {struct.map((item) => (
                <div className="p-inputgroup chips-items" key={item.wd}>
                    <span className="p-inputgroup-addon">{item.label}</span>
                    <span className="p-float-label">
                        <Chips
                            onInput={(e) => customInput(item.wd, e)}
                            placeholder={"--:--"}
                            keyfilter={`num`}
                            value={slot && slot.ranges?.[item.wd] ? slot.ranges[item.wd].sort((a, b) => (a > b ? 1 : -1)) : null}
                            onChange={(e) => updateRanges(item.wd, e)}
                        />
                    </span>
                </div>
            ))}
        </div>
    );
};

export default ChipsItems;
