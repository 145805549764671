import React, { useEffect, useRef, useState } from "react";

import axios from "axios";
import {ENDPOINT} from "../../../../../../env";

import DataTable from "../../../../Common/DataTable";
import {Toast} from "primereact/toast";

import "./style.scss";

const CalendarList = ({ state, handleCurrent, updateState }) => {
    const toast = useRef(null)

    const handleRemove = (e, rowData) => {
        e.stopPropagation();
        request('delete', 'stages', rowData?._id)
            .then(resp => {
                if (resp.success) {
                    toast.current.show({severity: 'success', summary: 'Успешно', detail: 'Стадия удалена', life: 3000})
                    updateState(state.stages.filter(s => s._id !== rowData._id), 'stages')
                }
            })
    };

    const typeData = (x) => {
        switch (x) {
            case "round":
                return <div className="tournamets2-calendar-list__row circle">Матчи в круг</div>;
            case "groups":
                return <div className="tournamets2-calendar-list__row group">Групповой этап</div>;
            case "playoff":
                return <div className="tournamets2-calendar-list__row playoff">Плейофф</div>;
            default:
                return null;
        }
    };

    return (
        !!state &&
        state?.stages?.length > 0 ? (
            <div className="tournamets2-calendar-list">
                <Toast ref={toast} />
                <DataTable
                    search
                    noCreateBtn
                    columns={[
                        { field: "_name", header: "Название" },
                        { field: "_type", header: "Тип" },
                        { field: "_teams", header: "Команд" },
                    ]}
                    rows={state?.stages?.map((row) => ({
                        ...row,
                        _name: { value: row?.title },
                        _type: { custom: typeData(row?.type), value: row?.type },
                        _teams: { value: row?.playoffgrids?.[0]?.teams?.length || row?.teams?.length || 0 },
                    }))}
                    paginator={state.length > 8}
                    perPage={8}
                    handleRemove={handleRemove}
                    onClick={handleCurrent}
                />
            </div>
        ) : (
            <div className="tournamets2-disqualification__notfound">Еще не было добавлено стадий</div>
        )
    );
};

const request = async (method, collection, id, body) => {
    const options = {
        headers: {
            authorization: localStorage.getItem('_amateum_auth_tkn'),
            signedby: localStorage.getItem('_amateum_auth_tkn')
        }
    }
    const resp = await axios[method](`${ENDPOINT}v2/${collection}/${id || ''}`, (['get', 'delete'].includes(method) ? options : body || {}), options)
    return resp.data
}

export default CalendarList;
