import React, { useEffect, useState } from "react";

import axios from "axios";
import {ENDPOINT} from "../../../../../env";

import ChipsItems from "./Chips";

import "./style.scss";

const Platform = ({ state, updateState, toast }) => {
    const [locations, setLocations] = useState([])
    const [slots ,setSlots] = useState([])

    const unusedLocations = locations ? locations.filter(loc => !slots.map(s => s.locationId).includes(loc._id)) : []
    
    useEffect(() => {
        // axios.get(`${ENDPOINT}v2/relations/slots?tournamentId=${state.leagueId}`, {
        //     // tournamentId=${state.leagueId}
        //     headers: {
        //         authorization: localStorage.getItem('_amateum_auth_tkn')
        //     }
        // }).then(resp => {
        //     setLocations(resp.data)
        // })

        axios.get(`${ENDPOINT}v2/list/locations`, {
            headers: {
                Authorization: localStorage.getItem('_amateum_auth_tkn')
            }
        }).then(resp => {
            setLocations(resp.data)
        })

        axios.get(`${ENDPOINT}v2/relations/slots?tournamentId=${state._id}`, {
            headers: {
                authorization: localStorage.getItem('_amateum_auth_tkn')
            }
        }).then(resp => {
            setSlots(resp.data)
        })
    }, []);

    useEffect(() => {
        if (state && state.btnOptions && state.btnOptions.click) {
            updateState({
                click: false,
                listbox: {
                    options: unusedLocations.map(ul => ({label: ul.name, value: ul})),
                    onClick: updateSlots
                }
            }, 'btnOptions')
        }
    }, [state]);

    const updateSlots = () => {
        setTimeout(() => {
            axios.get(`${ENDPOINT}v2/relations/slots?tournamentId=${state._id}`, {
                headers: {
                    authorization: localStorage.getItem('_amateum_auth_tkn')
                }
            })
                .then(resp => {
                    setSlots(resp.data)
                })
        }, 250);
    }
    
    return slots?.length > 0 ? (
        <div className="tournamets2-platform">
            {slots.map((item, idx) => <ChipsItems key={idx} slot={item} data={slots} setData={setSlots} toast={toast} idx={idx} locations={locations} />)}
        </div>
    ) : (
        <div className="tournamets2-disqualification__notfound">Еще не было добавлено площадок</div>
    );
};

export default Platform;
