import { Switch, Route } from "react-router-dom"

import Root from './Root'

const Routing = () => {
    return (
        <Switch>
            <Route path={"/:firstParam/:secondParam/:thirdParam/:fourthParam"} component={Root} />
            <Route path={"/:firstParam/:secondParam/:thirdParam"} component={Root} />
            <Route path={"/:firstParam/:secondParam"} component={Root} />
            <Route path={"/:firstParam"} component={Root} />
            <Route path={"/"} component={Root} />
        </Switch>
    )
}

export default Routing
