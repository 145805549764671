import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { deleteByUrl, getByUrl, postByUrl, putByUrl } from "../../../../v3MethodsService";
import { GlobalContext, WorkspaceContext } from "../../../../ctx";

import { RadioButton } from "primereact/radiobutton";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import FilesUploadUi from "./MediaBank/FilesUpload";
import { ProgressBar } from "primereact/progressbar";

import SideBar from "./SideBar";
import ClubsProfile from "./Profile";
import Teams from "./Teams";
import Members from "./Members";
import Application from "./Application";
import MediaBank from "./MediaBank";
import DataTable from "../../Common/DataTable";

import defaultLogo from "../img/defaultLogo.png";

import "./style.scss";
import { calcMb } from "../../../../utils";

const options = [
    { name: "Профиль", key: "profile" },
    { name: "Команды", key: "teams" },
    { name: "Представители", key: "members" },
    { name: "Заявки", key: "application" },
    // { name: "Медиабанк", key: "mediabank" },
];

const ClubsDetail = ({ toast, loader, setLoader }) => {
    const wctx = useContext(WorkspaceContext);
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token;
    const role = global?.profile?.scopes?.find(f => f.token == token) || null
    const currentSubj = wctx && wctx.workspace && wctx.workspace.subject && wctx.workspace.subject.type ? wctx.workspace.subject.type : false;
    const [clubs, setClubs] = useState(false);
    const param = window.location.pathname.split("/");
    const [selectedOption, setSelectedOption] = useState(options[0]);
    const [radio, setRadio] = useState("");
    const [data, setData] = useState(null);
    const [state, setState] = useState(null);
    const [checkbox, setCheckbox] = useState([]);
    const [dialogVisible, setDialogVisble] = useState(false);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [dataSidebar, setDataSidebar] = useState([]);
    const [stateSidebr, setStateSidebar] = useState([]);
    const [optionRegions, setOptionRegions] = useState([]);
    const [optionSettelments, setOptionSettelments] = useState([]);
    const [optionsDisciplines, setOptionsDisciplines] = useState([]);
    const [medias, setMedias] = useState([])
    const toastRef = useRef(null)

    const mode = window.location.search.replace('?mode=', '')?.split('&')?.[0]

    const history = useHistory();

    useEffect(() => {
        if (mode) {
            const option = options.find(o => o.key === mode) || options[0];
            setSelectedOption(option)
        }
    }, [mode]);

    useEffect(() => {
        getByUrl(`getRefsList?section=regions&mode=checkAllRegions`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setOptionRegions(resp.data);
            }
        });

        getByUrl(`getRefsList?section=disciplines`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setOptionsDisciplines(resp.data);
            }
        });

        // getByUrl(`getRefsList?section=settlements`).then((resp) => {
        //     if (resp && resp.success && resp.data) {
        //         setOptionSettelments(resp.data)
        //     }
        // });
    }, [token]);

    useEffect(() => {
        if(!(param[1] == 'clubs' && role?.type == 'league') && !options.find(f => f.key == 'mediabank')) {
            options.push({ name: "Медиабанк", key: "mediabank" },)
        }
        if (param[2] === "add") {
            setData(initialData);
            setState(initialData);
            return;
        }
        getData();
    }, [param[2]]);

    useEffect(() => {
        if (data?.teams?.length === 0) {
            deleteByUrl(`clubs/${param[2]}`).then((resp) => {
                if (resp && resp.success) {
                    history.replace("/clubs");
                } else {
                    toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            });
        }
    }, [data]);

    const getData = (toastName) => {
        setLoader(true);
        getByUrl(`clubs/${param[2]}`)
            .then((resp) => {
                if (resp && resp.success && resp.data) {
                    setData(resp.data);
                    setState(resp.data);
                    setLoader(false);

                    if (resp.data._id) {
                        getByUrl(`medias?sampleId=${resp.data._id}&sampleType=clubs&isPublished=true`).then((resp) => {
                            if (resp && resp.success && resp.data) {
                                setMedias(resp.data);
                            }
                        });
                    }

                    if (resp.data.regionId) {
                        getByUrl(`getRefsList?section=settlements&subjectId=${resp.data.regionId}`).then((resp) => {
                            if (resp && resp.success && resp.data) {
                                setOptionSettelments(resp.data);
                            }
                        });
                    }
                }
            })
            .then(() => {
                if (toastName) {
                    toast.show(toastName);
                }
            });
    };

    const updateData = (val, key, subKey, dropdown) => {
        if (subKey) {
            setData({ ...data, [key]: { ...data[key], [subKey]: val } });
        } else {
            setData({ ...data, [key]: val });
        }

        if (dropdown) {
            getByUrl(`getRefsList?section=settlements&subjectId=${val}`).then((resp) => {
                if (resp && resp.success && resp.data) {
                    setOptionSettelments(resp.data);
                }
            });
        }
    };

    const updateSidebar = (val, key) => {
        setDataSidebar({ ...dataSidebar, [key]: val });
    };

    const Specified = selectedOption ?  wrap[selectedOption.key] : wrap["profile"];

    const addMemeber = () => {
        history.replace(`/invite?clubId=${data._id}&regionId=${data.regionId}&leagueId=${data.federationId}&roleId=66719fb86b9734f0f893e610`);
    };

    const onChecboxChange = (e) => {
        let _checkbox = [...checkbox];

        if (e.checked) _checkbox.push(e.value);
        else _checkbox.splice(_checkbox.indexOf(e.value), 1);

        setCheckbox(_checkbox);
    };

    const changeClub = async () => {
        if (!clubs) {
            await getByUrl(`clubs`).then((resp) => {
                //тут возможно будет меняться позже, а то получать 9 тыс долго
                if (resp && resp.success && resp.data) {
                    setClubs(resp.data);
                    setLoader(false);
                }
            });
        }
        setDialogVisble(true);
    };

    const createCustomItem = (logo, name, id) => (
        <div className="clubs-list__table-item">
            <RadioButton value={id} onChange={(e) => setRadio(e.value)} checked={radio === id} />
            {logo ? <img src={logo} /> : <img src={defaultLogo} />}
            <span>{name}</span>
        </div>
    );

    const handleChangeClub = async (e) => {
        let invalid = false;
        for (let i = 0; i < checkbox.length; i++) {
            const id = checkbox[i];

            const resp = await putByUrl(`teams/${id}`, { clubId: radio });
            if (!(resp && resp.success)) {
                invalid = true;
                toast?.show({ severity: "error", life: 4000, summary: resp.message || "Ошибка при перемещении команд" });
                break;
            }
        }
        if (!invalid) {
            toast.show({ severity: "success", summary: "Команды перемещены" });
            setLoader(true);
            getByUrl(`clubs/${param[2]}`).then((resp) => {
                if (resp && resp.success && resp.data) {
                    setData(resp.data);
                    setState(resp.data);
                    setLoader(false);
                }
            });
        } else {
            toast?.show({ severity: "warn", life: 4000, summary: "Не все команды были перемещены" });
        }
        setDialogVisble(false);
    };

    const handleItem = (e) => {
        setSidebarVisible(true);

        getByUrl(`teams/${e.data._id}`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setDataSidebar(resp.data);
                setStateSidebar(resp.data);
            }
        });
    };

    const handleNewTeam = () => {
        setSidebarVisible(true);
        setDataSidebar({ ...initialSideData, clubId: data._id, name: data.name, emblem: data.emblem });
        setStateSidebar({ ...initialSideData, clubId: data._id, name: data.name, emblem: data.emblem });
    };

    return (
        <div className="clubs-detail">
            {clubs && (
                <Dialog
                    appendTo={"self"}
                    visible={dialogVisible}
                    onHide={() => {
                        if (!dialogVisible) return;
                        setDialogVisble(false);
                    }}
                    header={
                        <div className="clubs-detail__dialog-header">
                            <span>Выберите клуб для перемещения команд</span>
                            <Button label={`Переместить ${checkbox.length} команд${pluralForm(checkbox.length)}`} disabled={!radio.length} onClick={handleChangeClub} />
                        </div>
                    }
                >
                    <DataTable
                        search
                        noCreateBtn
                        columns={[
                            { field: "name", header: "Название" },
                            { field: "locality", header: "Населенный пункт" },
                            { field: "teams", header: "Кол-во команд" },
                            { field: "memebrs", header: "Кол-во представитей" },
                        ]}
                        rows={clubs.map((row) => ({
                            ...row,
                            name: { custom: createCustomItem(row?.emblem, row?.name, row?._id), value: row?.name },
                            locality: { value: row?.settlement?.name },
                            teams: { value: row?.teams.length },
                            memebrs: { value: row?.sponsors?.length },
                        }))}
                        paginator={clubs.length > 6}
                        perPage={6}
                    />
                </Dialog>
            )}
            <SideBar
                options={optionsDisciplines}
                visible={sidebarVisible}
                setVisible={setSidebarVisible}
                data={dataSidebar}
                updateData={updateSidebar}
                stateSidebr={stateSidebr}
                toast={toast}
                setStateSidebar={setStateSidebar}
                teamsCount={data?.teams?.length}
                canonical={data?.teams?.find((t) => t.canonical)?._id}
                getData={getData}
            />
            {param?.[2] !== "add" && (
                <div className="clubs-detail__header">
                    <SelectButton
                        options={options}
                        value={selectedOption}
                        onChange={(e) => {
                            if (e.value) setSelectedOption(e.value);
                        }}
                        optionLabel="name"
                    />
                    {selectedOption?.key === "members" && <Button icon="pi pi-plus" label="Добавить представителя" onClick={addMemeber} />}
                    {selectedOption?.key === "teams" && (
                        <div className="clubs-detail__buttons">
                            {currentSubj !== 'club' && <Button icon="pi pi-reply" label="В другой клуб" className="p-button-outlined" disabled={!checkbox.length} onClick={changeClub} />}
                            <Button icon="pi pi-plus" label="Добавить команду" onClick={handleNewTeam} />
                        </div>
                    )}
                    <Toast ref={toastRef} position="top-right"/>
                    {selectedOption?.key === "mediabank" && (
                        <div className="leagues-detail__buttons mediabank">
                            <FilesUploadUi
                                mode={'basic'}
                                accept=".jpeg, .jpg, .png, .svg"
                                customUpload
                                multiple
                                // minFileSize={10}
                                minFileSize={102400}
                                maxFileSize={10485760}
                                className='mediabank__upload'
                                toast={toastRef}
                                remainingSpace={medias?.quota?.remainingSpace}
                                onChange={setMedias}
                                data={data}
                            />
                            <div className="mediabank__size">
                                <div className="mediabank__size-text">Использовано</div>
                                <div className="mediabank__size-text">{calcMb(medias?.list)?.length ? calcMb(medias?.list)[0] + ' ' + calcMb(medias?.list)[1] : 0} / из 1 ГБ</div>
                                {/* <div className="mediabank__size-text">{calcMb(medias?.list)?.length ? calcMb(medias?.list)[0] + ' ' + calcMb(medias?.list)[1] : 0} / из {calcMb([{size: medias?.quota?.quota}])}</div> */}
                                <ProgressBar className="mediabank__size-progress" value={(calcMb(medias?.list, true) / 1e+9) * 100}></ProgressBar>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {loader ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <Specified
                    currentSubj={currentSubj}
                    data={data}
                    state={state}
                    updateData={updateData}
                    setData={setData}
                    setState={setState}
                    checkbox={checkbox}
                    onChecboxChange={onChecboxChange}
                    handleItem={handleItem}
                    optionRegions={optionRegions}
                    optionSettelments={optionSettelments}
                    toast={toastRef}
                    medias={medias}
                    setMedias={setMedias}
                />
            )}
        </div>
    );
};

const wrap = {
    profile: ClubsProfile,
    teams: Teams,
    members: Members,
    application: Application,
    mediabank: MediaBank,
};

export default ClubsDetail;

const initialData = {
    inSearch: false,
    emblem: "",
    name: "",
    shortName: "",
    regionId: "",
    settlementId: "",
    phone: "",
    email: "",
    colors: { mainColor: "", extraColor: "" },
    created: "",
    homeLocation: "",
    socials: { vk: "", tg: "", yt: "" },
    scopes: [],
    verifications: [],
};

const initialSideData = {
    // name: "",
    emblem: "",
    canonical: false,
    age: null,
    // disciplineId: "",
    team_img: "",
};

const pluralForm = (n) => {
    let arr = ["у", "ы", ""];
    return arr[n % 10 == 1 && n % 100 != 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2];
};
