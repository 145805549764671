import React, { useEffect, useRef, useState } from "react";

import { getByUrl } from "../../../../v3MethodsService";
import moment from "moment";

import { locale, addLocale, updateLocaleOption, updateLocaleOptions, localeOption, localeOptions } from 'primereact/api';
        
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";

import "./style.scss";

const SideProfileEditor = ({ setDisabledBtn, state, changeState, editorData, showInvalid, isRole, stateRole, setStateRole }) => {
    const [data, setData] = useState(initData);

    const currentRole = data.roles.find((i) => i._id === stateRole.roleId);
    const level = currentRole && currentRole.level ? currentRole.level : false;

    useEffect(() => {
        if (isRole) {
            getByUrl("roles").then((resp) => {
                if (resp && resp.data) {
                    setData((prev) => ({ ...prev, roles: resp.data.filter((d) => d.level !== "system" && !state.rolescopes.find((r) => r.role._id === d._id)) }));
                }
            });
        }
    }, [isRole]);

    useEffect(() => {
        setDisabledBtn(level && ((level == 'club' && stateRole.clubId) || ((level == 'league' || level == 'leagueStaff') && stateRole.leagueId) || (level == 'region' && stateRole.regionId) || level == 'system'))
    }, [stateRole])

    const updateState = (val, stateKey, key) => {
        setStateRole((prev) => ({ ...prev, [stateKey]: val }));

        if (key) {
            const regId = stateKey == "regionId" ? val : "";
            const legId = stateKey == "leagueId" ? val : "";
            const url = {
                roles: `getRegistrationRefs?section=regions`,
                regions: `getRegistrationRefs?section=leagues&subjectId=${regId}`,
                leagues: `getRegistrationRefs?section=clubs&subjectId=${legId}`,
            };

            if (url[key]) {
                if (key == "roles") {
                    setStateRole((prev) => ({ ...prev, regionId: null, leagueId: null, clubId: null }));
                    setData((prev) => ({ ...prev, regions: [], leagues: [], clubs: [] }));
                } else if (key == "regions") {
                    setStateRole((prev) => ({ ...prev, leagueId: null, clubId: null }));
                    setData((prev) => ({ ...prev, leagues: [], clubs: [] }));
                } else if (key == "leagues") {
                    setStateRole((prev) => ({ ...prev, clubId: null }));
                    setData((prev) => ({ ...prev, clubs: [] }));
                }

                getByUrl(url[key]).then((resp) => {
                    if (resp && resp.success && resp.data) {
                        const sKeys = {
                            roles: "regions",
                            regions: "leagues",
                            leagues: "clubs",
                        };
                        setData((prev) => ({ ...prev, [sKeys[key]]: resp.data }));
                    }
                });
            }
        }
    };

    return (
        <div className="side-profile-editor">
            <div className="side-profile-editor__title">{editorData.title}</div>
            <div className="side-profile-editor__fields">
                {isRole
                    ? editorData.formData.map((f) => (
                          <div key={f.key} className="auth2__input-container auth2__input-container_full">
                              <label htmlFor={f.key} className="auth2__input-container-label">
                                  {f.label}
                              </label>
                              <Dropdown
                                  emptyFilterMessage={"Ничего не найдено"}
                                  emptyMessage={"Ничего не найдено"}
                                  value={stateRole[f.stateKey] || ""}
                                  onChange={(e) => updateState(e.value, f.stateKey, f.key)}
                                  placeholder={f.placeholder}
                                  options={data[f.key] || []}
                                  disabled={!state.email || (level && f.disabled.includes(level)) || !data[f.key].length}
                                  optionLabel="name"
                                  optionValue="_id"
                                  filter
                                  showFilterClear
                                  appendTo={"self"}
                              />
                          </div>
                      ))
                    : /*(
                        <div className='auth2__input-container auth2__input-container_full'>
                            <label htmlFor='role' className='auth2__input-container-label'>Роль</label>
                            <Dropdown id='role' value={selectedRole} options={data.roles} optionLabel="name" onChange={(e) => updateState(e.target.value, 'roleId', 'roles')} placeholder='Выберите роль' className='auth2__input'/>
                        </div>
                    )*/

                      editorData.formData.map((f) => (
                          <div key={f.key} className={`auth2__input-container auth2__input-container_${f.size}`}>
                              <label htmlFor={f.key} className="auth2__input-container-label">
                                  {f.label}
                              </label>
                              {renderField(state, f, changeState, showInvalid)}
                          </div>
                      ))}
            </div>
        </div>
    );
};

const renderField = (state, field, inputHandler, showInvalid) => {
    switch (field.type) {
        case "input":
            return (
                <InputText
                    id={field.key}
                    value={state?.[field.key]?.trim() || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__input ${showInvalid && !state?.[field.key] ? " auth2__input_invalid" : ""}`}
                />
            );
        case "password":
            return (
                <Password
                    id={field.key}
                    value={state?.[field.key] || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__password${showInvalid ? " auth2__password_invalid" : ""}`}
                    toggleMask
                    feedback={false}
                />
            );
        case "calendar":
            return (
                <Calendar
                    id={field.key}
                    value={state?.[field.key] ? moment(state?.birthday, "DD.MM.YYYY").toDate() : ""}
                    onChange={(e) => inputHandler(e.target.value ? moment(e.target.value).format("DD.MM.YYYY") : e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__calendar${showInvalid && !state?.[field.key] ? " auth2__calendar_invalid" : ""}`}
                    showIcon
                    locale="ru"
                    dateFormat="dd.mm.yy"
                    maxDate={new Date()}
                    mask="99.99.9999"
                    appendTo={"self"}
                />
            );
        case "mask":
            return (
                <InputMask
                    id={field.key}
                    value={state?.[field.key] || ""}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className={`auth2__input ${showInvalid && !state?.[field.key] && field.key !== 'phone' ? " auth2__input_invalid" : ""}`}
                    mask={field.mask}
                />
            );
        case "dropdown":
            return (
                <Dropdown
                    id={field.key}
                    value={state?.[field.key] || ""}
                    options={field.options}
                    onChange={(e) => inputHandler(e.target.value, field.key)}
                    placeholder={field.placeholder}
                    className="auth2__input"
                    appendTo={"self"}
                />
            );
        default:
            return null;
    }
};

const initData = {
    roles: [],
    regions: [],
    leagues: [],
    clubs: [],
};

addLocale("ru", {
    firstDayOfWeek: 1,
    dayNames: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Субота'],
    dayNamesMin: ['Вс1', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', ' Ноябрь', 'Декабрь'],
    monthNamesShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
    today: "Сегодня",
    clear: "Очистить",
});


export default SideProfileEditor;
