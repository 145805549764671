import ImageWithFallback from "./ImageWithFallback/index.jsx";

import './style.scss'

const BodyItem = ({ item }) => {

    const renderBodyItem = () => {
        if (item.custom) {
            return item.custom
        }

        switch (item.type) {
            case 'photo': return <ImageWithFallback src={item.value} className='body-item__photo' fallback={<div className='body-item__photo'><i className='pi pi-image'/></div>}/>
            case 'date': return <div className='body-item__date'><span>{item.value}</span>{item.text}</div>
            default: return <div className='body-item__default'>{item.value}</div>
        }
    }

    return item && <div className='body-item'>{renderBodyItem()}</div>
}

export default BodyItem