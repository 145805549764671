import React, {useEffect, useState} from "react";
import moment from "moment/moment";

import { pluralForm } from "../../../../../utils";
import service from "../../../../Tournaments/Disqualifications/service";
import axios from "axios";
import {ENDPOINT} from "../../../../../env";

import DataTable from "../../../Common/DataTable";
import SideBar from "./SideBar";

import "./style.scss";

const Disqualification = ({ state, updateState, toast }) => {
    const [data, setData] = useState([]);
    const [status, setStatus] = useState("current");
    const [sideBar, setSideBar] = useState(false);
    const [selected, setSelected] = useState(null);

    useEffect(() => {
        if (!sideBar) service.disquals(state._id).then(resp => setData(resp))
    }, [sideBar]);

    useEffect(() => {
        if (state && state.btnOptions && state.btnOptions.click) {
            updateState(false, 'btnOptions')
            setSideBar(true)
            setSelected({
                tournamentId: state._id,
                playerId: null,
                squadId: null,
                count: 2,
                finishDate: null,
                fictive: false,
                player: null,
                missedMatches: [],
                startDate: moment().format('YYYY-MM-DD'),
                isManual: true,
                isRedCard: false,
                globalDisqTill: false
            })
        }
    }, [state]);

    const handleRemove = (e, disqual) => {
        e?.stopPropagation();
        axios.delete(`${ENDPOINT}v2/disqualifications/${disqual._id}`, options).then(resp => {
            if(resp.data && resp.data.success) {
                setData(prev => ({...prev, disquals: prev.disquals.filter(d => d._id !== disqual._id)}))
                if (toast) {
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Дисквалификация удалена', life: 1000})
                }
            } else {
                if (toast) {
                    toast.show({severity: 'error', summary: 'Ошибка сервера', detail: 'Не удалось удалить дисквалификацию', life: 1000})
                }
            }
        })
    };

    const handleClick = (item) => {
        setSelected(item.data)
        setSideBar(true)
    }

    return data ? (
        <div className="tournamets2-disqualification">
            <SideBar visible={sideBar} setVisible={setSideBar} state={selected} deleteHandler={handleRemove}/>
            <div className="tournamets2-disqualification__header">
                <div className={`tournamets2-disqualification__header-btn ${status === "current" ? "active" : ""}`} onClick={() => setStatus("current")}>
                    Действующие
                </div>
                <div className={`tournamets2-disqualification__header-btn ${status === "completed" ? "active" : ""}`} onClick={() => setStatus("completed")}>
                    Завершенные
                </div>
            </div>

            <DataTable
                paginator={data.disquals?.length > 8}
                onClick={handleClick}
                perPage={8}
                noCreateBtn
                columns={[
                    { field: "name", header: "ФИО и команда" },
                    { field: "term", header: "Срок дискфалификации" },
                    status === "current" ? { field: "remaining", header: "Осталось" } : null,
                    { field: "date", header: "Дата дисквалификации" },
                ]}
                rows={data.disquals
                    ?.filter((item) => status === "completed" ? item.finished : !item.finished)
                    .map((row) => ({
                        ...row,
                        name: { custom: userItem(row), value: row?.player?.name },
                        term: { value: !row?.finishDate ? 'не указано' : "до " + moment(row?.finishDate, 'YYYY-MM-DD').format('DD.MM.YYYY')},
                        remaining: { value: row.count || row.count === 0 ? ((row.missedMatches ? row.count - row.missedMatches.length : row.count) + ' матч' + pluralForm(row.missedMatches ? row.count - row.missedMatches.length : row.count, ["", "а", "ей"])) : moment(row?.finishDate, 'YYYY-MM-DD').fromNow()?.replace('через', '') },
                        date: { value: row?.startDate ? moment(row?.startDate, 'YYYY-MM-DD').format('DD.MM.YYYY') : null },
                    }))}
                search
                searchPlaceholder="ФИО или команда..."
                emptyMessage={status === "current" ? "Действующих дисквалификаций нет" : "Завершенных дисквалификаций нет"}
                handleRemove={handleRemove}
            />
        </div>
    ) : (
        <div className="tournamets2-disqualification__notfound">Еще не было добавлено дисквалификаций</div>
    );
};

const userItem = (item) => {
    return (
        <div className="tournamets2-disqualification__user">
            <div className="tournamets2-disqualification__user-circle">{(item?.player?.surname ? item?.player?.surname[0] : '') + (item?.player?.name ? item?.player?.name[0] : '')}</div>
            <div className="tournamets2-disqualification__user-container">
                <span className="tournamets2-disqualification__user-fio">{item?.player?.fio}</span>
                <span className="tournamets2-disqualification__user-club">{`${item?.team?.name} • ${item?.tournament?.name}`}</span>
            </div>
        </div>
    );
};

const options = {
    headers: {
        authorization: localStorage.getItem('_amateum_auth_tkn'),
        SignedBy: localStorage.getItem('_amateum_auth_tkn')
    }
}

export default Disqualification;


