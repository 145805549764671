import React from "react";

import {Button} from "primereact/button";
import {AutoComplete} from "primereact/autocomplete";

const ManualCircle = ({ unassigned, assigned, filteredTeams, searchTeam, sendTeams, addAllTeams, isFinished, handleFinish }) => {
    return (
        <div className="circle-structure__card teams">
            <div className="circle-structure__container teams">
                <span className="circle-structure__title">Команды</span>
                <Button icon="pi pi-users" label="Добавить все команды" className="p-button-outlined" onClick={addAllTeams} disabled={!(unassigned?.length > 0) || isFinished}/>
            </div>
            {unassigned?.length > 0 ? (
                <div className="circle-structure__hint">
                    <i className="pi pi-info-circle"/>
                    <span>Не распределены команды: {unassigned.map((t) => t.name).join(", ")}</span>
                </div>
            ) : null}
            <div className="circle-structure__chips">
                <AutoComplete
                    value={assigned}
                    suggestions={filteredTeams}
                    field="name"
                    completeMethod={searchTeam}
                    onChange={(e) => sendTeams(e.value?.map((t) => ({_id: t._id})))}
                    multiple
                    placeholder="Начните вводить название команды"
                    appendTo={'self'}
                    disabled={isFinished}
                />
            </div>
            {!isFinished && <Button onClick={handleFinish} label="Завершить стадию" className="circle-structure__btn p-button-warning"/>}
        </div>
    )
}

export default ManualCircle