import React from "react";

import PlayoffItem from "./PlayoffItem";

const ManualPlayoff = ({ data, renderTitleOrInput, state, removeGrid, addAllTeams, assignTeams, updateTeams, isFinished }) => {
    return (
        data.playoffgrids?.map((item, idx) => (
            <PlayoffItem
                key={idx}
                item={item}
                idx={idx}
                renderTitleOrInput={renderTitleOrInput}
                state={state}
                removeGrid={removeGrid}
                addAllTeams={addAllTeams}
                assignTeams={assignTeams}
                updateTeams={updateTeams}
                isFinished={isFinished}
            />
        ))
    )
}

export default ManualPlayoff;