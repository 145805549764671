import React, { useContext, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";
import { getByUrl } from "../../../../v3MethodsService";
import { GlobalContext } from "../../../../ctx";

import DataTable from "../../Common/DataTable";
import defaultLogo from "../img/defaultLogo.png";

import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import "./style.scss";

const LeaguesList = ({ setDropdown, dropdown = null }) => {
    const [global, setGlobal] = useContext(GlobalContext)
    const token = global && global.auth && global.auth.token
    const history = useHistory();
    const [clubs, setClubs] = useState([]);
    const [options, setOptions] = useState([])

    const filteredCities = options?.filter((city) => clubs?.some((item) => item.settlementId === city._id));

    useEffect(() => {
        getByUrl('getRefsList?section=settlements').then(resp => {
            if (resp && resp.success && resp.data) {
                setOptions(resp.data)
            }
        })

        getByUrl(`leagues`).then(resp => { //тут возможно будет меняться позже, а то получать 9 тыс долго
            if (resp && resp.success && resp.data) {
                setClubs(resp.data)
            }
        })
    }, [token])

    const createCustomItem = (logo, name) => (
        <div className="clubs-list__table-item">
            {logo ? <img src={logo} /> : <img src={defaultLogo} />}
            <span>{name}</span>
        </div>
    );

    const handleClick = (e) => {
        history.replace(`/leagueData/${e.data._id}`);
    };

    const handleAddClub = () => {
        history.replace("/leagueData/add");
    };

    const data = dropdown ? clubs.filter((item) => item.settlementId === dropdown) : clubs;

    return (
        <div className="leagues-list">
            <div className="leagues-list__header">
                {options ? (
                    <Dropdown
                        value={dropdown}
                        options={filteredCities}
                        onChange={(e) => setDropdown(e.value)}
                        emptyMessage={"Ничего не найдено"}
                        emptyFilterMessage={"Ничего не найдено"}
                        optionLabel="name"
                        optionValue="_id"
                        showClear
                        placeholder="Выберите город"
                        appendTo={"self"}
                    />
                ) : null}
                <Button label="Добавить лигу" icon="pi pi-plus" onClick={handleAddClub} />
            </div>
            <DataTable
                onClick={handleClick}
                search
                noCreateBtn
                columns={[
                    { field: "name", header: "Название" },
                    { field: "locality", header: "Населенный пункт" },
                ]}
                rows={data.map((row) => ({
                    ...row,
                    name: { custom: createCustomItem(row?.emblem, row?.name), value: row?.name },
                    locality: { value: row?.settlement?.name },
                }))}
                paginator={clubs.length > 8}
                perPage={8}
            />
        </div>
    );
};

export default LeaguesList;
