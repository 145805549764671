import React, { useState, useEffect } from 'react'

// import { inject, observer } from "mobx-react";
// import { computed } from "mobx";

import TagsControl from '../TagsControl.js';
import InputUi from "../../../../UI/InputUi/index.jsx";
import CalendarUi from "../../../../UI/CalendarUi/index.jsx";
import FileUploadUi from "../../../../UI/FileUploadUi/index.jsx";
import CustomUploads from '../CustomUploads.js';

import './style.scss'
import moment from 'moment'
import {ImageHandler} from "../../../Atoms";
import CustomUploader from "../CustomUploader";

const FormMain = ({ mainStore, data, setData, onChange, isExternal }) => {

    const coverImage = data && data.attachments && data.attachments[0] && getCover(data.attachments[0])

    const inputHandler = (val, key) => {
        onChange(key, val)
    }

    const imageHandler = (val) => {
        let newArray = data && data.attachments
        if (val) {
            newArray.unshift({
                selected: true,
                type: 'photo',
                photo: {
                    sizes: [val]
                }
            })
        } else {
            newArray.shift()
        }
        onChange('attachments', newArray)
    }

    const updateTagsPost = (k, v) => {
        const updatedForm = {...data, tagsdata: {...data.tagsdata, [k]: v}}
        const { tagsdata } = updatedForm;

        if (k === 'matches') {
            for (let m of v) {
                const {stage, home, away} = m;
                if (!tagsdata['teams']) {
                    tagsdata['teams'] = [
                        {_id: home._id, name: home.name},
                        {_id: away._id, name: away.name}
                    ]
                } else {
                    for (let side of ['home','away']) {
                        const hasData = tagsdata['teams'].find(t => t._id.toString() === m[side]._id.toString())
                        if (!hasData) {
                            tagsdata['teams'].push({_id: m[side]._id, name: m[side].name})
                        }
                    }
                }
                const tour = stage ? stage.tournament : null
                if (tour) {
                    if (!tagsdata['tournaments']) {
                        tagsdata['tournaments'] = [
                            {_id: tour._id, name: tour.name}
                        ]
                    } else {
                        const hasData = tagsdata['tournaments'].find(t => t._id.toString() === tour._id.toString())
                        if (!hasData) {
                            tagsdata['tournaments'].push({_id: tour._id, name: tour.name})
                        }
                    }
                }
            }
            updatedForm.tagsdata = tagsdata
        }
        setData(updatedForm)
    }
    
    const customUploader = async (event) => {
        let newArray = data.attachments
        if (event.files.length && event.files.length > 1) {
            for (let i=0; i < event.files.length && i < 5; i++){
                newArray.push({
                    type: 'file',
                    file: event.files[i],
                    selected: true
                })
            }
        } else {
            newArray.push({
                type: 'file',
                file: event.files[0],
                selected: true
            })
        }
        setData({
            ...data,
            attachments: newArray
        })
    }
    
    return data && (
        <div className='mediaForm-detail__main'>
            <div className='mediaForm-detail__main-col flex'>
                <CustomUploader
                    image={coverImage ? {file: coverImage} : null}
                    onChange={imageHandler}
                    label='Главное изображение*'
                    mutedLabel='(ширина от 980px до 1920px)'
                />
                {/*<FileUploadUi image={coverImage ? {file: coverImage} : null} label='Главное изображение*' mutedLabel='(ширина от 980px до 1920px)' disabled={true}/>*/}
                {/*<div className='custom-upload'>*/}
                {/*    <div className='upload-section'>*/}
                {/*        <div className='upload-action'>*/}
                {/*            {!coverImage && (*/}
                {/*                <ImageHandler*/}
                {/*                    label='Загрузить'*/}
                {/*                    ratio={16/9}*/}
                {/*                    onUploaded={obj => setData({*/}
                {/*                        ...data,*/}
                {/*                        attachments: [{*/}
                {/*                            selected: true,*/}
                {/*                            type: 'photo',*/}
                {/*                            photo: {*/}
                {/*                                sizes: [obj]*/}
                {/*                            }*/}
                {/*                        }].concat(data.attachments)*/}
                {/*                    })}*/}
                {/*                />*/}
                {/*            )}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*<FileUploadUi image={!!data.image && {file: data.image}} onChange={imageHandler} label='Главное изображение*' mutedLabel='(ширина от 980px до 1920px)' minSize={1} preview={`/main/news/${data._id ? data._id : 'newPublication'}`} accept=".png, .jpg, .jpeg"/>*/}
                {!isExternal && <div className='mediaForm-detail__main-divider'><span/>или<span/></div>}
                {!isExternal && (
                    <CustomUploads
                        attachments={data.attachments}
                        onUploaded={imageHandler}
                        chooseOptions={chooseOptions}
                        customUploader={customUploader}
                    />
                )}
            </div>
            <div className='mediaForm-detail__main-col'>
                <InputUi value={data.title} onChange={(e) => inputHandler(e.target.value, 'title')} label='Заголовок публикации*' placeholder='Укажите заголовок'/>
                <CalendarUi value={data.date} onChange={(e) => inputHandler(moment(e.target.value), 'date')} label='Дата публикации*' dateFormat='dd.mm.yy' mask='99.99.99'/>
                {tagsTypes.map((t, i) => (
                    <div className='tags-controls_item' key={i}>
                        <h6>{t.label}</h6>
                        <TagsControl
                            type={t.type}
                            value={data['tagsdata'] ? data['tagsdata'][t.type] : []}
                            updateTagsPost={updateTagsPost}
                            placeholder={t.placeholder}
                            // searchData={searchData}
                            // suggestions={suggestions[t.type]}
                        />
                    </div>
                ))}
            </div>
        </div>
    )
}

const chooseOptions = {label: 'Загрузить фото', icon: 'pi pi-upload'};

const tagsTypes = [
    {label: 'Привязка к матчам', type: 'matches', placeholder: 'Начните вводить названия команд'},
    {label: 'Привязка к командам', type: 'teams', placeholder: 'Начните вводить название команды'},
    {label: 'Привязка к турнирам', type: 'tournaments', placeholder: 'Начните вводить названия турниров'},
    {label: 'Привязка к игрокам', type: 'players', placeholder: 'Начните вводить ФИО игрока'}
]

const getPhotoSrc = obj => {
    if(obj) {
        if (obj.sizes && obj.sizes.length) {
            return obj.sizes.sort((a, b) => b.height - a.height)[0].url
        } if (obj.objectURL) {
            return obj.objectURL
        } if (obj.name && obj.path) {
            return obj.path
        } else {
            return ''
        }
    }
}

const getVideoSrc = obj => {
    if(obj.image && obj.image.length) {
        return obj.image.sort((a, b) => b.height - a.height)[0].url
    } else {
        return ''
    }
}

const getFileName = obj => {
    if (obj.name) {
        return obj.name
    } else {
        return ''
    }
}

const getCover = (item) => {
    switch (item.type) {
        case 'article':
            return getPhotoSrc(item.photo)
        case 'photo':
            return getPhotoSrc(item.photo)
        case 'album':
            return getPhotoSrc(item.album.thumb)
        case 'video':
            return getVideoSrc(item.video)
        case 'file':
            return getFileName(item.file)
        default:
            return null
    }
}

export default FormMain