import React, { useEffect, useRef, useState } from "react";
import {useHistory} from "react-router-dom";

import {postByUrl, putByUrl} from "../../../../v3MethodsService";
import { useClickOutside } from "../../../../utils";
import {schemas} from "../../../../references";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";

import "./style.scss";

const ReferencesModal = ({ state = false, onClose, type, toast }) => {
    const ref = useRef();
    useClickOutside(state, ref, onClose);

    const history = useHistory();

    const [data, setData] = useState(null);
    const [sending, setSending] = useState(false);

    useEffect(() => {
        if (state) {
            setData(state)
        }
    }, [state]);

    const handleSave = () => {

        setSending(true)

        const executeAfterRequest = (resp) => {
            if (resp && resp.success) {
                toast.current.show({severity: 'success', detail: 'Справочник сохранен', life: 2000})
                setSending(false)
                onClose()
            }
        }

        if (data._id) {
            putByUrl(`${type}/${data._id}`, data).then(resp => executeAfterRequest(resp))
        } else {
            postByUrl(`${type}`, data).then(resp => executeAfterRequest(resp))
        }
    }

    const changeData = (value, path) => {
        if (path) {
            setData(prev => {
                const updateNested = (obj, keys) => {
                    const [firstKey, ...restKeys] = keys;
                    if (!restKeys.length) return {...obj, [firstKey]: value};
                    return {
                        ...obj,
                        [firstKey]: updateNested(obj[firstKey] || {}, restKeys)
                    };
                };

                return updateNested(prev, path.split('.'));
            });
            return
        }
        setData(value)
    };

    const renderHeader = () => {
        if (type === 'rffs') {
            return (
                <div className="side-profile__header-btn-group">
                    <Button className="side-profile__button-save auth2__button" label='Сохранить' onClick={handleSave} disabled={inputs[type].find(i => !getNestedValue(data, i.key))} loading={sending}/>
                    {!!data._id && <Button className="side-profile__button-save references-modal__button_outlined" label="Перейти в профиль РФФ" onClick={() => history.push(`regions/${data._id}`)}/>}
                </div>
            )
        }
        return (
            <div className="side-profile__header-btn-group">
                <Button className="side-profile__button-save auth2__button" label='Сохранить' onClick={handleSave} disabled={inputs[type].find(i => !getNestedValue(data, i.key))} loading={sending}/>
            </div>
        )
    }

    const renderInput = (input) => {
        switch (input.type) {
            case 'select': return <Dropdown value={getNestedValue(data, input.key) || ""} onChange={(e) => input.key === 'format' ? changeData({...data, [input.key]: e.target.value, formation: null}) : changeData(e.target.value, input.key)} options={input.key === 'formation' ? schemas[data.format?.split('x')?.[0]] : input.options} placeholder={input.placeholder} className='auth2__input' appendTo='self'/>
            default: return <InputText value={getNestedValue(data, input.key) || ""} onChange={(e) => changeData(e.target.value, input.key)} placeholder={input.placeholder} className='auth2__input'/>
        }
    }

    return !!data && !!state && (
        <div className="verifyModal references-modal">
            <div
                className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <div className="side-profile p-component p-sidebar-enter-done references-modal__side" ref={ref}>
                <div className="side-profile__header">
                        {renderHeader()}
                        <i className="side-profile__icon-close pi pi-times" onClick={onClose}/>
                    </div>
                    <div className="side-profile__body">
                        {!!inputs[type] && inputs[type].map(i => (
                            <div key={i.key} className={`auth2__input-container auth2__input-container_full`}>
                                <label htmlFor='title' className="auth2__input-container-label">{i.label}</label>
                                {renderInput(i)}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const formatOptions = ['5x5', '6x6', '7x7', '8x8', '9x9', '11x11']

const inputs = {
    rffs: [{key: 'federationData.name', label: 'РФФ*', placeholder: 'Укажите РФФ'}],
    disciplines: [
        {key: 'name', label: 'Название*', placeholder: 'Укажите название'},
        {key: 'format', label: 'Количество игроков в команде (формат)*', placeholder: 'Выберите число', type: 'select', options: formatOptions},
        {key: 'formation', label: 'Расстановка по-умолчанию*', placeholder: 'Выберите расстановку', type: 'select'}
    ],
    positions: [
        {key: 'name', label: 'Название*', placeholder: 'Укажите название'},
        {key: 'shortName', label: 'Сокращенное название (для инфографики)*', placeholder: 'Укажите название'},
    ]
}

const getNestedValue = (obj, path) => path.split('.').reduce((acc, key) => acc?.[key], obj) || ''

export default ReferencesModal