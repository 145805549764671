import React, {useState} from "react";

import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import './style.scss'

const CircleToPlayoff = ({ selectedStage, item, data, updateData }) => {

    const unassignedPlaces = selectedStage?.teams
        // ?.map((_, k) => ({value: {place: k + 1}, label: `${k + 1} место`}))
        ?.map((_, k) => k + 1)
        .filter(t => !item?.rules?.map(r => r.home?.place).includes(t) && !item?.rules?.map(r => r.away?.place).includes(t))
        .map(v => ({value: {place: v}, label: `${v} место`}))

    const parentRound = item && item.parentId && data && data.playoffgrids ? data.playoffgrids.find(p => p._id === item.parentId) : false

    const unassignedPlacesWinners = parentRound?.rules
        ?.map((_, k) => k + 1)
        .filter(t => !item?.rules?.map(r => r.home?.pair).includes(t) && !item?.rules?.map(r => r.away?.pair).includes(t))
        ?.map((_, k) => ({value: { who: "winner", pair: k + 1}, label: `Победитель ${k + 1} пары`}))

    const addPair = () => {
        const newRules = item.rules ? [...item.rules] : []
        newRules.push({pair: newRules.length + 1 , home: parentRound ? {who: "winner", place: null} : {place: null}, away: parentRound ? {who: "winner", place: null} : {place: null}})
        const newPlayoffgrids = [...data.playoffgrids]
        const idxItem = newPlayoffgrids.findIndex(p => p._id === item._id)
        newPlayoffgrids[idxItem] = {...item, rules: newRules}
        updateData(newPlayoffgrids, 'playoffgrids')
    }

    const selectPlace = (value, idx, target) => {
        const newRules = item.rules ? [...item.rules] : []
        newRules[idx][target] = value
        const newPlayoffgrids = [...data.playoffgrids]
        const idxItem = newPlayoffgrids.findIndex(p => p._id === item._id)
        newPlayoffgrids[idxItem] = {...item, rules: newRules}
        updateData(newPlayoffgrids, 'playoffgrids')
    }

    const selectedPlaceTemplate = (option, props) => props.value && (props.value.place || props.value.pair) ? parentRound ? `Победитель ${props.value.pair} пары` : `${props.value.place} место` : props.placeholder

    return (
        <div className='circle-to-playoff'>
            {item.rules?.map((p, k) => [
                <div key={k} className='circle-to-playoff__pair'>
                    <div className='circle-to-playoff__label'>
                        Пара {k + 1}
                    </div>
                    <div className='circle-to-playoff__selects'>
                        <Dropdown
                            value={p.home}
                            options={parentRound ? unassignedPlacesWinners : unassignedPlaces}
                            onChange={(e) => selectPlace(e.value, k, 'home')}
                            placeholder={parentRound ? 'Выберите победителя пары' : 'Выберите место'}
                            className='circle-to-playoff__dropdown'
                            valueTemplate={selectedPlaceTemplate}
                            emptyMessage="Ничего не найдено"
                        />
                        <span>vs</span>
                        <Dropdown
                            value={p.away}
                            onChange={(e) => selectPlace(e.value, k, 'away')}
                            options={parentRound ? unassignedPlacesWinners : unassignedPlaces}
                            placeholder={parentRound ? 'Выберите победителя пары' : 'Выберите место'}
                            className='circle-to-playoff__dropdown'
                            valueTemplate={selectedPlaceTemplate}
                            emptyMessage="Ничего не найдено"
                        />
                    </div>
                </div>,
                <Divider key={`divider-${k}`}/>
            ])}
            <Button onClick={addPair} label='Добавить пару' icon='pi pi-plus' className='circle-to-playoff__button'/>
        </div>
    )
}

export default CircleToPlayoff;