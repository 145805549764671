import React, {useEffect, useState} from "react";

import {getByUrl, putByUrl} from "../../../../../../../../../v3MethodsService";

import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import CircleToGroup from "./CircleToGroup";
import GroupToGroup from "./GroupToGroup";
import PlayoffToGroup from "./PlayoffToGroup";

import './style.scss'

const AutoGroup = ({ createGroup, data, selectGroup, groupOp, renameGroups, updateData, state, toast, isFinished, handleFinish }) => {
    const [loading, setLoading] = useState(false)
    const [selectedStage, setSelectedStage] = useState(null)
    const [stages, setStages] = useState([])
    const [sending, setSending] = useState(false)
    const [isChanged, setIsChanged] = useState(false)

    useEffect(() => {
        if (data && data.parentId && !selectedStage && stages.length > 0) {
            setSelectedStage(stages.find(s => s.value._id === data.parentId).value)
        }
    }, [data, stages]);

    useEffect(() => {
        if (state) {
            setLoading(true)
            getByUrl(`stages?tournamentId=${state._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setStages(resp.data.filter(r => ['groups', 'round'].includes(r.type) && r._id !== data._id).map(r => ({label: r.title, value: r})))
                    }
                    setLoading(false)
                })
        }
    }, [state]);

    const handleSelect = (value) => {
        _updateData({...data, parentId: value._id, rules: [], groups: data.groups?.map(g => ({...g, rules: []})) || []})
        setSelectedStage(value)
    }

    const _updateData = (val, key) => {
        updateData(val, key)
        setIsChanged(true)
    }

    const handleSave = () => {
        setSending(true)
        const body = { parentId: data.parentId, rules: data.rules }
        putByUrl(`stages/${data._id}`, body)
            .then(async resp => {
                if (resp && resp.success && toast) {
                    updateData({...data, extractedTeams: resp.data.extractedTeams, teams: resp.data.teams})
                    for (const group of data.groups) {
                        const bodyGroup = {parentId: data.parentId, rules: group.rules}
                        await putByUrl(`groups/${group._id}`, bodyGroup)
                    }
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Данные сохранены!', life: 1000})
                }
                setSending(false)
                setIsChanged(false)
            })
    }

    const disabledSave = !isChanged || sending || !data?.parentId || !data?.rules?.length

    const Specified = selectedStage && selectedStage.type && wrap[selectedStage.type] ? wrap[selectedStage.type] : wrap.round

    return loading ? <i className="pi pi-spin pi-spinner" /> : (
        <div className='auto-group'>
            <div className='auto-group__header'>
                Команды
                <Button label="Добавить группу" icon="pi pi-users" className="p-button-outlined" onClick={createGroup} disabled={isFinished}/>
            </div>
            <div className='auto-circle__content'>
                <div className='auto-circle__select'>
                    <div className='auto-circle__select-label'>Из какой стадии наследуются*</div>
                    <Dropdown value={selectedStage} onChange={e => handleSelect(e.value)} options={stages} placeholder='Выберите стадию' className='auto-circle__select-dropdown' disabled={isFinished}/>
                </div>
                {!!selectedStage && <Specified stage={selectedStage} data={data} updateData={_updateData} selectGroup={selectGroup} groupOp={groupOp} renameGroups={renameGroups} isFinished={isFinished}/>}
            </div>
            <div className="clubs-detail__button">
                {!isFinished && <Button onClick={handleFinish} label="Завершить стадию" className="p-button-warning"/>}
                {isChanged && <Button label="Сохранить" onClick={handleSave} disabled={disabledSave || isFinished} loading={sending}/>}
            </div>
        </div>
    )
}

const wrap = {
    round: CircleToGroup,
    groups: GroupToGroup,
    playoff: PlayoffToGroup
}

export default AutoGroup;