import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";

import { getByUrl, postByUrl, putByUrl } from "../../../v3MethodsService";
import { pluralForm, useClickOutside } from "../../../utils";
import { WorkspaceContext } from "../../../ctx";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Tooltip } from "primereact/tooltip";
import { MultiSelect } from "primereact/multiselect";
import { InputNumber } from "primereact/inputnumber";
import Payment from "./Payment";

import "./style.scss";

const Payments = ({ state = false, onClose, onRemove, toast }) => {
    const wctx = useContext(WorkspaceContext);
    const role = wctx && wctx.appSubject && wctx.appSubject.type;
    const ref = useRef();
    const [dataToSend, setDataToSend] = useState({ subjectType: "club" });
    const [create, setCreate] = useState(false);
    const [select, setSelect] = useState(opt[0]);
    const [options, setOptions] = useState([]);
    const [disabledBtn, setDisabledBtn] = useState(false);
    useClickOutside(state, ref, () => handleClose());

    useEffect(() => {
        getByUrl("clubs").then((resp) => {
            if (resp && resp.success && resp.data) {
                setOptions(resp.data);
            }
        });
    }, [create]);

    const handleClose = () => {
        setDataToSend({ subjectType: "club" });
        onClose();
        setCreate(false);
    };

    const updateData = (val, key, subKey) => {
        setDataToSend((prev) => ({
            ...prev,
            [key]: subKey ? { ...prev[key], [subKey]: val } : val,
        }));
    };

    const renderHeader = () => {
        if (role === "club") {
            return <span className="payments__title">Заявки на платеж</span>;
        }
        return (
            <div className="side-profile__header-btn-group">
                {create ? (
                    <Button className="side-profile__button-save auth2__button" label="Отправить" onClick={handlePost} disabled={!disabled || disabledBtn} />
                ) : (
                    <Button className="side-profile__button-save notices-modal__button_outlined" label="Создать заявку на платеж" onClick={() => setCreate(true)} />
                )}
            </div>
        );
    };

    const handlePost = async () => {
        setDisabledBtn(true);
        const response = await postByUrl(`bills`, dataToSend);
        setDisabledBtn(false);
        response?.success ? onSuccessfulPost("Заявки были отправлены") : onError(response?.message);
    };

    const onSuccessfulPost = (text) => {
        toast.current.show({ severity: "success", summary: text });
        setCreate(false);
        handleClose();
    };

    const onError = (message) => {
        toast.current.show({
            severity: "error",
            summary: message || "Ошибка сервера. Повторите попытку позже.",
            life: 1000,
        });
    };

    const bills = state ? state.filter((item) => item.status === select.key) : [];
    const disabled = dataToSend && dataToSend.title && dataToSend.description && dataToSend.subjectIds && dataToSend.amount;

    const renderBody = () => {
        return create ? (
            <div className="payments__body">
                <span className="payments__title">Для кого событие</span>
                <div className="clubs-profile__container">
                    <label className="clubs-profile__label">Заголовок*</label>
                    <InputText placeholder="Укажите заголовок" value={dataToSend.title} onChange={(e) => updateData(e.target.value, "title")} />
                </div>
                <div className="clubs-profile__container">
                    <label className="clubs-profile__label">Описание*</label>
                    <InputTextarea placeholder="Опишите подробнее" value={dataToSend.description} onChange={(e) => updateData(e.target.value, "description")} />
                </div>
                <div className="clubs-profile__container">
                    <label className="clubs-profile__label">Клубы*</label>
                    <MultiSelect
                        selectedItemsLabel={dataToSend?.subjectIds?.length + " клуб" + pluralForm(dataToSend?.subjectIds?.length, ["", "а", "ов"])}
                        maxSelectedLabels={0}
                        value={dataToSend.subjectIds}
                        filter
                        options={options}
                        optionLabel="name"
                        optionValue="_id"
                        appendTo={"self"}
                        onChange={(e) => updateData(e.value, "subjectIds")}
                        placeholder="Выберите клубы"
                        emptyFilterMessage="Ничего не найдено"
                    />
                    <div className="clubs-profile__container">
                        <label className="clubs-profile__label">Запрашиваемая сумма в рублях*</label>
                        <InputNumber placeholder="Укажите сумму" value={dataToSend.amount} onValueChange={(e) => updateData(e.target.value, "amount")} />
                    </div>
                </div>
            </div>
        ) : state.length > 0 ? (
            <div className="payments__items">
                {role === "league" && <span className="payments__title">Отправленные заявки на платеж</span>}
                <SelectButton
                    options={opt}
                    optionLabel="label"
                    value={select}
                    onChange={(e) => {
                        if (e.value) {
                            setSelect(e.value);
                        }
                    }}
                />
                {bills.length > 0 ? (
                    bills.map((item, idx) => <Payment d={item} idx={idx} role={role} onSuccessfulPost={onSuccessfulPost} onError={onError} />)
                ) : (
                    <div className="payments__empty">{select.none}</div>
                )}
            </div>
        ) : (
            <div className="payments__empty">Пока еще не было создано заявок</div>
        );
    };

    return (
        state && (
            <div className="verifyModal payments">
                <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                    <div className="side-profile p-component p-sidebar-enter-done notices-modal__side" ref={ref}>
                        <div className="side-profile__header">
                            {renderHeader()}
                            <i className="side-profile__icon-close pi pi-times" onClick={handleClose} />
                        </div>
                        <div className="side-profile__body">{renderBody()}</div>
                    </div>
                </div>
            </div>
        )
    );
};

const opt = [
    { label: "Неподтвержденные", key: "active", none: "Все заявки обработаны" },
    { label: "Отклоненные", key: "declined", none: "Пока еще не никто не отклонил заявку" },
    { label: "Подтвержденные", key: "finished", none: "Пока еще не никто не подтвердил заявку" },
];

export default Payments;
