import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { getByUrl } from "../../../v3MethodsService";

import { Toast } from "primereact/toast";

import RolesDetail from "./Detail";
import RolesList from "./List";

import "./style.scss";
import QueriesVerify from "../QueriesVerify";

const Roles = () => {
    const param = window.location.pathname.split("/");
    const toast = useRef(null);
    const location = useLocation();
    const paginationParam = window.location.search;
    const [pagination, setPagination] = useState(0);

    const [state, setState] = useState({});

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        if (params) {
            const firstParam = params.get("pagination");
            setPagination(firstParam);
        }
    }, [paginationParam]);

    useEffect(() => {
        if (!state.roles) {
            getRoles();
        }
    // }, [param]); // пока не понятно почему так было
    }, []);

    useEffect(() => {
        if (param && param[2] && !["newRole", "copy"].includes(param[2]) && state.roles && !state.active) {
            const activeObj = state.roles.find((r) => r._id === param[2]);
            getByUrl(`users?roleId=${param[2]}`).then((resp) => {
                if (resp && resp.success) {
                    activeObj.users = resp.data;
                }
                updateState(activeObj, "active");
            });
        } else if (param && !param[2] && (state.active || state.copy)) {
            setState((prev) => ({ ...prev, active: null, copy: null }));
        }
    }, [param[2], state.roles]);

    const updateState = (val, key) => {
        setState((prev) => ({ ...prev, [key]: val }));
    };

    const getRoles = () => {
        getByUrl("roles").then((resp) => {
            if (resp && resp.success) {  
                updateState(resp.data, "roles");
            }
        });
    };

    const Specified = param && param[2] ? wrap.detail : wrap.list;

    return (
        <div className="roles">
            <Toast ref={toast} position="top-right" />
            <Specified toast={toast.current} state={state} updateState={updateState} getRoles={getRoles} pag={pagination} />
        </div>
    );
};

const wrap = {
    detail: RolesDetail,
    list: RolesList,
};

export default Roles;
