import React, { useEffect, useContext, useRef, useState } from "react";

import { getByUrl, postByUrl } from "../../../v3MethodsService";
import { GlobalContext } from "../../../ctx";

import { SelectButton } from "primereact/selectbutton";
import {ProgressSpinner} from "primereact/progressspinner";

import Header from "../Common/Header";
import Table from "./Table";
import VerifyModal from "./VerifyModal";

import logo from "./img/logo.png";

import "./style.scss";

const QueriesVerify = () => {
    const [global, setGlobal] = useContext(GlobalContext);

    const [data, setData] = useState([]);
    const [modal, setModal] = useState(false);
    const [tab, setTab] = useState("new_users");
    const [option, setOption] = useState(options[0]);
    const [req, setReq] = useState(false);
    const [loader, setLoader] = useState(false)

    const scope = global && global.auth && global.auth.data && global.auth.token ? global.auth.data.find((i) => i.token == global.auth.token) : false;
    const type = scope ? scope.type : false;

    const filtered = data
        .filter((f) => f?.status == option?.id)
        .filter((f) => {
            let condition = "";
            switch (tab) {
                case "new_users":
                    condition = f.type == "USER" && (scope.type === 'league' ? (scope._id === f.subject?._id || scope._id === f.league?._id) : true);
                    break;
                case "new_leagues":
                    condition = f.type == "LEAGUE";
                    break;
                case "new_clubs":
                    condition = f.type == "CLUB" && (scope.type === 'league' ? scope._id === f.league?._id : true);
                    break;
                case "rff":
                    condition = f.type == "ROLE" && f.subjectType == "region";
                    break;
                case "leagues":
                    condition = f.type == "ROLE" && (f.subjectType == "league" || f.subjectType == "leagueStaff") && (scope.type === 'league' ? scope._id === f.subjectId : true);
                    break;
                case "clubs":
                    condition = f.type == "ROLE" && f.subjectType == "club" && (scope.type === 'league' ? scope._id === f.league?._id : true);
                    break;

                default:
                    break;
            }

            return condition;
        });

    useEffect(() => {
        if (!data.length || req) {
            setLoader(true)
            getByUrl("verifications").then((resp) => {
                if (resp && resp.success && resp.data) {
                    setData(resp.data);
                    setReq(false);
                    setLoader(false)
                }
            });
        }
    }, [req]);

    useEffect(() => {
        if (type) {
            setTab(tabs[type]?.[0] || "new_users")
        }
    }, [type]);

    const renderHeader = () => {
        return (
            <div className="header__top">
                <span className="header__title">Верификация заявок</span>
                <SelectButton
                    value={option}
                    onChange={(e) => {
                        if (e.value) setOption(e.value);
                    }}
                    options={options}
                />
            </div>
        );
    };

    const fileredTabs = type ? items.filter((f) => tabs[type].some((s) => f.type == s)) : items;

    return (
        <div className="queries-verify">
            <Header items={fileredTabs} state={data} setTab={setTab} headerTop={renderHeader} tabMenu/>
            {loader ? (
                <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className='loader'/>
            ) : (
                <Table state={filtered} type={tab} option={option} onClick={(e) => setModal(e.data)} />
            )}
            <VerifyModal state={modal} onClose={() => setModal(false)} tab={tab} setReq={setReq} />
        </div>
    );
};

export default QueriesVerify;

const items = [
    { label: "Новые пользователи", type: "new_users" },
    { label: "Новые лиги", type: "new_leagues" },
    { label: "Новые клубы", type: "new_clubs" },
    { label: "Представитель РФФ", type: "rff" },
    { label: "Представитель лиги", type: "leagues" },
    { label: "Представитель клуба", type: "clubs" },
];

const tabs = {
    system: ["new_users", "new_leagues", "new_clubs", "rff", "leagues", "clubs"],
    region: ["new_users", "new_leagues", "new_clubs", "rff", "leagues", "clubs"],
    league: ["new_users", "new_clubs", "leagues", "clubs"],
    leagueStaff: ["new_clubs"],
    club: [],
};

const options = [
    { label: "Необработанные", id: "MODERATION" },
    { label: "Отклоненные", id: "NOT_VALID" },
    { label: "Принятые", id: "VALID" },
];
