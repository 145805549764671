import React, { useState, useEffect } from 'react'

import { getByUrl } from "../../../v3MethodsService";

import Success from './Success' //это путь регистрации
import Error from './Error' //это путь ошибки создания учетной записи

const Reg = ({ }) => {

    const currentUrl = window.location.href;
    const token = getToken(currentUrl) ? getToken(currentUrl) : false

    const [loading, setLoading] = useState(true)
    const [isError, setIsError] = useState(true)
    const [tokenData, setTokenData] = useState(null)
    const [step, setStep] = useState('email') //email, kind, personData
   
    useEffect(() => {
        if (token) {
            setLoading(true)
            getByUrl(`check_verify?verify=${token}`)
                .then(resp => {
                    if (resp) {
                        setIsError(!resp.success)
                        setTokenData({...resp.data, token: token})
                        setStep('personData')
                    }
                    setLoading(false)
                })
        } else {
            setIsError(true)
            setLoading(false)
        }
    }, [token])

    const Specified = isError ? Error : Success;

    return loading ? <div className='auth2__container'></div> : <Specified tokenData={tokenData} step={step} setStep={setStep}/>
}

const getToken = (url) => {
    const arr = url?.split('?')
    const token = arr[arr.length - 1]
    if(token.includes('token')) {
        return token.split('=')[1]
    }
}

export default Reg