import React, { useEffect, useRef, useState } from "react";

import { ENDPOINT } from "../../../../../../../../env";
import axios from "axios";
import utils from "../../../../../../../Tournaments/Structure/utils";
import service from "../../../../../../../Tournaments/service";
import {clearToast, diffData} from "../../../../../../../../utils";
import {putByUrl} from "../../../../../../../../v3MethodsService";

import { OverlayPanel } from "primereact/overlaypanel";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import {confirmDialog} from "primereact/confirmdialog";

import ManualCircle from "./Manual";
import AutoCircle from "./Auto";

import "./style.scss";

const CircleStructure = ({ current, state, toast, updateCurrent, isFinished }) => {
    const op = useRef(null);

    const [timeoutID, setTimeoutID] = useState(null);
    const [mode, setMode] = useState('manual')

    const [data, setData] = useState(null);
    const [filteredTeams, setFilteredTeams] = useState([]);
    const [renderingGraphics, setRenderingGraphics] = useState(false);

    const isDiff = diffData(data, state)

    const unassigned = state?.teams.filter((t) => !data?.teams?.map((c) => c._id).includes(t._id));

    const assigned = state?.teams.filter((t) => data?.teams?.map((c) => c._id).includes(t._id));

    const [initial, setInitial] = useState(current && current.initial ? utils.mapperInitial(current.initial, assigned) : null);

    useEffect(() => {
        if (current && !data) {
            setData(current);
            setInitial(current && current.initial ? utils.mapperInitial(current.initial, assigned) : null);
            if (current.parentId) {
                setMode('auto')
            }
        }
    }, [current]);

    useEffect(() => {
        if (data && isDiff) {
            updateCurrent(data)
        }
    }, [isDiff]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData((prev) => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else if (key) {
            setData((prev) => ({ ...prev, [key]: val }));
        } else {
            setData(val);
        }
    };

    const searchTeam = (event) => {
        const filtered = unassigned.filter((team) => team.name?.toLowerCase().includes(event.query.toLowerCase()));
        setFilteredTeams(filtered);
    };

    const addAllTeams = async () => {
        const mapped = [...data?.teams, ...unassigned.map((t) => ({ _id: t._id }))];
        updateData(mapped, "teams");
        await service.updateStage(data._id, { teams: mapped }, toast);
        clearToast(toast, timeoutID, setTimeoutID)
    };

    const renderStandingsGraphics = () => {
        setRenderingGraphics(true);
        const sharableUrl = data.federationId === "624c17e25887f52dbfc6819c" ? `${ENDPOINT}render/ole/round/${data._id}` : `${ENDPOINT}render/elegance/standingsv2/${data._id}?type=schedule`;
        axios.get(`${ENDPOINT}share?url=${encodeURIComponent(sharableUrl)}&asDecoded=true`).then((resp) => {
            const a = document.createElement("a");
            a.href = "data:image/png;base64," + resp.data;
            a.download = `${data.title}_${data._id}.png`;
            a.click();
            setRenderingGraphics(false);
        });
    };

    const sendInitial = async () => {
        const init = utils.teamsToInitial(initial);
        if (op && op.current) {
            op.current.hide();
        }
        if (init || data.initial) {
            await service.updateStage(data._id, { initial: init }, toast);
            updateData(init, "initial");
        }
    };

    const updateInitial = (id, value, key = "pts") => {
        const mapd = initial.map((init) => (init.team._id === id ? { ...init, [key]: value } : init));
        setInitial(mapd);
    };

    const sendTitle = async () => {
        await service.updateStage(data._id, { title: data?.title }, toast);
    };

    const sendRounds = async (val) => {
        if (data.roundsQty !== val) {
            await service.updateStage(data._id, { roundsQty: val }, toast);
            updateData(val, "roundsQty");
        }
    };

    const sendTeams = async (val) => {
        const mapped = val.map((t) => ({ _id: t._id }));
        await service.updateStage(data._id, { teams: mapped }, toast);
        clearToast(toast, timeoutID, setTimeoutID)
        updateData(mapped, "teams");
    };

    const changeMode = (mode) => {
        if (mode === 'manual' && data && data.rules && data.rules.length > 0) {
            confirmDialog({
                message: 'После смены режима редактирования будут удалены все настройки наследования',
                header: 'Выбрать ручной режим?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                accept: changeModeToManual,
            });
            return
        }
        setMode(mode)
    }

    const changeModeToManual = () => {
        const body = { parentId: null, rules: null, matchdays: [], teams: [], extractedTeams: []}
        putByUrl(`stages/${data._id}`, body)
            .then(resp => {
                if (resp && resp.success && toast) {
                    updateData(resp.data)
                    setMode('manual')
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Настройки удалены!', life: 1000})
                }
            })
    }

    const handleFinish = () => {
        confirmDialog({
            message: 'Это действие нельзя отменить. Вы точно хотите завершить стадию?',
            header: 'Внимание!',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptFinish,
            acceptLabel: 'Да',
            rejectLabel: 'Нет'
        });
    }

    const acceptFinish = () => {
        putByUrl(`stages/${data._id}`, {finished: true})
            .then(resp => {
                if (resp && resp.success && toast) {
                    updateData(resp.data)
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Стадия завершена!', life: 1000})
                }
            })
    }

    const Specified = mode ? wrap[mode] : wrap.manual

    return (
        !!data && (
            <div className="circle-structure">
                <div className="circle-structure__card">
                    <div className="circle-structure__container">
                        <span className="circle-structure__title">Основная информация</span>
                        <div className="circle-structure__btns">
                            <Button
                                icon="pi pi-pencil"
                                label="Изменить очки"
                                className="p-button-outlined"
                                onClick={(e) => {
                                    if (!initial) {
                                        const init = utils.getInitial(assigned);
                                        setInitial(init);
                                    }
                                    op.current.toggle(e);
                                }}
                                disabled={isFinished}
                            />
                            <Button
                                icon="pi pi-download"
                                label={renderingGraphics ? "Рендеринг..." : "Таблица"}
                                className="p-button-outlined"
                                onClick={renderStandingsGraphics}
                                loading={renderingGraphics}
                            />
                        </div>
                        <OverlayPanel ref={op} style={{ maxWidth: "363px" }}>
                            <div className="circle-structure__overlay">
                                {initial?.map((item, idx) => (
                                    <div key={idx} className="circle-structure__score-item">
                                        <span className="circle-structure__score-name">{item.team.name}</span>
                                        <InputNumber value={item.pts} onValueChange={(e) => updateInitial(item.team._id, e.value, "pts")} disabled={isFinished}/>
                                    </div>
                                ))}
                            </div>

                            <div className="cancel-wrap" style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                                <Button
                                    onClick={sendInitial}
                                    icon="pi pi-save"
                                    className="btn-create p-button-sm p-button-outlined"
                                    label="Сохранить"
                                    disabled={!initial || !initial.find((t) => t.pts) || isFinished}
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </OverlayPanel>
                    </div>
                    <div className="circle-structure__container">
                        <div className="circle-structure__input">
                            <label className="circle-structure__input-label">Название стадии*</label>
                            <InputText value={data.title} onChange={(e) => updateData(e.target.value, "title")} placeholder="Укажите название" onBlur={sendTitle} disabled={isFinished}/>
                        </div>
                        <div className="circle-structure__input">
                            <label className="circle-structure__input-label">Количество кругов*</label>
                            <InputNumber value={data.roundsQty} onValueChange={(e) => sendRounds(e.target.value)} placeholder="Укажите количество " disabled={isFinished}/>
                        </div>
                    </div>
                    {state?.stages?.length > 1 && <SelectButton value={mode} onChange={e => e.value ? changeMode(e.value) : null} options={modeOptions} optionLabel="label" disabled={isFinished}/>}
                </div>
                <Specified
                    state={state}
                    unassigned={unassigned}
                    assigned={assigned}
                    filteredTeams={filteredTeams}
                    searchTeam={searchTeam}
                    sendTeams={sendTeams}
                    addAllTeams={addAllTeams}
                    updateData={updateData}
                    data={data}
                    toast={toast}
                    isFinished={isFinished}
                    handleFinish={handleFinish}
                />
                {/*<Button label="Удалить" className="circle-structure__btn p-button-danger"  />*/}
            </div>
        )
    );
};

const wrap = {
    manual: ManualCircle,
    auto: AutoCircle
}

const modeOptions = [{label: 'Ручной режим', value: 'manual'}, {label: 'Наследовать из другой стадии', value: 'auto'}]

export default CircleStructure;
