import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { ENDPOINT } from "../../../../../env";

import { Toast } from "primereact/toast";

import Detail from "./Detail";
import List from "./List";

import "./style.scss";

const DreamLineups = ({ state, updateState }) => {
    const param = window.location.pathname.split("/");
    const toast = useRef(null);

    const [current, setCurrent] = useState(null);

    useEffect(() => {
        if (param[2] && !current) {
            getDreamLineups();
        }
    }, [param[2], current]);

    const getDreamLineups = () => {
        axios
            .get(`${ENDPOINT}v2/getTournamentDreamLineups/${param[2]}`, {
                headers: {
                    authorization: localStorage.getItem("_amateum_auth_tkn"),
                },
            })
            .then((resp) => {
                if (resp.data) {
                    updateState(resp.data.lineups, "lineups");
                }
            });
    };

    useEffect(() => {
        if (state && state.btnOptions && state.btnOptions.click) {
            updateState(false, "btnOptions");
            setCurrent({
                name: "",
                players: [],
                tournamentId: state._id,
                federationId: state.federationId,
                formation: "",
            });
        }
    }, [state]);

    const handleCurrent = (e) => {
        setCurrent(state && state.lineups ? state.lineups.find((l) => l._id === e?.data?._id) : null);
    };

    const Specified = current ? wrap.detail : wrap.list;

    return (
        <div className="tournamets2-team">
            <Toast ref={toast} position="top-right" />
            <Specified getDreamLineups={getDreamLineups} toast={toast.current} state={state} updateState={updateState} handleCurrent={handleCurrent} current={current} closeLineup={() => setCurrent(null)} />
        </div>
    );
};

const wrap = {
    detail: Detail,
    list: List,
};

export default DreamLineups;
