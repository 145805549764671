import React, {useEffect, useRef, useState} from "react"

import { getByUrl } from "../../../v3MethodsService";

import {Toast} from "primereact/toast";

import ClubsDetail from "./Detail";
import ClubsList from "./List";

import './style.scss'

const ClubsManagment = () => {
    const param = window.location.pathname.split('/')
    const toast = useRef(null)

    const [state, setState] = useState({})

    const [dropdown, setDropdown] = useState(null)

    const [loader, setLoader] = useState(false)

    const updateState = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }



    const Specified = param && param[2] ? wrap.detail : wrap.list;

    return (
        <div className='clubs-managment'>
            <Toast ref={toast} position="top-right"/>
            <Specified toast={toast.current} state={state} updateState={updateState}  dropdown={dropdown} setDropdown={setDropdown} loader={loader} setLoader={setLoader}/>
        </div>
    )
}

const wrap = {
    detail: ClubsDetail,
    list: ClubsList
}

export default ClubsManagment