import React, { useRef, useState, useEffect, useContext } from "react";

import "./style.scss";
import { getByUrl, postByUrl } from "../../../v3MethodsService";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { SelectButton } from "primereact/selectbutton";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import { Button } from "primereact/button";
import { addMonths, startOfQuarter, endOfQuarter, startOfYear, endOfYear } from "date-fns";
import { Toast } from "primereact/toast";
import { GlobalContext } from "../../../ctx";
import axios from "axios";
import { ENDPOINT } from "../../../env";
import { ProgressSpinner } from "primereact/progressspinner";

const options = [
    { name: "Ручной выбор", key: "hand" },
    { name: "Текущий квартал", key: "curQuarter" },
    { name: "Прошлый квартал", key: "lastQuarter" },
    { name: "Текущий год", key: "curYear" },
    { name: "Прошлый год", key: "lastYear" },
];

const Report = () => {
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token;
    const matched = global?.auth?.data?.find((sub) => sub.token === token);
    const toast = useRef(null);
    // const autoCompleteRefs = useRef([]);
    const regionsRef = useRef(null)
    const leaguesRef = useRef(null)
    const formatsRef = useRef(null)
    const sexesRef = useRef(null)
    const [data, setData] = useState({});
    const [optionTypes, setOptionTypes] = useState(options[0]);
    const [filter, setFilter] = useState({
        sexes: [
            { name: "Мужской", _id: "man" },
            { name: "Женский", _id: "woman" },
        ],
    });
    const [loader, setLoader] = useState(false);

    const handlePeriodChange = (period) => {
        let startDate, endDate;
        const currentDate = new Date();

        switch (period) {
            case "curQuarter":
                startDate = startOfQuarter(currentDate);
                endDate = endOfQuarter(currentDate);
                break;
            case "lastQuarter":
                startDate = startOfQuarter(addMonths(currentDate, -3));
                endDate = endOfQuarter(addMonths(currentDate, -3));
                break;
            case "curYear":
                startDate = startOfYear(currentDate);
                endDate = currentDate;
                break;
            case "lastYear":
                startDate = startOfYear(new Date(currentDate.getFullYear() - 1, 0, 1));
                endDate = endOfYear(new Date(currentDate.getFullYear() - 1, 11, 31));
                break;
            default:
                return;
        }

        setData((prev) => ({ ...prev, dateStart: moment(startDate).format("DD.MM.YYYY"), dateEnd: moment(endDate).format("DD.MM.YYYY") }));
    };

    const [filteredCountries, setFilteredCountries] = useState(null);

    useEffect(() => {
        if (token) {
            switch (matched?.type) {
                case "system":
                    getByUrl(`getRefsList?section=regions&mode=needLeagues`).then((resp) => {
                        if (resp && resp.success && resp.data) {
                            setFilter((prev) => ({ ...prev, regions: resp.data }));
                        }
                    });
                    break;
                case "league":
                    getByUrl(`getRefsList?section=formats`).then((resp) => {
                        if (resp && resp.success && resp.data) {
                            setFilter((prev) => ({ ...prev, formats: resp.data }));
                        }
                    });
                    break;
                case "region":
                    getByUrl(`getRefsList?section=leagues`).then((resp) => {
                        if (resp && resp.success && resp.data) {
                            setFilter((prev) => ({ ...prev, leagues: resp.data }));
                        }
                    });
                    break;
                default:
                    break;
            }
        }
    }, [token]);

    const search = (event, key, all) => {
        if (all) {
            setFilteredCountries(filter[key]?.filter((item) => !data[key]?.some((selected) => selected._id === item._id)));
        } else {
            if (filter && key && filter[key]) {
                let _filteredCountries;

                if (!event.query.trim().length) {
                    _filteredCountries = [...filter[key]];
                } else {
                    _filteredCountries = filter[key]?.filter((country) => {
                        return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                    });
                }

                _filteredCountries = _filteredCountries?.filter((item) => !data[key]?.some((selected) => selected._id === item._id));

                setFilteredCountries(_filteredCountries);
            }
        }
    };

    const updateData = (value, key) => {
        if (key === "regions") {
            let leagues = [];
            value.forEach((item) => {
                item.leagues.forEach((it) => leagues.push(it));
            });
            setFilter((prev) => ({ ...prev, leagues: leagues }));
        }

        setData((prev) => ({ ...prev, [key]: value }));
    };

    const formatDate = (dateString) => {
        const [day, month, year] = dateString.split(".");
        const shortYear = year.slice(-2);
        return `${shortYear}-${month}-${day}`;
    };

    let headers = {
        Authorization: localStorage.getItem("_amateum_auth_tkn"),
        scopetoken: localStorage.getItem("_amateum_auth_tkn"),
    };

    const instance = axios.create({
        baseURL: `${ENDPOINT}methodsV3/`,
        headers: headers,
    });

    const handleSend = async () => {
        setLoader(true);
        const newData = { ...data };
        if (data.dateStart || data.dateEnd) {
            const startDate = formatDate(data.dateStart);
            const endDate = formatDate(data.dateEnd);
            newData.dateStart = startDate;
            newData.dateEnd = endDate;
        }

        Object.entries(data).forEach((item) => {
            if (item[0] !== "dateStart" && item[0] !== "dateEnd") newData[item[0]] = item[1].map((i) => i._id);
        });

        instance
            .post(`getLeaguesStats`, newData, {
                responseType: "arraybuffer",
                headers: {
                    ...headers,
                    accept: "application/octet-stream",
                },
            })
            .then((resp) => {
                if (resp.data && !resp.data.error) {
                    const obj = new Blob([resp.data], { type: "application/octet-stream" });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(obj);
                    link.download = `${moment().format("DD.MM.YYYY")}.xlsx`;
                    link.click();
                    toast.current.show({ severity: "success", summary: "Отчёт сформирован" });
                } else {
                    toast.current.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
                setLoader(false);
            });
        setData({});
        setOptionTypes(options[0]);
    };

    const disabled = data && data.dateStart && data.dateEnd;

    const inputs = [
        { name: "География", placeholder: "Выберите регионы", key: "regions", isVisible: ["system"], ref: regionsRef },
        { name: "Лиги", placeholder: "Выберите лиги", key: "leagues", isVisible: ["system", "region"], ref: leaguesRef },
        { name: "Форматы", placeholder: "Выберите форматы", key: "formats", isVisible: ["league"], ref: formatsRef },
        { name: "Пол", placeholder: "Выберите пол", key: "sexes", ref: sexesRef },
    ];

    return (
        <div className="report">
            {loader && (
                <div className="loader-bg">
                    <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" animationDuration=".5s" className="loader" />
                </div>
            )}
            <Toast ref={toast} position="top-right" />
            <div className="report__card">
                <span className="report__card-title">Период для формирования отчета</span>
                <SelectButton
                    options={options}
                    value={optionTypes}
                    onChange={(e) => {
                        if (e.value) setOptionTypes(e.value);
                        handlePeriodChange(e.value.key);
                    }}
                    optionLabel="name"
                />
                <div className="report__card-top">
                    <div className="report__card-container">
                        <label className="report__card-label">Начало периода*</label>
                        <Calendar
                            value={data?.dateStart ? moment(data?.dateStart, "DD.MM.YYYY").toDate() : "" || ""}
                            onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("DD.MM.YYYY") : e.target.value, "dateStart")}
                            className="auth2__calendar"
                            showIcon
                            locale="ru"
                            dateFormat="dd.mm.yy"
                            maxDate={new Date()}
                            mask="99.99.9999"
                            placeholder="Выберите дату"
                            appendTo={"self"}
                            disabled={optionTypes !== options[0]}
                        />
                    </div>
                    <div className="report__card-container">
                        <label className="report__card-label">Конец периода*</label>
                        <Calendar
                            value={data?.dateEnd ? moment(data?.dateEnd, "DD.MM.YYYY").toDate() : "" || ""}
                            onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("DD.MM.YYYY") : e.target.value, "dateEnd")}
                            className="auth2__calendar"
                            showIcon
                            locale="ru"
                            dateFormat="dd.mm.yy"
                            maxDate={new Date()}
                            mask="99.99.9999"
                            placeholder="Выберите дату"
                            appendTo={"self"}
                            disabled={optionTypes !== options[0]}
                        />
                    </div>
                </div>
            </div>
            <div className="report__card">
                <span className="report__card-title">Фильтр</span>
                <div className="report__card-items">
                    {inputs.map((item, idx) => {
                        return (
                            (!item.isVisible || item.isVisible.includes(matched?.type)) && (
                                <div className={`report__card-container ${item.key}`} key={idx}>
                                    <label className="report__card-label">{item.name}</label>
                                    <AutoComplete
                                        // ref={(el) => (autoCompleteRefs[idx] = el)}
                                        ref={item.ref}
                                        value={data[item.key]}
                                        field="name"
                                        suggestions={filteredCountries}
                                        completeMethod={(e) => search(e, item.key)}
                                        onChange={(e) => updateData(e.value, item.key)}
                                        multiple
                                        placeholder={item.placeholder}
                                        dropdown
                                        appendTo={"self"}
                                        showEmptyMessage
                                        emptyMessage="Ничего не найдено"
                                        dropdownAutoFocus={true}
                                        onFocus={() => {
                                            search(null, item.key, true);
                                            console.log('item.ref', item.ref);
                                            item.ref.current.show()
                                            // if (autoCompleteRefs && autoCompleteRefs[idx] && autoCompleteRefs[idx].show) {
                                            //     autoCompleteRefs[idx].show();
                                            // }
                                        }}
                                        disabled={!(filter[item.key] && filter[item.key].length > 0)}
                                    />
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
            <Button label="Сформировать отчет" icon="pi pi-download" className="report__btn" onClick={handleSend} disabled={!disabled} />
        </div>
    );
};

export default Report;
