import React, { useState, useEffect } from 'react'

import Hotkeys from "react-hot-keys";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import './style.scss'

const Email = ({ state, setState, setStep }) => {

    const changeHandler = (val, key) => {
        setState(prev => ({...prev, [key]: val}))
    }

    const sendHandler = async () => {
        console.log('send')
        setStep('personData')
    }

    const onKeyDown = async (e) => {
        if ((e === 'Enter' || e.key === 'Enter')) {
            await sendHandler()
        }
    }

    return (
        <div className='reg-success-email'>
            <div className='auth2__title'>
                Создание учетной записи
            </div>
            <Hotkeys keyName="Enter" onKeyDown={onKeyDown}>
                <div className='auth2__inputs'>
                    <InputText value={state.email || ''} id='email' onChange={(e) => changeHandler(e.target.value, 'email')} placeholder='Введите почту' onKeyDown={onKeyDown} className='auth2__input'/>
                    <Button label='Отправить приглашение на email' onClick={sendHandler} className='auth2__button' disabled={!(state.email && isValidEmail(state.email))} />
                </div>
            </Hotkeys>
        </div>
    )
}

const isValidEmail = (email) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
    return regex.test(email);
}

export default Email