import React, {useEffect, useRef, useContext, useState} from "react";
import { useHistory } from "react-router-dom";

import { transliterate } from "transliteration";
import { diffData } from "../../../../../utils";
import { postByUrl, putByUrl } from "../../../../../v3MethodsService";
import { WorkspaceContext } from "../../../../../ctx";

import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";

import FileUploadUi from "../FileUploadUi";

import "./style.scss";

const Profile = ({ data, updateData, optionRegions, optionSettelments, state, toast, setState }) => {
    const param = window.location.pathname.split("/");
    const [isChanged, setIsChanged] = useState(false);
    const dataToSend = isChanged ? {...diffData(state, data), sponsors: data.sponsors} : diffData(state, data);
    const history = useHistory();
    const wctx = useContext(WorkspaceContext);
    const toastRef = useRef();

    const onSelect = async (event, key, subKey = false, arr = false) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                let array = []
                if (arr) {
                    if (subKey) {
                        array = data[key]?.[subKey] || []
                        array.push(file)
                    } else {
                        array = data[key] || []
                        array.push(file)
                    }
                }
                updateData(arr ? array : file, key, subKey)
                setIsChanged(true)
            }
        });
    };

    const handleSave = async () => {
        setIsChanged(false)
        if (param[2] === "add") {
            await postByUrl(`leagues`, dataToSend).then((resp) => {
                if (resp && resp.success) {
                    setState(data);
                    history.push(`/leagueData/${resp.data._id}`);
                    toast?.show({ severity: "success", summary: "Данные сохранены" });
                } else {
                    toast?.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            });
        } else {
            await putByUrl(`leagues/${param[2]}`, dataToSend).then((resp) => {
                if (resp && resp.success) {
                    setState(data);
                    if (wctx?.appSubject?.type !== "league") {
                        toast?.show({ severity: "success", summary: "Данные сохранены" });
                        history.push("/leagueData");
                        return;
                    }
                    toastRef?.current?.show({ severity: "success", summary: "Данные сохранены" });
                } else {
                    toast?.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            });
        }
    };

    const removeFileHandler = (item) => {
        let newData = {...data}
        if(item) {
            newData.sponsors = newData.sponsors.filter(f => f !== item)
        }
        updateData(newData)
        setIsChanged(true)
    }

    const disabledBtn = data && data.emblem && data.name && data.regionId && data.settlementId && (dataToSend || isChanged);

    return (
        data && (
            <div className="clubs-profile">
                <Toast ref={toastRef} />
                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Основная информация</span>
                    </div>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Эмблема лиги* <span className="clubs-profile__label muted">(jpg, jpeg, png, до 5 Мб)</span>
                            </label>
                            <FileUploadUi image={data.emblem} onChange={(e) => onSelect(e, "emblem")} updateData={updateData} remove={() => updateData(null, 'emblem')}/>
                        </div>
                        <div className="clubs-profile__main">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Название*</label>
                                <InputTextarea placeholder="Укажите название" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Сокращенное название</label>
                                <InputText placeholder="Укажите сокращенное название" value={data.shortName || ""} onChange={(e) => updateData(e.target.value, "shortName")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Основной цвет*</label>
                                <div className="clubs-profile__color">
                                    <ColorPicker format="hex" value={data.mainColor?.trim() || "EEEEEE"} onChange={(e) => updateData(e.value.trim(), "mainColor")}/>
                                    <InputText value={data.mainColor?.trim() || ""} onChange={(e) => updateData(e.target.value.trim(), "mainColor")} placeholder="HEX"/>
                                </div>
                            </div>
                            <div className="clubs-profile__flex">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Регион*</label>
                                    <Dropdown
                                        placeholder="Выберите регион"
                                        emptyFilterMessage={"Ничего не найдено"}
                                        emptyMessage={"Ничего не найдено"}
                                        value={data.regionId || ""}
                                        onChange={(e) => updateData(e.value, "regionId", false, true)}
                                        options={optionRegions}
                                        optionLabel="name"
                                        optionValue="_id"
                                        filter
                                        showFilterClear
                                        appendTo={"self"}
                                    />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Населенный пункт*</label>
                                    <Dropdown
                                        placeholder="Выберите населенный пункт"
                                        emptyFilterMessage={"Ничего не найдено"}
                                        emptyMessage={"Ничего не найдено"}
                                        value={data.settlementId || ""}
                                        onChange={(e) => updateData(e.value, "settlementId")}
                                        disabled={!data.regionId}
                                        options={optionSettelments}
                                        filter
                                        showFilterClear
                                        optionLabel="name"
                                        optionValue="_id"
                                        appendTo={"self"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Баннер</span>
                        <div className='clubs-profile__switch'>
                            <InputSwitch checked={data.banner?.isVisible} onChange={e => updateData(e.value, "banner", "isVisible")}/>
                            <label className="clubs-profile__label">Баннер отображается</label>
                        </div>
                    </div>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                        <label className="clubs-profile__label">Изображение* <span className="clubs-profile__label muted">(PNG, без фона)</span></label>
                            <FileUploadUi image={data.banner?.image} onChange={(e) => onSelect(e, "banner", 'image')} updateData={updateData} remove={() => updateData(null, "banner", 'image')}/>
                        </div>
                        <div className="clubs-profile__main">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Цвет баннера*</label>
                                <div className="clubs-profile__color">
                                    <ColorPicker format="hex" value={data.banner?.color?.trim() || "EEEEEE"} onChange={(e) => updateData(e.value.trim(), "banner", "color")}/>
                                    <InputText value={data.banner?.color?.trim() || ""} onChange={(e) => updateData(e.target.value.trim(), "banner", "color")} placeholder="HEX"/>
                                </div>
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Заголовок* </label>
                                <InputText placeholder="Укажите заголовок" value={data.banner?.title || ""} onChange={(e) => updateData(e.target.value, "banner", "title")}/>
                            </div>
                            <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Подзаголовок</label>
                                <InputTextarea placeholder="Укажите подзаголовок" value={data.banner?.subtitle || ""} onChange={(e) => updateData(e.target.value, "banner", "subtitle")}/>
                            </div>
                            <div className='clubs-profile__inputcontainer'>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Название кнопки*</label>
                                    <InputText placeholder="Укажите название" value={data.banner?.buttonName || ""} onChange={(e) => updateData(e.target.value, "banner", "buttonName")}/>
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Ссылка для перехода с кнопки*</label>
                                    <InputText placeholder="Вставьте ссылку" value={data.banner?.buttonLink || ""} onChange={(e) => updateData(e.target.value, "banner", "buttonLink")}/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Логотипы партнеров</span>
                    </div>
                    <div className="clubs-profile__content logos">
                        {data?.sponsors?.map((item, index) => <FileUploadUi key={index} image={item} onChange={(e) => onSelect(e, 'sponsors', false, true)} updateData={updateData} remove={() => removeFileHandler(item)}/>)}
                        <FileUploadUi onChange={(e) => onSelect(e, 'sponsors', false, true)} updateData={updateData}/>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <span className="clubs-profile__header-title">Контактная информация</span>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Юридическое лицо</label>
                            <InputText placeholder="Укажите название" value={data.organization || ""} onChange={(e) => updateData(e.target.value, "organization")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Адрес</label>
                            <InputText
                                placeholder="Пример: 108823, Московская область, г. Подольск, ул. Подольская, д. 1"
                                value={data.address || ""}
                                onChange={(e) => updateData(e.target.value, "address")}
                            />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Телефон</label>
                            <InputMask mask="+7 (999) 999 99 99" placeholder="+7 (___) ___ __ __" value={data.phone || ""} onChange={(e) => updateData(e.target.value, "phone")} />
                        </div>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">ИНН</label>
                                <InputText placeholder="Укажите ИНН" value={data.inn || ""} onChange={(e) => e.target.value?.length < 13 && updateData(e.target.value, "inn")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Email</label>
                                <InputText placeholder="Укажите почту" value={data.email || ""} onChange={(e) => updateData(e.target.value, "email")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <span className="clubs-profile__header-title">Внешние ресурсы</span>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Сообщество ВКонтакте</label>
                            <InputText placeholder="Вставьте ссылку" value={data.outerLinks?.vk || ""} onChange={(e) => updateData(e.target.value, "outerLinks", "vk")} />
                        </div>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Канал в Telegram</label>
                                <InputText placeholder="Вставьте ссылку" value={data.outerLinks?.tg || ""} onChange={(e) => updateData(e.target.value, "outerLinks", "tg")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Канал Youtube</label>
                                <InputText placeholder="Вставьте ссылку" value={data.outerLinks?.yt || ""} onChange={(e) => updateData(e.target.value, "outerLinks", "yt")} />
                            </div>
                        </div>
                    </div>
                </div>
                <Button className="clubs-detail__button" label="Сохранить" onClick={handleSave} disabled={!disabledBtn} />
            </div>
        )
    );
};

export default Profile;
