import React, { useState, useEffect } from 'react'

import SendEmail from './SendEmail' //это путь регистрации
import ChangePass from './ChangePass' //это путь ошибки создания учетной записи

const Recover = ({ }) => {

    const currentUrl = window.location.href;
    const token = getToken(currentUrl) ? getToken(currentUrl) : false

    const [isChange, setIsChange] = useState(false) //true
   
    useEffect(() => {
        setIsChange(!!token)
    }, [token])

    const Specified = isChange ? ChangePass : SendEmail;

    return <Specified token={token}/>
}

const getToken = (url) => {
    const arr = url?.split('?')
    const token = arr[arr.length - 1]
    if(token.includes('token')) {
        return token.split('=')[1]
    }
}

export default Recover