import React from 'react'

import EmailPassword from "./EmailPassword";
import Response from "./Response";

const OldUser = () => {

    const currentUrl = window.location.href;
    const token = getToken(currentUrl) ? getToken(currentUrl) : false

    const Specified = token ? Response : EmailPassword

    return <Specified token={token}/>
}

const getToken = (url) => {
    const arr = url?.split('?')
    const token = arr[arr.length - 1]
    if(token.includes('token')) {
        return token.split('=')[1]
    }
}

export default OldUser