import React, { useEffect, useRef, useState } from "react";

import RoundCalendar from "./Round";
import GroupsCalendar from "./Groups";
import PlayoffCalendar from "./Playoff";

import "./style.scss";

const Calendar = ({ current, state, toast, grid, updateCurrent, isFinished }) => {
    const Specified = wrap[current?.type];

    return (
        <div className="calendar-structure">
            <Specified current={current} state={state} toast={toast} grid={grid} updateCurrent={updateCurrent} isFinished={isFinished}/>
        </div>
    );
};

export default Calendar;

const wrap = {
    round: RoundCalendar,
    groups: GroupsCalendar,
    playoff: PlayoffCalendar
}
