import React, {useEffect, useState} from "react";

import {Button} from "primereact/button";

import ItemSchemaPlayer from "../../../../../../MatchEditModal/ItemSchemaPlayer";

import './style.scss'

const Lineup = ({ lineup, arrBasic, updateArrBasic }) => {
    const limit = ((lineup.formation.split('-').join('')).split('').reduce((a, b) => a + parseInt(b), 0));
    const arrSchema = lineup.formation.split('-').map(item => +item);

    useEffect(() => {
        if (!(arrBasic?.length > 0)) {
            updateArrBasic(mapperGetActive(fillArray(limit)))
        }
    }, [arrBasic]);

    return (
        <div className="team-detail__card">
            <div className="team-detail__card-header">
                <span className="team-detail__card-title">Расстановка</span>
                <Button onClick={() => updateArrBasic(mapperGetActive(fillArray(limit)))} className="p-button-danger p-button-outlined" label="Очистить" icon="pi pi-refresh"/>
            </div>
            <div className={`dream-lineup__tactic-img team${arrSchema[3] ? "" : " nomiddls"}`}>
                {!!arrSchema[3] && (
                    <div className="forws">
                        {arrBasic.slice(limit - arrSchema[3], limit).map(item => <ItemSchemaPlayer key={item._id} item={item} active={item.active}/>)}
                    </div>
                )}
                <div className="middles">
                    {arrBasic.slice(arrSchema[1] + 1, arrSchema[3] ? limit - arrSchema[3] : limit).map(item => <ItemSchemaPlayer key={item._id} item={item} active={item.active}/>)}
                </div>
                <div className="defs">
                    {arrBasic.filter((item, ind) => ind > 0 && ind <= arrSchema[1]).map(item => <ItemSchemaPlayer key={item._id} item={item} active={item.active}/>)}
                </div>
                <div className="keeper">
                    {arrBasic.filter((item, ind) => ind === 0).map(item => <ItemSchemaPlayer key={item._id} item={item} active={item.active}/>)}
                </div>
            </div>
        </div>
    )
}

const fillArray = len => {
    const res = [];
    for (let i = 0; i < len; i++) {
        res.push({_id: `plr_${i}`, indx: i})
    }
    return res;
}

const mapperGetActive = arr => {
    let checkFirst = false;
    const res = [];
    for (let item of arr) {
        if (item.surname || item.name|| item.num) {
            res.push(item);
        } else {
            if (!checkFirst) {
                res.push({...item, active: true});
                checkFirst = true
            } else {
                res.push(item);
            }
        }
    }
    return res;
}

export default Lineup;