import React, { useEffect, useRef, useState } from "react";

import {getByUrl} from "../../../../../../v3MethodsService";

import Structure from "./Structure";
import Calendar from "./Calendar";

import { SelectButton } from "primereact/selectbutton";
import {TabMenu} from "primereact/tabmenu";

import "./style.scss";

const CalendarDetail = ({ current, state, toast, onClose, updateCurrent }) => {

    const [activeIdx, setActiveIdx] = useState(0);
    const [value, setValue] = useState(options[0]);

    const [parent, setParent] = useState(null);

    useEffect(() => {
        if (current?.parentId) {
            getByUrl(`stages/${current.parentId}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setParent(resp.data)
                    }
                })
        }
    }, [current?.parentId]);

    const typeData = (type) => {
        switch (type) {
            case "round":
                return <div className="tournamets2-calendar-list__row circle">Матчи в круг</div>;
            case "groups":
                return <div className="tournamets2-calendar-list__row group">Групповой этап</div>;
            case "playoff":
                return <div className="tournamets2-calendar-list__row playoff">Плейофф</div>;
            default:
                return null;
        }
    };

    const isFinished = !!(current && current.finished)

    const Specified = wrap[value.key] ? wrap[value.key] : wrap["structure"];

    return !!current && (
        <div className="calendar-detail">
            <div className="calendar-detail__header">
                <div className='calendar-detail__select'>
                    {!(parent && !parent.finished) && <SelectButton value={value} onChange={e => e.value ? setValue(e.value) : null} options={options} optionLabel="name"/>}
                    <div className='calendar-detail__tags'>
                        {!!current.finished && <div className="tournamets2-calendar-list__row finished">Завершена</div>}
                        {typeData(current.type)}
                    </div>
                </div>
                {current.type === 'playoff' && value.key === 'calendar' && current.playoffgrids && (
                    <div className='calendar-detail__tabmenu'>
                        <TabMenu activeIndex={activeIdx} onTabChange={e => setActiveIdx(e.index)} model={current?.playoffgrids?.map(p => ({label: p.name}))} optionLabel="name"/>
                    </div>
                )}
            </div>

            <Specified type={current.type} state={state} current={current} toast={toast} grid={current?.playoffgrids?.[activeIdx]} updateCurrent={updateCurrent} isFinished={isFinished}/>
        </div>
    );
};

export default CalendarDetail;

const wrap = {
    structure: Structure,
    calendar: Calendar,
};

const options = [
    {name: "Структура", key: "structure"},
    {name: "Календарь", key: "calendar"},
];
