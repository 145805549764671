import './style.scss'

import React, {useRef} from "react";

import {Toast} from "primereact/toast";

import Form from "./Form";
import SideNotes from "../SideNotes";

const Support = () => {
    const toast = useRef(null)

    return <div className='support'>
        <Toast ref={toast} position='bottom-right'/>

        <div className='support__form'>
            <Form/>
        </div>

        <div className='support__side-notes'>
            <SideNotes
                style={{width: '100%'}}
                icon='support'
                content={(
                    <ul role="list" className={'text-group__text'}>
                        <li>
                            В случае, если у вас наблюдаются проблемы при работе с системой
                            или возникает необходимость получения консультации по системе,
                            вы можете обратиться в техническую поддержку:
                        </li>
                        <li>
                            или позвонить на телефон <br/>
                            <span
                                onClick={() => {
                                    navigator.clipboard.writeText('8-800-775-75-20')
                                    toast.current.show({severity: 'success', detail: 'Номер поддержки скопирован', life: 3000});
                                }}
                            >
                                8-800-775-75-20
                            </span> (звонок по России бесплатный), выбор цифры 3
                        </li>
                    </ul>
                )}
            />
        </div>
    </div>
}

export default Support