import React, {useRef, useState} from "react"

import {Toast} from "primereact/toast";

import TournamentsRequestsDetail from "./Detail";
import TournamentsRequestsList from "./List";

import './style.scss'

const TournamentsRequests = () => {
    const toast = useRef(null)
    const param = window.location.pathname.split('/')
    const [reqPath, setReqPath] = useState('queries')

    const [copyData, setCopyData] = useState([])
    
    const Specified = param && param[2] ? wrap.detail : wrap.list;

    return (
        <div className='tournamentsV2'>
            <Toast ref={toast} position="top-right"/>
            <Specified toast={toast.current} setCopyData={setCopyData} copyData={copyData} reqPath={reqPath} updateReqPath={setReqPath}/>
        </div>
    )
}

const wrap = {
    detail: TournamentsRequestsDetail,
    list: TournamentsRequestsList
}

export default TournamentsRequests