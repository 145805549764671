import React from "react";

import {Calendar} from "primereact/calendar";
import { addLocale } from 'primereact/api';

import './style.scss'

const CalendarUi = ({ value = null, onChange = (any) => {}, placeholder = '', label = '', id = 'date', className = '', type = '', timeOnly = false, disabled = false, maxDate = null, minDate = null, invalid = false, dateFormat = 'dd/mm/yy', mask = null, keepInvalid = false, showOnFocus = true, view = 'date' }) => {
    return (
        <div className={`input-container ${className}`}>
            {label ? <label htmlFor={id} className='input-container__label'>{label}</label> : null}
            <Calendar
                id={id}
                value={value ? new Date(value) : ''}
                onChange={onChange}
                placeholder={placeholder}
                className={`input ${type} ${invalid ? 'p-invalid' : ''}`}
                showIcon={timeOnly ? false : true}
                disabled={disabled}
                locale={'ru'}
                dateFormat={dateFormat}
                maxDate={maxDate}
                minDate={minDate}
                // mask={mask}
                keepInvalid={keepInvalid}
                showOnFocus={showOnFocus}
                view={view}
                timeOnly={timeOnly}
                hourFormat="24"
            />
        </div>
    )
}

addLocale('ru', {
    firstDayOfWeek: 1,
    dayNames: ['воскресенье','понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'],
    dayNamesShort: ['вос', 'пон', 'вто', 'сре', 'чет', 'пят', 'суб'],
    dayNamesMin: ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'],
    monthNames: ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'],
    monthNamesShort: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
    today: 'Сегодня',
    clear: 'Очистить',
});

export default CalendarUi