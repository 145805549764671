import React, {useContext, useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import moment from "moment/moment";
import {deleteByUrl, getByUrl} from "../../../../../v3MethodsService";
import axios from "axios";
import {GlobalContext} from "../../../../../ctx";
import {FANTASY} from "../../../../../env";

import DataTable from "../../../Common/DataTable"

import Sidebar from "./Sidebar";

import {ProgressSpinner} from "primereact/progressspinner";
import {Toast} from "primereact/toast";

import "./style.scss";

const Fantasy = ({ archived }) => {
    const history = useHistory();
    const location = useLocation();
    const toast = useRef(null);

    const [global, setGlobal] = useContext(GlobalContext)
    const tkn = global && global.auth && global.auth.token ? global.auth.token : ''

    const [data, setData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        getFantasy()
    }, []);

    useEffect(() => {
        if (location && location.search && location.search === '?create=newFantasy') {
            setSelectedItem(initialFantasy)
            history.replace(location.pathname)
        }
    }, [location]);

    const getFantasy = () => {
        setLoader(true)
        getByUrl('betseasons')
            .then(resp => {
                if (resp && resp.success) {
                    setData(resp.data)
                }
                setLoader(false)
            })
    }

    const handleClick = (value) => {
        setSelectedItem(value.data)
    }

    const closeHandler = () => {
        setSelectedItem(null)
        getFantasy()
    }

    const handleDownload = (e, item) => {
        e.stopPropagation()
        axios.get(`${FANTASY}book/getSeasonRatings/${item._id}`, {
            headers: {
                Authorization: tkn
            },
            responseType: 'blob'
        })
            .then(resp => {
                if (resp && resp.status === 200) {
                    const url = window.URL.createObjectURL(new Blob([resp.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `season_${item.title}_ratings.xls`);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                    window.URL.revokeObjectURL(url);
                } else {
                    toast.current.show({ severity: 'error', detail: resp?.message || 'Произошла ошибка' })
                }
            })
            .catch(err => {
                console.log(err)
                toast.current.show({ severity: 'error', detail: err?.message || 'Произошла ошибка' });
            });
    }

    const handleRemove = (e, item) => {
        e.stopPropagation()
        deleteByUrl(`betseasons/${item._id}`)
            .then(resp => {
                if (resp && resp.success) {
                    getFantasy()
                } else {
                    toast.current.show({ severity: 'error', detail: resp?.message || 'Произошла ошибка' })
                }
            })
            .catch(err => {
                console.log(err)
                toast.current.show({ severity: 'error', detail: err?.message || 'Произошла ошибка' });
            });
    }

    return (
        <div className='fantasy clubs-application'>
            <Toast ref={toast} position="top-right"/>
            {loader ? <ProgressSpinner style={{ width: '100px', height: '100px' }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className='loader' /> : null}
            <Sidebar
                state={selectedItem}
                onClose={closeHandler}
                toast={toast && toast.current}
            />
            {!!(data && data.length > 0) && (
                <DataTable
                    noCreateBtn
                    emptyMessage="Документы не найдены"
                    search
                    onClick={handleClick}
                    columns={[
                        { field: "_name", header: "Название" },
                        { field: "_dateStart", header: "Дата начала" },
                        { field: "_dateEnd", header: "Дата окончания" },
                    ]}
                    rows={data
                        ?.filter((item) => {
                            if (archived.key === "archived") {
                                return !item.active;
                            } else {
                                return item.active;
                            }
                        })
                        // .sort((a, b) => {
                        //     return data.docs?.indexOf(a._id) - data.docs?.indexOf(b._id);
                        // })
                        .map((row) => ({
                            ...row,
                            _name: { value: row?.title},
                            _dateStart: { value: moment(row?.dateStart, "YY-MM-DD").format("DD.MM.YYYY") },
                            _dateEnd: { value: moment(row?.dateEnd, "YY-MM-DD").format("DD.MM.YYYY") },
                        }))}
                    paginator={data.length > 8}
                    perPage={8}
                    handleRemove={handleRemove}
                    handleDownload={handleDownload}
                />
            )}
        </div>
    )
}

const initialFantasy = {
    "title": "", //необязательно
    "description": "",//необязательно
    "dateStart": null,
    "dateEnd": null,
    "balance": 1000
}

export default Fantasy