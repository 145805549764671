import React, {useEffect, useState} from "react";

import './style.scss'

const CircleToCircle = ({ stage, data, updateData, isFinished }) => {

    const places = data && data.rules ? data.rules.map(r => r.place) : []

    const updateSelected = (i) => {
        if (isFinished) return;
        if (places.includes(i)) {
            updateData(data.rules.filter(p => p.place !== i), 'rules')
            return
        }
        const newSelected = data?.rules ? [...data.rules] : []
        newSelected.push({place: i})
        updateData(newSelected, 'rules')
    }

    return (
        <div className='circle-to-circle'>
            <div className='circle-to-circle__header'>Выберите команды по итогам предыдущей стадии</div>
            <div className='circle-to-circle__content'>
                {Array.from({ length: stage.teams?.length || 0 }, (_, i) => (
                    <div key={i} onClick={() => updateSelected(i + 1)} className={`circle-to-circle__team ${places.includes(i + 1) ? 'circle-to-circle__team_selected' : ''}`}>
                        {i + 1} <span>место</span>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CircleToCircle;