import React, { useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";
import "./style.scss";
import moment from "moment";
import DataTable from "../../Common/DataTable";

const Documents = ({ data, archived, handleItem, deleteHandler }) => {
    const handleRemove = (e, item) => {
        e.stopPropagation();
        deleteHandler(item?._id);
    };

    const handleDownload = (e, item) => {
        e.stopPropagation();
        window.open(item?.fullpath);
    };

    return (
        data && (
            <div className="clubs-application">
                <DataTable
                    noCreateBtn
                    emptyMessage="Документы не найдены"
                    search
                    onClick={handleItem}
                    columns={[
                        { field: "name", header: "Название" },
                        { field: "file", header: "Оригинальный файл" },
                        { field: "author", header: "Автор" },
                        { field: "uploaded", header: "Загружен" },
                    ]}
                    rows={data
                        ?.filter((item) => {
                            if (archived.key === "archived") {
                                return item.archived;
                            } else {
                                return !item.archived;
                            }
                        })
                        .sort((a, b) => {
                            return data.docs?.indexOf(a._id) - data.docs?.indexOf(b._id);
                        })
                        .map((row) => ({
                            ...row,
                            name: { value: row?.publicname },
                            file: { value: row?.filename },
                            author: {
                                value: `${row?.creator?.surname ? row?.creator?.surname : ""} ${row?.creator?.name ? row?.creator?.name.slice(0, 1) + "." : ""}${
                                    row?.creator?.middlename ? row?.creator?.middlename.slice(0, 1) + "." : ""
                                }`,
                            },
                            uploaded: { value: moment(row?.createDate, "YY-MM-DD").format("DD.MM.YYYY") },
                        }))}
                    paginator={data.length > 8}
                    perPage={8}
                    handleRemove={handleRemove}
                    handleDownload={handleDownload}
                />
            </div>
        )
    );
};

export default Documents;
