import { useRef, useContext, useState } from "react";
import { getByUrl, postByUrl } from "../../../../../../v3MethodsService";
import { FileUpload } from "primereact/fileupload";
import { pluralForm } from "../../../../../../utils";
import { GlobalContext } from "../../../../../../ctx";

import './style.scss'

const FilesUploadUi = ({
    onChange = (any) => { },
    label = "",
    mutedLabel = "",
    minFileSize = 81,
    maxFileSize = 838860800,
    accept,
    className = "",
    multiple = false,
    filesLimit = 50,
    remainingSpace,
    queue = 5,
    toast,
    data,
}) => {
    const fileUploadRef = useRef(null);
    const [global, setGlobal] = useContext(GlobalContext)
    const token = localStorage.getItem('_amateum_auth_tkn')
    const [loader, setLoader] = useState(false)

    const role = global?.profile?.scopes?.find(f => f.token == token) || null

    const uploadHandler = (event) => {
        event.options.clear();
    };

    const delay = (sec) => new Promise(resolve => setTimeout(resolve, sec))

    const onSelect = async (event) => {
        if (event.files.length) {
            if (filesLimit && event.files.length > filesLimit) {
                toast.current.show({ severity: "warn", summary: `За один раз можно загрузить не более ${filesLimit} фото` });
            } else if ([...event.files].some((s) => (s.size <= minFileSize || s.size >= maxFileSize) && !s.name.includes("jest_sample_photo"))) {
                toast.current.show({ severity: "warn", summary: "Минимальный размер 100 Кб, максимальный размер 10 Мб" });
            } else if ([...event.files].reduce((acc, val) => acc + val.size, 0) > remainingSpace) {
                toast.current.show({ severity: "warn", summary: "На диске недостаточно свободного места" });
            } else {
                if (queue) {
                    const filesArr = [...event.files];
                    const loaderData = { fileCount: 0, fileLength: filesArr.length };

                    for (let i = 0; i < filesArr.length; i += queue) {
                        if (!(i % queue)) {
                            const formData = new FormData();
                            formData.append('sampleId', data._id)
                            formData.append('sampleType', role.type == 'league' ? 'leagues' : 'clubs')
                            formData.append('isPublished', true)
                            const secondIdx = i + queue;
                            const firstIdx = i ? secondIdx - queue : 0;
                            const sendData = filesArr.slice(firstIdx, secondIdx);

                            sendData.forEach((f, fIdx) => formData.append(`file${fIdx}`, f));

                            try {
                                const resp = await postByUrl(`medias`, formData, false)
                                if (resp.success) {
                                    const arr = Object.values(resp.data).map((f) => {
                                        // if (f.success) return { name: f.data.filename, file: f.data.path };
                                        if (f.success) return f.data;
                                    });

                                    loaderData.fileCount += arr.length;
                                    // mainStore.setMainStore("loader", loaderData);
                                    setLoader(true)

                                    if (loaderData.fileCount == filesArr.length) {
                                        await delay(1000);
                                    }
                                    getByUrl(`medias?sampleId=${data._id}&sampleType=${role.type == 'league' ? 'leagues' : 'clubs'}&isPublished=true`).then((resp) => {
                                        if (resp && resp.success && resp.data) {
                                            onChange(resp.data);
                                        }
                                    });

                                } else {
                                    console.log("error upload");
                                    toast?.current.show({ severity: "warning", summary: 'Что-то пошло не так', detail: `Загружено - ${loaderData.fileCount} файл${pluralForm(loaderData.fileCount, ['', 'а', 'ов'])}` });
                                    break;
                                }
                            } catch (error) {
                                console.log("error upload catch", error);
                                toast?.current.show({ severity: "warning", summary: 'Что-то пошло не так', detail: `Загружено - ${loaderData.fileCount} файл${pluralForm(loaderData.fileCount, ['', 'а', 'ов'])}` });
                                break;
                            }
                        }
                    }
                    toast?.current.show({ severity: "success", summary: `Загружено - ${loaderData.fileCount} файл${pluralForm(loaderData.fileCount, ['', 'а', 'ов'])}` });
                    // mainStore.setMainStore("loader", false);
                    setLoader(false)
                } else {
                    const formData = new FormData();
                    formData.append('sampleId', data._id);
                    formData.append('sampleType', 'clubs');
                    formData.append('isPublished', true);
                    [...event.files].forEach((f, fIdx) => formData.append(`file${fIdx}`, f));

                    try {
                        const resp = await postByUrl(`medias`, formData, false)

                        if (resp.success) {
                            const arr = Object.values(resp.data).map((f) => {
                                // if (f.success) return { name: f.data.filename, file: f.data.path };
                                if (f.success) return f.data;
                            });
                            getByUrl(`medias?sampleId=${data._id}&sampleType=clubs&isPublished=true`).then((resp) => {
                                if (resp && resp.success && resp.data) {
                                    onChange(resp.data);
                                }
                            });
                            toast.current.show({ severity: "success", summary: `Файлы успешно загружены` });
                        } else {
                            toast.current.show({ severity: "warning", summary: `error upload` });
                        }
                    } catch (error) {
                        console.log("error upload", error);
                    }
                }
            }
        }

        fileUploadRef.current.clear();
    };

    const headerTemplate = (options) => {
        const { className, chooseButton } = options;
        
        return (
            <div className={className} >
                {loader ? <div className="choose__loader"><i className="pi pi-spin pi-spinner"></i></div> : chooseButton}
            </div>
        );
    };

    const chooseOptions = { label: "Загрузить", icon: "pi pi-fw pi-plus"};

    return (
        <div className={`fileupload mediaBank ${className}`}>
            <div className="fileupload__field">
                {label ? (
                    <label htmlFor="comment" className="fileupload__label">
                        {label} <span>{mutedLabel}</span>
                    </label>
                ) : null}
                <FileUpload
                    ref={fileUploadRef}
                    // mode={"basic"}
                    accept={accept ? accept : ".jpeg, .jpg, .png, .svg"}
                    onSelect={(event) => (onSelect(event))}
                    customUpload
                    minFileSize={81920}
                    maxFileSize={838860800}
                    uploadHandler={uploadHandler}
                    chooseOptions={chooseOptions}
                    // chooseLabel="Загрузить"
                    className={`fileupload__input`}
                    multiple={multiple}
                    headerTemplate={headerTemplate}
                />
            </div>
        </div>
    );
}

export default FilesUploadUi