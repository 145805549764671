import React, { useEffect, useRef, useState } from "react";

import service from "../../../../../../../Tournaments/service";
import {clearToast, diffData} from "../../../../../../../../utils";
import {getByUrl, putByUrl} from "../../../../../../../../v3MethodsService";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import {InputNumber} from "primereact/inputnumber";
import {SelectButton} from "primereact/selectbutton";
import {Dropdown} from "primereact/dropdown";
import {confirmDialog} from "primereact/confirmdialog";

import ManualPlayoff from "./Manual";
import AutoPlayoff from "./Auto";

import "./style.scss";

const PlayoffStructure = ({ current, state, toast, updateCurrent, isFinished }) => {
    const [timeoutID, setTimeoutID] = useState(null);
    const [data, setData] = useState(null);
    // const [filteredTeams, setFilteredTeams] = useState([]);
    const [isUpdate, setIsUpdate] = useState(true);

    const [loading, setLoading] = useState(false)
    const [selectedStage, setSelectedStage] = useState(null)
    const [stages, setStages] = useState([])
    const [sending, setSending] = useState(false)
    const [isChanged, setIsChanged] = useState(false)
    const [mode, setMode] = useState('manual')

    const isDiff = diffData(data, state)

    const unassigned = state?.teams
        .filter(t => !data?.teams?.map(c => c._id).includes(t._id))

    const assigned = state?.teams
        .filter(t => data?.teams?.map(c => c._id).includes(t._id))

    useEffect(() => {
        if (data && data.parentId && !selectedStage && stages.length > 0) {
            setSelectedStage(stages.find(s => s.value._id === data.parentId).value)
        }
    }, [data, stages]);

    useEffect(() => {
        if (mode === 'auto' && state) {
            setLoading(true)
            getByUrl(`stages?tournamentId=${state._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setStages(resp.data.filter(r => ['groups', 'round'].includes(r.type) && r._id !== data._id).map(r => ({label: r.title, value: r})))
                    }
                    setLoading(false)
                })
        }
    }, [mode]);

    useEffect(() => {
        if (current && !data) {
            setData(current)
            if (current.parentId) {
                setMode('auto')
            }
        }
    }, [current]);

    useEffect(() => {
        if (data && isDiff) {
            updateCurrent(data)
        }
    }, [isDiff]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData((prev) => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else if (key) {
            setData((prev) => ({ ...prev, [key]: val }));
        } else {
            setData(val);
        }
    };

    // const searchTeam = (event) => {
    //     const filtered = unassigned.filter(team => team.name?.toLowerCase().includes(event.query.toLowerCase()));
    //     setFilteredTeams(filtered);
    // };

    const addAllTeams = async () => {
        await updateTeams(0, data.playoffgrids[0]?._id, [...data?.teams, ...unassigned.map(t => ({_id: t._id}))]);
    };

    const updateTeams = async (idx, pid, value) => {
        const mapped = value.map(t => ({_id: t._id}))
        setData({...data, teams: idx === 0 ? mapped : data.playoffgrids[0]?.teams, playoffgrids: data.playoffgrids.map((g, i) => i === idx ? {...g, teams: mapped} : g)})
        if (pid) {
            await service.patchData(pid, 'playoffgrids', {teams: mapped}, toast)
            clearToast(toast, timeoutID, setTimeoutID)
        }
    }

    const assignTeams = arr => arr.map(t => state.teams.find(_t => _t._id === t._id))

    const handleBlur = async (item, val) => {
        setIsUpdate(false);
        await service.patchData(item._id, 'playoffgrids', {name: val}, toast);
    };

    const updateForm = (key, val, idx) => {
        const newPlayoffs = [...data.playoffgrids];
        newPlayoffs[idx][key] = val;
        updateData(newPlayoffs, 'playoffgrids');
    };

    const renderTitleOrInput = (data, idx) => isUpdate === idx ? (
        <input value={data.name} className="playoff-structure__name" onChange={(e) => updateForm("name", e.target.value, idx)} onBlur={(e) => handleBlur(data, e.target.value)} autoFocus placeholder="Название раунда" />
    ) : (
        <span className="playoff-structure__name-container">
            <span className="playoff-structure__name">{data.name}</span>
            <i className="pi pi-pencil" onClick={() => setIsUpdate(idx)}></i>
        </span>
    );

    const addRound = async () => {
        const newRound = {...initGrid, playoffId: data._id}
        const upd = await service.postGrid(newRound, toast)
        const updated = {...newRound, ...upd}
        const added = data.playoffgrids ? [...data.playoffgrids, updated] : [updated];
        updateData(added, 'playoffgrids')
    };

    const removeGrid = async (form) => {
        const filtered = data.playoffgrids.filter((g, i) => g._id !== form._id)
        await service.removeGroup(form._id, toast, 'playoffgrids')
        updateData(filtered, 'playoffgrids')
    }

    const sendTitle = async () => {
        await service.updateStage(data._id, {title: data?.title}, toast)
    }

    const sendRounds = async val => {
        if (data.roundsQty !== val) {
            await service.updateStage(data._id, {roundsQty: val}, toast)
            updateData(val, 'roundsQty')
        }
    }

    const handleSave = () => {
        setSending(true)
        const body = { parentId: data.parentId, rules: data.playoffgrids[0]?.rules }
        putByUrl(`stages/${data._id}`, body)
            .then(async resp => {
                if (resp && resp.success && toast) {
                    updateData({...data, extractedTeams: resp.data.extractedTeams, teams: resp.data.teams, rules: resp.data.rules})
                    for (const playoffgrid of data.playoffgrids) {
                        const bodyPlayoffgrid = { parentId: playoffgrid.parentId || data.parentId, rules: playoffgrid.rules }
                        await putByUrl(`playoffgrids/${playoffgrid._id}`, bodyPlayoffgrid)
                    }
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Данные сохранены!', life: 1000})
                }
                setSending(false)
                setIsChanged(false)
            })
    }

    const handleSelect = (value) => {
        _updateData({...data, parentId: value._id, rules: [], playoffgrids: data.playoffgrids?.map(g => ({...g, playoffgrids: []})) || []})
        setSelectedStage(value)
    }

    const _updateData = (val, key) => {
        updateData(val, key)
        setIsChanged(true)
    }

    const handleFinish = () => {
        confirmDialog({
            message: 'Это действие нельзя отменить. Вы точно хотите завершить стадию?',
            header: 'Внимание!',
            icon: 'pi pi-exclamation-triangle',
            accept: acceptFinish,
            acceptLabel: 'Да',
            rejectLabel: 'Нет'
        });
    }

    const acceptFinish = () => {
        putByUrl(`stages/${data._id}`, {finished: true})
            .then(resp => {
                if (resp && resp.success && toast) {
                    updateData(resp.data)
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Стадия завершена!', life: 1000})
                }
            })
    }

    const changeMode = (mode) => {
        if (mode === 'manual' && data && ((data.rules && data.rules.length > 0) || data.parentId)) {
            confirmDialog({
                message: 'После смены режима редактирования будут удалены все настройки наследования',
                header: 'Выбрать ручной режим?',
                icon: 'pi pi-info-circle',
                defaultFocus: 'reject',
                acceptClassName: 'p-button-danger',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                accept: changeModeToManual,
            });
            return
        }
        setMode(mode)
    }

    const changeModeToManual = () => {
        const body = { parentId: null, rules: null, matchdays: [], teams: [], extractedTeams: [], playoffgrids: [] }
        putByUrl(`stages/${data._id}`, body)
            .then(resp => {
                if (resp && resp.success && toast) {
                    updateData(resp.data)
                    setMode('manual')
                    setSelectedStage(null)
                    toast.show({severity: 'success', summary: 'Успешно', detail: 'Настройки удалены!', life: 1000})
                }
            })
    }

    const disabledSave = !isChanged || sending

    const Specified = mode ? wrap[mode] : wrap.manual

    return !!data && (
        <div className="calendar-structure">
            <div className="playoff-structure">
                <div className="playoff-structure__card">
                    <div className="playoff-structure__container">
                        <span className="playoff-structure__title">Основная информация</span>
                    </div>
                    <div className="playoff-structure__container">
                        <div className="playoff-structure__input">
                            <label className="playoff-structure__input-label">Название стадии*</label>
                            <InputText value={data.title} onChange={e => updateData(e.target.value, 'title')} onBlur={sendTitle} placeholder="Укажите название" disabled={isFinished}/>
                        </div>
                        <div className="playoff-structure__input">
                            <label className="playoff-structure__input-label">Количество матчей в паре*</label>
                            <InputNumber value={data.roundsQty} onValueChange={e => sendRounds(e.target.value)} placeholder="Укажите количество " disabled={isFinished}/>
                        </div>
                    </div>
                    {state?.stages?.length > 1 && <SelectButton value={mode} onChange={e => e.value ? changeMode(e.value) : null} options={modeOptions} optionLabel="label" disabled={isFinished}/>}
                    {mode === 'auto' && (
                        <div className='auto-circle__select'>
                            <div className='auto-circle__select-label'>Из какой стадии наследуются*</div>
                            <Dropdown value={selectedStage} onChange={e => handleSelect(e.value)} options={stages} placeholder='Выберите стадию' className='auto-circle__select-dropdown' disabled={isFinished}/>
                        </div>
                    )}
                </div>

                {loading ? <i className="pi pi-spin pi-spinner" /> : (
                    <Specified
                        data={data}
                        renderTitleOrInput={renderTitleOrInput}
                        state={state}
                        removeGrid={removeGrid}
                        addAllTeams={addAllTeams}
                        assignTeams={assignTeams}
                        updateTeams={updateTeams}
                        selectedStage={selectedStage}
                        updateData={_updateData}
                        isFinished={isFinished}
                    />
                )}

                <div className="playoff-structure__btn">
                    {isChanged && <Button label="Сохранить" onClick={handleSave} disabled={disabledSave || isFinished} loading={sending}/>}
                    <Button
                        label="Добавить раунд"
                        onClick={addRound}
                        disabled={isFinished}
                        // disabled={data?.playoffgrids?.slice(-1)[0] && !(data?.playoffgrids?.slice(-1)[0]?.teams?.length >= 2)}
                    />
                    {!isFinished && <Button onClick={handleFinish} label="Завершить стадию" className="p-button-warning"/>}
                    {/*<Button label="Удалить" className="p-button-danger" />*/}
                </div>
            </div>
        </div>
    );
};

const wrap = {
    manual: ManualPlayoff,
    auto: AutoPlayoff
}

const modeOptions = [{label: 'Ручной режим', value: 'manual'}, {label: 'Наследовать из другой стадии', value: 'auto'}]

const initGrid = {
    name: 'Безымянный раунд',
    type: 'playoff',
    teams: [],
    roundsQty: 1,
    _id: 'newGrid'
}

export default PlayoffStructure;
