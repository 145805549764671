import {useEffect, useState} from "react";

import {getByUrl} from "../../../../../../../../../../v3MethodsService";

import {Divider} from "primereact/divider";

import './style.scss'

const GroupToCircle = ({ stage, data, updateData, isFinished }) => {

    const [groups, setGroups] = useState([])

    const places = data && data.rules ? data.rules : []

    useEffect(() => {
        if (stage && stage._id) {
            getByUrl(`groups?stageId=${stage._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        setGroups(resp.data.filter(r => r.teams?.length > 0))
                    }
                })
        }
    }, [stage]);

    const updateSelected = (i, g) => {
        if (isFinished) return;
        if (places.find(p => p.groupId === g._id && p.place === i)) {
            updateData(data.rules.filter(p => !(p.groupId === g._id && p.place === i)), 'rules')
            return
        }
        const newRules = data?.rules ? [...data.rules] : []
        newRules.push({ groupId: g._id, groupName: g.name, place: i })
        updateData(newRules, 'rules')
    }

    return (
        <div className='group-to-circle'>
            <div className='circle-to-circle__header'>Выберите команды по итогам предыдущей стадии</div>
            <div className='group-to-circle__content'>
                {groups?.map((g, gi) => [
                    <div key={gi} className='group-to-circle__container'>
                        <div className='group-to-circle__group-label'>{g.name}</div>
                        <div className='circle-to-circle__content'>
                            {Array.from({ length: g.teams?.length }, (_, i) => (
                                <div key={i} onClick={() => updateSelected(i + 1, g)} className={`group-to-circle__team circle-to-circle__team ${places?.find(p => p.groupId === g._id && p.place === i + 1) ? 'circle-to-circle__team_selected' : ''}`}>
                                    {i + 1} <span>место</span>
                                </div>
                            ))}
                        </div>
                    </div>,
                    gi !== groups?.length - 1 && <Divider key={`divider-${gi}`} />
                ])}
            </div>
        </div>
    )
}

export default GroupToCircle;