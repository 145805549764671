import React, {useState} from "react";

import {Divider} from "primereact/divider";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import './style.scss'

const PlayoffToPlayoff = () => {
    const [pairs, setPairs] = useState([...initPairs])

    return (
        <div className='playoff-to-playoff circle-to-playoff'>
            {pairs.map((p, k) => [
                <div key={k} className='circle-to-playoff__pair'>
                    <div className='circle-to-playoff__label'>
                        Пара {k + 1}
                    </div>
                    <div className='circle-to-playoff__selects'>
                        <Dropdown placeholder='Выберите победителя/проигравшего' className='circle-to-playoff__dropdown'/>
                        <span>vs</span>
                        <Dropdown placeholder='Выберите победителя/проигравшего' className='circle-to-playoff__dropdown'/>
                    </div>
                </div>,
                <Divider key={`divider-${k}`}/>
            ])}
            <Button label='Добавить пару' icon='pi pi-plus' className='circle-to-playoff__button'/>
        </div>
    )
}

const initPairs = [
    {home: '', away: ''},
    {home: '', away: ''},
    {home: '', away: ''},
    {home: '', away: ''}
]

export default PlayoffToPlayoff;