import React, {useRef, useState} from "react"

import {Toast} from "primereact/toast";

import TournamentsV2Detail from "./Detail";
import TournamentsV2List from "./List";

import './style.scss'

const TournamentsV2 = () => {
    const toast = useRef(null)
    const param = window.location.pathname.split('/')

    const Specified = param && param[2] ? wrap.detail : wrap.list;

    return (
        <div className='tournamentsV2'>
            <Toast ref={toast} position="top-right"/>
            <Specified toast={toast.current} />
        </div>
    )
}

const wrap = {
    detail: TournamentsV2Detail,
    list: TournamentsV2List
}

export default TournamentsV2