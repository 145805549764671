import React, { useEffect, useRef, useState } from "react";

import { useHistory } from "react-router-dom";

import { Toast } from "primereact/toast";
import "./style.scss";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import DataTable from "../../Common/DataTable";
import defaultLogo from "../img/defaultLogo.png";
import { getByUrl } from "../../../../v3MethodsService";

const RffList = ({ setDropdown, dropdown = null }) => {
    const history = useHistory();
    const [clubs, setClubs] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        getByUrl(`rffs`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setClubs(resp.data);
            }
        });

        getByUrl('getRefsList?section=regions').then(resp => {
            if (resp && resp.success && resp.data) {
                setOptions(resp.data)
            }
        })

    }, []);

    const createCustomItem = (logo, name) => (
        <div className="clubs-list__table-item">
            <img src={logo ? logo : defaultLogo} /> 
            <span>{name}</span>
        </div>
    );

    const handleClick = (e) => {
        history.replace(`/regions/${e.data._id}`);
    };


    const data = dropdown ? clubs.filter((item) => item._id === dropdown) : clubs;

    return (
        <div className="rff-list">
            <div className="rff-list__header">
                {options ? (
                    <Dropdown
                        value={dropdown}
                        options={options}
                        onChange={(e) => setDropdown(e.value)}
                        emptyMessage={"Ничего не найдено"}
                        emptyFilterMessage={"Ничего не найдено"}
                        optionLabel="name"
                        optionValue="_id"
                        showClear
                        placeholder="Выберите регион"
                        appendTo={"self"}
                    />
                ) : null}
            </div>
            <DataTable
                onClick={handleClick}
                searchPlaceholder="Выберите регион"
                search
                noCreateBtn
                columns={[
                    { field: "name", header: "Название" },
                    { field: "locality", header: "Регион" },
                ]}
                rows={data.map((row) => ({
                    ...row,
                    name: { custom: createCustomItem(row?.federationData?.emblem, row?.federationData?.name), value: row?.federationData?.name },
                    locality: { value: row?.name },
                }))}
                paginator={clubs.length > 8}
                perPage={8}
            />
        </div>
    );
};

export default RffList;
