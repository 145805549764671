import React, { useContext, useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import "./style.scss";
import { SelectButton } from "primereact/selectbutton";

import { Button } from "primereact/button";
import { deleteByUrl, getByUrl, putByUrl } from "../../../v3MethodsService";
import Documents from "./Documents";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";

const archivedOptions = [
    { name: "Опубликованные", key: "active" },
    { name: "Неопубликованные", key: "archived" },
];

const AdminDocs = () => {
    const toast = useRef(null);
    const [archived, setArchived] = useState(archivedOptions[0]);
    const [data, setData] = useState(null);
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [state, setState] = useState(null);
    const [sideBarData, setSideBarData] = useState([]);
    const [sideBarState, setSideBarState] = useState([]);
    const [docs, setDocs] = useState([]);
    const [loader, setLoader] = useState(false);

    const getDocs = () => {
        getByUrl(`platformdocs`).then((resp) => {
            if (resp && resp.success && resp.data) {
                setData(resp.data);
                setState(resp.data);
                setLoader(false);
                setSidebarVisible(false);
            }
        });
    };

    useEffect(() => {
        getDocs();
    }, []);

    const updateSideBar = (val, key) => {
        setSideBarData({ ...sideBarData, [key]: val });
    };

    const handleItem = (e) => {
        if (e.data) {
            let sideData = data.find((item) => item._id === e.data._id);
            setSideBarData(sideData);
            setSideBarState(sideData);
        } else {
            setSideBarData({});
            setSideBarState({});
        }
        setSidebarVisible(true);
    };

    const deleteHandler = (id) => {
        confirmDialog({
            message: "После удаления безвозвратно исчезнет вся информация по этому документу",
            header: "Удалить документ?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px" },
            draggable: false,
            accept: () => {
                removeAttachment(id);
            },
            reject: () => {},
        });
    };

    const removeAttachment = (id, toastItem = true) => {
        deleteByUrl(`platformdocs/${id || sideBarData._id}`).then((resp) => {
            if (resp && resp.success) {
                getDocs();
                if (toastItem) {
                    toast.current.show({ severity: "success", summary: "Документ удален" });
                }
                setSidebarVisible(false);
            } else {
                toast.current.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    return (
        <div className="admin-docs">
            <Toast ref={toast} position="top-right" />
            <SideBar
                sideBarState={sideBarState}
                getDocs={getDocs}
                visible={sidebarVisible}
                setVisible={setSidebarVisible}
                data={sideBarData}
                state={state}
                updateData={updateSideBar}
                setData={setSideBarData}
                toast={toast.current}
                docs={docs}
                setDocs={setDocs}
                deleteHandler={deleteHandler}
                removeAttachment={removeAttachment}
                loader={loader}
                setLoader={setLoader}
            />
            <div className="admin-docs__header">
                <SelectButton
                    options={archivedOptions}
                    value={archived}
                    onChange={(e) => {
                        if (e.value) setArchived(e.value);
                    }}
                    optionLabel="name"
                />
                <Button icon="pi pi-plus" label="Добавить документ" onClick={handleItem} />
            </div>
            <Documents data={data} handleItem={handleItem} archived={archived} deleteHandler={deleteHandler} />
        </div>
    );
};

export default AdminDocs;
