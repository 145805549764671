import React, { useState, useEffect } from "react";

import { Card } from 'primereact/card';

import './style.scss'

const CardUi = ({ header = null, footer = null, content = null, className = '' }) => {
    return (
        <Card header={header} footer={footer} className={`card ${className}`}>
            {content}
        </Card>
    )
}

export default CardUi