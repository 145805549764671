import React, { useContext, useEffect, useRef, useState } from "react";

import moment from "moment";

import { useClickOutside } from "../../../../../utils";
import {postByUrl} from "../../../../../v3MethodsService";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

import "./style.scss";

const SideBar = ({ visible, setVisible, state, toast, addPlayer }) => {
    const ref = useRef();
    useClickOutside(visible, ref, () => setVisible(false));

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setData({})
    }, [visible]);

    const updateData = (val, key, subKey) => {
        if (subKey) {
            setData((prev) => ({ ...prev, [key]: { ...prev[key], [subKey]: val } }));
        } else {
            setData((prev) => ({ ...prev, [key]: val }));
        }
    };

    const savePlayer = () => {
        setLoading(true)
        postByUrl('players', data)
            .then(resp => {
                if (resp && resp.success) {
                    setVisible(false)
                    addPlayer(resp.data)
                    toast?.show({ severity: "success", summary: "Игрок создан", life: 3000 });
                } else {
                    toast?.show({ severity: "error", summary: resp?.message || resp?.msg || "Ошибка", life: 3000 });
                }
                setLoading(false)
            })
    }

    const disabledBtn = data && data.name && data.surname && data.middlename && data.birthday && data.email;

    return (
        !!visible && (
            <div className="side-profile__overlay p-sidebar-mask p-component-overlay p-component-overlay-enter p-sidebar-visible p-sidebar-right">
                <div className="side-profile p-component p-sidebar-enter-done" ref={ref}>
                    <div className="side-profile__header">
                        <div className="side-profile__btns">
                            <Button label="Добавить игрока" className="side-profile__button-save auth2__button" disabled={!disabledBtn || loading} onClick={savePlayer} loading={loading}/>
                        </div>
                    </div>
                    <div className="side-profile__body">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Фамилия*</label>
                            <InputText placeholder="Укажите фамилию" value={data.surname} onChange={(e) => updateData(e.target.value, "surname")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Имя*</label>
                            <InputText placeholder="Укажите имя" value={data.name} onChange={(e) => updateData(e.target.value, "name")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Отчество*</label>
                            <InputText placeholder="Укажите отчество" value={data.middlename} onChange={(e) => updateData(e.target.value, "middlename")} />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Дата рождения*</label>
                            <Calendar
                                placeholder="Выберите дату"
                                value={data.birthday ? moment(data.birthday, "DD.MM.YYYY").toDate() : ""}
                                onChange={(e) => updateData(e.target.value ? moment(e.target.value).format("DD.MM.YYYY") : e.target.value, "birthday")}
                                className={`auth2__calendar`}
                                showIcon
                                locale="ru"
                                dateFormat="dd.mm.yy"
                                maxDate={new Date()}
                                mask="99.99.9999"
                                appendTo="self"
                            />
                        </div>
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Email*</label>
                            <InputText placeholder="Укажите почту" value={data.email} onChange={(e) => updateData(e.target.value, "email")} />
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default SideBar;
