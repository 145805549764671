import React, { useRef, useState } from "react";

import TournamentsRequestsNew from "./New";
import TournamentsRequestsProcessed from "./Processed";

import "./style.scss";

const TournamentsRequestsDetail = ({ toast, copyData, reqPath }) => {
    const param = window.location.pathname.split('/')

    const Specified = param && param[2] && param[2] === 'new' ? wrap.new : wrap.detail;

    return (
        <div className="tournamentsReqests-detail">
            <Specified toast={toast} copyData={copyData} reqPath={reqPath}/>
        </div>
    );
};

const wrap = {
    new: TournamentsRequestsNew,
    detail: TournamentsRequestsProcessed
}

export default TournamentsRequestsDetail;


