import React, {useEffect, useState} from "react";

import {Draggable} from "react-beautiful-dnd";
import {Link, useLocation} from "react-router-dom";
import {Tag} from "primereact/tag";
import moment from "moment/moment";
import styled from "styled-components";
import Ball from '../../../assets/img/soccer-ball.png'
import { Button } from 'primereact/button'

import axios from 'axios';
import { ENDPOINT } from '../../../env'
import {Tooltip} from "primereact/tooltip";
import {getByUrl} from "../../../v3MethodsService";

const Container = styled.div`
  width: ${props => props.isDragDisabled ? 'auto' : '30px'};
  display: grid;
  justify-content: center;
`

function getStyle(style, snapshot) {
    if (!snapshot.isDropAnimating) {
        return style;
    }
    const { moveTo, curve, duration } = snapshot.dropAnimation;
    const translate = `translate(${moveTo.x + 5}px, ${moveTo.y}px)`;
    const rotate = 'rotate(1turn)';

    return {
        ...style,
        transform: `${translate} ${rotate}`,
        opacity: 0,
        transition: `transform ${curve} ${duration + 0.35}s, opacity 0.9s`,
    };
}

const RefinementsDefault = {
    1: 'pi pi-bolt',
    2: 'pi pi-star',
    3: 'pi pi-users',
    4: 'pi pi-id-card'
}

const TooltipForRefinements = {
    1: 'Не заполнен счет',
    2: 'Не заполнены авторы голов',
    3: 'Не заполнен персонал',
    4: 'Не заполнен состав'
}

const MatchItem = ({match, index, subject, setConfirmModal, selectedMatchDay, setSelectedMatchDay}) => {
    const [loading, setLoading] = useState(false);
    let location = useLocation();

    const useRefinements = subject.federationId === '63720deb3d69d811c73373e5'
    const [refinements, setRefinements] = useState([])

    useEffect(() => {
        if (useRefinements) {
            const newRefinements = []
            if (match){
                if (!match.score && match.score === null){
                    newRefinements.push(1)
                } else {
                    if (!match.isFullGoalEvents){
                        newRefinements.push(2)
                    }
                }
                if (!match.employees || match.employees.length === 0){
                    newRefinements.push(3)
                }
                if (!match.rosters || match.rosters.length === 0 || match.rosters.length !== 2 || (match.rosters[0].players === 0 && match.rosters[1].players === 0)){
                    newRefinements.push(4)
                }
                setRefinements(newRefinements)
            }
        }
    }, [match])

    const _downloadSheet = async () => {
        setLoading(true)
        const tkn = localStorage.getItem('_amateum_auth_tkn')

        const body = {
            federationId: null,
            tournamentId: null,
            minDate: null,
            maxDate: null,
            matchId: match._id
        };

        try {
            const response = await axios.post(
                `${ENDPOINT}v2/getZipPdfProtocols`,
                body,
                {responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/octet-stream',
                    'Authorization': tkn
                }}
            )

            if(!response.error && response.succes !== false ) {
                setLoading(false)
                const blob = new Blob([response.data], {type: 'application/octet-stream'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${match.homeName}_${match.awayName}_${moment(match.date, 'YY-MM-DD').format('DD.MM.YY')}.pdf`
                link.click()
            }
        } catch (e) {
            console.log('get protocols failed', e);
        }
    }

    const downloadSheet = async (mode) => {
        setLoading(mode)
        const tkn = localStorage.getItem('_amateum_auth_tkn')

        try {
            const response = await axios.get(`${ENDPOINT}methodsV3/getMatchProtocol/${match._id}${mode  ? `?mode=${mode}` : ''}`, {
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/octet-stream',
                    'Authorization': tkn,
                    "scopetoken": tkn
                }
            })

            if(!response.error && response.succes !== false ) {
                setLoading(false)
                const blob = new Blob([response.data], {type: 'application/octet-stream'})
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${match.homeName}_${match.awayName}_${moment(match.date, 'YY-MM-DD').format('DD.MM.YY')}.pdf`
                link.click()
            }
        } catch (e) {
            console.log('get protocols failed', e);
        }
    }

    const showDialog = (e, match) => {
        e.preventDefault();
        e.stopPropagation();
        setConfirmModal({
            message: `После подтверждения результат, события и статистика матча <br/><span>${match?.homeName || ''} - ${match?.awayName || ''} (${moment(match.date, 'YY-MM-DD').format('DD.MM.YYYY')} ${match.time})</span><br/> будут безвозвратно удалены из базы данных.`,
            header: 'Аннулирование матча',
            acceptLabel: 'Аннулировать матч',
            rejectLabel: 'Оставить без изменений',
            accept: () => clearMatchData(match),
            style: {minHeight: 'unset'}
        })
    }

    const clearMatchData = (match) => {
        getByUrl(`clearMatchData/${match._id}`)
            .then(resp => {
                if (resp && resp.success) {
                    clearMatchDataById(match._id)
                }
            })
    }
    const clearMatchDataById = (id) => {
        const newMatches = [...selectedMatchDay.matches].map(m => {
            if(m._id == id) {
                // m.time = null
                // m.date = null
                m.score = null
                return m
            } else {
                return m
            }
        })
        setSelectedMatchDay(prev => ({...prev, matches: newMatches}))
    }
    
    return <Draggable key={match._id} draggableId={match._id} index={index} isDragDisabled={!!match.date}>
        {(provided, snapshot) => (
            <Container
                {...provided.draggableProps}
                ref={provided.innerRef}
                isDragDisabled={!!match.date}
                isDragging={snapshot.isDragging && !snapshot.isDropAnimating}
                style={getStyle(provided.draggableProps.style, snapshot)}
            >
                {
                    match.score || match.date ? <Link
                            to={{
                                pathname: `/schedule?editmatch=${match._id}`,
                                state: { background: location }
                            }}
                            style={{color: 'inherit', textDecoration: 'inherit', display: 'flex', alignItems: 'center', justifyContent:'center'}}
                        >
                            <div className={'match'} style={{background: (index%2 !== 0) ? 'none' : '#F6F9FC'}}>
                                <div className='sheet-buttons'>
                                    {match?.docs?.length ? <i className="pi pi-book"></i> : null}
                                    {(match.rosters?.length > 0 && match.rosters.some(s => s.players > 0)) ? (
                                        <Button
                                            className='p-button p-button-sm btn-create sheet-trigger'
                                            icon='pi pi-users'
                                            loading={loading == 'with_roster'}
                                            onClick={async e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                await downloadSheet('with_roster');
                                            }}
                                            tooltip='бланк с составами'
                                        />
                                    ) : null} 
                                    {/* <div style={{width: '1.5rem'}}></div> */}
                                    <Button
                                        className='p-button p-button-sm btn-create sheet-trigger'
                                        icon='pi pi-download'
                                        loading={loading == 'without_roster'}
                                        onClick={async e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            await downloadSheet('without_roster');
                                        }}
                                        tooltip='бланк без составов'
                                    />
                                    {!!match.score && (
                                        <Button
                                            className='p-button p-button-sm btn-delete sheet-trigger'
                                            icon='pi pi-bolt'
                                            loading={loading == 'withevts'}
                                            onClick={async e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                await downloadSheet('withevts');
                                            }}
                                            tooltip='бланк с результатами'
                                        />
                                    )}
                                </div>
                                {/*<Button
                                    className='p-button p-button-sm btn-create sheet-trigger'
                                    icon='pi pi-file'
                                    loading={loading}
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        _downloadSheet();
                                    }}
                                />*/}
                               {refinements?.length ? <div className='refinements-group'>
                                    {refinements.map(r => [
                                        <Tooltip target={`.tooltip${r}`}/>,
                                        <i
                                            className={`tooltip${r} ${RefinementsDefault[r]}`}
                                            data-pr-tooltip={TooltipForRefinements[r]}
                                            data-pr-position='left'
                                        />
                                    ])}
                                </div> : null}
                                <div className={'text-1'}>{match.homeName}</div>
                                <div className={'text-2'}>{match.score ? <Tag>{match.score}</Tag> : match.date ? <div>
                                    <div className='match-time'>{match.time}</div>
                                    <div className='match-date'>{moment(match.date, 'YY-MM-DD').format('D MMMM')}</div>
                                </div> : '🆚'}</div>
                                <div className={'text-3'}>{match.awayName}</div>
                                {!!match.score ? <Button tooltip="Аннулировать матч" icon='pi pi-trash' className='match-deleteBtn' onClick={e => showDialog(e, match)} data-pr-position="top"  data-pr-at="center top-5" data-pr-tooltip="Аннулировать матч"/> : <div style={{minWidth: 18}}></div>}
                            </div>
                        </Link>
                        :
                        <div
                            className={'match'}
                            style={{
                                gridTemplateColumns: '1fr 75px 1fr',
                                padding: '0 42px 0 42px',
                                background: snapshot.isDragging ? 'none' : (index%2 !== 0) ? 'none' : '#F6F9FC',
                                transition: 'background 0.25s'
                            }}
                        >
                            <div className={'text-1'}
                                 style={{
                                     opacity: snapshot.isDragging ? 0 : 1,
                                     transition: 'opacity 0.25s, max-width 1s',
                                    //  maxWidth: snapshot.isDragging ? 'calc((100% - 200px)/2)' : 'calc((100% - 100px)/2)'
                                }}
                            >
                                {match.homeName}
                            </div>
                            <div className={'text-2'}{...provided.dragHandleProps}>
                                {snapshot.isDragging ? <img src={Ball}/> : '🆚'}
                            </div>
                            <div className={'text-3'}
                                 style={{
                                     opacity: snapshot.isDragging ? 0 : 1,
                                     transition: 'opacity 0.25s, max-width 1s',
                                    //  maxWidth: snapshot.isDragging ? 'calc((100% - 200px)/2)' : 'calc((100% - 100px)/2)'
                                }}
                            >
                                {match.awayName}
                            </div>
                        </div>
                }
            </Container>
        )}
    </Draggable>
}

export default MatchItem
