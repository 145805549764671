import React, {useEffect, useContext, useRef, useState} from "react";

import moment from "moment/moment";

import DataTable from "../../Common/DataTable";

import defaultLogo from '../img/logo.png'

import "./style.scss";

const Table = ({state = [], type, option, onClick}) => {

    const colomns = (type) => {
        switch (type) {
            case "new_users": {
                return [
                    {field: "createdAt", header: "Дата создания"},
                    {field: "creator", header: "Создатель "},
                    {field: "club", header: "Клуб"},
                    {field: "league", header: "Лига"},
                    {field: "federation", header: "Регион"},
                    option?.label === "Принятые"
                        ? {field: "verify", header: "Верификация"}
                        : option?.label === "Отклоненные"
                            ? {field: "declain", header: "Отклонение"}
                            : null,
                ];
            }
            case "new_leagues":
                return [
                    {field: "createdAt", header: "Дата создания"},
                    {field: "league", header: "Лига"},
                    {field: "federation", header: "Регион"},
                    {field: "creator", header: "Создатель "},
                    {field: "email", header: "email"},
                    option?.label === "Принятые"
                        ? {field: "verify", header: "Верификация"}
                        : option?.label === "Отклоненные"
                            ? {field: "declain", header: "Отклонение"}
                            : null,
                ];
            case "new_clubs":
                return [
                    {field: "createdAt", header: "Дата создания"},
                    {field: "club", header: "Клуб"},
                    {field: "federation", header: "Регион"},
                    {field: "creator", header: "Создатель "},
                    {field: "email", header: "email"},
                    option?.label === "Принятые"
                        ? {field: "verify", header: "Верификация"}
                        : option?.label === "Отклоненные"
                            ? {field: "declain", header: "Отклонение"}
                            : null,
                ];
            case "rff":
                return [
                    {field: "createdAt", header: "Дата создания"},
                    {field: "creator", header: "Создатель "},
                    {field: "federation", header: "Регион"},
                    option?.label === "Принятые"
                        ? {field: "verify", header: "Верификация"}
                        : option?.label === "Отклоненные"
                            ? {field: "declain", header: "Отклонение"}
                            : null,
                ];
            case "leagues":
                return [
                    {field: "createdAt", header: "Дата создания"},
                    {field: "creator", header: "Создатель "},
                    {field: "league", header: "Лига"},
                    {field: "federation", header: "Регион"},
                    option?.label === "Принятые"
                        ? {field: "verify", header: "Верификация"}
                        : option?.label === "Отклоненные"
                            ? {field: "declain", header: "Отклонение"}
                            : null,
                ];
            case "clubs":
                return [
                    {field: "createdAt", header: "Дата создания"},
                    {field: "creator", header: "Создатель "},
                    {field: "club", header: "Клуб"},
                    {field: "league", header: "Лига"},
                    {field: "federation", header: "Регион"},
                    option?.label === "Принятые"
                        ? {field: "verify", header: "Верификация"}
                        : option?.label === "Отклоненные"
                            ? {field: "declain", header: "Отклонение"}
                            : null,
                ];
            default:
                break;
        }
    };

    const createCustomItem = (logo, name) => (
        <div className="queries-verify__table-item">
            {logo ? <img src={logo}/> : name ? <img src={defaultLogo}/> : null}
            <span>{name}</span>
        </div>
    );

    const rows = (type) => {
        switch (type) {
            case "new_users": {
                return state.map((row) => ({
                    ...row,
                    createdAt: {value: moment(row?.createdAt).format("DD.MM.YYYY")},
                    creator: {value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1).toUpperCase() + "." : ""}${row?.user?.middlename ? row?.user?.middlename.slice(0, 1).toUpperCase() + "." : ""}`}, //+
                    club: row.subjectType === 'club' ? {
                        custom: createCustomItem(row?.subject?.emblem, row?.subject?.name), //+
                        value: row?.subject?.name, //+
                    } : null,
                    league: row.subjectType === 'league' || row.subjectType === 'leagueStaff' ? {
                        custom: createCustomItem(row?.subject?.emblem, row?.subject?.name),
                        value: row?.subject?.name,
                    } : {
                        custom: createCustomItem(row?.league?.emblem, row?.league?.name),
                        value: row?.league?.name,
                    },
                    federation: {
                        // custom: createCustomItem(
                        //   row?.region?.logo,
                        //   row?.region?.name
                        // ),
                        value: row.subjectType === 'region' ? row?.subject?.name : row?.region?.name,//+
                    },
                    verify: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')}, //+
                    declain: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')},
                }));
            }
            case "new_leagues": {
                return state.map((row) => ({
                    ...row,
                    createdAt: {value: moment(row?.createdAt).format("DD.MM.YYYY")},
                    league: {
                        custom: createCustomItem(row?.league?.emblem, row?.league?.name),
                        value: row?.league?.name,
                    },
                    federation: {
                        // custom: createCustomItem(
                        //   row?.region?.logo,
                        //   row?.region?.name
                        // ),
                        value: row?.region?.name,//+
                    },
                    creator: {value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1).toUpperCase() + "." : ""}${row?.user?.middlename ? row?.user?.middlename.slice(0, 1).toUpperCase() + "." : ""}`}, //+

                    email: {value: row?.user?.email || row?.userMail}, //+
                    verify: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')}, //+
                    declain: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')},
                }));
            }
            case "new_clubs": {
                return state.map((row) => ({
                    ...row,
                    createdAt: {value: moment(row?.createdAt).format("DD.MM.YYYY")},
                    club: {
                        custom: createCustomItem(row?.subject?.emblem, row?.subject?.name), //+
                        value: row?.subject?.name, //+
                    },
                    federation: {
                        // custom: createCustomItem(
                        //   row?.region?.logo,
                        //   row?.region?.name
                        // ),
                        value: row?.region?.name,//+
                    },
                    creator: {value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1).toUpperCase() + "." : ""}${row?.user?.middlename ? row?.user?.middlename.slice(0, 1).toUpperCase() + "." : ""}`}, //+
                    email: {value: row?.user?.email || row?.userMail}, //+
                    verify: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')}, //+
                    declain: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')},
                }));
            }
            case "rff": {
                return state.map((row) => ({
                    ...row,
                    createdAt: {value: moment(row?.createdAt).format("DD.MM.YYYY")},
                    creator: {value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1).toUpperCase() + "." : ""}${row?.user?.middlename ? row?.user?.middlename.slice(0, 1).toUpperCase() + "." : ""}`}, //+
                    federation: {
                        // custom: createCustomItem(
                        //   row?.region?.logo,
                        //   row?.region?.name
                        // ),
                        value: row?.region?.name || row?.subject?.name,//+
                    },
                    verify: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')}, //+
                    declain: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')},
                }));
            }
            case "leagues": {
                return state.map((row) => ({
                    ...row,
                    createdAt: {value: moment(row?.createdAt).format("DD.MM.YYYY")},
                    creator: {value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1).toUpperCase() + "." : ""}${row?.user?.middlename ? row?.user?.middlename.slice(0, 1).toUpperCase() + "." : ""}`}, //+
                    league: {
                        custom: createCustomItem(row?.league?.emblem, row?.league?.name),
                        value: row?.league?.name,
                    },
                    federation: {
                        // custom: createCustomItem(
                        //   row?.region?.logo,
                        //   row?.region?.name
                        // ),
                        value: row?.region?.name,//+
                    },
                    verify: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')}, //+
                    declain: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')},
                }));
            }
            case "clubs": {
                return state.map((row) => ({
                    ...row,
                    createdAt: {value: moment(row?.createdAt).format("DD.MM.YYYY")},
                    creator: {value: `${row?.user?.surname ? row?.user?.surname : ""} ${row?.user?.name ? row?.user?.name.slice(0, 1).toUpperCase() + "." : ""}${row?.user?.middlename ? row?.user?.middlename.slice(0, 1).toUpperCase() + "." : ""}`}, //+
                    club: {
                        custom: createCustomItem(row?.subject?.emblem, row?.subject?.name), //+
                        value: row?.subject?.name, //+
                    },
                    league: {
                        custom: createCustomItem(row?.league?.emblem, row?.league?.name),
                        value: row?.league?.name,
                    },
                    federation: {
                        // custom: createCustomItem(
                        //   row?.region?.logo,
                        //   row?.region?.name
                        // ),
                        value: row?.region?.name,//+
                    },
                    verify: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')}, //+
                    declain: {value: moment(row?.handledAt)?.format('DD.MM.YYYY')},
                }));
            }
            default:
                break;
        }
    };

    return (
        <DataTable
            perPage={8}
            columns={colomns(type)}
            rows={rows(type)}
            paginator={state.length > 8}
            noCreateBtn
            onClick={onClick}
        />
    );
};

export default Table;
