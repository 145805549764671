import React, { useContext, useEffect, useRef, useState } from "react";

import { Toast } from "primereact/toast";

import Profile from "./Profile";
import { GlobalContext } from "../../../../ctx";

import { Button } from "primereact/button";

import { getByUrl, putByUrl } from "../../../../v3MethodsService";

import "./style.scss";

const RffDetail = ({ toast }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token;
    const param = window.location.pathname.split("/");
    const [data, setData] = useState(null);
    const [state, setState] = useState(null);
    const [name, setName] = useState(null);
    const curToast = toast ? toast : global && global.toast ? global.toast : {};
    const matched = global?.auth?.data?.find(sub => sub.token === token)

    useEffect(() => {
        getByUrl(`rffs/${param[2]}`).then((resp) => {
            if (resp && resp.success && resp.data && resp.data.federationData) {
                setData(resp.data.federationData);
                setState(resp.data.federationData);
                setName(resp.data.name)
            }
        });

    }, []);


    const updateData = (val, key, subKey, dropdown) => {
        if (subKey) {
            setData({ ...data, [key]: { ...data[key], [subKey]: val } });
        } else {
            setData({ ...data, [key]: val });
        }
    };

    return (
        <div className="rff-detail">
            <Profile
                data={data}
                state={state}
                updateData={updateData}
                setData={setData}
                setState={setState}
                toast={curToast}
                name={name}
                matched={matched}
            />
        </div>
    );
};

const wrap = {
    profile: Profile,
};

export default RffDetail;

const initialData = {
    emblem: "",
    name: "",
    shortName: "",
    phone: "",
    email: "",
    colors: { mainColor: "", extraColor: "" },
    created: "",
    homeLocation: "",
    socials: { vk: "", tg: "", yt: "" },
};

const initialSideData = {
    file: "",
    name: "",
};
