import React, { useEffect, useState } from "react";

import { TabView, TabPanel } from "primereact/tabview";

import DataTable from "../DataTable";

import "./style.scss";
import { pluralForm } from "../../../../utils";

const TabTable = ({ content, onClick, name = '' }) => {

    const dataTab = [
        { header: "Все пользователи", data: content },
        { header: `Только роль «${name}»`, data: content?.filter((item) => item.roles?.some((role) => role.type === 'system') && item.roles?.length === 1) },
        { header: "Несколько ролей", data: content?.filter((item) => item.roles?.length > 1)},
    ];

    return (
        <div className="tab-table">
            <TabView>
                {dataTab.map((item, idx) => (
                    <TabPanel key={idx} header={item.header}>
                        <DataTable
                            rows={item.data?.map((row) => ({
                                ...row,
                                icon: {custom: row.avatarUrl ? <img src={row.avatarUrl} className="tab-table__icon"/> : <div className="tab-table__icon">{row.surname ? row.surname.slice(0, 1).toUpperCase() : ' ' + row.name?.slice(0, 1).toUpperCase()}</div>, value: `${row.surname?.slice(0, 1).toUpperCase() || ''}${row.name?.slice(0, 1).toUpperCase() || ''}`},
                                fio: { value: `${row.surname || ''} ${row.name || ''} ${row.middlename || ''}` },
                                email: { value: row?.email },
                                place: { value: row?.place },
                                rolesCount: {custom: <div className="tab-table__roles">{row?.roles?.length + ' рол' + pluralForm(row?.roles?.length, ['ь', 'и', 'ей'])}</div>, value: row?.roles?.length },
                            }))}
                            columns={[{ field: "icon", className: "tag" }, { field: "fio" }, { field: "email" }, { field: "place" }, { field: "rolesCount", className: "tag count" }]}
                            search={true}
                            paginator={item.data?.length > 8}
                            searchPlaceholder="ФИО, email или регион..."
                            actions={{'angle': true}}
                            perPage={8}
                            onClick={onClick}
                            noCreateBtn={true}
                        />
                    </TabPanel>
                ))}
            </TabView>
        </div>
    );
};

export default TabTable;

