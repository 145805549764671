import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import moment from "moment";
import { deleteByUrl, getByUrl } from "../../../../v3MethodsService";
import { GlobalContext, WorkspaceContext } from "../../../../ctx";

import DataTable from "../../Common/DataTable";
import Header from "../../Common/Header";

import { Button } from "primereact/button";
import { OverlayPanel } from "primereact/overlaypanel";
import { ProgressSpinner } from "primereact/progressspinner";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";

import defaultLogo from "../img/defaultLogo.png";

import "./style.scss";
import { confirmDialog } from "primereact/confirmdialog";

const TournamentsRequestsList = ({ setCopyData, updateReqPath, toast }) => {
    const wctx = useContext(WorkspaceContext);
    const history = useHistory();
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token;
    const matched = global?.auth?.data?.find((sub) => sub.token === token);
    const isClub = !!(wctx && wctx.appSubject && wctx.appSubject.type && wctx.appSubject.type === "club");

    const [state, setState] = useState(null);
    const [loading, setLoading] = useState(true);
    const [tab, setTab] = useState("not_handled");
    const [type, setType] = useState(types[0].value);

    useEffect(() => {
        getData();
    }, [tab, type]);

    const getData = () => {
        setLoading(true);
        getByUrl(tab === "handled" ? `squads${type !== "all" ? `?type=${type}` : ""}` : `queries?status=${tab}${type !== "all" ? `&type=${type}` : ""}`).then((resp) => {
            setLoading(false);
            if (resp && resp.success) setState(resp.data?.sort((a, b) => (moment(a.openDate).toDate() > moment(b.openDate).toDate() ? 1 : -1)));
        });
    };

    const renderHeader = () => {
        return (
            <div className="header__top">
                <span className="header__title">Заявки на турнир</span>
                <div className="header__btns">
                    {!isClub && <Dropdown value={type} onChange={(e) => setType(e.target.value)} options={types} optionValue="value" disabled={loading} />}
                    {!isClub && <Dropdown value={tab} onChange={(e) => setTab(e.target.value)} options={items} optionValue="type" disabled={loading} />}
                    {/* <Button label="История изменений" icon="pi pi-book" className="p-button-outlined" onClick={() => setVisible(true)} /> */}
                    {!!isClub && (
                        <Button
                            label="Добавить заявку"
                            icon="pi pi-plus"
                            onClick={() => {
                                setCopyData([]);
                                history.push("/queries/new");
                            }}
                        />
                    )}
                </div>
            </div>
        );
    };

    const createCustomItem = (logo, name) => (
        <div className="queries-verify__table-item">
            <img src={logo ? logo : defaultLogo} />
            <span>{name}</span>
        </div>
    );

    const handleItemClick = (e) => {
        if (e && e.data && e.data._id) history.push(`/queries/${e.data._id}`);
        updateReqPath(tab === "handled" ? "squads" : "queries");
    };

    return (
        <div className="tournamentsReqests-list">
            <Header setTab={setTab} headerTop={renderHeader} tabMenu={isClub} state={true} items={items} disabled={loading} />
            {loading ? (
                <ProgressSpinner className="loading" animationDuration="1s" />
            ) : state ? (
                <DataTable
                    noCreateBtn
                    search
                    columns={[
                        { field: "_players", header: "Игроков", className: "secondary" },
                        { field: "_team", header: "Команда" },
                        { field: "_tournament", header: "Турнир" },
                        { field: "_league", header: "Формат" },
                        tab === "handled" && { field: "_date", header: "Дата принятия", sortable: true },
                        tab !== "handled" && { field: "_createdAt", header: "Дата создания", sortable: true },
                        tab === "declined" && { field: "_handleDate", header: "Дата отклонения", sortable: true }
                    ]}
                    rows={state.map((row) => ({
                        ...row,
                        items: { value: row?.players ? row?.players : row?.data },
                        _players: { value: row?.players ? row?.players?.length : row?.data?.length ? row?.data?.length : row?.data?.addon?.length || 0 },
                        _team: { custom: createCustomItem(row?.team?.club?.emblem, row?.team?.name), value: row?.team?.name },
                        _tournament: { custom: createCustomItem(false, row?.tournament?.name), value: row?.tournament?.name },
                        _league: { custom: createCustomItem(false, row?.tournament?.league?.name), value: row?.tournament?.league?.name },
                        _date: { value: row?.openDate ? moment(row?.openDate, "YY-MM-DD").format("DD.MM.YYYY") : null },
                        _createdAt: { value: row?.createdAt ? moment(row?.createdAt).format("DD.MM.YYYY") : null },
                        _handleDate: { value: row?.handledAt ? moment(row?.handledAt).format("DD.MM.YYYY") : null }
                    }))}
                    paginator={state.length > 8}
                    perPage={8}
                    customDataColumn={matched && matched.type === 'league' ? null : () => CustomDataColumn(setCopyData, tab, toast, getData)}
                    onClick={handleItemClick}
                />
            ) : (
                <div className="tournamentsReqests-list__notfound">Еще не было добавлено сборных</div>
            )}
        </div>
    );
};

const CustomDataColumn = (setCopyData, tab, toast, getData) => {
    return (
        <Column
            headerClassName="data-table__action"
            bodyClassName="data-table__action"
            body={(rowData, idx) => <OverlayData setCopyData={setCopyData} tab={tab} rowData={rowData} toast={toast} getData={getData} />}
        />
    );
};

const OverlayData = ({ setCopyData, tab, rowData, toast, getData }) => {
    const op = useRef(null);
    const history = useHistory();

    const handleCopy = (item) => {
        setCopyData({ id: item?._id, reqPath: tab === "handled" ? "squads" : "queries" });
        history.replace("queries/new");
    };

    const removeReq = () => {
        confirmDialog({
            message: "Заявка будет безвозвратно удалена",
            header: "Удалить заявку?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Удалить",
            rejectLabel: "Не удалять",
            style: { maxWidth: "582px", width: "100%" },
            draggable: false,
            accept: acceptRemove,
            reject: () => {},
        });
    };

    const acceptRemove = () => {
        deleteByUrl(`queries/${rowData._id}`).then((resp) => {
            if (resp && resp.success) {
                toast?.show({ severity: "success", detail: "Заявка успешно удалена", life: 3000 });
                getData();
            }
        });
    };

    return (
        <>
            <i
                className="pi pi-ellipsis-v"
                onClick={(e) => {
                    e.stopPropagation();
                    op.current.toggle(e);
                }}
            />
            <OverlayPanel ref={op}>
                <div className="column__overlay">
                    {tab === "not_handled" && (
                        <span
                            className="column__overlay-item"
                            onClick={(e) => {
                                op.current.toggle(e);
                                e.stopPropagation();
                                removeReq();
                            }}
                        >
                            Удалить
                        </span>
                    )}
                    <span
                        className="column__overlay-item"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleCopy(rowData);
                        }}
                    >
                        Скопировать
                    </span>
                </div>
            </OverlayPanel>
        </>
    );
};

const types = [
    { label: "Любой тип", value: "all" },
    { label: "Начальные заявки", value: "squad" },
    { label: "Изменения составов", value: "addon" },
];

const items = [
    { label: "В обработке", type: "not_handled" },
    { label: "Итоговые составы", type: "handled" },
    { label: "Отклоненные запросы", type: "declined" },
];

export default TournamentsRequestsList;
