import React, { useState, useEffect, useContext, useRef } from "react";
import { useHistory } from "react-router-dom";

import { MatchContext } from "../../ctx";

import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { addLocale, locale } from "primereact/api";

import moment from "moment";

import axios from "axios";
import { ENDPOINT } from "../../../../env";
import {FileUpload} from "primereact/fileupload";
import {transliterate} from "transliteration";
import {deleteByUrl, getByUrl, postByUrl} from "../../../../v3MethodsService";
import {ProgressSpinner} from "primereact/progressspinner";
import ConfirmModal from "../../../v2/Common/ConfirmModal";

addLocale("ru", {
    firstDayOfWeek: 1,
    dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Субота"],
    dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
    monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", " Ноябрь", "Декабрь"],
    monthNamesShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Сегодня",
    clear: "Очистить",
});

locale("ru");

const Information = ({ match }) => {
    const ctx = useContext(MatchContext);
    const { form } = ctx;

    const fileUploadRef = useRef();
    const [loader, setLoader] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const [employeesList, setEmployeesList] = useState(null);
    const [employees, setEmployees] = useState(null);
    const [focusedSuggestions, setFocusedSuggestions] = useState(null);
    const [publishing, setPublishing] = useState(false);

    const toastRef = useRef(null);

    const [confirmModal, setConfirmModal] = useState(false)

    const history = useHistory();

    const usedReferees = employees ? [...new Set(Object.entries(employees).reduce((acc, val) => {
        const [key, value] = val
        if(["head", "linesman1", "linesman2", "reserve"].includes(key) && value.length) {
            acc.push(...value)
        }
        return acc
    }, []))] : []

    useEffect(() => {
        if (employeesList) {
            // console.log(11111, employeesList["referee"]);
            
            setEmployees(
                employeeCategories.reduce((acc, cat) => {
                    if (form.employees) {
                        if (["head", "linesman1", "linesman2", "reserve"].includes(cat.path)) {
                            acc[cat.path] = employeesList["referee"] ? employeesList["referee"].filter(f => form.employees.find(femp => femp.subrole ? femp.subrole == cat.path && femp._id == f._id : femp._id == f._id)) : [];
                        } else {
                            acc[cat.path] = employeesList[cat.path] ? employeesList[cat.path].filter((p) => form.employees.find((e) => e.role === cat.path && e._id === p._id)) : [];
                        }
                    } else {
                        acc[cat.path] = [];
                    }

                    return acc;
                }, {})
            );
        }
    }, [employeesList]);

    useEffect(() => {
        axios
            .get(`${ENDPOINT}v2/list/employees`, {
                headers: {
                    authorization: localStorage.getItem("_amateum_auth_tkn"),
                    scopetoken: localStorage.getItem("_amateum_auth_tkn"),
                },
            })
            .then((resp) => {
                setEmployeesList(resp.data);
            })
            .catch((e) => {
                console.log("Method unavailable");
            });
    }, []);

    const publishMatch = () => {
        setPublishing(true);
        axios
            .post(
                `${ENDPOINT}v2/applyMatchChanges`,
                { form: ctx.form },
                {
                    headers: {
                        authorization: localStorage.getItem("_amateum_auth_tkn"),
                    },
                }
            )
            .then((resp) => {
                let info = resp.data ? resp.data.info || "" : "";
                if (info) {
                    toastRef.current.show({ severity: "info", summary: "Матч сохранён успешно!", detail: info, life: 4000 });
                } else {
                    toastRef.current.show({ severity: "success", summary: "Успешно", detail: "Матч сохранён!", life: 4000 });
                }
                setTimeout(() => {
                    setPublishing(false);
                }, 1500);
            });
    };

    const updateCtxEmployees = (obj, employee) => {
        const apply = () => {
            setEmployees(obj);
            ctx.setForm({
                ...ctx.form,
                employees: Object.entries(obj).reduce((acc, e) => {
                    acc = acc.concat(
                        e[1].map((p) => {
                            if (["head", "linesman1", "linesman2", "reserve"].includes(e[0])) {
                                return { _id: p._id, role: "referee", name: p.name, subrole: e[0] };
                            } else {
                                return { _id: p._id, role: e[0], name: p.name };
                            }
                        })
                    );
                    return acc;
                }, []),
            });
        }

        if (Array.isArray(employee)) {
            apply()
        } else {
            getByUrl(`checkRefereeOnMatch/${employee._id}?matchId=${match._id}`)
                .then(resp => {
                    if (resp && resp.success) {
                        apply()
                        return
                    } else {
                        toastRef.current.show({severity: 'error', summary: resp.message,})
                    }
                    const blockMatch = resp.match
                    if (blockMatch) {
                        setConfirmModal({
                            message: `Сотрудник ${employee.name} уже назначен на параллельный матч<br/><span>${blockMatch.away?.name || ''} - ${blockMatch.home?.name || ''} (${moment(blockMatch.date, 'YY-MM-DD').format('DD.MM.YYYY')} ${blockMatch.time})</span><br/>Пожалуйста, выберите действие:`,
                            header: 'Параллельное назначение',
                            acceptLabel: 'Переназначить на выбранный матч',
                            rejectLabel: 'Оставить без изменений',
                            accept: () => {
                                getByUrl(`removeRefereeFromMatch/${employee._id}?matchId=${blockMatch._id}`)
                                    .then(resp => {
                                        if (resp && resp.success) {
                                            apply()
                                        }
                                    })
                            },
                        })
                    }
                })
        }
    };

    const onSelect = async (event) => {
        setLoader(true);
        const formData = new FormData();

        const originalFile = event.files[0];

        if (originalFile.size >= 5242880) {
            toastRef.current.show({severity: 'error', summary: '', detail: 'Максимальный размер файла 5 МБ'})
            setLoader(false);
            fileUploadRef.current.clear()
            return
        }

        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("sampleId", match._id);
        formData.append("sampleType", "matches");
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data;
                const newDocs = form && form.attachments && form.attachments.length > 0 ? form.attachments.concat(file) : [].concat(file);
                ctx.setForm({...ctx.form, attachments: newDocs});
            }
            setLoader(false);
        });
    };

    const removeAttachment = (_id) => {
        setDeleting(true)
        deleteByUrl(`remove_attach/${_id}`)
            .then((resp) => {
                if (resp && resp.success) {
                    ctx.setForm({...ctx.form, attachments: form.attachments.filter(a => a._id !== _id && a.attachId !== _id)});
                }
                setDeleting(false)
            })
    }

    const locationsOptions = match && match.stage && match.stage.league && match.stage.league.locations ? match.stage.league.locations.map((l) => ({ ...l, name: l.name || l.address })) : [];

    return (
        <div className="information__block">
            <Toast ref={toastRef}/>
            <ConfirmModal visible={!!confirmModal} {...confirmModal} onClose={() => setConfirmModal(false)}/>

            <div className="information__block_date">
                <div className="date__input_date input__label">
                    <label htmlFor="basic">Дата</label>
                    <Calendar
                        id="basic"
                        value={moment(form.date, "YY-MM-DD").toDate()}
                        onChange={(e) => ctx.setForm({...ctx.form, date: moment(e.value).format("YY-MM-DD")})}
                        dateFormat="dd MM yy"
                    />
                </div>

                <div className="date__input_time input__label">
                    <label htmlFor="time12">Время</label>
                    <Calendar
                        id="time12"
                        value={moment(form.time, "HH:mm").toDate()}
                        onChange={(e) => ctx.setForm({...ctx.form, time: moment(e.value).format("HH:mm")})}
                        stepMinute={5}
                        timeOnly
                        hourFormat="24"
                        mask="99:99"
                    />
                </div>

                <div className="date__input_location input__label">
                    <label htmlFor="basic">Локация</label>
                    <Dropdown
                        value={locationsOptions.find((o) => o._id === form.locationId)}
                        options={locationsOptions}
                        onChange={(e) => ctx.setForm({
                            ...form,
                            locationId: e.value && e.value._id ? e.value._id : null
                        })}
                        optionLabel="name"
                        showClear
                        placeholder="Выберите локацию"
                        valueTemplate={(option, props) => {
                            if (option) {
                                return (
                                    <div className="country-item country-item-value">
                                        <div>{option.name || option.address}</div>
                                    </div>
                                );
                            }

                            return <span>{props.placeholder}</span>;
                        }}
                        itemTemplate={(option) => {
                            return (
                                <div className="country-item">
                                    <div>{option.name}</div>
                                </div>
                            );
                        }}
                    />
                </div>
            </div>

            <div className="information__block_personals">
                <span className="personals__title">Персонал:</span>

                <div className="personals__block">
                    {employeeCategories.map((cat, i) => {
                        let employeesListFiltered = []
                        if(["head", "linesman1", "linesman2", "reserve"].includes(cat.path)) {
                            employeesListFiltered = employeesList?.referee.filter(f => usedReferees.length ? !usedReferees.map(m => m._id).includes(f._id) : f)
                        }

                        return (
                            <div className={`${cat.blockName} input__label`} key={i}>
                            <label htmlFor="basic">{cat.label}:</label>
                            {["head", "linesman1", "linesman2", "reserve"].includes(cat.path) ? (
                                <Dropdown
                                    emptyMessage="Ничего не найдено"
                                    optionLabel="name"
                                    options={employees?.[cat.path][0] ? [employees?.[cat.path][0]].concat(employeesListFiltered) : employeesListFiltered}
                                    onChange={(e) => {
                                        const patched = {...employees, [cat.path]: [{...e.value}]};
                                        updateCtxEmployees(patched, e.value);
                                        setEmployees(patched);
                                    }}
                                    value={employees && employees[cat.path] ? employees[cat.path][0] : {}}
                                />
                            ) : (
                                <AutoComplete
                                    value={employees ? employees[cat.path] || [] : []}
                                    suggestions={focusedSuggestions}
                                    completeMethod={(e) => {
                                        setFocusedSuggestions(
                                            employeesList && employeesList[cat.path]
                                                ? employeesList[cat.path]
                                                    .filter((el) => !employees[cat.path].includes(el))
                                                    .filter((p) => (e.query.length ? p.name.toLowerCase().includes(e.query.toLowerCase()) : p.name))
                                                : []
                                        );
                                    }}
                                    minLength={0}
                                    field="name"
                                    multiple
                                    dropdown
                                    onChange={(e) => {
                                        const patched = {...employees, [cat.path]: e.value};
                                        updateCtxEmployees(patched, e.value);
                                    }}
                                    aria-label={`complete_${cat.path}`}
                                />
                            )}
                        </div>
                        )
                    })}
                </div>
            </div>

            <div className='information__block_media-docs'>
                {/* <div className="information__block_media">
                    <span className="media__title">Медиа:</span>
                    <div className="media__block">
                        <div className="media__block_foto">
                            <label htmlFor="basic">Фотоальбом:</label>
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-camera"></i>
                            </span>
                                <InputText placeholder="https://" disabled/>
                            </div>
                        </div>

                        <div className="media__block_live">
                            <label htmlFor="basic">Трансляция (Youtube/VK)</label>
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-bolt"></i>
                            </span>
                                <InputText placeholder="https://" value={ctx.form.broadcastURL || null}
                                           onChange={(e) => ctx.setForm({...ctx.form, broadcastURL: e.target.value})}/>
                            </div>
                        </div>

                        <div className="media__block_hailait">
                            <label htmlFor="basic">Хайлайты</label>
                            <div className="p-inputgroup">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-star"></i>
                            </span>
                                <InputText placeholder="https://" disabled/>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="information__block_docs">
                    <div className="docs__title">
                        Документы:
                        {loader ? (
                            <ProgressSpinner style={{width: "40px", height: "40px"}} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader"/>
                        ) : (
                            <FileUpload
                                ref={fileUploadRef}
                                mode="basic"
                                customUpload
                                accept=".doc,.docx,.txt,.pdf"
                                className="docs__button"
                                chooseLabel="добавить документ"
                                chooseOptions={{icon: "pi pi-plus"}}
                                onSelect={onSelect}
                                disabled={form?.attachments?.length >= 5}
                            />
                        )}
                    </div>
                    {form.attachments?.length > 0 && (
                        <div className="docs__block">
                            {form.attachments.map((a, k) => (
                                <div key={k} className="docs__link">
                                    <i className='pi pi-download' onClick={() => window.open(a.path)}/>
                                    <div className='docs__info'>
                                        {a.filename}
                                        <span>загружено {!!a.date && `${moment(a.date, 'YY-MM-DD').format('DD.MM.YYYY')},`} {a.user?.name || ''} {a.user?.surname || ''}</span>
                                    </div>
                                    <Button onClick={() => removeAttachment(a._id || a.attachId)} icon='pi pi-trash' className='match-item__deleteBtn docs__remove' disabled={deleting}/>
                                </div>
                            ))}

                        </div>
                    )}
                </div>
            </div>

            <div className="instant-save">
                <Button label={publishing ? "Сохраняем матч" : "Сохранить изменения"} icon="pi pi-check" className="p-button-success p-button-sm" onClick={() => publishMatch()} loading={publishing}/>
            </div>
        </div>
    );
};

const employeeCategories = [
    { path: "head", blockName: "personals__block_referee", label: "Главный арбитр" },
    { path: "linesman1", blockName: "personals__block_referee", label: "Первый помощник" },
    { path: "linesman2", blockName: "personals__block_referee", label: "Второй помощник" },
    { path: "reserve", blockName: "personals__block_referee", label: "Резервный помощник" },
    { path: "executive", blockName: "personals__block_inspector", label: "Инспектор / Делегат" },
    { path: "medicine", blockName: "personals__block_medic", label: "Медицина" },
    { path: "photo", blockName: "personals__block_foto", label: "Фото" },
    { path: "video", blockName: "personals__block_video", label: "Видео" },
];

export default Information;
