import React, { useEffect, useRef, useState } from "react";
import {useHistory} from "react-router-dom";

import { Toast } from "primereact/toast";
import { Sidebar } from "primereact/sidebar";

import DataTable from "../../../Common/DataTable";
import Request from "../../../../Clubs/Request";

import defaultLogo from "../../img/defaultLogo.png";

import "./style.scss";

const Application = ({data}) => {
    const history = useHistory()

    const [state, setState] = useState(null);
    const [team, setTeam] = useState(false)

    useEffect(() => {
        if (data?.teams?.length > 0) {
            setState(data.teams.map(t => t.squads.map(s => ({
                _id: t._id,
                name: t.name,
                squadId: s._id,
                tournamentId: s.tournament?._id,
                playersApproved: s.players?.length || 0,
                tournament: s.tournament?.name || 'Не указан',
                discipline: s.tournament?.league?.discipline?.name || 'Не указана',
                season: s.tournament?.season?.name || 'Не указан',
                playersConsideration: s.queriedPlrs?.length || 0
            }))).flat())
        }
    }, [data])

    const createCustomItem = (logo, name) => (
        <div className="clubs-list__table-item">
            {logo ? <img src={logo} /> : <img src={defaultLogo}/>}
            <span>{name}</span>
        </div>
    );

    const openHandler = (e) => {
        if (e?.data) {
            history.push(`/queries/${e?.data?.squadId}?mode=squads&path=clubs&id=${data._id}`)
            // history.push(`/clubs/${data._id}/teamsquad/${e?.data.squadId}?tournamentId=${e?.data.tournamentId}`)
            // setTeam(e?.data)
        }
    }

    const hideHandler = () => {
        setTeam(false)
        history.push(`/clubs/${data._id}`)
    }

    return (
        <div className="clubs-application">
            {/*<Sidebar className='request-sidebar' visible={!!team} position="right" showCloseIcon={false} onHide={hideHandler} style={{width: '1546px', overflow: 'hidden'}}>
                <Request
                    team={team}
                    setVisibleRight={hideHandler}
                />
            </Sidebar>*/}
            {state?.length > 0 ? (
                <DataTable
                    searchPlaceholder="Название турнира..."
                    search={true}
                    columns={[
                        { field: "team", header: "Команда" },
                        { field: "tournament", header: "Турнир" },
                        { field: "playersApproved", header: "Одобрено игроков" },
                        { field: "playersConsideration", header: "Игроки на рассмотрении" },
                        { field: "discipline", header: "Дисциплина" },
                        { field: "season", header: "Сезон" },
                    ]}
                    rows={state
                        .map((row) => ({
                            ...row,
                            team: { custom: createCustomItem(row?.emblem, row?.name), value: row?.name },
                            tournament: { value: row?.tournament },
                            playersApproved: { value: row?.playersApproved },
                            playersConsideration: { custom: <span style={{color: "#9FA9B7"}}>{row?.playersConsideration}</span>, value: row?.playersConsideration },
                            discipline: { value: row?.discipline },
                            season: { value: row?.season },
                        }))}
                    paginator={state.length > 8}
                    perPage={8}
                    onClick={openHandler}
                />
            ) : (
                <div className="clubs-application__notfound">Еще не было подано заявок</div>
            )}
        </div>
    );
};

export default Application;

const data = [
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
    { tournament: { name: "Высший Дивизион", emblem: defaultLogo }, playersApproved: 16, playersConsideration: 3, discipline: "8x8", season: 2024 },
];
