import './style.scss'

import React, {useRef, useState} from "react";

import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputMask } from 'primereact/inputmask';
import {Button} from "primereact/button";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";

import axios from "axios";
import {ENDPOINT} from "../../../env";

const initialData = {
    problemName: '',
    post: '',
    fio: '',
    org: '',
    mail: '',
    phone: '',
    comment: '',
    images: []
}

const token = localStorage.getItem('_amateum_auth_tkn')

const checkField = (val) => {
    return val.replaceAll(' ', '').length === 0
}

const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.onload = () => {
            resolve(fileReader.result);
        }
        fileReader.onerror = (error) => {
            reject(error);
        }
    })
}

const sendForm = async (body) => {
    try {
        const resp = await axios.post(`${ENDPOINT}v2/sendmail`, body, {
            headers: {
                authorization: token,
                SignedBy: token
            }
        })

        return resp.data
    } catch (e) {
        return e.response
    }
}

const Form = () => {
    const [formData, setFormData] = useState({...initialData})
    const [showInvalid, setShowInvalid] = useState(false)

    const fileUploadRef = useRef(null);
    const [countFields, setCoutFields] = useState(3)
    const [loading, setLoading] = useState(false)

    const toast = useRef(null)

    const uploadHandler = (event) => {
        event.options.clear()
    }

    const chooseOptions = {label: ' ', icon: 'pi pi-fw pi-plus'};

    const onSelect = async (event) => {
        if (event.files[0]) {
            // const fileName = event.files[0].name + (event.files[0].name.includes('jest_sample') ? newImages.length+1 : '') + event.files[0].size
            const fileName = event.files[0].name
            const newPhotos = formData.images || []
            const decoded = await convertBase64(event.files[0])
            newPhotos.push({name: fileName, file: decoded})
            setCoutFields(prev => prev - 1)
            updateForm(newPhotos, 'images')
        }
        fileUploadRef.current.clear()
    }

    const onRemove = (findex) => {
        updateForm(formData.images.filter((i, index) => index !== findex), 'images')
        setCoutFields(prev => prev + 1)
    }

    const updateForm = (val, key) => {
        setFormData({...formData, [key]: val})
    }

    const clickSendForm = () => {
        for (let key in formData) {
            if (key !== 'images' && checkField(formData[key])) {
                setShowInvalid(true)
                return
            }
        }
        setShowInvalid(false)
        setLoading(true)
        sendForm(formData)
            .then(resp => {
                setLoading(false)
                if (resp) {
                    setFormData({...initialData, images: []})
                    setCoutFields(3)
                    toast.current.show({severity: 'success', detail: `Ваше обращение отправлено. Ожидайте ответа по указанной почте.`, life: 3000});
                } else toast.current.show({severity: 'error', detail: `Ошибка сервера. Повторите попытку позже.`, life: 3000});
            })
    }

    return <div className='form'>
        <Toast ref={toast} position='bottom-right'/>

        <div className='form__title'>
            Связаться с нами
        </div>

        <div className='form__fields'>
            <div className='form__field'>
                <label htmlFor="problemName" className="form__label">Название проблемы*</label>
                <InputText
                    id='problemName'
                    placeholder='Укажите название проблемы'
                    value={formData.problemName}
                    className={`form__input${showInvalid && checkField(formData.problemName) ? ' form__input_invalid' : ''}`}
                    onChange={(e) => updateForm(e.target.value, 'problemName')}
                    disabled={loading}
                />
            </div>
            <div className='form__fields form__fields_row'>
                <div className='form__field'>
                    <label htmlFor="fio" className="form__label">ФИО*</label>
                    <InputText
                        id='fio'
                        placeholder='Укажите ФИО'
                        value={formData.fio}
                        className={`form__input${showInvalid && checkField(formData.fio) ? ' form__input_invalid' : ''}`}
                        onChange={(e) => updateForm(e.target.value, 'fio')}
                        disabled={loading}
                    />
                </div>
                <div className='form__field'>
                    <label htmlFor="post" className="form__label">Должность*</label>
                    <InputText
                        id='post'
                        placeholder='Укажите должность'
                        value={formData.post}
                        className={`form__input${showInvalid && checkField(formData.post) ? ' form__input_invalid' : ''}`}
                        onChange={(e) => updateForm(e.target.value, 'post')}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className='form__fields form__fields_row'>
                <div className='form__field'>
                    <label htmlFor="org" className="form__label">Организация*</label>
                    <InputText
                        id='org'
                        placeholder='Укажите наименование организации'
                        value={formData.org}
                        className={`form__input${showInvalid && checkField(formData.org) ? ' form__input_invalid' : ''}`}
                        onChange={(e) => updateForm(e.target.value, 'org')}
                        disabled={loading}
                    />
                </div>
                <div className='form__field'>
                    <label htmlFor="mail" className="form__label">Почта*</label>
                    <InputText
                        id='mail'
                        placeholder='Укажите почту'
                        value={formData.mail}
                        className={`form__input${showInvalid && checkField(formData.mail) ? ' form__input_invalid' : ''}`}
                        onChange={(e) => updateForm(e.target.value, 'mail')}
                        disabled={loading}
                    />
                </div>
                <div className='form__field'>
                    <label htmlFor="phone" className="form__label">Телефон*</label>
                    <InputMask
                        id='phone'
                        placeholder='+7 (___) ___ - __ - __'
                        mask="+ 7 (999) 999-99-99"
                        value={formData.phone}
                        className={`form__input${showInvalid && checkField(formData.phone) ? ' form__input_invalid' : ''}`}
                        onChange={(e) => updateForm(e.target.value, 'phone')}
                        disabled={loading}
                    />
                </div>
            </div>
            <div className='form__field'>
                <label htmlFor="comment" className="form__label">Комментарий*</label>
                <InputTextarea
                    id='comment'
                    placeholder='Опишите подробнее'
                    value={formData.comment}
                    className={`form__input form__input_area${showInvalid && checkField(formData.comment) ? ' form__input_invalid' : ''}`}
                    onChange={(e) => updateForm(e.target.value, 'comment')}
                    disabled={loading}
                />
            </div>
            <div className='form__fields form__fields_row'>
                {formData.images.map((v, index) => {
                    return <div key={index} className='form__field'>
                        {index === 0 ? <label htmlFor="comment" className="form__label">Скриншоты (если необходимо)</label> : null}
                        <div key={index} className='form__input form__input_item'>
                            <img src={v.file}/>
                            <Button
                                icon="pi pi-trash"
                                className="form__remove-btn"
                                onClick={() => onRemove(index)}
                                disabled={loading}
                            />
                        </div>
                    </div>
                })}
                {Array.from(Array(countFields).keys()).map((f, index) => {
                    return <div key={index} className={`form__field${formData.images.length === 0 ? ' form__field_upload' : ''}`}>
                        {index === 0 && formData.images.length === 0 ? <label htmlFor="comment" className="form__label">Скриншоты (если необходимо)</label> : null}
                        <FileUpload
                            ref={fileUploadRef}
                            mode={'basic'}
                            accept=".jpeg, .jpg, .png"
                            onSelect={onSelect}
                            customUpload
                            uploadHandler={uploadHandler}
                            chooseOptions={chooseOptions}
                            className='form__input'
                            disabled={loading}
                        />
                    </div>
                })}
            </div>
        </div>

        <Button className='form__button' onClick={clickSendForm} loading={loading} label={'Отправить'}/>

    </div>
}

export default Form