import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";

import DataTable from "../../Common/DataTable";

import "./style.scss";
import { Dropdown } from "primereact/dropdown";

const RolesList = ({ state, updateState, toast, pag }) => {
    const history = useHistory();
    const [pagination, setPagination] = useState(pag);
    const [value, setValue] = useState(options[0]);
    const addHandler = () => {
        history.push("/roles/newRole");
    };

    const clickHandler = (e) => {
        history.push({ pathname: `/roles/${e.data._id}`, search: `?pagination=${pagination}` });
    };

    const copyHandler = (id) => {
        const obj = state.roles.find((r) => r._id === id);
        if (obj) {
            const newObj = {
                ...obj,
                type: "flex",
                name: `${obj.name} КОПИЯ`,
                usersCount: 0,
                users: [],
                _id: null,
            };
            updateState(newObj, "copy");
            history.push("/roles/copy");
            return;
        }
        toast.show({ severity: "error", summary: "Не удалось скопировать роль", life: 3000 });
    };

    return (
        !!state?.roles?.length && (
            <div className="roles-list">
                <div className="roles-list__header">
                    <Dropdown
                        valueTemplate={
                            <div className="roles-list__dropdown">
                                <i className="pi pi-plus"></i>
                                <span>Добавить роль</span>
                            </div>
                        }
                        icon="pi pi-plus"
                        onChange={(e) => copyHandler(e.target.value)}
                        options={state?.roles?.filter((item) => item.type === "system")}
                        optionLabel="name"
                        optionValue="_id"
                        appendTo={"self"}
                    />
                    <SelectButton
                        value={value}
                        onChange={(e) => {
                            if (e.value) setValue(e.value);
                        }}
                        options={options}
                    />
                </div>

                <DataTable
                    rows={state.roles
                        .filter((item) => (value === options[1] ? item.type === "system" : value === options[2] ? item.type === "flex" : item))
                        .map((row) => ({
                            ...row,
                            type: { custom: typeData(row?.type), value: row?.type },
                            role: { value: row?.name },
                            users: { value: row?.usersCount },
                        }))}
                    columns={[
                        { field: "type", header: "Тип", className: "secondary" },
                        { field: "role", header: "Название роли" },
                        { field: "users", header: "Пользователей с этой ролью" },
                    ]}
                    perPage={8}
                    onClick={clickHandler}
                    onAdd={addHandler}
                    actions={{ copy: true }}
                    copyHandler={copyHandler}
                    paginator={state.roles.length > 8}
                    pagination={pagination}
                    setPagination={setPagination}
                />
            </div>
        )
    );
};

export default RolesList;

const options = ["Все", "Системные", "Гибкие"];

const typeData = (x) => {
    switch (x) {
        case "flex":
            return <div className="roles-list__row flex">Гибкая</div>;
        default:
            return <div className="roles-list__row">Системная</div>;
    }
};
