import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";

import axios from "axios";
import {ENDPOINT} from "../../../../env";

import {Button} from "primereact/button";

const oldTkn = localStorage.getItem("_amateum_tkn")

const Response = ({ token }) => {
    const history = useHistory();

    const [isError, setIsError] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkVerify = async () => {
            try {
                const resp = await axios.get(`${ENDPOINT}v2/verifiedMigrate?verify=${token}`, {headers: {Signedby: oldTkn}})

                if (resp && resp.data) {
                    return resp.data.success
                }

                return false
            } catch (e) {
                console.log(e)
                return false
            }
        }

        if (token) {
            setLoading(true)
            checkVerify().then(resp => {
                setIsError(!resp)
                setLoading(false)
                if (resp) {
                    localStorage.removeItem('_amateum_tkn')
                }
            })
        } else {
            setIsError(true)
            setLoading(false)
        }
    }, [token]);

    return (
        loading ? (
            <div className='auth2__container'></div>
        ) : (
            <div className='auth2__container reg-error'>
                <div className='auth2__title'>
                    {!isError ? 'Учетная запись успешно создана' : 'Ошибка создания учетной записи'}
                </div>
                {isError && (
                    <div className='reg-success-kind__subtitle'>
                        Ссылка, которой Вы воспользовались не существует или устарела. Пожалуйста, проверьте источник ссылки.
                    </div>
                )}
                <Button label='Перейти ко входу' onClick={() => history.push('/login')} className='auth2__button'/>
            </div>
        )
    )
}

export default Response