import React, { useEffect, useState } from "react";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox } from "primereact/checkbox";

import "./style.scss";
import {getByUrl} from "../../../../../v3MethodsService";

const RolesAccess = ({ state, updateForm, toast }) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        if (state && state.level && state.scopes) {
            getByUrl(`getRoleScopes?level=${state.level}`)
                .then(resp => {
                    if (resp && resp.success) {
                        const groupedScopes = resp.data.reduce((acc, scope) => {
                            const { section, public: publicName } = scope;
                            if (!acc[section]) {
                                acc[section] = {
                                    title: publicName,
                                    items: []
                                };
                            }
                            acc[section].items.push({ name: scope.scopeDesc, value: !!state.scopes.find(s => s.id === scope.id), original: scope });
                            return acc;
                        }, {});

                        const result = Object.values(groupedScopes);

                        setData(result)
                    }
                })
        }
    }, [state]);

    // const handleCheckbox = (val, idx) => {
    //     const newBlocks = [...data.timelines];
    // }

    // const blockHandler = (val, index) => {
    //     const newBlocks = [...data];
    //     newBlocks[index] = val;
    //     inputHandler(newBlocks, "timelines");
    // };

    const handleCheckboxChange = (tabIndex, itemIndex, checked) => {
        const newData = [...data];
        newData[tabIndex].items[itemIndex].value = checked;
        setData(newData);

        const filteredOriginalScopes = newData.flatMap(group =>
            group.items
                .filter(item => item.value !== false)
                .map(item => item.original)
        );

        updateForm('scopes', filteredOriginalScopes)
    };

    return !!data && (
        <div className="roles-access">
            {data.map((item, tabIndex) => (
                <Accordion key={tabIndex}>
                    <AccordionTab
                        disabled={!item.items}
                        header={
                            <div className="roles-access__header">
                                <span>{item.title}</span>
                                {item.items?.filter(i => i.value)?.length ? <div className="roles-access__btn">доступно функций: {item.items?.filter(i => i.value)?.length}</div> : <div className="roles-access__btn disabled">нет доступных функций</div>}
                            </div>
                        }
                    >
                        {item.items?.map((i, itemIndex) => (
                            <div key={itemIndex} className="roles-access__checkbox">
                                <Checkbox value={i.value} onChange={(e) => handleCheckboxChange(tabIndex, itemIndex, e.checked)} checked={i.value} />
                                <label>{i.name}</label>
                            </div>
                        ))}
                    </AccordionTab>
                </Accordion>
            ))}
        </div>
    );
};

export default RolesAccess;
