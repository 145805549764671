import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { getByUrl } from "../../../../v3MethodsService";
import { GlobalContext } from "../../../../ctx";

import { Toast } from "primereact/toast";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";

import DataTable from "../../Common/DataTable";

import defaultLogo from "../img/defaultLogo.png";

import "./style.scss";

const ClubsList = ({ setDropdown, dropdown = null, loader, setLoader }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const token = global && global.auth && global.auth.token;

    const history = useHistory();

    const [clubs, setClubs] = useState([]);
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setLoader(true);

        getByUrl("getRefsList?section=settlements").then((resp) => {
            if (resp && resp.success && resp.data) {
                setOptions(resp.data);
            }
        });

        getByUrl(`clubs`).then((resp) => {
            //тут возможно будет меняться позже, а то получать 9 тыс долго
            if (resp && resp.success && resp.data) {
                setClubs(resp.data);
                setLoader(false);
            }
        });
    }, [token]);

    const filteredCities = options?.filter((city) => clubs?.some((item) => item.settlementId === city._id));

    const createCustomItem = (logo, name) => (
        <div className="clubs-list__table-item">
            {logo ? <img src={logo} /> : <img src={defaultLogo} />}
            <span>{name}</span>
        </div>
    );

    const handleClick = (e) => {
        history.replace(`/clubs/${e.data._id}`);
    };

    const handleAddClub = () => {
        history.replace("/clubs/add");
    };

    const data = dropdown ? clubs.filter((item) => item.settlementId === dropdown) : clubs;

    return (
        <div className="clubs-list">
            <div className="clubs-list__header">
                {options ? (
                    <Dropdown
                        appendTo={"self"}
                        value={dropdown}
                        options={filteredCities}
                        onChange={(e) => setDropdown(e.value)}
                        emptyMessage={"Ничего не найдено"}
                        emptyFilterMessage={"Ничего не найдено"}
                        optionLabel="name"
                        optionValue="_id"
                        showClear
                        placeholder="Выберите город"
                    />
                ) : null}
                <Button label="Добавить клуб" icon="pi pi-plus" onClick={handleAddClub} />
            </div>
            {loader ? (
                <ProgressSpinner style={{ width: "40px", height: "40px" }} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className="loader" />
            ) : (
                <DataTable
                    onClick={handleClick}
                    search
                    noCreateBtn
                    columns={[
                        { field: "name", header: "Название" },
                        { field: "locality", header: "Населенный пункт" },
                        { field: "teams", header: "Кол-во команд" },
                        { field: "memebrs", header: "Кол-во представителей" },
                    ]}
                    rows={data.map((row) => ({
                        ...row,
                        name: { custom: createCustomItem(row?.emblem, row?.name), value: row?.name },
                        locality: { value: row?.settlement?.name },
                        teams: { value: row?.teams.length },
                        memebrs: { value: row?.scopes?.length },
                    }))}
                    paginator={clubs.length > 8}
                    perPage={8}
                />
            )}
        </div>
    );
};

export default ClubsList;
