import React, { useState, useEffect, useRef, useContext } from "react";

import { getByUrl, postByUrl } from "../../../../v3MethodsService";

import "./style.scss";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { GlobalContext } from "../../../../ctx";

const FormInvite = ({ updateList, toast }) => {
    const [global, setGlobal] = useContext(GlobalContext);
    const [state, setState] = useState({
        email: "",
    });
    const [data, setData] = useState(initData);
    const [emailErr, setEmailErr] = useState(false);

    const currentRole = data.roles.find((i) => i._id == state.roleId);
    const level = currentRole && currentRole.level ? currentRole.level : false;
    const scope = global && global.auth && global.auth.data && global.auth.token ? global.auth.data.find((i) => i.token == global.auth.token) : false;
    const type = scope ? scope.type : false;

    useEffect(() => {
        getByUrl("roles").then((resp) => {
            if (resp && resp.success && resp.data) {
                let res = resp.data;
                if (type) {
                    const fields = {
                        system: ["system", "region", "league", "leagueStaff", "club"],
                        region: ["region", "league", "leagueStaff", "club"],
                        league: ["league", "leagueStaff", "club"],
                        leagueStaff: ["league", "leagueStaff", "club"],
                        club: ["club"],
                    };
                    res = fields[type].map((i) => resp.data.filter((f) => f.level == i))?.flat();
                }

                setData((prev) => ({ ...prev, roles: res }));
            }
        });

        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.size > 0) {
            const params = { email: "" };
            let idx = 0;
            const code = ["roles", "regions", "leagues", "clubs"];
            searchParams.forEach((value, key) => {
                params[key] = value;
                updateState(value, key, code[idx]);
                idx++;
            });
            setState(params);
        }
    }, []);

    const updateState = (val, stateKey, key) => {
        setState((prev) => ({ ...prev, [stateKey]: val }));

        if (key) {
            const regId = stateKey == "regionId" ? val : "";
            const legId = stateKey == "leagueId" ? val : "";
            const url = {
                roles: `getRefsList?section=regions`,
                regions: `getRegistrationRefs?section=leagues&subjectId=${regId}`,
                leagues: `getRegistrationRefs?section=clubs&subjectId=${legId}`,
            };

            if (url[key]) {
                if (key == "roles") {
                    setState((prev) => ({ ...prev, regionId: null, leagueId: null, clubId: null }));
                    setData((prev) => ({ ...prev, regions: [], leagues: [], clubs: [] }));
                } else if (key == "regions") {
                    setState((prev) => ({ ...prev, leagueId: null, clubId: null }));
                    setData((prev) => ({ ...prev, leagues: [], clubs: [] }));
                } else if (key == "leagues") {
                    setState((prev) => ({ ...prev, clubId: null }));
                    setData((prev) => ({ ...prev, clubs: [] }));
                }

                getByUrl(url[key]).then((resp) => {
                    if (resp && resp.success && resp.data) {
                        const sKeys = {
                            roles: "regions",
                            regions: "leagues",
                            leagues: "clubs",
                        };
                        setData((prev) => ({ ...prev, [sKeys[key]]: resp.data }));
                    }
                });
            }
        } else {
            setEmailErr(false);
        }
    };

    const handleValid = (e) => {
        const value = e.target.value;
        const validate = value.length > 0 ? validateEmail(value) : true;

        setEmailErr(!validate);
    };

    const renderInput = (item) => {
        switch (item.type) {
            case "input": {
                return (
                    <InputText
                        value={state[item.key] || ""}
                        onChange={(e) => updateState(e.target.value, item.key)}
                        placeholder={item.placeholder}
                        className={emailErr ? "p-invalid" : ""}
                        onBlur={handleValid}
                    />
                );
            }

            case "dropdown":
                return (
                    <Dropdown
                        emptyFilterMessage={"Ничего не найдено"}
                        emptyMessage={"Ничего не найдено"}
                        value={state[item.stateKey] || ""}
                        onChange={(e) => updateState(e.value, item.stateKey, item.key)}
                        placeholder={item.placeholder}
                        options={data[item.key] || []}
                        disabled={!state.email || (level && item.disabled.includes(level)) || !data[item.key].length}
                        optionLabel="name"
                        optionValue="_id"
                        filter
                        showFilterClear
                    />
                );
        }
    };

    const handleBtn = () => {
        const subjectId = {
            club: "clubId",
            league: "leagueId",
            leagueStaff: "leagueId",
            region: "regionId",
            system: null,
        };
        const body = {
            email: state.email,
            scope: {
                subjectType: level,
                roleId: state.roleId,
                subjectId: state[subjectId[level]] || null,
            },
            desc: {
                region: data.regions?.find((r) => r._id === state.regionId)?.name || null,
                league: data.leagues?.find((l) => l._id === state.leagueId)?.name || null,
                club: data.clubs?.find((l) => l._id === state.clubId)?.name || null,
                role: currentRole?.name || "",
            },
        };

        updateList(body)
        setState({email: ''})
        setData(prev => ({...initData, roles: prev.roles}))

        /*postByUrl('create', body).then(resp => {
            if (resp && resp.success) {
                toast.show({ severity: "success", life: 4000, summary: "Приглашение отправлено" });
            }else {
                toast.show({ severity: "error", life: 4000, summary: resp.message });
            }
            setState({email: ''})
            setData((prev) => ({...prev, regions: [], leagues: [], clubs: []}))
        })*/
    };

    const disabledBtn =
        state.email &&
        state.roleId &&
        level &&
        ((level == "club" && state.clubId) || ((level == "league" || level == "leagueStaff") && state.leagueId) || (level == "region" && state.regionId) || level == "system");

    return (
        <div className="formInvite">
            <div className="formInvite__title">Кого пригласить</div>
            <div className="formInvite__container">
                {inputs.map((item, idx) => (
                    <div key={idx} className="formInvite__input">
                        <label className="formInvite__input-label">{item.label}</label>
                        {renderInput(item)}
                    </div>
                ))}
                <Button className="formInvite__btn" label="Добавить пользователя в список" disabled={emailErr || !disabledBtn} onClick={handleBtn} />
            </div>
        </div>
    );
};

export default FormInvite;

const inputs = [
    { type: "input", label: "Почта*", key: "email", placeholder: "Укажите почту" },
    { type: "dropdown", label: "Роль*", key: "roles", stateKey: "roleId", placeholder: "Выберите роль", disabled: [] },
    { type: "dropdown", label: "Регион*", key: "regions", stateKey: "regionId", placeholder: "Выберите регион", disabled: ["system"] },
    { type: "dropdown", label: "Лига*", key: "leagues", stateKey: "leagueId", placeholder: "Выберите лигу", disabled: ["system", "region"] },
    { type: "dropdown", label: "Клуб*", key: "clubs", stateKey: "clubId", placeholder: "Выберите клуб", disabled: ["system", "region", "league", "leagueStaff"] },
];

const initData = {
    roles: [],
    regions: [],
    leagues: [],
    clubs: [],
};
const validateEmail = (email) => {
    let regexEmailTest = new RegExp(
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
    );
    return regexEmailTest.test(String(email).toLowerCase());
};
