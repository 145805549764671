import React, {useEffect, useRef, useState} from "react"

import {Toast} from "primereact/toast";

import Detail from "./Detail";
import List from "./List";

import './style.scss'

const Stages = ({ state, updateState, setView }) => {
    const toast = useRef(null)

    const [current, setCurrent] = useState(null)

    useEffect(() => {
        if (state && state.btnOptions && state.btnOptions.click) {
            updateState({
                click: false,
                listbox: {
                    options: [
                        {label: 'Матчи в круг', value: 'round'},
                        {label: 'Групповой этап', value: 'groups'},
                        {label: 'Плейофф', value: 'playoff'}
                    ],
                    onClick: handleAdd
                }
            }, 'btnOptions')
        }

        // return setView('list')
    }, [state]);

    const handleAdd = (item) => {
        setView('detail')
        setCurrent(item)
    }

    const handleCurrent = (e) => {
        setView('detail')
        setCurrent(state && state.stages ? state.stages.find(s => s._id === e?.data?._id) : null)
    }

    const Specified = current ? wrap.detail : wrap.list;

    return (
        <div className="tournamets2-team">
            <Toast ref={toast} position="top-right"/>
            <Specified toast={toast.current} state={state} updateState={updateState} handleCurrent={handleCurrent} current={current} onClose={() => {setCurrent(null); setView('list')}} updateCurrent={setCurrent}/>
        </div>
    )
}

const wrap = {
    detail: Detail,
    list: List
}

export default Stages