import React, { useState, useEffect } from 'react'

import {Editor} from "primereact/editor";

import './style.scss'

const EditorUi = ({id = 'editor', value = '', onChange = (any) => {}, placeholder = '', className}) => {
    const renderHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" aria-label="Bold"></button>
                <button className="ql-italic" aria-label="Italic"></button>
                <button className="ql-underline" aria-label="Underline"></button>
                <button className="ql-link" aria-label="Link"></button>
                {/* <button className="ql-list" aria-label="Unordered List"></button> */}
            </span>
        );
    }

    const header = renderHeader();

    return <Editor className={className} id={id} headerTemplate={header} value={value} onTextChange={onChange} placeholder={placeholder}/>
}

export default EditorUi