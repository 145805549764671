import React, {useEffect, useRef, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import {useClickOutside} from "../../../../utils";

import {Button} from "primereact/button";

import "./style.scss";

const ConfirmModal = ({ visible = false, onClose = () => {}, message = '', header = '', icon = 'pi pi-info-circle', acceptLabel = 'Да', rejectLabel = 'Нет', accept = () => {}, reject = () => {}, customBody = null, style = {} }) => {
    const modalRef = useRef(null)

    useClickOutside(visible, modalRef, onClose)

    return !!visible && (
        <div className='confirm-modal__overlay'>
            <div className='confirm-modal' style={style} ref={modalRef}>
                <div className='confirm-modal__header'>
                    <i className={icon}/>
                    {header}
                </div>
                <div className='confirm-modal__message' dangerouslySetInnerHTML={{__html: message}}></div>
                {customBody}
                <div className='confirm-modal__buttons'>
                    {!!acceptLabel && <Button label={acceptLabel} onClick={() => {accept(); onClose()}}/>}
                    {!!rejectLabel && <Button label={rejectLabel} className="p-button-outlined p-button-secondary" onClick={() => {reject(); onClose()}}/>}
                </div>
            </div>
        </div>
    )
}

export default ConfirmModal