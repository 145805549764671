import React, { useEffect, useState } from "react";

import { TabMenu } from "primereact/tabmenu";

import "./style.scss";

const Header = ({ state, setTab, tab, items, headerTop, tabMenu = false, disabled = false }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    return !!state && (
        <div className="header">
            <div className="header__container">
                {headerTop ? headerTop() : null}
                {(state.type && state.type !== "system") || tabMenu ? (
                    <div>
                        <TabMenu
                            model={items}
                            activeIndex={activeIndex}
                            onTabChange={(e) => {
                                if (!disabled) {
                                    setTab(e.value.type);
                                    setActiveIndex(e.index);
                                }
                            }}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    )
};

export default Header;
