import React, { useEffect, useRef, usedata } from "react";
import { useHistory } from "react-router-dom";

import { transliterate } from "transliteration";
import { deleteByUrl, postByUrl, putByUrl } from "../../../../../v3MethodsService";
import { diffData } from "../../../../../utils";

import { Toast } from "primereact/toast";
import { InputSwitch } from "primereact/inputswitch";
import { ColorPicker } from "primereact/colorpicker";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";

import FileUploadUi from "../FileUploadUi";

import "./style.scss";

const ClubsProfile = ({ data, updateData, optionRegions, optionSettelments, state, toast, setState, setData, currentSubj }) => {
    const param = window.location.pathname.split("/");
    const dataToSend = diffData(state, data);

    const history = useHistory();

    useEffect(() => {
        if (data && !data.regionId && optionRegions?.length > 0) {
            updateData(optionRegions[0]._id, "regionId", false, true);
        }
    }, [data, optionRegions]);

    const onSelect = async (event) => {
        const formData = new FormData();

        const originalFile = event.files[0];
        const originalFileName = originalFile.name;
        const transliteratedFileName = transliterate(originalFileName);

        const renamedFile = new File([originalFile], transliteratedFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified,
        });

        formData.append("s3only", true);
        formData.append("file", renamedFile);

        postByUrl("upload_attachment", formData, true).then((resp) => {
            if (resp && resp.success) {
                const file = resp.data.file.data.path;
                updateData(file, "emblem");
            }
        });
    };

    const handleSave = async () => {
        if (param[2] === "add") {
            await postByUrl(`clubs`, dataToSend).then((resp) => {
                if (resp && resp.success) {
                    toast.show({ severity: "success", summary: "Данные сохранены" });
                    history.replace(`/clubs/${resp.data._id}`);
                } else {
                    toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            });
        } else {
            await putByUrl(`clubs/${param[2]}`, dataToSend).then((resp) => {
                if (resp && resp.success) {
                    setState(data);
                    toast.show({ severity: "success", summary: "Данные сохранены" });
                } else {
                    toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
                }
            });
        }
    };

    const deleteHandler = () => {
        confirmDialog({
            message: "Вы уверены, что хотите удалить клуб?",
            header: "Удалить?",
            icon: "pi pi-exclamation-triangle",
            acceptClassName: "p-button-danger",
            acceptLabel: "Да",
            rejectLabel: "Нет",
            draggable: false,
            accept: () => handleDelete(),
            reject: () => {},
        });
    };

    const handleDelete = async () => {
        await deleteByUrl(`clubs/${param[2]}`).then((resp) => {
            if (resp && resp.success) {
                history.replace("/clubs");
                toast.show({ severity: "success", summary: "Клуб удален", life: 1000 });
            } else {
                toast.show({ severity: "error", summary: resp?.message || "Ошибка сервера. Повторите попытку позже.", life: 1000 });
            }
        });
    };

    // const disabledBtn = data && data.emblem && data.name && data.regionId && data.phone && data.email && data.settlementId && dataToSend;
    const disabledBtn = data && data.emblem && data.name && dataToSend;

    return (
        data && (
            <div className="clubs-profile">
                <div className="clubs-profile__card">
                    <div className="clubs-profile__header">
                        <span className="clubs-profile__header-title">Основная информация</span>
                        <div className="clubs-profile__switch">
                            <InputSwitch checked={data.inSearch || ""} onChange={(e) => updateData(e.value ? e.value : null, "inSearch")} />
                            <lablel className="clubs-profile__label">Клуб в поиске игроков</lablel>
                        </div>
                    </div>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">
                                Эмблема клуба* <span className="clubs-profile__label muted">(jpg, jpeg, png, до 5 Мб)</span>
                            </label>
                            <FileUploadUi image={data.emblem} onChange={onSelect} updateData={() => updateData(null, "emblem")} />
                        </div>
                        <div className="clubs-profile__main">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Название*</label>
                                <InputTextarea placeholder="Укажите название" value={data.name || ""} onChange={(e) => updateData(e.target.value, "name")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Сокращенное название</label>
                                <InputText placeholder="Укажите сокращенное название" value={data.shortName || ""} onChange={(e) => updateData(e.target.value, "shortName")} />
                            </div>
                            <div className="clubs-profile__inputs">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Регион</label>
                                    <Dropdown
                                        placeholder="Выберите регион"
                                        emptyFilterMessage={"Ничего не найдено"}
                                        emptyMessage={"Ничего не найдено"}
                                        value={data.regionId || ""}
                                        onChange={(e) => updateData(e.value, "regionId", false, true)}
                                        options={optionRegions}
                                        optionLabel="name"
                                        optionValue="_id"
                                        filter
                                        showFilterClear
                                        appendTo={"self"}
                                        disabled={optionRegions?.length === 1}
                                    />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Населенный пункт</label>
                                    <Dropdown
                                        placeholder="Выберите населенный пункт"
                                        emptyFilterMessage={"Ничего не найдено"}
                                        emptyMessage={"Ничего не найдено"}
                                        value={data.settlementId || ""}
                                        onChange={(e) => updateData(e.value, "settlementId")}
                                        disabled={!data.regionId}
                                        options={optionSettelments}
                                        filter
                                        showFilterClear
                                        optionLabel="name"
                                        optionValue="_id"
                                        appendTo={"self"}
                                    />
                                </div>
                            </div>
                            <div className="clubs-profile__inputs">
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Телефон</label>
                                    <InputMask mask="+7 (999) 999 99 99" placeholder="+7 (___) ___ __ __" value={data.phone || ""} onChange={(e) => updateData(e.target.value, "phone")} />
                                </div>
                                <div className="clubs-profile__container">
                                    <label className="clubs-profile__label">Email</label>
                                    <InputText placeholder="Укажите почту" value={data.email || ""} onChange={(e) => updateData(e.target.value, "email")} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-profile__content">
                    <div className="clubs-profile__card">
                        <span className="clubs-profile__header-title">Клубные цвета</span>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Основной цвет</label>
                                <div className="clubs-profile__color">
                                    <ColorPicker format="hex" value={data.colors?.mainColor?.trim() || "EEEEEE"} onChange={(e) => updateData(e.value.trim(), "colors", "mainColor")} />
                                    <InputText value={data.colors?.mainColor?.trim() || ""} onChange={(e) => updateData(e.target.value.trim(), "colors", "mainColor")} placeholder="HEX" />
                                </div>
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Доп. цвет</label>
                                <div className="clubs-profile__color">
                                    <ColorPicker format="hex" value={data.colors?.extraColor?.trim() || "EEEEEE"} onChange={(e) => updateData(e.value.trim(), "colors", "extraColor")} />
                                    <InputText value={data.colors?.extraColor?.trim() || ""} onChange={(e) => updateData(e.target.value.trim(), "colors", "extraColor")} placeholder="HEX" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="clubs-profile__card">
                        <span className="clubs-profile__header-title">Дополнительная информация</span>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Год основания</label>
                                <InputText placeholder="Укажите год" value={data.created || ""} maxLength={4} onChange={(e) => updateData(e.target.value, "created")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Домашний стадион</label>
                                <InputText placeholder="Укажите стадион" value={data.homeLocation || ""} onChange={(e) => updateData(e.target.value, "homeLocation")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-profile__card">
                    <span className="clubs-profile__header-title">Внешние ресурсы</span>
                    <div className="clubs-profile__content">
                        <div className="clubs-profile__container">
                            <label className="clubs-profile__label">Сообщество ВКонтакте</label>
                            <InputText placeholder="Вставьте ссылку" value={data.socials?.vk || ""} onChange={(e) => updateData(e.target.value, "socials", "vk")} />
                        </div>
                        <div className="clubs-profile__inputs">
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Канал в Telegram</label>
                                <InputText placeholder="Вставьте ссылку" value={data.socials?.tg || ""} onChange={(e) => updateData(e.target.value, "socials", "tg")} />
                            </div>
                            <div className="clubs-profile__container">
                                <label className="clubs-profile__label">Канал Youtube</label>
                                <InputText placeholder="Вставьте ссылку" value={data.socials?.yt || ""} onChange={(e) => updateData(e.target.value, "socials", "yt")} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clubs-detail__button">
                    {currentSubj !== 'club' && <Button label="Удалить" onClick={deleteHandler} className="p-button-danger"/>}
                    <Button label="Сохранить" onClick={handleSave} disabled={!disabledBtn} />
                </div>
            </div>
        )
    );
};

export default ClubsProfile;
