import React, { useState, useEffect } from 'react'

import {InputSwitch} from "primereact/inputswitch";

import './style.scss'

const SwitchUi = ({ id = 'switch', onChange = (any) => {}, checked = false, label = '', type = '', className = '', disabled = false, invalid = false, }) => {
    return (
        <div className={`switch-container ${className}`}>
            <InputSwitch id={id} inputId={id} onChange={onChange} checked={checked} className={`switch ${type} ${invalid ? 'p-invalid' : ''}`} disabled={disabled} />
            <label htmlFor={id} className={`switch-container__label`}>{label}</label>
        </div>
    )
}

export default SwitchUi