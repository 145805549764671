import React, { useEffect, useRef, useState } from "react";
import {useHistory, useLocation} from "react-router-dom";

import {getByUrl} from "../../../../v3MethodsService";

import {ProgressSpinner} from "primereact/progressspinner";

import DataTable from "../../Common/DataTable";

import './style.scss'
import defaultLogo from "../../ClubsManagment/img/defaultLogo.png";

const TournamentsV2List = () => {
    const location = useLocation()
    const history = useHistory()

    const [loader, setLoader] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoader(true)
        getByUrl('tournaments')
            .then(resp => {
                if (resp && resp.success) {
                    setData(resp.data);
                    setLoader(false)
                }
            })
    }, [location]);

    const handleClick = (e) => {
        history.push(`/tournaments/${e.data._id}`)
    }

    const createCustomItem = (logo, name) => (
        <div className="clubs-list__table-item">
            {logo ? <img src={logo} /> : <img src={defaultLogo} />}
            <span>{name}</span>
        </div>
    );

    return loader ? (
        <ProgressSpinner style={{width: '40px', height: '40px'}} strokeWidth="4" fill="var(--surface-ground)" animationDuration=".5s" className='loader'/>
    ) : (
        <div className='tournamentsV2-list'>
            <DataTable
                onClick={handleClick}
                search
                noCreateBtn
                columns={[
                    { field: "name", header: "Название" }
                ]}
                rows={data.map((row) => ({
                    ...row,
                    name: { custom: createCustomItem(row?.emblem, row?.name), value: row?.name },
                }))}
                paginator={data.length > 8}
                perPage={8}
            />
        </div>
    )
}

export default TournamentsV2List;